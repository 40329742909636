import React, { useEffect, useRef } from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Button from 'react-bootstrap/Button'
import { useReactToPrint } from 'react-to-print'
import { generateBarcode, getSampleDetails, shippedSample } from '../../services/Sample'
import { useParams } from 'react-router-dom'
import { AppUtil } from '../../utils/app.utils'
import { Col, Row, Stack } from 'react-bootstrap'
import { Sample } from '../../models/Sample'
import { ViewSampleDetails } from '../ViewPatient/components/ViewSampleDetails'
import { trackPromise } from 'react-promise-tracker'
import '../ViewPatient/components/styles/ViewSamples.scss'
import { SampleStatusMasterData } from '../SampleInformationForm/helpers'
import { PRIVILEGES } from '../../utils/constants'
import { AuthUtil } from '../../utils/auth.utils'
import QRCode from 'react-qr-code'
import './SampleBarcodeGenerate.scss'
import {jsPDF} from 'jspdf';

interface BarcodeInfoProps {
  show: boolean,
  label1: string[],
  label2: string[],
  barcode: string
}

const shippedOrReportReady = [
  SampleStatusMasterData.sampleStatusValueMap.shipped,
  SampleStatusMasterData.sampleStatusValueMap.report_ready,
];

const readyToShip = [
  SampleStatusMasterData.sampleStatusValueMap.ready_for_shipment
]

export const BarcodeGenerate = (props: any) => {
  const { buttonClass, setValue, getValues } = props
 
  const [barcodeInfo, setBarcodeInfo] = useState<BarcodeInfoProps | null>(null)
  const { id, patientId } = useParams()
  const contentToPrint = useRef(null)
  const tanslateDM = 'sampleBarcode'
  const viewSampleDetialsParentClass = 'view-samples'
  const parentClass = 'vcr-pp-sample-info-'
  const { t } = useTranslation()
  const [sampleLists, setSampleLists] = useState<Sample[]>([])

  useEffect(() => {
    if (!getValues('sampleStatus')) {
      setBarcodeInfo({ show: false, label1: [], label2: [], barcode: '' })
    }
  }, [getValues('sampleStatus')])

  const isReadyToShip =
    readyToShip.includes(getValues('sampleStatus'))

  const [isShippedOrReportReady, setIsShippedOrReportReady] = useState<boolean>(
    shippedOrReportReady.includes(getValues('sampleStatus')))

  const hasEditAfterShippmentPrivilege = AuthUtil.checkUserAccess(PRIVILEGES.EDIT_AFTER_SHIPMENT)

  const fetchSampleLists = async () => {
    try {
      const sampleListsData = await trackPromise(getSampleDetails(id ?? '', patientId ?? ''))
      if (sampleListsData.status === 200) {
        setValue('sampleStatus', sampleListsData.data.sampleStatus)        
        setSampleLists([{ ...sampleListsData.data }])
      }
    } catch (error: any) {
      AppUtil.logError(error)
    }
  }

  const handleShow = async () => {
    try {
      const res = await generateBarcode(id ?? '')
      const { deidentifiedData, testDetails, sampleStatus, blockLable } = res.data
      setIsShippedOrReportReady(
        shippedOrReportReady.includes(sampleStatus),
      )
      if (!shippedOrReportReady.includes(sampleStatus)) {
        setValue('sampleStatus', sampleStatus)
      }

      setBarcodeInfo({ show: true, label1: testDetails, label2: blockLable, barcode: deidentifiedData })
      //setValue('barcode', deidentifiedData)
    } catch (error: any) {
      AppUtil.logError(error)
    }
  }

  useEffect(() => {
    if (isReadyToShip || isShippedOrReportReady) {
      handleShow()
      if (isShippedOrReportReady) {
        fetchSampleLists()
      }
    }
  }, [])

  async function printQRCode() {
    const res = await generateBarcode(id ?? "");
    if (res && res.data) {
      const doc = new jsPDF({
        orientation: "landscape",
        unit: "mm",
        format: [21, 7],
      });
      const data = res.data.deidentifiedData;
      const qrCodeUrl: any = `https://api.qrserver.com/v1/create-qr-code/?size=1000x1000&data=${data}`;
      const img: any = await loadImage(qrCodeUrl);
      const qrCodeWidth = 5;
      const qrCodeHeight = 5;
      // Add QR Code to the first page
      doc.addImage(img, "PNG", 1, 2, qrCodeWidth, qrCodeHeight);
      doc.setFontSize(6);
      const text = doc.splitTextToSize(res.data.testDetails, 12);
      const lineHeight = 1.2;
      const totalTextHeight = text.length * lineHeight;
      const startY = (7 - totalTextHeight) / 2;
      doc.text(text, 8, startY + 1.5);
      // Add additional pages if needed
      if (res.data.blockLable.length !== 0) {
        for (let i = 0; i < res.data.blockLable.length; i++) {
          doc.addPage();
          doc.addImage(img, "PNG", 1, 2, qrCodeWidth, qrCodeHeight);
          const blockText = doc.splitTextToSize(res.data.blockLable[i], 12);
          const blockTotalTextHeight = blockText.length * lineHeight;
          const blockStartY = (7 - blockTotalTextHeight) / 2;
          doc.text(blockText, 8, blockStartY + 1.5);
        }
      }
      // Generate the PDF Blob
      const pdfBlob = doc.output("blob");
      // Create a temporary window and trigger the print dialog
      const printWindow: any = window.open('', '', 'width=800,height=600');
      printWindow.document.write('<html><head><title>Print</title></head><body>');
      printWindow.document.write('<embed width="100%" height="100%" src="' + URL.createObjectURL(pdfBlob) + '" type="application/pdf">');
      printWindow.document.write('</body></html>');
      // When the document is ready, call the print dialog
      printWindow.document.close();
      printWindow.onload = function () {
        printWindow.print();
        printWindow.onafterprint = function () {
          printWindow.close(); // Close the print window after printing
        };
      };
    }
  }

  function loadImage(url: any) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = "anonymous";
      img.onload = () => resolve(img);
      img.onerror = (err) => reject(err);
      img.src = url;
    });
  }

  const handleShipped = async () => {
    try {
      const isShipped = await trackPromise(shippedSample(id ?? ''))
      if (isShipped.status === 200) {
        setValue('sampleStatus', isShipped.data.sampleStatus)
        setIsShippedOrReportReady(
          shippedOrReportReady.includes(isShipped.data.sampleStatus),
        )
        fetchSampleLists()
      }
    } catch (error: any) {
      AppUtil.logError(error)
    }
  }

  return (
    <>
      {!barcodeInfo?.show && (
        <Button className={buttonClass} variant="primary" onClick={handleShow}>
          {t(`${tanslateDM}.createBarcode`)}
        </Button>
      )}

      {barcodeInfo?.show && (
        <Stack gap={3}>
          <div ref={contentToPrint}>
            <Row>
              <Col lg={3}>
                <h5 className={'mb-4'}>{t(`${tanslateDM}.clinicalSampleBarcode`)}</h5>
                <div className={`${parentClass}qrInfo`}>
                  <QRCode
                    className={`${parentClass}qrcode`}
                    value={barcodeInfo?.barcode}
                    viewBox={`0 0 25 25`}
                  />
                  <div className={`${parentClass}qrcode-label`}>{barcodeInfo?.label1}</div>
                </div>
              </Col>
            </Row>
            <div>
            <Row>
              {barcodeInfo?.label2?.length > 0 && (<h5>{t(`${tanslateDM}.biorepositorySamplesBarcode`)}</h5>)
              }
              {barcodeInfo?.label2?.map((info, index) => (
                <Col lg={3} key={index} className={`${parentClass}qrInfo`}>                
                  <QRCode
                    className={`${parentClass}qrcode`}
                    value={barcodeInfo.barcode}
                    viewBox={`0 0 25 25`}
                  />
                  <div className={`${parentClass}qrcode-label`}>{info}</div>
                </Col>
              ))
              }
            </Row>
            </div>
          </div>
          <Row>
            <Col>
              <Button className={`${parentClass}save-create-barcode mx-0`} onClick={() => printQRCode()}>
                {t(`${tanslateDM}.printBarcode`)}
              </Button>

              {!isShippedOrReportReady && !hasEditAfterShippmentPrivilege && (
                <Button className={`${parentClass}save-create-barcode`} onClick={() => handleShipped()}>
                  {t(`${tanslateDM}.shipSample`)}
                </Button>
              )}
            </Col>
          </Row>
          <br />
          {sampleLists.map((sample, index) => (
            <div key={index}>
              <div className={`${viewSampleDetialsParentClass}`}>
                <h5>{t(`${tanslateDM}.shippedBarcodeDetails`)}</h5>
                <ViewSampleDetails patientID={patientId ?? ''} sample={sample} index={index} />
              </div>
            </div>
          ))}
        </Stack>
      )}
    </>
  )
}
