import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import translationEn from './translation/en/translation.json'

const i18nOptions = {
  debug: true,
  resources: {
    // fr: translationFr,
    en: translationEn
  },

  /* When react i18next not finding any language to as default in borwser */
  fallbackLng: 'en',

  // have a translation namespace used around the full app
  ns: ['translation'],

  // default namespace used if not passed to translation function
  defaultNS: 'translation',

  interpolation: {
    // escapes passed in values to avoid XSS(Cross Site Scripting) injection
    escapeValue: false // react already safes from xss
  },

  // locale will be fully lowercased eg. en-US --> en-us
  lowerCaseLng: true,

  // react-i18next options
  react: {
    useSuspense: true,
  },

  // to detect language from navigator
  detection: {
    /* order and from where user language should be detected
      order: ['navigator', 'querystring', 'cookie', 'localStorage', 'htmlTag'], */
    order: ['navigator'],

    /*  cache user language on
        caches: ['localStorage', 'cookie'], */
    caches: []
  }
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init(i18nOptions);

export default i18n;
