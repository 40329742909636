export const PP_API_URL = process.env.REACT_APP_PP_API_HOST
export const PP_API_TIMEOUT = process.env.REACT_APP_PP_API_TIMEOUT ?? '5000'
export const PP_AUTH_COOKIE_NAME = 'patient_portal_auth_token'
export const PP_REFRESH_COOKIE_NAME = 'patient_portal_refresh_token'
export const PP_LOGGED_IN_USER_DETAIL = 'loggedInUserDetail'
export const APP_DATE_FORMAT = 'DD-MM-YYYY'
export const APP_DATE_FORMAT_WITH_TIMESTAMP = 'DD-MM-YYYY HH:mm:ss'
export const DATA_SOURCE = 'PP'

export const DEFAULT_SESSION_TIMEOUT = 1800 //in seconds

export const HTTP_STATUS_CODE = {
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  INTERNAL_SERVER_ERROR: 500,
  SERVICE_UNAVAILABLE: 503,
  REQUEST_TIMEOUT: 408,
}

export const PRIVILEGES = {
  CREATE_USER: 'CREATE_USER',
  ASSIGN_ROLE: 'ASSIGN_ROLE',
  EDIT_USER: 'EDIT_USER',
  VIEW_USER_LIST: 'VIEW_USER_LIST',
  LOCK_UNLOCK_USER: 'LOCK_UNLOCK_USER',
  CREATE_PATIENT: 'CREATE_PATIENT',
  EDIT_PATIENT: 'EDIT_PATIENT',
  EDIT_BEFORE_SHIPMENT: 'EDIT_BEFORE_SHIPMENT',
  EDIT_AFTER_SHIPMENT: 'EDIT_AFTER_SHIPMENT',
  PATIENT_CONSENT: 'PATIENT_CONSENT',
  CHECK_PATHOGEN_FREE_REPORT: 'CHECK_PATHOGEN_FREE_REPORT',
  VIEW_PATIENT: 'VIEW_PATIENT',
  SEARCH_PATIENT: 'SEARCH_PATIENT',
  ADD_SAMPLE: 'ADD_SAMPLE',
  EDIT_SAMPLE: 'EDIT_SAMPLE',
  VIEW_SAMPLE: 'VIEW_SAMPLE',
  CHECK_FIRST_LEVEL_QC: 'CHECK_FIRST_LEVEL_QC',
  PRINT_BARCODE_WITH_SAMPLE_LABEL: 'PRINT_BARCODE_WITH_SAMPLE_LABEL',
  SHIP_CLINICAL_SAMPLE: 'SHIP_CLINICAL_SAMPLE',
  VIEW_QIAGEN_REPORT: 'VIEW_QIAGEN_REPORT',
  DOWNLOAD_QIAGEN_REPORT: 'DOWNLOAD_QIAGEN_REPORT',
  E_SIGN_REPORT: 'E_SIGN_REPORT',
  CREATE_NOTE: 'CREATE_NOTE',
  EDIT_NOTE: 'EDIT_NOTE',
  VIEW_NOTE: 'VIEW_NOTE'
}

export const MASTER_DATA_KEYS = {
  GENDER: 'Gender',
  STATE: 'State',
  CITIES: 'Cities',
  RACE: 'Race',
  ETHNICITY: 'Ethnicity',
  NATIONALITY: 'Nationality',
  STATUS: 'Status',
  TEST_TYPE: 'PrimaryTag',
  SAMPLE_TYPE: 'SampleType',
  SMOKING_STATUS: 'SmokingStatus',
  ALCOHOL_USE: 'AlcoholUse',
  PRE_MALIGNANT_COMORBID_ILLNESS: 'PreMalignantComorbidIllness',
  ORGAN_TRANSPLANT_SITE: 'OrganTransplantSite',
  RELATION_TO_PATIENT: 'RelationToPatient',
  FAMILY_MEMBER_CANCER_SITE: 'FamilyMemberCancerSite',
  AGE_AT_DIAGNOSIS: 'FamilyMemberAgeAtDiagnosis',
  DIAGNOSIS_PRIMARY_SITE: 'DiagnosisPrimarySite',
  LATERALITY: 'Laterality',
  HISTOLOGY: 'Histology',
  OTHER_STAGING_SYSTEM_GRADE_DATA: 'OtherStagingSystemGradeData',
  CLINICAL_T: 'ClinicalT',
  CLINICAL_N: 'ClinicalN',
  CLINICAL_M: 'ClinicalM',
  CLINICAL_GROUP_STAGE: 'ClinicalGroupStage',
  PATHOLOGICAL_T: 'PathologicalT',
  PATHOLOGICAL_N: 'PathologicalN',
  PATHOLOGICAL_M: 'PathologicalM',
  PATHOLOGICAL_GROUP_STAGE: 'PathologicalGroupStage',
  PERFORMANCE_STATUS_AT_DIAGNOSIS: 'PerformanceStatusAtDiagnosis',
  TYPE_OF_PERFORMANCE_STATUS_SCALE_FOR_DIAGNOSIS: 'TypeOfPerformanceStatusScaleForDiagnosis',
  DIAGNOSTIC_STAGE: 'DiagnosticStage',
  TREATMENT_STATUS: 'TreatmentStatus',
  ORIGINAL_PRIMARY_SITE_OF_DIAGNOSIS: 'OriginalPrimarySiteOfDiagnosis',
  METASTASIS_SITE: 'MetastasisSite',
  IMAGING_SCAN_TYPE: 'ImagingScanType',
  IMAGING_MODALITY_METHOD: 'ImagingModalityMethod',
  IMAGING_SCAN_SITE: 'ImagingScanSite',
  MEASURABLE_DISEASE_PRESENT: 'MeasurableDiseasePresent',
  NEW_LESIONS: 'NewLesions',
  SITE: 'Site',
  BIOMARKER_TEST: 'BiomarkerTest',
  MARKER_TYPE: 'MarkerType',
  UNITS_OF_MEASURE_FOR_MARKER: 'UnitsOfMeasureForMarker',
  LAB_TYPE: 'LabType',
  RESULTS: 'Results',
  HIGH_AND_LOW_REF: 'HighAndLowRef',
  UNITS_OF_MEASURE: 'UnitsOfMeasure',
  TEST_TYPE_DELETIONS_TRANSLOCATIONS: 'TestTypeDeletionsTranslocations',
  TUMOR_SEQUENCING_TEST_TYPE: 'TumorSequencingTestType',
  DIAGNOSIS_PRIMARY_SITE_FOR_SURGERY: 'DiagnosisPrimarySiteForSurgery/Biopsy',
  SURGERY_BIOPSY_DONE:'SurgeryBiopsyDone',
  SURGERY_BIOPSY_LOCATION: 'SurgeryBiopsyLocation',
  SURGERY_TYPE: 'surgeryType',
  BIOPSY_TYPE: 'biopsyType',
  ANETHESIA_TYPE: 'anesthesiaType',
  SURGICAL_APPROACH: 'surgicalApproach',
  MEDICATION_NAME: 'MedicationName',
  DISEASE: 'Disease',
  REGIMEN: 'Regimen',
  SCT_TYPE: 'stemCellTransplantType',
  CELL_SOURCE: 'sourceOfStemCells',
  DIAGNOSIS_PRIMARY_SITE_FOR_RADIATION_THERAPY: 'DiagnosisPrimarySiteForRadiationTherapy',
  RADIATION_DISEASE: 'RadiationDisease',
  RADIATION_REGIMEN: 'RadiationRegimen',
  RADIATION_MODALITY: 'RadiationModality',
  RADIATION_DOSE: 'RadiationDose',
  PROGRESSION_SOURCE: 'ProgressionSource',
  RECURRENCE_TYPE: 'RecurrenceType',
  RECURRENCE_PERFORMANCE_STATUS: 'RecurrencePerformanceStatus',
  TYPE_OF_STATUS_SCALE_USED_RECIST_CHEMOTHERAPY: 'RECISTChemotherapy',
  TYPE_OF_STATUS_SCALE_USED_IRRECIST_IMMUNOTHERAPY: 'IR-RECISTImmunotherapy',
  RESPONSE_BY_DISEASE_GROUP: 'DiseaseGroup',
  RESPONSE_BY_BASELINE_DISEASE_STATUS: 'BaselineDiseaseStatus',
  RESPONSE_BY_RESPONSE_ASSESSMENT: 'ResponseAssessment',
  CURRENT_DISEASE_STATUS: 'RecistScoreDetails',
  ROLES: 'Roles',
  FIXATION_TIME_FOR_TISSUE_BLOCK: 'FixationTimeForTissueBlock',
  H_E_STAINING: 'hAndeStaining',
  ORGAN_TRANSPLANT: 'OrganTransplant',
  FFPEBLOCKS_AVAILABLE: 'FFPEBlocksAvailable',
  HISTOPATHOLOGY_REPORT:'HistopathologyReportDone',
  WHETHER_ON_CHEMOTHERAPY: 'WhetherOnChemotherapy',
  NUMBER_OF_CYCLES: 'NumberOfCycles',
  OTHER_DRUGS_PRESCRIBED: 'otherDrugsPrescribed',
  BLOOD_TRANSFUSION:'BloodTransfusionDone',
  BLOOD_TRANSFUSION_HISTORY: 'BloodTransfusionHistory',
  BONE_MARROW_TRANSPLANT:'BoneMarrowTransplantDone',
  BONE_MARROW_TRANSPLANT_HISTORY: 'BoneMarrowTransplantHistory',
  RECENTLY_TESTED_INFECTIOUS_DISEASE: 'RecentlyTestedInfectiousDisease',
  TEST_ID: 'TestId',
  CYTOGENETICS_TESTING_PERFORMANCE: 'CytogeneticsTestingPerformance',
  FAMILY_HISTORY: 'FamilyHistoryOfCancer',
  RADIATION_THERAPY: 'RadiationTherapy',
  CELLULAR_THERAPY: 'CellularTherapy',
  USER_STATE: 'UserState',
  ORGAN_TRANSPLANT_DONE:'OrganTransplantDone',
  NOTE_TAG: 'NoteTag',
  PROGRESSION_RECURRENCE_DONE: 'ProgressionRecurrenceDone'
}

export const MASTER_DATA_ID = {
  GENDER_OTHER: '5',
  RACE_OTHER: '7',
  ETHNICITY_OTHER: '7',
  RELATION_TO_PATIENT_OTHER: '9',
  FAMILY_HISTORY_OF_CANCER_OPTION_YES: '1',
  FAMILY_MEMBER_CANCER_SITE_OTHER: '16',
  ORGAN_TRANSPLANT_SITE_OTHER: '10',
  PRE_MALIGNANT_COMORBID_ILLNESS_OTHER: '16',
  DIAGNOSIS_PRIMARY_SITE_OTHER: '16',
  IMAGING_SCAN_SITE_OTHER: '7',
  OTHER_STAGING_SYSTEM_GRADE_DATA_OTHER: '12',
  SITE_OTHER: '9',
  DIAGNOSIS_PRIMARY_SITE_FOR_SURGERY_OTHER: '21',
  CLINICAL_GROUP_STAGE_OTHER: '5',
  PATHOLOGICAL_GROUP_STAGE_OTHER: '5',
  IMAGING_TYPE_OF_SCAN_NONE: '30',
  TYPE_OF_TUMOR_SEQUENCING_DETAILS: '2',
  SURGERY_BIOPSY_DONE_OPTION_YES:'1',
  SURGERY_BIOPSY_LOCATION_OTHER: '8',
  SAMPLE_TYPE_BLOOD: ['21','22'],
  SAMPLE_TYPE_FFPE: '11',
  H_E_OPTION_YES:'1', 
  ORGAN_TRANSPLANTATION_DONE_OPTION_YES: '1',
  CYTOGENETICS_TESTING_PERFORMANCE_OPTION_YES: '1',
  HISTOPATHOLOGY_REPORT_OPTION_YES:'1',
  WHETHER_ON_CHEMOTHERAPY_OPTION_YES: '1',
  OTHER_DRUGS_PRESCRIBED_OPTION_YES: '1',
  BLOOD_TRANSFUSION_OPTION_YES:'1',
  BONE_MARROW_TRANSPLANT_OPTION_YES:'1',
  CELLULAR_THERAPY_OPTION_YES: '1',
  RECENTLY_TESTED_INFECTIOUS_DISEASE_OPTION_YES: '1',
  RADIATION_THERAPY_OPTION_YES: '1',
  CRC_ROLE: '3', 
  METASTASIS_SITE_OTHER_ORGAN: '8',
  NOTE_TAG_OTHER: '3',
  METASTASIS_SITE_NOT_APPLICABLE: '9',
  BIOMARKER_TEST_VALUE:'0',
  WBC_VALUE:'1',
  PROGRESSION_RECURRENCE_OPTION_YES: '1'
}

// we remove this constant once proper functionality is implemented for report ready
// export const SYNOPSIS_REPORT_PDF_URL = process.env.PUBLIC_URL + '/pdf/SynopsisReportSignFields.pdf';
export const SYNOPSIS_REPORT_PDF_URL = process.env.PUBLIC_URL + '/pdf/references_withform.pdf';
export const SUMMARY_REPORT_PDF_URL = process.env.PUBLIC_URL + '/pdf/overall_summary.pdf';
export const CTI_REPORT_PDF_URL = process.env.PUBLIC_URL + '/pdf/clinical_trials_information.pdf';
export const MMPD_REPORT_PDF_URL = process.env.PUBLIC_URL + '/pdf/material_methods_panel_details.pdf';
export const REFERENCE_REPORT_PDF_URL = process.env.PUBLIC_URL + '/pdf/references.pdf';
