import React, { useContext } from 'react'
import Container from 'react-bootstrap/Container'
import { useTranslation } from 'react-i18next'
import { TabProps } from '../../../models/Sample'
import { ViewSampleDetailsRow } from './ViewSampleDetailsRow'
import '../ViewSampleContainer.scss'
import { AppUtil } from '../../../utils/app.utils'
import { MASTER_DATA_KEYS as MDK } from '../../../utils/constants'
import { MasterDataContext } from '../../../contexts'
import _ from 'lodash'

export const CancerDiagnosis = (props: TabProps) => {
  const { sampleDetails } = props
  const { t } = useTranslation()
  const { masterData } = useContext(MasterDataContext)
  const translatn = 'viewSample.'
  const parentClass = ''

  const detailsArr = [
    {
      label: t(`${translatn}dateOfFirstContact`),
      value: sampleDetails?.dateOfFirstContact,
      show: true,
    },
    {
      label: t(`${translatn}ageAtFirstContact`),
      value: sampleDetails?.ageAtFirstContact,
      show: true,
    },
    {
      label: t(`${translatn}primarySiteOfDiagnosis`),
      value: AppUtil.getFormattedValueWithOther(
        MDK.DIAGNOSIS_PRIMARY_SITE,
        masterData,
        sampleDetails?.primarySiteOfDiagnosis,
        sampleDetails?.primarySiteOfDiagnosisOther,
      ),
      show: true,
    },
    {
      label: t(`${translatn}laterality`),
      value: AppUtil.getLabel(MDK.LATERALITY, masterData, sampleDetails?.laterality),
      show: true,
    },
    {
      label: t(`${translatn}treatmentStatus`),
      value: sampleDetails?.treatmentStatus,
      show: true,
    },
    {
      label: t(`${translatn}histology`),
      value: AppUtil.getLabel(MDK.HISTOLOGY, masterData, sampleDetails?.histology),
      show: true,
    },
    {
      label: t(`${translatn}physicianComment`),
      value: sampleDetails?.physicianComment,
      show: sampleDetails?.histology ? true : false,
    },
    {
      label: t(`${translatn}otherStagingSystemAndGradeData`),
      value: AppUtil.getFormattedValueWithOther(
        MDK.OTHER_STAGING_SYSTEM_GRADE_DATA,
        masterData,
        sampleDetails?.otherStagingSystemAndGradeData,
        sampleDetails?.otherStagingSystemAndGradeDataOther,
      ),
      show: true,
    },
    {
      label: t(`${translatn}otherStagingGradeOrScoreDetails`),
      value: sampleDetails?.otherStagingGradeOrScoreDetails,
      show: true,
    },
    {
      label: t(`${translatn}dateOfDiagnosis`),
      value: sampleDetails?.dateOfDiagnosis,
      show: true,
    },
    {
      label: t(`${translatn}ageAtDiagnosis`),
      value: sampleDetails?.ageAtDiagnosis,
      show: true,
    },
    {
      label: t(`${translatn}clinicalT`),
      value: AppUtil.getLabel(MDK.CLINICAL_T, masterData, sampleDetails?.clinicalT),
      show: true,
    },
    {
      label: t(`${translatn}clinicalN`),
      value: AppUtil.getLabel(MDK.CLINICAL_N, masterData, sampleDetails?.clinicalN),
      show: true,
    },
    {
      label: t(`${translatn}clinicalM`),
      value: AppUtil.getLabel(MDK.CLINICAL_M, masterData, sampleDetails?.clinicalM),
      show: true,
    },
    {
      label: t(`${translatn}clinicalGroupStage`),
      value: AppUtil.getFormattedValueWithOther(
        MDK.CLINICAL_GROUP_STAGE,
        masterData,
        sampleDetails?.clinicalGroupStage,
        sampleDetails?.clinicalGroupStageOther,
      ),
      show: true,
    },
    {
      label: t(`${translatn}pathologicalT`),
      value: AppUtil.getLabel(MDK.PATHOLOGICAL_T, masterData, sampleDetails?.pathologicalT),
      show: true,
    },
    {
      label: t(`${translatn}pathologicalN`),
      value: AppUtil.getLabel(MDK.PATHOLOGICAL_N, masterData, sampleDetails?.pathologicalN),
      show: true,
    },
    {
      label: t(`${translatn}pathologicalM`),
      value: AppUtil.getLabel(MDK.PATHOLOGICAL_M, masterData, sampleDetails?.pathologicalM),
      show: true,
    },
    {
      label: t(`${translatn}pathologicalGroupStage`),
      value: AppUtil.getFormattedValueWithOther(
        MDK.PATHOLOGICAL_GROUP_STAGE,
        masterData,
        sampleDetails?.pathologicalGroupStage,
        sampleDetails?.pathologicalGroupStageOther,
      ),
      show: true,
    },
    {
      label: t(`${translatn}performanceStatusOfDiagnosis`),
      value: AppUtil.getLabel(
        MDK.PERFORMANCE_STATUS_AT_DIAGNOSIS,
        masterData,
        sampleDetails?.performanceStatusAtDiagnosis,
      ),
      show: true,
    },
    {
      label: t(`${translatn}typeOfPerformanceStatusScaleUsedAtDiagnosis`),
      value: AppUtil.getLabel(
        MDK.TYPE_OF_PERFORMANCE_STATUS_SCALE_FOR_DIAGNOSIS,
        masterData,
        sampleDetails?.typeOfPerformanceStatusScaleUsedAtDiagnosis,
      ),
      show: true,
    },
    {
      label: t(`${translatn}dateOfDiagnosticPerformanceStatus`),
      value: sampleDetails?.dateOfDiagnosticPerformanceStatus,
      show: true,
    },
    {
      label: t(`${translatn}ageAtDiagnosisPerformanceStatus`),
      value: sampleDetails?.ageAtDiagnosticPerformanceStatus,
      show: true,
    },
  ]

  const data = detailsArr.filter((details) => details.show === true)

  const rowCount = _.chunk(data, 5)

  return (
    <Container fluid>
      <h5 className="fw-bold pt-3"> {t(`${translatn}cancerDiagnosis`)}</h5>
      {rowCount.map((row, index) => (
        <ViewSampleDetailsRow key={index} index={index} rowArr={rowCount[index]} parentClass={parentClass} />
      ))}
    </Container>
  )
}
