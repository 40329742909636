import React, { useContext, useEffect, useState } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import { useForm, FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { AppUtil } from '../../utils/app.utils'
import { TextInput } from '../InputComponents'
import { changePassword } from '../../services'
import { ChangePasswordFormSchema, ChangePasswordDefaultData } from './helpers'
import './ChangePassword.scss'
import { AuthContext } from '../../contexts'
import { AuthContextProps } from '../../contexts/AuthContext'

type PasswordChangeModalProps = {
  show: boolean
  isCancelDisabled: boolean
  handleClose: () => void
}

type ValidationSchema = z.infer<typeof ChangePasswordFormSchema>

function changePasswordSubmit(
  data: ValidationSchema,
  passwordMismatchErr: string,
  setChangePasswordError: any,
  handleClose: any,
  auth: AuthContextProps,
) {
  if (data.confirmPassword !== data.newPassword) {
    setChangePasswordError(passwordMismatchErr)
    return
  }
  setChangePasswordError('')
  const response = changePassword(data.oldPassword, data.newPassword)
    .then(
      (apiResponse) => {
        if (apiResponse.status == 200) {
          handleClose()
          auth.updateChangePasswordFlag(false)
          return { status: true, message: apiResponse?.data?.message || '' }
        }
      },
      (error) => {
        AppUtil.logError(error)
        setChangePasswordError(error?.response?.data?.message || '')
        return { status: false, message: error?.response?.data?.message || '' }
      },
    )
    .catch((error) => {
      AppUtil.logError(error)
      setChangePasswordError(error?.response?.data?.message || '')
      return { status: false, message: error?.response?.data?.message || '' }
    })
  return response
}

export function PasswordChangeModal(props: Readonly<PasswordChangeModalProps>) {
  const { show, isCancelDisabled, handleClose } = props
  const translatn = 'changePassword'
  const parentClass = 'vcr-pp-usr-password-change'
  const { t } = useTranslation()
  const [changePasswordError, setChangePasswordError] = useState('')
  const auth = useContext(AuthContext)

  const formMethods = useForm<ValidationSchema>({
    resolver: zodResolver(ChangePasswordFormSchema),
    mode: 'all',
    defaultValues: ChangePasswordDefaultData,
  })

  const { handleSubmit, reset } = formMethods

  useEffect(() => {
    reset(ChangePasswordDefaultData)
  }, [show])

  const changePasswordFromSubmit = function (data: ValidationSchema) {
      changePasswordSubmit(data,t(`${translatn}.errors.confirmPasswordMatchCheck`),setChangePasswordError,
    handleClose,auth)
  }

  const cancelAndHandleClose = function () {
    if (isCancelDisabled) return
    handleClose()
  }

  return (
    <FormProvider {...formMethods}>
      <Modal show={show} onHide={cancelAndHandleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>{t(`${translatn}.changePassword`)}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate className={parentClass}>
            <TextInput
              type="password"
              name="oldPassword"
              id="change-password-old-password"
              title={t(`${translatn}.oldPassword`)}
              placeholder={t(`${translatn}.oldPasswordPlaceholder`)}
              disabled={false}
              readOnly={false}
              required={true}
              className={`${parentClass}-text-input`}
              onPaste={(e: any) => e.preventDefault()}
            />
            <TextInput
              type="password"
              name="newPassword"
              id="change-password-new-password"
              title={t(`${translatn}.newPassword`)}
              placeholder={t(`${translatn}.newPasswordPlaceholder`)}
              disabled={false}
              readOnly={false}
              required={true}
              onChange={() => setChangePasswordError('')}
              className={`${parentClass}-text-input`}
              onPaste={(e: any) => e.preventDefault()}
            />
            <TextInput
              type="password"
              name="confirmPassword"
              id="change-password-confirm-new-password"
              title={t(`${translatn}.confirmPassword`)}
              placeholder={t(`${translatn}.confirmPasswordPlaceholder`)}
              disabled={false}
              readOnly={false}
              required={true}
              onChange={() => setChangePasswordError('')}
              className={`${parentClass}-text-input`}
              onPaste={(e: any) => e.preventDefault()}
            />
            <div className={parentClass + '-invalid-password-message'}>{changePasswordError}</div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          {!isCancelDisabled && (
            <Button variant="secondary" onClick={handleClose}>
              {t(`${translatn}.cancel`)}
            </Button>
          )}
          <Button variant="primary" onClick={handleSubmit(changePasswordFromSubmit)}>
            {t(`${translatn}.changePassword`)}
          </Button>
        </Modal.Footer>
      </Modal>
    </FormProvider>
  )
}
