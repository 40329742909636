import React from 'react'
import Container from 'react-bootstrap/Container'
import { useTranslation } from 'react-i18next'
import { TabProps } from '../../../models/Sample'
import { ViewSampleDetailsRow } from './ViewSampleDetailsRow'

export const PhysicalAssessment = (props: TabProps) => {
  const { sampleDetails } = props
  const { t } = useTranslation()

  const translatn = 'viewSample.'

  const parentClass = ''
  const detailsArr = [
    {
      label: t(`${translatn}dateOfPhysical`),
      value: sampleDetails?.dateOfPhysical,
      row: 1,
    },
    {
      label: t(`${translatn}ageAtPhysical`),
      value: sampleDetails?.ageAtPhysical,
      row: 1,
    },
    {
      label: t(`${translatn}weight`),
      value: sampleDetails?.weight,
      row: 1,
    },
    {
      label: t(`${translatn}height`),
      value: sampleDetails?.height,
      row: 1,
    },
    {
      label: t(`${translatn}bmi`),
      value: sampleDetails?.bmi,
      row: 1,
    },
  ]

  const rowCount = [1]
  return (
    <Container fluid>
      <h5 className="fw-bold "> {t(`${translatn}physicalAssessment`)}</h5>
      {rowCount.map((row, index) => (
        <ViewSampleDetailsRow
          key={row}
          index={index}
          rowArr={detailsArr.filter((details) => details.row == row)}
          parentClass={parentClass}
        />
      ))}
    </Container>
  )
}
