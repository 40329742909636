import React, { useEffect } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { Multiselect } from 'multiselect-react-dropdown'
import Form from 'react-bootstrap/Form'
import './styles/MultiSelectDropdown.scss'
import { access, renderRequiredFieldLabel } from './InputComponentHelpers'
import { useTranslation } from 'react-i18next'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import { InfoCircle } from 'react-bootstrap-icons'

type MultSelectItem = {
  label: string
  value: string | number
}

type MultiSelectType = {
  name: string
  id: string
  title: string
  placeholder?: string
  defaultValue?: string
  optionsValueArray: MultSelectItem[]
  disabled: boolean
  required: boolean
  hideSelectedValues?: boolean
  onCustomRemove?: Function
  tooltipText?: string
  onCustomChange?: Function
}

export const MultiSelectDropdown = (props: MultiSelectType) => {
  const {
    name,
    id,
    title,
    placeholder,
    optionsValueArray,
    disabled,
    required,
    hideSelectedValues = false,
    onCustomRemove,
    tooltipText,  
    onCustomChange
  } = props
  const formMethods = useFormContext()
  const {
    register,
    formState: { errors },
  } = formMethods
  const error = access(name, errors)
  const { t } = useTranslation()

  useEffect(() => {
    if (document.getElementById(name)?.querySelector('.searchWrapper')) {
      document.getElementById(name)?.querySelector('.searchWrapper')?.classList.add('form-select')
    }
  }, [])

  useEffect(() => {
    if (document.getElementById(name)?.querySelector('.searchWrapper')) {
      if (error) {
        document.getElementById(name)?.querySelector('.searchWrapper')?.classList.add('is-invalid')
      } else {
        document.getElementById(name)?.querySelector('.searchWrapper')?.classList.remove('is-invalid')
      }
    }
  }, [error])

  const label = () => {
    return <Form.Label htmlFor={id}>{required ? renderRequiredFieldLabel(title) : title}</Form.Label>
  }

  return (
    <div className="multi-select-dropdown" id={name}>
      <Form.Group>
        {tooltipText ? (
          <OverlayTrigger
            trigger={'hover'}
            placement={'right'}
            overlay={<Tooltip id={`tooltip-right}`}>{tooltipText}</Tooltip>}
          >
            <span>
            {label()}
            <InfoCircle className='mx-2'/>
            </span>
          </OverlayTrigger>
        ) : (
          label()
        )}
        <Controller
          name={name}
          render={({ field: { value, onChange } }) => {
            return (
              <Multiselect
                options={optionsValueArray}
                isObject={true}
                showCheckbox={true}
                hidePlaceholder={true}
                closeOnSelect={false}
                avoidHighlightFirstOption={true}
                onSelect={(selectedList,selectedItem)=>{
                  onChange(selectedList)
                  if (onCustomChange) {
                    onCustomChange(selectedList,selectedItem)
                  }
                }}
                onRemove={(selectedList, removedItem) => {
                  onChange(selectedList)
                  if (onCustomRemove) {
                    onCustomRemove(removedItem?.value)
                  }
                }}
                selectedValues={value}
                placeholder={placeholder}
                hideSelectedList={hideSelectedValues}
                disable={disabled}
                displayValue="label"
                {...register(name)}
              />
            )
          }}
        />
        {error && <div className="invalid-multiselect">{t(error?.message)} </div>}
      </Form.Group>
    </div>
  )
}
