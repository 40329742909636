import React, { useEffect, useState, useContext } from 'react'
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { Button, Card, Form, Offcanvas } from 'react-bootstrap'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import _, { sample } from 'lodash'
import {
  StepOneSchema,
  StepFiveSchema,
  StepFourSchema,
  StepTwoSchema,
  StepSixSchema,
  StepSevenSchema,
  StepThreeSchema,
  AddSampleInfoDefaultData,
  formatRequestPayload,
  SampleStatusMasterData,
} from './helpers'
import { TabOne, TabThree, TabTwo, TabFour, TabFive, TabSix } from './components'
import './SampleInformationFormContainer.scss'
import { useTranslation } from 'react-i18next'
import { generatePath, useLocation, useNavigate, useParams } from 'react-router-dom'
import { appRoutes } from '../../utils/routes'
import { addSample, getPatientDetails } from '../../services'
import { trackPromise } from 'react-promise-tracker'
import { MultiSelectItem, Sample, SampleRequestPayload } from '../../models/Sample'
import { AxiosResponse } from 'axios'
import { AppUtil } from '../../utils/app.utils'
import { getSampleDetails, updateSample } from '../../services/Sample'
import { formatFormValues as formatFormValuesForNotes } from '../HandwrittenNotes/helpers/HandwrittenNotesHelpers'
import { formatFormValues, formatMultiselectPayload } from './helpers/AddSampleInfoHelpers'
import { Patient } from '../../models/RegisterPatient'
import { HTTP_STATUS_CODE, PRIVILEGES, MASTER_DATA_KEYS as MDK } from '../../utils/constants'
import Stepper from './components/Stepper'
import { AuthContext, getLoggedInUserDetail, MasterDataContext } from '../../contexts'
import { ArrowLeftShort, ArrowRightShort, List } from 'react-bootstrap-icons'
import { TabSeven } from './components/TabSeven'
import { AuthUtil } from '../../utils/auth.utils'
import { WarningDialog } from './components/WarningDialog'
import { getHandwrittenNotes, getHandwrittenNotesByTags } from '../../services/HandwrittenNotes'
import { MultiSelectDropdown } from '../InputComponents'
import Multiselect from 'multiselect-react-dropdown'
import { HandwrittenNotes, HandwrittenNotesRequestPayload } from '../../models/HandwrittenNotes'
import { useDebounce } from '../../hooks'

const translatn = 'sampleInfoForm.'
const parentClass = 'vcr-pp-sample-info-'
const barcodeTabNumber = 5

const tabs = [
  { tab: <TabOne key="tabone" />, schema: StepOneSchema },
  { tab: <TabTwo key="tabtwo" />, schema: StepTwoSchema },
  { tab: <TabThree key="tabthree" />, schema: StepThreeSchema },
  { tab: <TabFour key="tabfour" />, schema: StepFourSchema },
  { tab: <TabFive key="tabfive" />, schema: StepFiveSchema },
  { tab: <TabSix key="tabsix" />, schema: StepSixSchema },
  { tab: <TabSeven key="tabseven" />, schema: StepSevenSchema },
]

const getButtonText = (barcodeTabNumber: number, tabLength: number, selectedTab: number) => {
  let text = 'save'
  const hasEditAfterShippmentPrivilege = AuthUtil.checkUserAccess(PRIVILEGES.EDIT_AFTER_SHIPMENT)

  if (selectedTab == barcodeTabNumber && hasEditAfterShippmentPrivilege) {
    text = 'barcodeBtnNext'
  } else if (selectedTab == barcodeTabNumber) {
    text = 'barcodeGeneration'
  } else if (selectedTab == tabLength - 1) {
    text = hasEditAfterShippmentPrivilege ? 'close' : 'finish'
  }
  return text
}

function getUserBasic(auth: any) {
  const userinfo = getLoggedInUserDetail()
  const userFullName = userinfo?.name ?? ''
  const userEmployeeId = auth?.authenticatedUser?.employeeId ?? null
  const userSampleCollectedByID = userinfo?.id ?? null
  return { userinfo: userinfo, userFullName: userFullName, userEmployeeId: userEmployeeId, userSampleCollectedByID: userSampleCollectedByID }
}

let holdModifiedFields = {}

export const SampleInformationFormContainer = () => {
  const location = useLocation()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { patientId, id } = useParams()

  const auth = useContext(AuthContext)
  const { userFullName, userEmployeeId, userSampleCollectedByID } = getUserBasic(auth)
  const [selectedTab, setSelectedTab] = useState(0)
  const [showWarning, setShowWarning] = useState<boolean>(false);
  const { masterData } = useContext(MasterDataContext)

  const [sidebar, setSidebar] = useState<boolean>(false)
  const [tags, setTags] = useState<MultiSelectItem[]>([])
  const [notes, setNotes] = useState<HandwrittenNotes[]>([])
  const [searchByTags, setSearchByTags] = useState<MultiSelectItem[]>([])

  const hasEditAfterShippmentPrivilege = AuthUtil.checkUserAccess(PRIVILEGES.EDIT_AFTER_SHIPMENT)
  const hasEditBeforeShippmentPrivilege = AuthUtil.checkUserAccess(PRIVILEGES.EDIT_BEFORE_SHIPMENT)
  const [siteName, setSiteName] = useState('');
  const [siteId, setSiteId] = useState('');

  const debouncedSearch = useDebounce(searchByTags.length, 2000)

  let controller = new AbortController()
  let signal = controller.signal

  const formMethods = useForm<Sample>({
    resolver: zodResolver(tabs[selectedTab]?.schema),
    mode: 'all',
    defaultValues: AddSampleInfoDefaultData,
  })

  useEffect(() => {
    if (
      id && location.state &&
      ((hasEditAfterShippmentPrivilege && [0, 1].includes(location.state?.sampleStatus ?? -1)) ||
        (hasEditBeforeShippmentPrivilege && [2, 3].includes(location.state?.sampleStatus ?? -1)))
    ) {
      navigate(generatePath(appRoutes.viewSample, { patientId: patientId, id: id }))
    }

    if (location.state) {
      const newformattedData: Sample = formatFormValues(location.state, masterData)
      formMethods.reset({ ...formMethods.getValues(), ...newformattedData })
      formMethods.setValue('sampleCollectedBy', userFullName)
      formMethods.setValue('sampleCollectedByID', userSampleCollectedByID)
      formMethods.setValue('employeeId', userEmployeeId)
    }
  }, [location.state])

  const handlePrevious = () => {
    if (selectedTab > 0) {
      if (id && selectedTab !== tabs.length - 1) {
        const { formState: { dirtyFields } } = formMethods
        _.isEmpty(dirtyFields) || _.isEqual(dirtyFields, holdModifiedFields) ? setSelectedTab(selectedTab - 1) : setShowWarning(true)
      } else {
        setSelectedTab(selectedTab - 1)
      }
    }
  }

  const handleNext = () => {
    if (selectedTab === tabs.length - 1) {
      setTimeout(() => {
        navigate(appRoutes.home)
      }, 5000)
    } else {
      const { formState: { dirtyFields } } = formMethods
      holdModifiedFields = { ...dirtyFields }
      setSelectedTab((prevActiveStep) => prevActiveStep + 1)
    }
  }

  const formSubmit: SubmitHandler<Sample> = async function () {
    const payload = formatRequestPayload(formMethods.getValues(), masterData)

    const sampleId = formMethods.getValues('id') ?? id
    if (formMethods.getValues('testType')?.length > 0) {
      payload.testType = _.map(formMethods.getValues('testType') || [], (obj) => _.omit(obj, 'tat'))

    }
    if (!sampleId && !hasEditAfterShippmentPrivilege) {
      await trackPromise(
        addSample(payload, patientId ?? '').then(
          (res) => {
            formMethods.setValue('id', res.data.SampleId)
            navigate(
              generatePath(appRoutes.editSample, {
                patientId,
                id: res.data.SampleId,
              }),
            )
            handleNext()
          },
          (error) => AppUtil.logError(error),
        ),
      )
    } else if ((selectedTab == barcodeTabNumber || selectedTab == tabs.length - 1) && hasEditAfterShippmentPrivilege) {
      return handleNext()
    } else if (sampleId) {
      if (selectedTab == tabs.length - 1) {
        return handleNext()
      } else {
        await trackPromise(
          updateSample(payload, sampleId?.toString(), patientId ?? '').then(
            () => {
              return handleNext()
            },
            (error) => AppUtil.logError(error),
          ),
        )
      }
    }
  }

  useEffect(() => {

    if (id && masterData.length) {
      trackPromise(
        getSampleDetails(id, patientId ?? '').then(
          (res: AxiosResponse<SampleRequestPayload>) => {
            const data = res?.data
            const formattedData: Sample = formatFormValues(data, masterData)
            formMethods.reset({ ...formMethods.getValues(), ...formattedData })
            formMethods.setValue('doctorsName', formattedData.doctorsName)
            formMethods.setValue('typeOfSample', formattedData.typeOfSample)
            formMethods.setValue('siteId', formattedData.siteId)
            formMethods.setValue('sampleCollectedBy', userFullName)
            formMethods.setValue('sampleCollectedByID', userSampleCollectedByID)
            formMethods.setValue('employeeId', userEmployeeId)
            formMethods.setValue('sampleStatus', formattedData.sampleStatus)
            formMethods.setValue('isBiomarkerBeyond4Months', formattedData.isBiomarkerBeyond4Months ? String(formattedData.isBiomarkerBeyond4Months) : false)
            formMethods.setValue('isLabTypeBeyond4Months', formattedData.isLabTypeBeyond4Months ? String(formattedData.isLabTypeBeyond4Months) : false)
            formMethods.setValue('isProgressionSourceBeyond4Months', formattedData.isProgressionSourceBeyond4Months ? String(formattedData.isProgressionSourceBeyond4Months) : false)
            formMethods.setValue('replicateNumber', formattedData.replicateNumber ? String(formattedData.replicateNumber) : '1')
            setSiteName(formattedData.siteName)
            setSiteId(formattedData.siteId)

          },
          (error) => {
            AppUtil.logError(error)
            if (error.response.status === HTTP_STATUS_CODE.NOT_FOUND) {
              setTimeout(() => {
                navigate(appRoutes.home)
              }, 2000)
            }
          },
        ),
      )
    }
    if (patientId) {
      trackPromise(
        getPatientDetails(patientId).then(
          (res: AxiosResponse<Patient>) => {
            if (res.data) {
              formMethods.setValue('dob', res.data.dob ?? '')
              formMethods.setValue('siteId', res.data.siteID)
              formMethods.setValue('sampleCollectedBy', userFullName)
              formMethods.setValue('sampleCollectedByID', userSampleCollectedByID)
              formMethods.setValue('employeeId', userEmployeeId)
            }
          },
          (error) => AppUtil.logError(error),
        ),
      )
    }
  }, [id, patientId, formMethods.watch('barcode'), masterData, formMethods.watch('sampleStatus') == 1])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [selectedTab])

  useEffect(() => {
    if (patientId && masterData.length) {
      trackPromise(
        getHandwrittenNotes(patientId, undefined ,formatMultiselectPayload(searchByTags) ?? '').then(
          (res: AxiosResponse<HandwrittenNotesRequestPayload[]>) => {
            if (res.status == 200) {
              const data: HandwrittenNotes[] = res?.data?.map((item: HandwrittenNotesRequestPayload) => formatFormValuesForNotes(item, masterData)
              )
              setNotes(data)
            }
          },
          (error) => AppUtil.logError(error),
        ),
      )

      trackPromise(
        getHandwrittenNotesByTags(patientId).then(
          (res: AxiosResponse) => {
            if (res.status == 200) {
              const data: MultiSelectItem[] = res?.data?.[0]?.custom?.map((item: string) => {
                return { label: item, value: item, textbox: false }
              }
              ) ?? []
              setTags([...AppUtil.getOptions(MDK.NOTE_TAG, masterData), ...data])
            }
          },
          (error) => AppUtil.logError(error),
        ),
      )
    }
  }, [patientId, masterData])

  const handleStepperClick = (value: number) => {
    if (id && value !== tabs.length - 1) {
      const { formState: { dirtyFields } } = formMethods
      _.isEmpty(dirtyFields) || _.isEqual(dirtyFields, holdModifiedFields) ? setSelectedTab(value) : setShowWarning(true)
    }
  }

  function searchNotesByTags(value: MultiSelectItem[], signal?: AbortSignal) {
    trackPromise(
      getHandwrittenNotes(patientId ?? '', signal, formatMultiselectPayload(searchByTags) ?? '').then(
        (res: AxiosResponse<HandwrittenNotesRequestPayload[]>) => {
          const data: HandwrittenNotes[] = res?.data?.map((item: HandwrittenNotesRequestPayload) => formatFormValuesForNotes(item, masterData)
          )
          setNotes(data)
        },
        (error) => AppUtil.logError(error),
      ),
    )
  }


useEffect(() => {

  controller = new AbortController()
  signal = controller.signal
  if (debouncedSearch) {
    searchNotesByTags(searchByTags, signal)
  }
  return () => {
    controller.abort()
  }

}, [debouncedSearch])

return (
  <>
    {showWarning && <WarningDialog show={showWarning} handleClose={() => setShowWarning(false)} />}
    <Container className={`${parentClass}container`} fluid>
      <div className={`${parentClass}header-wrapper`}>
        <Row className={`justify-content-between ${parentClass}header`}>
          <Col className={`${parentClass}header-left-content`} lg={4} md={4} sm={4}>
            <ArrowLeftShort size={36} onClick={() => { return location.state || location.pathname.includes('new') ? navigate(-1) : navigate(-2) }} />
            <h4 className={`${parentClass}header-text `}>{t(`${translatn}formHeading`)}</h4>
          </Col>
          {hasEditAfterShippmentPrivilege &&
            <Col className={`${parentClass}header-center-content`} lg={4} md={4} sm={4}>
              <span title={t(`${translatn}siteName`)}>{siteName}</span>
              <span title={t(`${translatn}siteId`)}> {' : '}{siteId}</span>
            </Col>
          }
          <Col lg={4} md={4} sm={4} className={`${parentClass}button`}>
            <Button
              className={`${parentClass}save-create-barcode`}
              onClick={() => handlePrevious()}
              disabled={selectedTab === 0}
            >
              <ArrowLeftShort size={24} />
              <span className={`${parentClass}btn-text`}>{t(`${translatn}previousTab`)}</span>
            </Button>
            <Button
              className={`${parentClass}save-create-barcode`}
              type="submit"
              onClick={formMethods.handleSubmit(formSubmit)}
              disabled={
                selectedTab === tabs.length - 1
                  ? (hasEditAfterShippmentPrivilege ? false : !([SampleStatusMasterData.sampleStatusValueMap.shipped, SampleStatusMasterData.sampleStatusValueMap.report_ready].includes(
                    formMethods.getValues('sampleStatus') as number
                  )))
                  : false
              }
            >
              <span className={`${parentClass}btn-text`}>
                {t(`${translatn}${getButtonText(barcodeTabNumber, tabs.length, selectedTab)}`)}
              </span>
              <ArrowRightShort size={24} />
            </Button>
            {selectedTab < barcodeTabNumber && notes.length > 0 &&
              <Button className={`${parentClass}save-create-barcode`} onClick={() => setSidebar(true)}>
                <List size={24} />
              </Button>

            }
          </Col>
        </Row>
        <Row className={`${parentClass}form`}>
          <Col className={`${parentClass}stepper-wrapper`}>
            <Stepper selectedTab={selectedTab} sampleId={id} handleStepperClick={handleStepperClick} />
          </Col>
        </Row>
      </div>
      <Row className={`${parentClass}form-tabs`}>
        <Col>
          <FormProvider {...formMethods}>
            <Form noValidate>{tabs[selectedTab].tab}</Form>
          </FormProvider>
        </Col>
      </Row>
      <Offcanvas show={sidebar} onHide={() => setSidebar(false)} placement='end'>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>{t(`${translatn}sideBarTitle`)}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Form.Label><b>Filter By:</b></Form.Label>
          <Multiselect
            options={tags}
            isObject={true}
            showArrow={true}
            showCheckbox={true}
            hidePlaceholder={true}
            closeOnSelect={false}
            avoidHighlightFirstOption={true}
            placeholder={'Search...'}
            onSelect={(selectedList) => {
              setSearchByTags([...selectedList])
            }}
            selectedValues={searchByTags}
            onRemove={(selectedList) => {
              setSearchByTags([...selectedList])
               if(selectedList.length === 0 && masterData.length) {
                trackPromise(
                  getHandwrittenNotes(patientId ?? '').then(
                    (res: AxiosResponse<HandwrittenNotesRequestPayload[]>) => {
                      if (res.status == 200) {
                        const data: HandwrittenNotes[] = res?.data?.map((item: HandwrittenNotesRequestPayload) => formatFormValuesForNotes(item, masterData)
                        )
                        setNotes(data)
                      }
                    },
                    (error) => AppUtil.logError(error),
                  ),
                )
               }
            }}
            displayValue="label"
          />
          <br />
          {notes.map((note: HandwrittenNotes, index: number) => {
            return (
              <div key={index}>
                <Row >
                  <Col>
                    <p><b>Tag:</b> {AppUtil.getMultiselectLabels(note.predefined_Tags.map(item => item.value?.toString()) ?? [], MDK.NOTE_TAG, masterData)} <br />
                      <b>Custom Tag:</b> {note.custom_Tags} </p>
                    <Card>
                      <Card.Body dangerouslySetInnerHTML={{
                        __html: note.original_Converted_Text
                      }}>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
                <br />
              </div>
            )
          })
          }
          {notes?.length === 0 && <p>No notes found!</p>}
        </Offcanvas.Body>
      </Offcanvas>
    </Container>
  </>
)
}
