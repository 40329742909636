import React from 'react'
import { ReferralDetails } from './ReferralDetails'
import { PhysicalAssessment } from './PhysicalAssessment'
import { FamilyHistory } from './FamilyHistory'
import { History } from './History'
import { Sample } from '../../../models/Sample'
import '../ViewSampleContainer.scss'

type Props = {
  sampleDetails: Sample | null
}

export const TabOne = ({ sampleDetails }: Props) => {
 
  return (
    <div >
        <ReferralDetails  sampleDetails={sampleDetails}/>
        <PhysicalAssessment  sampleDetails={sampleDetails}/>
        <History  sampleDetails={sampleDetails}/>
        <FamilyHistory  sampleDetails={sampleDetails}/>
    </div>
  )
}