import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import './styles/ViewSampleNav.scss'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import { TabOne } from './TabOne'
import { TabTwo } from './TabTwo'
import { TabThree } from './TabThree'
import { TabFour } from './TabFour'
import { TabFive } from './TabFive'
import { TabSix } from './TabSix'
import { TabEight} from './TabEight'
import { TabSeven} from './TabSeven'
import { Sample } from '../../../models/Sample'
import { useLocation } from 'react-router-dom'
import { AuthUtil } from '../../../utils/auth.utils'
import { PRIVILEGES } from '../../../utils/constants'
import { SampleStatusMasterData } from '../../../utils/sampleStatusMasterData'
import { title } from 'process'

type Props = {
  sampleDetails: Sample | null
}

export const ViewSampleNav = ({ sampleDetails }: Props) => {
  const { t } = useTranslation(),
  translatn = 'viewSample.tabs.'
  const location = useLocation()
  const parentClass = 'vcr-pp-register-patient'
  const [activeTab, setActiveTab] = useState('identity')

  const tabs = [
    { id: 'identity', title: t(`${translatn}identity`), component: <TabOne sampleDetails={sampleDetails} /> },
    { id: 'diagnosis', title: t(`${translatn}diagnosis`), component: <TabTwo sampleDetails={sampleDetails} /> },
    { id: 'labWork', title: t(`${translatn}labWork`), component: <TabThree sampleDetails={sampleDetails} /> },
    { id: 'clinicalSurgery', title: t(`${translatn}clinicalSurgery`), component: <TabFour sampleDetails={sampleDetails} /> },
    { id: 'treamentStatus', title: t(`${translatn}treamentStatus`), component: <TabFive sampleDetails={sampleDetails} /> },
    { id: 'sampleTestDetailsAndShipment', title: t(`${translatn}sampleTestDetailsAndShipment`), component: <TabSix sampleDetails={sampleDetails} /> },
  ]

  const hasViewReportPrivilege = AuthUtil.checkUserAccess(PRIVILEGES.VIEW_QIAGEN_REPORT)
  const hasDownloadReportPrivilege = AuthUtil.checkUserAccess(PRIVILEGES.DOWNLOAD_QIAGEN_REPORT)
  const hasSignReportPrivilege = AuthUtil.checkUserAccess(PRIVILEGES.E_SIGN_REPORT)

  if(sampleDetails?.sampleStatus) {
    tabs.push(
      { id: 'qrcode',title: t(`${translatn}qrcode`), component: <TabSeven sampleDetails={sampleDetails}/>})
  }
  if((hasViewReportPrivilege || hasDownloadReportPrivilege || hasSignReportPrivilege) && SampleStatusMasterData.sampleStatusIDValues().sampleStatusValueMap['report_ready'] === sampleDetails?.sampleStatus) {
    tabs.push(
      { id: 'reports', title: t(`${translatn}reports`), component: <TabEight sampleDetails={sampleDetails} /> }
    )
  }
  

  useEffect(() => {
    if (location?.state?.tabName === 'sampleTests') setActiveTab('reports')
  }, [])

  return (
    <div>
   <Tabs className={`${parentClass}-tabs`} activeKey={activeTab} onSelect={(key) => setActiveTab(key ?? '')}>

{tabs.map((tab) => (
  <Tab eventKey={tab.id} id={tab.id} title={tab.title} key={tab.title}>
    {tab.component}
  </Tab>
))}
<Tab tabClassName={`${parentClass}-item-right`} title={<span title={t(`${translatn}siteName`)}>{sampleDetails?.siteName}</span>}>
</Tab>
<Tab tabClassName={`${parentClass}-item-right`} title={<span title={t(`${translatn}siteId`)}>{sampleDetails?.siteId}</span>}></Tab>
</Tabs>
    </div>
  )
}
