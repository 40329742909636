import React, { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { useFormContext } from 'react-hook-form'

import { NumberInput, RadioInput, DateInput, TextInput } from '../../InputComponents'
import { setAgeAtEvent } from '../helpers'
import { MASTER_DATA_KEYS as MDK, MASTER_DATA_ID as MDI } from '../../../utils/constants'
import { MasterDataContext } from '../../../contexts'
import { AppUtil as _ } from '../../../utils/app.utils'
import { cytogeneticsTestingPerformanceFields, getDateOfBirth, testTypeDeletionsTranslocationsFields } from '../helpers/AddSampleInfoHelpers'

export const CytogenicAbnormalities = () => {
  const { t } = useTranslation()
  const { setValue, getValues, watch, resetField, clearErrors } = useFormContext()
  const { masterData } = useContext(MasterDataContext)
  const translatn = 'sampleInfoForm'
  const parentClass = 'vcr-pp-sample-info-'

  const dob = getValues('dob')

  const isRequired = watch('cytogeneticsTestingPerformance') == MDI.CYTOGENETICS_TESTING_PERFORMANCE_OPTION_YES

  useEffect(() => {
    if (watch('cytogeneticsTestingPerformance') != MDI.CYTOGENETICS_TESTING_PERFORMANCE_OPTION_YES) {
      cytogeneticsTestingPerformanceFields.forEach((field) => {
        resetField(field, { defaultValue: null })
        clearErrors(field)
      })

      testTypeDeletionsTranslocationsFields.forEach((field) => {
        resetField(field, { defaultValue: '' })
        clearErrors(field)
      })
    }
  }, [watch('cytogeneticsTestingPerformance')])

  return (
    <div className={`${parentClass}input-field`}>
      <h5 className={`${parentClass}form-heading`}> {t(`${translatn}.cytogeneticAbnormalities`)}</h5>
      <Row lg={4} md={4} sm={4}>
        <Col>
          <RadioInput
            name={`cytogeneticsTestingPerformance`}
            id="cytogeneticTestingDone"
            valueArray={_.sortOptionsDesc(_.getOptions(MDK.CYTOGENETICS_TESTING_PERFORMANCE, masterData))}
            title={t(`${translatn}.cytogeneticTestingDone`)}
            disabled={false}
            inline={true}
            required={true}
          />
        </Col>
        </Row>
        <Row lg={4} md={4} sm={4}>
        {isRequired && <>
          <Col>
            <DateInput
              name={`dateOfProcedureForCytogeneticsAbnormalities`}
              id={'dateOfProcedure'}
              title={t(`${translatn}.dateOfProcedure`)}
              placeholder={t(`common.datePlaceholder`)}
              disabled={false}
              readOnly={false}
              required={isRequired}
              min={new Date(getDateOfBirth(dob)) ?? undefined}
              max={new Date()}
              onChange={(date: Date) => {
                setAgeAtEvent(dob, date, `ageAtProcedureForCytogeneticsAbnormalities`, setValue)
              }}
            />
          </Col>
          <Col>
            <NumberInput
              name={`ageAtProcedureForCytogeneticsAbnormalities`}
              id={'ageAtProcedure'}
              title={t(`${translatn}.ageAtProcedure`)}
              placeholder={t(`${translatn}.ageAtProcedurePlaceholder`)}
              disabled={false}
              readOnly={true}
              required={isRequired}
            />
          </Col>
          <Col>
            <TextInput
              type="text"
              name={`testTypeDeletionsTranslocations`}
              id={'testTypeDeletionsTranslocations'}
              title={t(`${translatn}.testTypeDeletionsTranslocations`)}
              placeholder={t(`${translatn}.testTypeDeletionsTranslocationsPlaceholder`)}
              disabled={false}
              readOnly={false}
              required={isRequired}
            />
          </Col>
          </>}
      </Row>
      
    </div>
  )
}
