import React from 'react'
import { useTranslation } from 'react-i18next'
import Form from 'react-bootstrap/Form'
import { access, renderRequiredFieldLabel } from './InputComponentHelpers'
import { Controller, useFormContext } from 'react-hook-form'

type SelectItem = {
  label: string
  value: string | number
}
type SelectInputFieldsTypes = {
  name: string
  id: string
  title: string
  placeholder?: string
  defaultValue?: string
  optionsValueArray: SelectItem[]
  disabled: boolean
  onCustomChange?: any
  onBlur?: any
  required: boolean
  noOptionsMessage?:string
  value?:string
}

export const SelectInput = function (props: SelectInputFieldsTypes) {
  const { name, id, title, placeholder, optionsValueArray, disabled, onCustomChange, required,noOptionsMessage } = props
  const { t } = useTranslation()
  const formMethods = useFormContext()
  const {
    register,
    formState: { errors },
  } = formMethods
  const placeholderStr = placeholder ? `Select ${placeholder}` : undefined
  const error = access(name, errors)
  
  return (
    <div>
      <Form.Label htmlFor={id}>{required ? renderRequiredFieldLabel(title) : title}</Form.Label>
      <Controller
        name={name}
        render={({ field: { value, onChange } }) => {
          return (
            <Form.Select           
              disabled={disabled}
              id={id}
              defaultValue={value}
              {...register(name)}
              isInvalid={error}
              onChange={(newValue: React.ChangeEvent<HTMLSelectElement>) => {
                onChange(newValue)
                if (onCustomChange) {
                  onCustomChange(newValue)
                }
              }}
            >             
              {placeholderStr ? <option value={''}>{placeholderStr}</option> : ''}
              {optionsValueArray?.map((option: SelectItem) => {
                return (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                )
              })}
              {noOptionsMessage ? <option value={''} disabled>{noOptionsMessage}</option> : ''}
            </Form.Select>
          )
        }}
      />

      <Form.Control.Feedback type="invalid">{t(error?.message)}</Form.Control.Feedback>
    </div>
  )
}
