import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Row, Col, Container, Button } from 'react-bootstrap'
import { SelectInputNoForm } from '../../InputComponents'
import { TabProps, TestDetails } from '../../../models/Sample'
import { BsDownload, BsUpload, BsVectorPen } from 'react-icons/bs'
import { getReportUploadURLs, getReportDownloadURLs, uploadPDFDataAsFile, downladPDFDataAsFile, updateReportStatus } from '../../../services'
import { AxiosResponse } from 'axios'
import { PDFViewer } from '../../PDFOperations'
import { PDFSignModal } from './PDFSignModal'
import { AppUtil } from '../../../utils/app.utils'
import "react-pdf/dist/esm/Page/TextLayer.css";
import 'react-pdf/dist/Page/AnnotationLayer.css';
import './styles/ReportViewer.scss'
import { PRIVILEGES } from '../../../utils/constants'
import { AuthUtil } from '../../../utils/auth.utils'

const translatReportTypess = 'reportTypes.'
const translatn = 'viewSample.'
const parentClass = 'vcr-pp-view-pdf-container'

async function getPDFContent(pdfURL: string, setPdfData: any, setFailedpdfdata: any) {
  const response = await fetch(pdfURL)
  if (response.status == 200) {
    const pdfArrayBuffer = await response.arrayBuffer()
    const uint8ArrayPDFData = new Uint8Array(pdfArrayBuffer)
    setFailedpdfdata(false);
    setPdfData(uint8ArrayPDFData)
  }
  else {
    setPdfData(undefined)
    setFailedpdfdata(true);
  }
}

interface IReportURLMapObject {
  [key: string]: any
}

interface IReportsURLMapObject {
  [key: string]: IReportURLMapObject
}

interface IPPNMapObject {
  [key: string]: TestDetails
}

function getReportSelectOptions(t: any, translatReportTypess: string) {
  let reportOptions = Array()
  const reportNameMapObject: { [key: string]: string } = {}
  const reportsKeys = ['synopsisReport', 'overall_summary']

  for (const oneReportKey of reportsKeys) {
    reportNameMapObject[oneReportKey] = t(`${translatReportTypess}${oneReportKey}`)
    reportOptions.push({ 'reportKey': oneReportKey, 'reportValue': t(`${translatReportTypess}${oneReportKey}`) })
  }

  return { reportOptions: reportOptions, reportNameMapObject: reportNameMapObject, selectOptionKey: 'reportKey', selectOptionValue: 'reportValue' }
}

function createTestDataMap(sampleId: string, testDetails: any) {
  let ppnOptions = Array()
  const ppnMapObject: IPPNMapObject = {}

  for (const oneTestDetail of testDetails) {
    if (oneTestDetail?.status !== 'report_ready') continue
    ppnMapObject[oneTestDetail.ppn] = oneTestDetail
    ppnOptions.push(oneTestDetail.ppn)
  }
  return { ppnMapObject: ppnMapObject, ppnOptions: ppnOptions }
}

function getURLs(urlFunction: any, sampleId: string, testId: string, selectedPPNValue: string, urlMapObject: any, setReportsURLMapObject: any) {
  if (!testId) return
  urlFunction(sampleId, String(testId)).then(
    (response: AxiosResponse) => {
      const singleReportURLsObject: IReportURLMapObject = {};
      const reportsURLMapObject: IReportsURLMapObject = structuredClone(urlMapObject);

      for (const singleReportsURL of response.data) {
        singleReportURLsObject[singleReportsURL.reportKey] = singleReportsURL
      }
      reportsURLMapObject[selectedPPNValue] = singleReportURLsObject
      setReportsURLMapObject(reportsURLMapObject)
    },
    (error: any) => {
      AppUtil.logError(error)
    },
  )
}

function findReportStatus(signRequired: any, isSigned: any, isLocalSigned: string, selectedReportKey: string, selectedPPNValue: string, urlMapObject: any, setReportsURLMapObject: any) {
  let reportStatus = "not_signed"
  if (!signRequired) reportStatus = "sign_not_required"
  else if (signRequired && isSigned) reportStatus = "signed_and_uploaded"
  else if (signRequired && !isSigned) reportStatus = "not_signed"
  if (reportStatus === 'signed_and_uploaded') {
    reportStatus = "signed_and_uploaded"
    const reportsURLMapObject: IReportsURLMapObject = structuredClone(urlMapObject);
    reportsURLMapObject[selectedPPNValue][selectedReportKey].isSigned = true
    setReportsURLMapObject(reportsURLMapObject)

  }
  return reportStatus
}

const PPNDetails = ({ ppnOptions, ppnMapObject, selectedPPN, setSelectedTestInfo }: { ppnOptions: any[], ppnMapObject: IPPNMapObject, selectedPPN: string, setSelectedTestInfo: any }) => {
  const { t } = useTranslation()

  return (
    <Row className={`${parentClass}-row`}>
      <Col lg={4} className={`${parentClass}-col`}>
        <div>
          <SelectInputNoForm
            name={`testPPN`}
            id="testPPN"
            optionsValueArray={ppnOptions}
            title={t(`${translatn}ppn`)}
            disabled={false}
            required={false}
            placeholder={t(`${translatn}ppn`)}
            onChange={(event: any) =>
              setSelectedTestInfo(event.target.value.trim())
            }
            className={`${parentClass}-select-input`}
            labelClassName={`${parentClass}-select-input-label`}
          />
        </div>
      </Col>
      <Col className={`${parentClass}-col m-4`}>
        <div className={`${parentClass}-row-label`}>{ppnMapObject[selectedPPN] && t(`${translatn}primaryTestTag`)}</div>
        <div className={`${parentClass}-row-value`}>{ppnMapObject[selectedPPN]?.primaryTestTag}</div>
      </Col>
      <Col className={`${parentClass}-col m-4`}>
        <div className={`${parentClass}-row-label`}>{ppnMapObject[selectedPPN] && t(`${translatn}testId`)}</div>
        <div className={`${parentClass}-row-value`}>{ppnMapObject[selectedPPN]?.testId}</div>
      </Col>
      <Col className={`${parentClass}-col m-4`}>
        <div className={`${parentClass}-row-label`}>{ppnMapObject[selectedPPN] && t(`${translatn}tat`)}</div>
        <div className={`${parentClass}-row-value`}>{ppnMapObject[selectedPPN]?.tat}</div>
      </Col>
    </Row>
  )
}

export const ReportsViewer = (props: TabProps) => {
  const { t } = useTranslation()
  const { id } = useParams()
  const { sampleDetails } = props

  const [signModalShow, setSignModalShow] = useState(false)
  const [selectedPPN, setSelectedPPN] = useState<string>("")
  const [selectedReportKey, setSelectedReportKey] = useState("")
  const [reportLocalStatus, setReportLocalStatus] = useState<string>("not_signed")
  const [pdfBytesData, setPdfBytesData] = useState<Uint8Array | undefined>()
  const [resetPDFPageNumber, setResetPDFPageNumber] = useState<string>("no")
  const [reportsDownloadURLMapObject, setReportsDownloadURLMapObject] = useState<IReportsURLMapObject>({})
  const { ppnMapObject, ppnOptions } = createTestDataMap(id ?? '', sampleDetails?.testType ?? [])
  const { reportOptions, reportNameMapObject, selectOptionKey, selectOptionValue } = getReportSelectOptions(t, translatReportTypess)
  const [failedpdfdata, setFailedpdfdata] = useState(false)
  const [isCrcOrCrm, setIsCrcOrCrm] = useState<boolean>(false)

  const hasViewReportPrivilege = AuthUtil.checkUserAccess(PRIVILEGES.VIEW_QIAGEN_REPORT)
  const hasDownloadReportPrivilege = AuthUtil.checkUserAccess(PRIVILEGES.DOWNLOAD_QIAGEN_REPORT)
  const hasSignReportPrivilege = AuthUtil.checkUserAccess(PRIVILEGES.E_SIGN_REPORT)

  function updatePDFSignData(pdfData: Uint8Array) {
    setResetPDFPageNumber("no")
    setPdfBytesData(pdfData)
    setReportLocalStatus("signed")
  }

  function setSelectedTestInfo(selectedPPNValue: string) {
    setSelectedPPN(selectedPPNValue)
    setSelectedReportKey("")
    setFailedpdfdata(false)

    getURLs(
      getReportDownloadURLs, id ?? '',
      String(ppnMapObject[selectedPPNValue]?.id ?? ''),
      selectedPPNValue,
      reportsDownloadURLMapObject,
      setReportsDownloadURLMapObject
    )
  }

  function setReportName(reportKey: string) {
    setSelectedReportKey(reportKey)
    if (reportKey) {
      setReportLocalStatus(findReportStatus(reportsDownloadURLMapObject[selectedPPN][reportKey].signRequired, reportsDownloadURLMapObject[selectedPPN][reportKey].isSigned, reportLocalStatus, reportKey, selectedPPN, reportsDownloadURLMapObject, setReportsDownloadURLMapObject))
      setIsCrcOrCrm(hasViewReportPrivilege && hasDownloadReportPrivilege && !hasSignReportPrivilege)
      setResetPDFPageNumber("reset")
      getPDFContent(reportsDownloadURLMapObject[selectedPPN][reportKey].path, setPdfBytesData, setFailedpdfdata)
    } else {
      setReportLocalStatus("not_signed")
      setFailedpdfdata(false)
      setIsCrcOrCrm(false)
      setResetPDFPageNumber("no")
      setPdfBytesData(undefined)
    }
  }

  function downloadPDFData() {
    downladPDFDataAsFile(reportNameMapObject[selectedReportKey] + '.pdf', pdfBytesData ?? new Uint8Array())
  }

  function uploadPDFData() {
    if (!pdfBytesData) return
    getReportUploadURLs(id ?? '', String(ppnMapObject[selectedPPN]?.id ?? '')).then(
      (response: AxiosResponse) => {
        const singleReportURLsObject: IReportURLMapObject = {};
        for (const singleReportsURL of response.data) {
          singleReportURLsObject[singleReportsURL.reportKey] = singleReportsURL
        }
        uploadPDFDataAsFile(singleReportURLsObject[selectedReportKey].path, pdfBytesData).then(
          () => {
            setReportLocalStatus("signed_and_uploaded")
            updateReportStatus(id ?? '', String(ppnMapObject[selectedPPN]?.id ?? ''), selectedReportKey, singleReportURLsObject[selectedReportKey].path, true).then(
              (res: any) => {
                getReportUploadURLs(id ?? '', String(ppnMapObject[selectedPPN]?.id ?? '')).then(
                  (response: AxiosResponse) => {
                    const singleReportURLsObject: IReportURLMapObject = {};
                    for (const singleReportsURL of response.data) {
                      singleReportURLsObject[singleReportsURL.reportKey] = singleReportsURL
                    }
                  },
                  (error: any) => {
                    AppUtil.logError(error)
                  },
                )
              }
            )
            reportsDownloadURLMapObject[selectedPPN][selectedReportKey].isSigned = true
            setReportsDownloadURLMapObject(reportsDownloadURLMapObject)

          },
          (error: any) => {
            AppUtil.logError(error)
          },
        )
      },
      (error: any) => {
        AppUtil.logError(error)
      },
    )


  }

  return (
    <Container className={`${parentClass}`} fluid>
      {signModalShow && <PDFSignModal show={signModalShow} handleClose={() => setSignModalShow(false)} pdfBytesData={pdfBytesData} reportName={reportOptions.find((report) => report.reportKey == selectedReportKey)?.reportValue} sampleID={id ?? ''} testID={ppnMapObject[selectedPPN]?.testId ?? ''} primaryTestTagID={ppnMapObject[selectedPPN]?.primaryTestTag ?? ''} setpdfBytesData={updatePDFSignData} />}

      <PPNDetails ppnOptions={ppnOptions} ppnMapObject={ppnMapObject} selectedPPN={selectedPPN} setSelectedTestInfo={setSelectedTestInfo} />

      <hr />

      {selectedPPN &&
        <Row className={`${parentClass}-row`}>
          <Col lg={4} className={`${parentClass}-col`}>
            <div>
              <SelectInputNoForm
                name={`reportName`}
                id="reportName"
                optionsValueArray={reportOptions}
                optionKeyFieldName={selectOptionKey}
                optionValueFieldName={selectOptionValue}
                title={t(`${translatn}reports`)}
                disabled={false}
                required={false}
                //value={selectedReportKey}
                placeholder={t(`${translatn}reports`)}
                onChange={(event: any) =>
                  setReportName(event.target.value.trim())
                }
                className={`${parentClass}-select-input`}
                labelClassName={`${parentClass}-select-input-label`}
              />
            </div>
          </Col>
          {selectedReportKey && !failedpdfdata &&
            <Col className={`${parentClass}-action-col`}>
              {!isCrcOrCrm && <Col>
                <Button
                  variant='link'
                  className={`${parentClass}-action-btn`}
                  size="sm"
                  name={`signReport`}
                  id="signReport"
                  title={t(`${translatn}signIconToolTip`)}
                  onClick={() => setSignModalShow(true)}
                  disabled={reportLocalStatus !== "not_signed"}
                >
                  <BsVectorPen size={20} />
                </Button>
              </Col>
              }
              {!isCrcOrCrm && <>
                <span className={`${parentClass}-vertical-line`}></span>
                <Col>
                  <Button
                    variant='link'
                    className={`${parentClass}-action-btn`}
                    size="sm"
                    name={`uploadReport`}
                    id="uploadReport"
                    title={t(`${translatn}uploadIconToolTip`)}
                    onClick={uploadPDFData}
                    disabled={reportLocalStatus !== "signed"}

                  >
                    <BsUpload size={20} />
                  </Button>
                </Col>
              </>
              }
              {(reportLocalStatus === "signed_and_uploaded" || reportLocalStatus === "sign_not_required") && <><span className={`${parentClass}-vertical-line`}></span>
                <Col>
                  <Button
                    variant='link'
                    className={`${parentClass}-action-btn`}
                    size="sm"
                    name={`downloadReport`}
                    id="downloadReport"
                    title={t(`${translatn}downloadIconToolTip`)}
                    onClick={downloadPDFData}
                  >
                    <BsDownload size={20} />
                  </Button>
                </Col></>}
            </Col>
          }
        </Row>
      }
      <Row className={`${parentClass}-details-row`}> {failedpdfdata && (<Col>{t(`${translatn}noReportIsAvailable`)}</Col>)}</Row>
      <Row className={`${parentClass}-details-row`}>
        <Col>
          {selectedPPN && selectedReportKey && pdfBytesData && <PDFViewer resetPageNumber={resetPDFPageNumber} pdfData={pdfBytesData} />}
        </Col>
      </Row>
    </Container>
  )
}
