import React, { useContext, useEffect, useState } from 'react'
import { Col, Container, Row, Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { ReactTable } from '../CommonComponents/Table'
import { getUserDetails } from '../../services/User'
import { MdEditNote } from 'react-icons/md'
import { PersonSquare } from 'react-bootstrap-icons'
import { MASTER_DATA_KEYS, PRIVILEGES } from '../../utils/constants'
import { trackPromise } from 'react-promise-tracker'
import { AuthUtil } from '../../utils/auth.utils'
import { UserCreateModal } from '../UserCreateForm'
import { UserDataType } from '../../models/Auth'
import { Site } from '../../models/MasterData'
import { getSiteList } from '../../services/MasterData'
import { AxiosResponse } from 'axios'
import './UsersList.scss'
import { AppUtil } from '../../utils/app.utils'
import { MasterDataContext } from '../../contexts'

type actionPropsTypes = {
  userInfo: UserDataType | null
  setUserFormData: (userInfo: UserDataType | null) => void
  setUserFormModalShow: (value: boolean) => void
}

function ActionColumn(props: Readonly<actionPropsTypes>) {
  const { t } = useTranslation()
  const translatn = 'usersList.'
  const parentClass = 'users-list'
  const hasEditUserPrivilege = AuthUtil.checkUserAccess(PRIVILEGES.EDIT_USER)

  function callShowModal() {
    props.setUserFormData(props.userInfo)
    props.setUserFormModalShow(true)
  }

  return (
    <Row className="flex-nowrap">
      {hasEditUserPrivilege && (
        <Col>
          <Button
            className={`${parentClass}-action-btn`}
            size="sm"
            id="editUser"
            title={t(`${translatn}editUser`)}
            onClick={callShowModal}
          >
            <MdEditNote size={20} />
          </Button>
        </Col>
      )}
    </Row>
  )
}

function formatRowData(rawData: any) {
  const rowData = rawData.map(function (singleRow: any) {
    return {
      userId: singleRow.user.id,
      firstName: singleRow.user.firstName,
      lastName: singleRow.user.lastName,
      username: singleRow.user.username,
      emailId: singleRow.user.userEmail,
      userState: singleRow.user.userState,
      userRole: singleRow.user.roleId,
      siteId: singleRow.user.siteId,
      siteName: singleRow.siteName,
      employeeId: singleRow.user.employeeId,
    }
  })
  return rowData
}

const getUsersData = async (page: any) => {
  const response = await getUserDetails(page)
    .then(
      (getResponse) => {
        if (getResponse.status == 200) {
          return getResponse?.data
        }
      },
      (error) => {
        console.log(error)
        return {}
      },
    )
    .catch((error) => {
      console.log(error)
      return {}
    })
  return response
}

const tableData: UserDataType[] = []

export function UsersTableInfo() {

  const { t } = useTranslation()
  const translatn = 'usersList.'
  const parentClass = 'users-list'
  const { masterData } = useContext(MasterDataContext)
  
  const [currentPage, setCurrentPage] = useState(1)
  const [userFormModalShow, setUserFormModalShow] = useState<boolean>(false)

  const [userFormData, setUserFormData] = useState<UserDataType | null>(null)

  const hasCreateUserPrivilege = AuthUtil.checkUserAccess(PRIVILEGES.CREATE_USER)

  const [pageData, setPageData] = useState({
    rowData: tableData,
    totalPages: 0,
    totalElements: 0,
    size: 10,
    pageIndex: 0,
  })

  const [siteList, setSiteList] = useState<Site[]>([])

  useEffect(() => {
    trackPromise(
      getSiteList().then((res: AxiosResponse<Site[]>) => {
        setSiteList(res.data)
      }),
    )
  }, [])

  const getUserLists = (pageNo: number) =>
    trackPromise(
      getUsersData(pageNo).then((apiData: any) => {
        setPageData({
          rowData: formatRowData(apiData?.content),
          totalPages: apiData?.totalPages,
          totalElements: apiData?.totalElements,
          size: apiData?.size,
          pageIndex: apiData?.number,
        })
      }),
    )
  const userFormModalClose = (userAPIState?: string) => {
    if (userAPIState === 'userCreated') {
      if (currentPage === 1) {
        getUserLists(1)
      }
      setCurrentPage(1)
    } else if (userAPIState === 'userUpdated') {
      getUserLists(currentPage)
    }
    setUserFormModalShow(false)
    setUserFormData(null)
  }

  useEffect(() => {
    getUserLists(currentPage)
  }, [currentPage])

  const columns = [
    {
      id: 'userId',
      header: t(`${translatn}userId`),
      accessorKey: 'userId',
      enableSorting: true,
      cell: (info: any) => <span>{info.getValue() || '-'}</span>,
    },
    {
      id: 'username',
      header: t(`${translatn}username`),
      accessorKey: 'username',
      enableSorting: true,
      cell: (info: any) => {
        const name=info.getValue();
        return (
          <div title={name} className={`${parentClass}-text-container`}>
           {name}
          </div>   
        );
        
      },
      sortingFn: (rowA: any, rowB: any, columnId: any) => {
        const valueA = rowA.getValue(columnId).toLowerCase()
        const valueB = rowB.getValue(columnId).toLowerCase()
        return valueA.localeCompare(valueB)
      },
    },
    {
      id: 'firstName',
      header: t(`${translatn}firstName`),
      accessorKey: 'firstName',
      enableSorting: true,
      cell: (info: any) => {
        const name=info.getValue();
        return (
          <div title={name} className={`${parentClass}-text-container`}>
           {name}
          </div>   
        );
        
      },
      sortingFn: (rowA: any, rowB: any, columnId: any) => {
        const valueA = rowA.getValue(columnId).toLowerCase()
        const valueB = rowB.getValue(columnId).toLowerCase()
        return valueA.localeCompare(valueB)
      },
    },
    {
      id: 'lastName',
      header: t(`${translatn}lastName`),
      accessorKey: 'lastName',
      enableSorting: true,
      cell: (info: any) => {
        const name=info.getValue();
        return (
          <div title={name} className={`${parentClass}-text-container`}>
           {name}
          </div>   
        );
        
      },
      sortingFn: (rowA: any, rowB: any, columnId: any) => {
        const valueA = rowA.getValue(columnId).toLowerCase()
        const valueB = rowB.getValue(columnId).toLowerCase()
        return valueA.localeCompare(valueB)
      },
    },
    {
      id: 'userState',
      header: t(`${translatn}userState`),
      accessorKey: 'userState',
      enableSorting: true,
      cell: (info: any) =>
        AppUtil.getLabel(MASTER_DATA_KEYS.USER_STATE, masterData, info.getValue()) || '-'
      ,
    },
    {
      id: 'userRole',
      header: t(`${translatn}userRole`),
      accessorKey: 'userRole',
      enableSorting: true,
      cell: (info: any) => AppUtil.getLabel(MASTER_DATA_KEYS.ROLES, masterData, info.getValue()) || '-',
    },
    {
      id: 'siteName',
      header: t(`${translatn}siteName`),
      accessorKey: 'siteName',
      enableSorting: true,
      cell: (info: any) => info.getValue() || 'NA',
      sortingFn: (rowA: any, rowB: any, columnId: any) => {
        const valueA = (rowA.getValue(columnId) ?? 'NA')?.toLowerCase()
        const valueB = (rowB.getValue(columnId) ?? 'NA')?.toLowerCase()
        return valueA.localeCompare(valueB)
      },
    },
    {
      id: 'action',
      header: t(`${translatn}action`),
      accessorKey: 'action',
      enableSorting: false,
      cell: (info: any) => (
        <ActionColumn
          userInfo={info.row.original}
          setUserFormData={setUserFormData}
          setUserFormModalShow={setUserFormModalShow}
        />
      ),
    },
  ]

  return (
    <>
      {userFormModalShow && <UserCreateModal
        show={userFormModalShow}
        handleClose={userFormModalClose}
        userInfo={userFormData}
        siteList={siteList}
      />}
      <Container fluid className={`${parentClass}`}>
        <Row className={`${parentClass}-header`}>
          <Col className="d-flex align-items-center justify-content-start">
            <h4 className={`${parentClass}-page-heading`}>{t(`${translatn}heading`)}</h4>
          </Col>
          <Col className="d-flex align-items-center justify-content-end">
            {hasCreateUserPrivilege && (
              <Button className={`${parentClass}-create-user-btn`} onClick={() => setUserFormModalShow(true)}>
                <PersonSquare color={'#075883'} size={25} className="pe-2" />
                {t(`${translatn}createUser`)}{' '}
              </Button>
            )}
          </Col>
        </Row>
        <Row>
          <Col lg={12} md={12} sm={12}>
            <div className={`${parentClass}-react-table-container`}>
              <ReactTable
                pageChangeHandler={setCurrentPage}
                data={pageData.rowData}
                columns={columns}
                pageSize={pageData.size}
                pageIndex={pageData.pageIndex}
                totalRows={pageData.totalElements}
                pageCount={pageData.totalPages}
                tableClassName="users-list"
                forcePage={currentPage - 1}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  )
}
