import React from 'react'
import { useTranslation } from 'react-i18next'
import Form from 'react-bootstrap/Form'
import { access, renderRequiredFieldLabel } from './InputComponentHelpers'
import { useFormContext } from 'react-hook-form'

type RadioItem = {
  label: string
  value: string | number
}

type RadioInputFieldsTypes = {
  name: string
  id: string
  title: string
  valueArray: RadioItem[] 
  inline: boolean
  disabled: boolean
  required: boolean
}

export const RadioInput = function (props: RadioInputFieldsTypes) {
  const { name, id, title, valueArray, inline, disabled, required } = props
  const formMethods = useFormContext()
  const {
    register,
    formState: { errors },
  } = formMethods
  const { t } = useTranslation()
  const error = access(name, errors)

  return (
    <div>
      <Form.Group className={error ? 'is-invalid' : ''}>
        <Form.Label htmlFor={name}>{required ? renderRequiredFieldLabel(title) : title}</Form.Label>
        <br />
        {valueArray?.map((option: RadioItem) => (
          <Form.Check
            key={`${id}-${option.value}`}
            id={`${id}-${option.value}`}
            value={option.value}
            type="radio"
            label={option.label}
            inline={inline}
            disabled={disabled}
            {...register(name)}
            isInvalid={error}
          />
        ))}
      </Form.Group>
      <Form.Control.Feedback type="invalid">{t(error?.message)}</Form.Control.Feedback>
    </div>
  )
}
