import React from 'react'
import { useTranslation } from 'react-i18next'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { MedicationModalBody } from './MedicationModalBody'
import "./styles/Medication.scss"

type Props = {
    open: boolean
    handleClose: () => void
    handleSave: () => void
    selectedIndex: number
}

export const MedicationModal = (props: Props) => {
    const { open, handleClose, handleSave, selectedIndex } = props
    const { t } = useTranslation()
    const translatn = 'sampleInfoForm'
    const parentClass = 'medication'

    return (
        <>
            <Modal className={`${parentClass}`} show={open} onHide={handleClose} centered backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>{t(`${translatn}.medications`)}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <MedicationModalBody count={selectedIndex} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {t(`${translatn}.cancelBtn`)}
                    </Button>
                    <Button variant="primary" className={`${parentClass}-save-btn`} onClick={handleSave}>{t(`${translatn}.saveBtn`)}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
