import React from 'react'
import Container from 'react-bootstrap/Container'
import { useTranslation } from 'react-i18next'
import { TabProps } from '../../../models/Sample'
import { ViewSampleDetailsRow } from './ViewSampleDetailsRow'
import './styles/ViewSampleNav.scss'

export const ReferralDetails = (props: TabProps) => {
  const { sampleDetails } = props
  const { t } = useTranslation()
  const translatn = 'viewSample.'
  const parentClass = ''

  const detailsArr = [
    {
      label: t(`${translatn}doctorName`),
      value: sampleDetails?.doctorsName,
      row: 1,
    },
    {
      label: t(`${translatn}doctorId`),
      value: sampleDetails?.doctorsId,
      row: 1,
    },
    {
      label: t(`${translatn}doctorContact`),
      value: sampleDetails?.doctorContactNumber,
      row: 1,
    },
    {
      label: t(`${translatn}doctorEmail`),
      value: sampleDetails?.doctorEmail,
      row: 1,
    },
    {
      label: t(`${translatn}siteName`),
      value: sampleDetails?.siteName,
      row: 1,
    },
  ]

  const rowCount = [1]

  return (
    <Container fluid>
      <h5 className="fw-bold pt-3"> {t(`${translatn}referralDetails`)}</h5>
      {rowCount.map((row, index) => (
        <ViewSampleDetailsRow
          key={row}
          index={index}
          rowArr={detailsArr.filter((details) => details.row == row)}
          parentClass={parentClass}
        />
      ))}
    </Container>
  )
}
