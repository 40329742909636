import React, { useContext, useEffect, useState } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { Button, Form } from 'react-bootstrap'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import './HandwrittenNotesContainer.scss'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { ArrowLeftShort } from 'react-bootstrap-icons'
import { AuthContext, MasterDataContext } from '../../contexts'
import { getPateintConsentUploadURLs, uploadConsentFileData } from '../../services/Patient'
import { HandwrittenNotesDefaultData, HandwrittenNotesFormSchema } from './helpers'
import { HandwrittenNotes, HandwrittenNotesRequestPayload } from '../../models/HandwrittenNotes'
import { AddHandwrittenNotes } from './AddHandwrittenNotes/AddHandwrittenNotes'
import { trackPromise } from 'react-promise-tracker'
import { addHandwrittenNotes, convertImageToText, getHandwrittenNotesById, getHandwrittenNotesUploadURLs, updateHandwrittenNoteDetails, uploadHandwrittenNoteData } from '../../services/HandwrittenNotes'
import { AxiosResponse } from 'axios'
import { appRoutes } from '../../utils/routes'
import { AppUtil } from '../../utils/app.utils'
import { formatFormValues, formatPayloadData, formatRequestPayload } from './helpers/HandwrittenNotesHelpers'

export const HandwrittenNotesContainer = () => {
  const { patientId, id } = useParams()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const translatn = 'addHandwrittenNotes.'
  const parentClass = 'vcr-pp-register-patient-'
  const { masterData } = useContext(MasterDataContext)

  let formDefaultValues = JSON.parse(JSON.stringify(HandwrittenNotesDefaultData))
  formDefaultValues.patientId = patientId
  formDefaultValues.id = id

  const [isUploaded, setIsUploaded] = useState<boolean>(false);
  const [imageSrc, setImageSrc] = useState<string | null>(null);

  const formMethods = useForm<HandwrittenNotes>({
    resolver: zodResolver(HandwrittenNotesFormSchema),
    mode: 'all',
    defaultValues: formDefaultValues,
  })

  useEffect(() => {
    if (id && masterData.length) {
      trackPromise(
        getHandwrittenNotesById(id).then(
          (res: AxiosResponse<HandwrittenNotesRequestPayload>) => {
            if (res.data) {
              const data = res?.data
              const formattedData: HandwrittenNotes = formatFormValues(data, masterData)
              formMethods.reset(formattedData)

              setIsUploaded(true)

              const fileName = res.data.image_Path?.split('/')?.pop() ?? '';
              const file = new File([''], fileName);

              const dataTransfer = new DataTransfer();
              dataTransfer.items.add(file);

              formMethods.setValue('file', dataTransfer.files);
            }
          },
          (error) => AppUtil.logError(error),
        ),
      )
    }
  }, [id, masterData])

  const convertImgToText = (fileName: string) => {
    trackPromise(
      convertImageToText(patientId ?? '', fileName).then(
        async (res: AxiosResponse) => {
          formMethods.setValue('image_Path', `s3/patients/${patientId}/notes/${fileName}`)
          formMethods.setValue('notes', res.data);
          formMethods.setValue('original_Converted_Text', res.data);
        },
        (error) => AppUtil.logError(error),
      ),
    )
  }

  const handleUploadFile = () => {    
    if (!id) {      
      const data = formatPayloadData(formMethods.getValues())
      trackPromise(getHandwrittenNotesUploadURLs(patientId ?? '', data.file?.[0]?.name ?? '').then(
        (resp: AxiosResponse) => {
          if (resp.status == 200) {
            trackPromise(
              uploadHandwrittenNoteData(resp.data?.path ?? '', data.file?.[0] as File).then(
                (response: AxiosResponse) => {
                  if (response.status == 200) {
                    convertImgToText(data.file?.[0]?.name ?? '')
                  }
                },
                (error: any) => {
                  AppUtil.logError(error)
                },
              )
            )
          }
        },
        (error: any) => {
          AppUtil.logError(error)
        },
      ))
    } else {
      const data = formMethods.getValues()
      convertImgToText(data.file?.[0]?.name ?? '')
    }
  }

  const saveHandwrittenNotes = async function () {
    const data: HandwrittenNotesRequestPayload = formatRequestPayload(formMethods.getValues())
    if (!id) {
      delete data.createdDate;
      delete data.lastModified;
      delete data.file;
      delete data.id;
      await trackPromise(
        addHandwrittenNotes(data).then(
          (res: AxiosResponse) => {
            if (res.status == 200) {
              navigate(generatePath(appRoutes.viewHandwrittenNotes, { id: patientId }))
            }
          },
          (error) => AppUtil.logError(error),
        ),
      )
    } else {
      delete data.createdDate;
      delete data.lastModified;
      delete data.file;
      await trackPromise(
        updateHandwrittenNoteDetails(data).then(
          (resp: AxiosResponse) => {
            if (resp.status == 200) {
              navigate(generatePath(appRoutes.viewHandwrittenNotes, { id: patientId }))
            }
          },
          (error) => AppUtil.logError(error),
        ),
      )
    }
  }

  return (
    <Container className={`${parentClass}container`} fluid>
      <Row className={`${parentClass}header `}>
        <Col className={`${parentClass}header-left-content`}>
          <ArrowLeftShort size={36} onClick={() => navigate(-1)} />
          <h4 className={`${parentClass}header-text `}>
            {id ? t(`${translatn}edit`) : t(`${translatn}new`)} {t(`${translatn}formHeading`)}
            {id ? ` (${t(`${translatn}patientId`)}: ${patientId})` : ''}
          </h4>
        </Col>
      </Row>
      <Row className={`${parentClass}form`}>
        <Col lg={12} md={12} sm={12}>
          <FormProvider {...formMethods}>
            <Form noValidate>
              <AddHandwrittenNotes isUploaded={isUploaded} setIsUploaded={setIsUploaded} handleUploadFile={handleUploadFile} imageSrc={imageSrc} setImageSrc={setImageSrc} />
            </Form>
          </FormProvider>
        </Col>
      </Row>
      {imageSrc && <Row className={`${parentClass}save`}>
        <Col lg={1} md={1} sm={1}>
          <Button className={`${parentClass}save-btn`} type="submit" onClick={formMethods.handleSubmit(saveHandwrittenNotes)}>
            {t(`${translatn}save`)}{' '}
          </Button>
        </Col>
      </Row>}
    </Container>
  )
}
