import React, { useState } from 'react'
import type { ColumnDef, PaginationState } from '@tanstack/react-table'
import {
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  getPaginationRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { Table } from 'react-bootstrap'
import ReactPaginate from 'react-paginate'
import { ChevronLeft, ChevronRight } from 'react-bootstrap-icons'

interface ReactTableProps<T extends object> {
  data: T[]
  columns: ColumnDef<T>[]
  pageIndex?: number
  pageSize?: number
  pageCount?: number
  totalRows?: number
  pageChangeHandler: any
  tableClassName: string
  showPagination?: boolean
  forcePage?: number
}

export function ReactTable<T extends object>({
  data: tblData,
  columns: tblColumns,
  pageIndex = 0,
  pageSize = 10,
  pageCount = 1,
  // totalRows = 1,
  pageChangeHandler,
  tableClassName,
  showPagination = true,
  forcePage = 0
}: Readonly<ReactTableProps<T>>) {

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: pageIndex,
    pageSize: pageSize,
  })

  const handlePageClick = (event: any) => {
    pageChangeHandler(event.selected + 1)
  }

  const table = useReactTable({
    data: tblData,
    columns: tblColumns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    pageCount,
    state: {
      pagination,
    },
    manualPagination: false,
    onPaginationChange: setPagination,
  })

  function secondHeaderCheck(headerColumnDef: any){
    if (headerColumnDef?.header2) return <><br/>{headerColumnDef.header2}</>
    return ''
  }

  return (
    <div>
      <div className={tableClassName}>
        <Table hover responsive className={tableClassName + '-app-table'}>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th key={header.id} className={`${tableClassName}-heading heading`}>
                    <div
                      style={header.column.getCanSort() ? { cursor: 'pointer' } : { userSelect: 'none' }}
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      {flexRender(header.column.columnDef.header, header.getContext())}
                      {secondHeaderCheck(header.column.columnDef)}
                      {{ asc: ' 🔼', desc: ' 🔽' }[header.column.getIsSorted() as string] ?? null}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id} className={`${tableClassName}-row table-row`}>
                {row.getVisibleCells().map((cell) => (
                  <td
                    style={{ width: cell.column.getSize() }}
                    key={cell.id}
                    className={`${tableClassName}-col table-col`}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      {showPagination && (
        <ReactPaginate
          breakLabel="..."
          nextLabel={<ChevronRight />}
          onPageChange={handlePageClick}
          pageRangeDisplayed={2}
          marginPagesDisplayed={2}
          pageCount={pageCount}
          previousLabel={<ChevronLeft />}
          renderOnZeroPageCount={null}
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakClassName="page-item"
          breakLinkClassName="page-link"
          containerClassName="pagination justify-content-center"
          activeClassName="active"
          forcePage={forcePage}
        />
      )}
    </div>
  )
}
