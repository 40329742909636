import React from 'react'
import { ViewDetailsColumn } from '../../CommonComponents'
import Row from 'react-bootstrap/Row'
import { Details } from '../../../models/Sample'

type Props = {
  rowArr: Details[]
  index: number
  parentClass: string
}

export const ViewSampleDetailsRow = (props: Props) => {
  const { rowArr, index, parentClass } = props

  return (
    <>
      <Row className={`${parentClass}row mt-4 `} border="dark" key={index}>
        {rowArr.map((col) => (
          <ViewDetailsColumn
            key={col.label + col.value}
            label={col.label}
            label2={col.label2}
            value={col.value}
            parentClass={parentClass}
            helperText={col.helperText}
          />
        ))}
      </Row>
      <hr />
    </>
  )
}
