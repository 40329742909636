import React from 'react'
import { TabProps } from '../../../models/Sample'
import { ReportsViewer } from './ReportsViewer'

export const TabEight = ({ sampleDetails }: TabProps) => {
  return (
    <div>
      <ReportsViewer sampleDetails={sampleDetails} />
    </div>
  )
}
