import { z } from 'zod'

const getTranslatedStr = (path: string): string => {
  const translatn = 'changePassword.errors'
  return `${translatn}.${path}`
}

export const ChangePasswordFormSchema = z.object({
  oldPassword: z.string().min(8, { message: getTranslatedStr('passwordMinLength') }),
  newPassword: z
    .string()
    .min(8, { message: getTranslatedStr('passwordMinLength') })
    .regex(/^[a-zA-Z]./, {message: getTranslatedStr('passwordStartChar')})
    .regex(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])/, { message: getTranslatedStr('invalidPassword') }),
  confirmPassword: z.string().min(8, { message: getTranslatedStr('passwordMinLength') }),
})
