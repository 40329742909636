import React  from 'react'
import { useTranslation } from 'react-i18next'
import Container from 'react-bootstrap/Container'
import {  TabProps } from '../../../models/Sample'
import { ViewSampleDetailsRow } from './ViewSampleDetailsRow'
import _ from 'lodash'

export const VitalStatus = (props: TabProps) => {
  const { sampleDetails } = props
  const { t } = useTranslation()

  const translatn = 'viewSample.'
  const parentClass = ''
  const detailsArr = [
    {
      label: t(`${translatn}vitalWeight`),
      value: sampleDetails?.vitalWeight,
      show: true
    },
    {
      label: t(`${translatn}vitalHeight`),
      value: sampleDetails?.vitalHeight,
      show: true
    },
    {
      label: t(`${translatn}vitalBMI`),
      value: sampleDetails?.vitalBMI,
      show: true
    },
    {
      label: t(`${translatn}vitalTemperature`),
      value: sampleDetails?.vitalTemperature,
      show: true
    },    
    {
      label: t(`${translatn}dateOfDeath`),
      value: sampleDetails?.dateOfDeath,
      show: true
    },
    {
      label: t(`${translatn}dateOfLastContact`),
      value: sampleDetails?.dateOfLastContactActivity,
      show: !!sampleDetails?.dateOfDeath
    },
    {
      label: t(`${translatn}ageAtLastContact`),
      value: sampleDetails?.ageAtLastContactActivity,
      show: !!sampleDetails?.dateOfDeath
    },
    {
      label: t(`${translatn}ageAtDeath`),
      value: sampleDetails?.ageAtDeath,
      show: !!sampleDetails?.dateOfDeath
    },
    {
      label: t(`${translatn}causeOfDeathContributingFactors`),
      value: sampleDetails?.causeOfDeathContributingFactors,
      show: !!sampleDetails?.dateOfDeath
    },
    {
      label: t(`${translatn}causeOfDeathAutopsyFindings`),
      value: sampleDetails?.causeOfDeathAutopsyFindings,
      show: !!sampleDetails?.dateOfDeath
    },
    {
      label: t(`${translatn}causeOfDeathMedicalHistory`),
      value: sampleDetails?.causeOfDeathMedicalHistory,
      show: !!sampleDetails?.dateOfDeath
    },
    {
      label: t(`${translatn}causeOfDeathDocumentationOfTerminalEvents`),
      value: sampleDetails?.causeOfDeathDocumentationOfTerminalEvents,
      show: !!sampleDetails?.dateOfDeath
    }
  ]

  const data = detailsArr.filter((details) => details.show === true)

  const rowCount = _.chunk(data, 5)

  return (
    <Container fluid>
      <h5> {t(`${translatn}vitalStatus`)}</h5>
      {rowCount.map((row, index) => (
        <ViewSampleDetailsRow
          key={index}
          index={index}
          rowArr={rowCount[index]}
          parentClass={parentClass}
        />
      ))}
    </Container>
  )
}
