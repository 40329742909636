import React from 'react'
import Container from 'react-bootstrap/Container'
import { useTranslation } from 'react-i18next'
import { TabProps } from '../../../models/Sample'
import { ReactTable } from '../../CommonComponents'
import './styles/TestDetails.scss'

export const TestDetails = (props: TabProps) => {
  const { sampleDetails } = props
  const { t } = useTranslation()
  const translatn = 'viewSample.'
  const parentClass = 'view-sample-tests'
  const columns = [
    {
      id: 'primaryTestTag',
      header: t(`${translatn}primaryTestTag`),
      accessorKey: 'primaryTestTag',
      enableSorting: false,
      cell: (info: any) => info.getValue(),
    },
    {
      id: 'testId',
      header: t(`${translatn}testId`),
      accessorKey: 'testId',
      enableSorting: false,
      cell: (info: any) => info.getValue(),
    },
    {
      id: 'tat',
      header: t(`${translatn}tat`),
      accessorKey: 'tat',
      enableSorting: false,
      cell: (info: any) => info.getValue() ?? '-',
    },
    {
      id: 'ppn',
      header: t(`${translatn}ppn`),
      accessorKey: 'ppn',
      enableSorting: false,
      cell: (info: any) => info.getValue() ?? '-' ,
    },
    {
      id: 'testStatus',
      header: t(`${translatn}testStatus`),
      accessorKey: 'status',
      enableSorting: false,
      cell: (info: any) => info.getValue()? t(`sampleStatus.${info.getValue()}`): t(`sampleStatus.invalid`),
    },
  ]

  return (
    <Container fluid className={`${parentClass}`}>
      <h5> {t(`${translatn}testDetails`)}</h5>
      <ReactTable
        data={sampleDetails?.testType ?? []}
        columns={columns}
        pageChangeHandler={() => {
          return
        }}
        tableClassName={'view-sample-tests'}
        showPagination={false}
        totalRows={sampleDetails?.testType?.length}
      />

      {!sampleDetails?.testType.length && (
        <div className={`${parentClass}-empty-table`}>{t(`${translatn}noTestAvailable`)}</div>
      )}
    </Container>
  )
}
