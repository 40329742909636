export const SampleStatusMasterData = {
  sampleStatusIDValues: () => {
    type idOptionType = {
      [key: number]: string
    }

    const sampleStatusIDMap: idOptionType = {
      0: 'draft',
      1: 'ready_for_shipment',
      2: 'shipped',
      3: 'report_ready',
    }

    const sampleStatusValueMap = {
      'draft': 0,
      'ready_for_shipment': 1,
      'shipped': 2,
      'report_ready': 3,
    }

    return { sampleStatusIDMap: sampleStatusIDMap, sampleStatusValueMap: sampleStatusValueMap }
  },

  getValue: (sampleStatusID: number | undefined) => {
    const { sampleStatusIDMap } = SampleStatusMasterData.sampleStatusIDValues()
    if (sampleStatusID !== undefined) {
      return sampleStatusIDMap[sampleStatusID] ?? 'invalid'
    }
    return 'invalid'
  },
}
