import React, { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { useFormContext } from 'react-hook-form'

import { NumberInput, DateInput, SelectInput, TextInput, RadioInput } from '../../InputComponents'
import { getDateOfBirth, setAgeAtEvent, surgeryBiopsyDoneAgeFields, surgeryBiopsyDoneDateFields, surgeryBiopsyDoneFields, surgeryBiopsyDoneTextFields} from '../helpers/AddSampleInfoHelpers'
import { MASTER_DATA_KEYS as MDK, MASTER_DATA_ID as MDI  } from '../../../utils/constants'
import { MasterDataContext } from '../../../contexts'
import { AppUtil, AppUtil as _ } from '../../../utils/app.utils'

export const SurgeryBiopsy = () => {
  const { t } = useTranslation()
  const { setValue, getValues, watch, resetField, clearErrors } = useFormContext()
  const { masterData } = useContext(MasterDataContext)
  const translatn = 'sampleInfoForm'
  const parentClass = 'vcr-pp-sample-info-'
  const dob = getValues('dob')

  let isSurgeryBiopsyDone = watch('surgeryBiopsyDone') === MDI.SURGERY_BIOPSY_DONE_OPTION_YES
  
  useEffect(()=> {
    if(!isSurgeryBiopsyDone){
      surgeryBiopsyDoneFields.forEach((field) => {
        resetField(field, { defaultValue: '' })
        clearErrors(field)
      })
      surgeryBiopsyDoneDateFields.forEach((field) => {
        resetField(field, { defaultValue: null })
        clearErrors(field)
      })
      surgeryBiopsyDoneTextFields.forEach((field) => {
        resetField(field, { defaultValue: '' })
        clearErrors(field)
      })
      surgeryBiopsyDoneAgeFields.forEach((field) => {
        resetField(field, { defaultValue: null })
        clearErrors(field)
      })
    }
  }, [isSurgeryBiopsyDone])

  return (
    <div className={`${parentClass}input-field`}>
      <h5 className={`${parentClass}form-heading`}> {t(`${translatn}.surgeryBiopsy`)}</h5>
      <Row lg={4} md={4} sm={4}>
        <Col>
          <RadioInput
            name={`surgeryBiopsyDone`}
            id="surgeryBiopsyDone"
            valueArray={_.sortOptionsDesc(_.getOptions(MDK.SURGERY_BIOPSY_DONE, masterData))}
            title={t(`${translatn}.surgeryBiopsyDone`)}
            disabled={false}
            inline={true}
            required={true}
          />
        </Col>
        </Row>
      <Row lg={4} md={4} sm={4}>
        {isSurgeryBiopsyDone && (<>
          <Col>
          <SelectInput
            name={`originalPrimarySiteOfDiagnosisForSurgeryPerformed`}
            id={'originalPrimarySiteOfDiagnosis'}
            title={t(`${translatn}.originalPrimarySiteOfDiagnosis`)}
            placeholder={t(`${translatn}.originalPrimarySiteOfDiagnosisPlaceholder`)}
            disabled={false}
            optionsValueArray={_.getOptions(MDK.DIAGNOSIS_PRIMARY_SITE_FOR_SURGERY, masterData)}
            onCustomChange={(event:React.ChangeEvent<HTMLSelectElement>) => AppUtil.resetOtherField(event.target.value, MDI.DIAGNOSIS_PRIMARY_SITE_FOR_SURGERY_OTHER, resetField, 'originalPrimarySiteOfDiagnosisForSurgeryPerformedOther')}
            required={false}
          />
        </Col>
        {watch('originalPrimarySiteOfDiagnosisForSurgeryPerformed') === MDI.DIAGNOSIS_PRIMARY_SITE_FOR_SURGERY_OTHER && (
          <Col>
            <TextInput
              type="text"
              name={`originalPrimarySiteOfDiagnosisForSurgeryPerformedOther`}
              id={'originalPrimarySiteOfDiagnosisOther'}
              title={t(`${translatn}.originalPrimarySiteOfDiagnosisOther`)}
              placeholder={t(`${translatn}.originalPrimarySiteOfDiagnosisOtherPlaceholder`)}
              disabled={false}
              readOnly={false}
              required={false}
            />
          </Col>
        )}
        <Col>
          <SelectInput
            name={`locationForSurgeryBiopsy`}
            id={'locationForSurgery'}
            title={t(`${translatn}.locationForSurgery`)}
            placeholder={t(`${translatn}.locationForSurgeryPlaceholder`)}
            disabled={false}
            optionsValueArray={_.getOptions(MDK.SURGERY_BIOPSY_LOCATION, masterData)}
            required={false}
            onCustomChange={(event:React.ChangeEvent<HTMLSelectElement>) => AppUtil.resetOtherField(event.target.value, MDI.SURGERY_BIOPSY_LOCATION_OTHER, resetField, 'locationForSurgeryBiopsyOther')}
          />
        </Col>
        {watch('locationForSurgeryBiopsy') === MDI.SURGERY_BIOPSY_LOCATION_OTHER && (
          <Col>
            <TextInput
              type="text"
              name={`locationForSurgeryBiopsyOther`}
              id={'locationForSurgeryBiopsyOther'}
              title={t(`${translatn}.locationForSurgeryOther`)}
              placeholder={t(`${translatn}.locationForSurgeryOtherPlaceholder`)}
              disabled={false}
              readOnly={false}
              required={false}
            />
          </Col>
        )}
        <Col>
          <SelectInput
            name={`surgeryType`}
            id={'surgeryType'}
            title={t(`${translatn}.surgeryType`)}
            placeholder={t(`${translatn}.surgeryTypePlaceholder`)}
            disabled={false}
            required={true}
            optionsValueArray={_.getOptions(MDK.SURGERY_TYPE, masterData)}
          />
        </Col>
        <Col>
        <TextInput
              type="text"
              name={`surgeryTypeDetails`}
              id={'surgeryTypeDetails'}
              title={t(`${translatn}.surgeryTypeDetails`)}
              placeholder={t(`${translatn}.surgeryTypeDetailsPlaceholder`)}
              disabled={false}
              readOnly={false}
              required={false}
            />
        </Col>
        <Col>
        <SelectInput
            name={`biopsyType`}
            id={'biopsyType'}
            title={t(`${translatn}.biopsyType`)}
            placeholder={t(`${translatn}.biopsyTypePlaceholder`)}
            disabled={false}
            required={true}
            optionsValueArray={_.getOptions(MDK.BIOPSY_TYPE, masterData)}
          />
        </Col>
        <Col>
        <TextInput
              type="text"
              name={`biopsyTypeDetails`}
              id={'biopsyTypeDetails'}
              title={t(`${translatn}.biopsyTypeDetails`)}
              placeholder={t(`${translatn}.biopsyTypeDetailsPlaceholder`)}
              disabled={false}
              readOnly={false}
              required={false}
            />
        </Col>
        <Col>
        <SelectInput
            name={`anesthesiaType`}
            id={'anesthesiaType'}
            title={t(`${translatn}.anesthesiaType`)}
            placeholder={t(`${translatn}.anesthesiaTypePlaceholder`)}
            disabled={false}
            required={true}
            optionsValueArray={_.getOptions(MDK.ANETHESIA_TYPE, masterData)}
          />
        </Col>
        <Col>
        <TextInput
              type="text"
              name={`anethesiaTypeDetails`}
              id={'anethesiaTypeDetails'}
              title={t(`${translatn}.anesthesiaTypeDetails`)}
              placeholder={t(`${translatn}.anesthesiaTypeDetailsPlaceholder`)}
              disabled={false}
              readOnly={false}
              required={false}
            />
        </Col>
        <Col>
        <SelectInput
            name={`surgicalApproach`}
            id={'surgicalApproach'}
            title={t(`${translatn}.surgicalApproach`)}
            placeholder={t(`${translatn}.surgicalApproachPlaceholder`)}
            disabled={false}
            required={true}
            optionsValueArray={_.getOptions(MDK.SURGICAL_APPROACH, masterData)}
          />
        </Col>
        <Col>
        <TextInput
              type="text"
              name={`surgicalApproachDetails`}
              id={'surgicalApproachDetails'}
              title={t(`${translatn}.surgicalApproachDetails`)}
              placeholder={t(`${translatn}.surgicalApproachDetailsPlaceholder`)}
              disabled={false}
              readOnly={false}
              required={false}
            />
        </Col>
        <Col>
          <DateInput
            name={`dateOfSurgeryBiopsy`}
            id={'dateOfSurgery'}
            title={t(`${translatn}.dateOfSurgery`)}
            placeholder={t(`common.datePlaceholder`)}
            disabled={false}
            readOnly={false}
            required={false}
            min={new Date(getDateOfBirth(dob)) ?? undefined}
            max={new Date()}
            onChange={(date: Date) => {
              if(date) {
                setAgeAtEvent(dob, date, `ageAtSurgeryBiopsy`, setValue)
              } else {
                surgeryBiopsyDoneAgeFields.forEach((field) => {
                  resetField(field, { defaultValue: null })
                  clearErrors(field)
                })
              }
            } } isClearable={true}          />
        </Col>
        <Col>
          <NumberInput
            name={`ageAtSurgeryBiopsy`}
            id={'ageAtSurgeryBiopsy'}
            title={t(`${translatn}.ageAtSurgeryBiopsy`)}
            placeholder={t(`${translatn}.ageAtSurgeryBiopsyPlaceholder`)}
            disabled={false}
            readOnly={true}
            required={false}
          />
        </Col>
        </>)}

      </Row>
    </div>
  )
}
