import React, { useContext } from 'react'
import Container from 'react-bootstrap/Container'
import { useTranslation } from 'react-i18next'
import { TabProps } from '../../../models/Sample'
import { ViewSampleDetailsRow } from './ViewSampleDetailsRow'
import { MASTER_DATA_KEYS as MDK, MASTER_DATA_ID as MDI } from '../../../utils/constants'
import { MasterDataContext } from '../../../contexts'
import { AppUtil } from '../../../utils/app.utils'
import _ from 'lodash'

export const History = (props: TabProps) => {
  const { sampleDetails } = props
  const { t } = useTranslation()
  const translatn = 'viewSample.'
  const { masterData } = useContext(MasterDataContext)
  const parentClass = ''

  const multiplePremalignantOrComorbidIllnesses = AppUtil.convertStrIntoArr(
    _.isString(sampleDetails?.multiplePremalignantOrComorbidIllnesses) &&
      sampleDetails?.multiplePremalignantOrComorbidIllnesses?.length
      ? sampleDetails?.multiplePremalignantOrComorbidIllnesses
      : '',
  )
  const multiplePremalignantOrComorbidIllnessesCapturedHereDetails = JSON.parse(
    _.isString(sampleDetails?.multiplePremalignantOrComorbidIllnessesCapturedHereDetails) &&
      sampleDetails?.multiplePremalignantOrComorbidIllnessesCapturedHereDetails?.length
      ? sampleDetails?.multiplePremalignantOrComorbidIllnessesCapturedHereDetails
      : '{}',
  )

  const detailsArr = [
    {
      label: t(`${translatn}smokingStatus`),
      value: AppUtil.getLabel(MDK.SMOKING_STATUS, masterData, sampleDetails?.smokingStatus),
      show: true,
    },
    {
      label: t(`${translatn}alcoholUse`),
      value: AppUtil.getLabel(MDK.ALCOHOL_USE, masterData, sampleDetails?.alcoholUse),
      show: true,
    },
    {
      label: t(`${translatn}multipleIllnesses`),
      value: AppUtil.getMultiSelectLabelsWithOther(
        MDK.PRE_MALIGNANT_COMORBID_ILLNESS,
        masterData,
        multiplePremalignantOrComorbidIllnesses,
        multiplePremalignantOrComorbidIllnessesCapturedHereDetails,
      ),
      show: true,
    },
    {
      label: t(`${translatn}organTransplantDone`),
      value: AppUtil.getLabel(MDK.ORGAN_TRANSPLANT_DONE, masterData, sampleDetails?.organTransplantDone),
      show: true,
    },
    {
      label: t(`${translatn}dateOfOrganTransplant`),
      value: sampleDetails?.dateOfOrganTransplant,
      show: sampleDetails?.organTransplantDone == MDI.ORGAN_TRANSPLANTATION_DONE_OPTION_YES,
    },
    {
      label: t(`${translatn}ageAtOrganTransplant`),
      value: sampleDetails?.ageAtOrganTransplant,
      show: sampleDetails?.organTransplantDone == MDI.ORGAN_TRANSPLANTATION_DONE_OPTION_YES,
    },
    {
      label: t(`${translatn}siteOfOrganTransplant`),
      value: AppUtil.getFormattedValueWithOther(
        MDK.ORGAN_TRANSPLANT_SITE,
        masterData,
        sampleDetails?.siteAtOrganTransplant,
        sampleDetails?.siteAtOrganTransplantOther,
      ),
      show: sampleDetails?.organTransplantDone == MDI.ORGAN_TRANSPLANTATION_DONE_OPTION_YES,
    },
  ]

  const data = detailsArr.filter((details) => details.show === true)

  const rowCount = _.chunk(data, 5)

  return (
    <Container fluid>
      <h5 className="fw-bold "> {t(`${translatn}history`)}</h5>
      {rowCount.map((row, index) => (
        <ViewSampleDetailsRow key={index} index={index} rowArr={rowCount[index]} parentClass={parentClass} />
      ))}
    </Container>
  )
}
