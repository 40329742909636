import React, { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { useFormContext } from 'react-hook-form'
import {
  NumberInput,
  DateInput,
  SelectInput,
  TextInput,
  RadioInput,
  MultiSelectDropdown,
  TextInputWithAddOn,
} from '../../InputComponents'
import { setAgeAtEvent } from '../helpers'
import { MASTER_DATA_KEYS as MDK, MASTER_DATA_ID as MDI } from '../../../utils/constants'
import { MasterDataContext } from '../../../contexts'
import { AppUtil, AppUtil as _ } from '../../../utils/app.utils'
import { MultiSelectItem } from '../../../models/Sample'
import {
  ageAtOrganTransplantFields,
  getDateOfBirth,
  organTransplantFields,
  siteAtOrganTransplantFields,
  siteAtOrganTransplantOtherFields,
} from '../helpers/AddSampleInfoHelpers'

export const History = () => {
  const { t } = useTranslation()
  const { setValue, getValues, watch, resetField, clearErrors } = useFormContext()
  const { masterData } = useContext(MasterDataContext)
  const translatn = 'sampleInfoForm'
  const parentClass = 'vcr-pp-sample-info-'
  const dob = getValues('dob')
  const handleRemoveMultiplePreMalignant = (value: string | number) => {
    setValue(
      'multiplePremalignantOrComorbidIllnesses',
      getValues('multiplePremalignantOrComorbidIllnesses')?.filter(
        (item: { value: string; label: string }) => item.value?.toString() !== value?.toString(),
      ),
    )
    resetField(`multiplePremalignantOrComorbidIllnessesCapturedHereDetails`, {defaultValue: {}})
  }

  let isRequired = watch('organTransplantDone') == MDI.ORGAN_TRANSPLANTATION_DONE_OPTION_YES

  useEffect(() => {
    if (watch('organTransplantDone') != MDI.ORGAN_TRANSPLANTATION_DONE_OPTION_YES) {
      organTransplantFields.forEach((field) => {
        resetField(field, { defaultValue: null })
        clearErrors(field)
      })

      ageAtOrganTransplantFields.forEach((field) => {
        resetField(field, { defaultValue: null })
      })

      siteAtOrganTransplantFields.forEach((field) => {
        resetField(field, { defaultValue: '' })
        clearErrors(field)
      })

      siteAtOrganTransplantOtherFields.forEach((field) => {
        resetField(field, { defaultValue: '' })
      })
      
    }
  }, [watch('organTransplantDone')])

  return (
    <div className={`${parentClass}input-field`}>
      <h5 className={`${parentClass}form-heading`}> {t(`${translatn}.history`)}</h5>
      <Row lg={4} md={4} sm={4}>
        <Col>
          <SelectInput
            name={`smokingStatus`}
            id={'smokingStatus'}
            title={t(`${translatn}.smokingStatus`)}
            placeholder={t(`${translatn}.smokingStatusPlaceholder`)}
            disabled={false}
            required={true}
            optionsValueArray={_.getOptions(MDK.SMOKING_STATUS, masterData)}
          />
        </Col>
        <Col>
          <SelectInput
            name={`alcoholUse`}
            id={'alcoholUse'}
            title={t(`${translatn}.alcoholUse`)}
            placeholder={t(`${translatn}.alcoholUsePlaceholder`)}
            disabled={false}
            required={true}
            optionsValueArray={_.getOptions(MDK.ALCOHOL_USE, masterData)}
          />
        </Col>
        </Row>
        <Row lg={4} md={4} sm={4}>
        <Col>
          <MultiSelectDropdown
            name={`multiplePremalignantOrComorbidIllnesses`}
            id={'multipleIllnesses'}
            title={t(`${translatn}.multipleIllnesses`)}
            placeholder={t(`${translatn}.multipleIllnessesPlaceholder`)}
            disabled={false}
            required={true}
            hideSelectedValues={false}
            optionsValueArray={_.getOptions(MDK.PRE_MALIGNANT_COMORBID_ILLNESS, masterData)}
            onCustomRemove={handleRemoveMultiplePreMalignant}
          />
        </Col>
        {watch('multiplePremalignantOrComorbidIllnesses')
          ?.filter((item: MultiSelectItem) => item.textBox)
          ?.map((type: MultiSelectItem) => {
            return (
              <Col key={type.value}>
                <TextInputWithAddOn
                  type="text"
                  name={`multiplePremalignantOrComorbidIllnessesCapturedHereDetails.type${type.value}`}
                  id={`multipleIllnessesOther.${type.value}`}
                  title={`${type.label}`}
                  placeholder={t(`${translatn}.multipleIllnessesOtherPlaceholder`)}
                  disabled={false}
                  readOnly={false}
                  required={false}
                />
              </Col>
            )
          })}
      </Row>
      <Row lg={4} md={4} sm={4}>
        <Col>
          <RadioInput
            name={`organTransplantDone`}
            id="organTransplantDone"
            valueArray={_.sortOptionsDesc(_.getOptions(MDK.ORGAN_TRANSPLANT_DONE, masterData))}
            title={t(`${translatn}.organTransplantDone`)}
            disabled={false}
            inline={true}
            required={true}
          />
        </Col>
        
        {isRequired && (
          <>      
            <Col>
              <DateInput
                name={`dateOfOrganTransplant`}
                id={'dateOfOrganTransplant'}
                title={t(`${translatn}.dateOfOrganTransplant`)}
                placeholder={t(`common.datePlaceholder`)}
                disabled={false}
                readOnly={false}
                min={new Date(getDateOfBirth(dob)) ?? undefined}
                max={new Date()}
                required={isRequired}
                onChange={(date: Date) => {
                  setAgeAtEvent(dob, date, `ageAtOrganTransplant`, setValue)
                }}
              />
            </Col>
            <Col>
              <NumberInput
                name={`ageAtOrganTransplant`}
                id={'ageAtOrganTransplant'}
                title={t(`${translatn}.ageAtOrganTransplant`)}
                placeholder={t(`${translatn}.ageAtOrganTransplantPlaceholder`)}
                disabled={true}
                readOnly={true}
                required={isRequired}
              />
            </Col>

          </>
        )}
        </Row>
        {isRequired && (<>
          <Row lg={4} md={4} sm={4}>
            <Col>
              <SelectInput
                name={`siteAtOrganTransplant`}
                id={'siteOfOrganTransplant'}
                title={t(`${translatn}.siteOfOrganTransplant`)}
                placeholder={t(`${translatn}.siteOfOrganTransplantPlaceholder`)}
                disabled={false}
                optionsValueArray={_.getOptions(MDK.ORGAN_TRANSPLANT_SITE, masterData)}
                required={isRequired}
                onCustomChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
                  AppUtil.resetOtherField(
                    event.target.value,
                    MDI.ORGAN_TRANSPLANT_SITE_OTHER,
                    resetField,
                    'siteAtOrganTransplantOther',
                  )
                }
              />
            </Col>
            {watch('siteAtOrganTransplant') === MDI.ORGAN_TRANSPLANT_SITE_OTHER && (
              <Col>
                <TextInput
                  type="text"
                  name={`siteAtOrganTransplantOther`}
                  id={'siteOfOrganTransplantOther'}
                  title={t(`${translatn}.siteOfOrganTransplantOther`)}
                  placeholder={t(`${translatn}.siteOfOrganTransplantOtherPlaceholder`)}
                  disabled={false}
                  readOnly={false}
                  required={false}
                />
              </Col>
            )}</Row></>)}
    
    </div>
  )
}
