import moment from 'moment'
import { UseFormSetValue } from 'react-hook-form/dist/types'
import { z } from 'zod'
import { Patient } from '../../../models/RegisterPatient'
import { AppUtil } from '../../../utils/app.utils'

const { formatDateForPayload } = AppUtil

export const setAgeAtEvent = (dob: string, eventDate: Date, name: string, setValue: UseFormSetValue<any>) => {
  const diff = moment(eventDate).diff(dob, 'years')
  setValue(name, diff)
}

const getTranslatedStr = (path: string): string => {
  const translatn = 'registerPatient.errors'
  return `${translatn}.${path}`
}

export const schemaHelpers = {
  date: z.coerce.date(),
  string: z.string(),
  number: z.coerce.number(),
  nullableDate: z.coerce.date().nullable(),  
  nullableStr:z.string().nullable()
  .refine(val => val===null || (typeof val==='string' && val.trim().length<=100),
  {message: getTranslatedStr('inputLength') })
  .transform(val=>val === null ?null:val.trim()),
  nullableAddress:z.string().nullable().pipe(z
  .string()
  .trim()
  .max(200,{ message: getTranslatedStr('addressLength') })),
  nullableNumber: z.coerce.number().nullable(),
  requiredSelect: z.coerce.string().min(1, { message: getTranslatedStr('required') }),
  requiredRadio: z.string().min(1, { message: getTranslatedStr('required') }),
  multiselect: z.string().array().optional(),
  select: z.string(),
  radio: z.string(),
  requiredDate: z.coerce.string().transform((dateString, ctx) => {
    if (!dateString || dateString == 'null') {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: getTranslatedStr('required'),
      })
    }
    const date = new Date(dateString)
    if (!z.date().safeParse(date).success) {
      ctx.addIssue({
        code: z.ZodIssueCode.invalid_date,
      })
    }
    return date
  }),
  requiredMultiselect: z
    .string()
    .array()
    .nonempty({ message: getTranslatedStr('required') }),
  requiredEmail: z
    .string()
    .min(2, { message: getTranslatedStr('required') })
    .max(100, { message: getTranslatedStr('maxEmailLength') })
    .email(getTranslatedStr('invalidEmail')),
  requiredPhone: z
    .string()
    .trim()
    .min(1, { message: getTranslatedStr('required') })
    .min(10, { message: getTranslatedStr('minPhoneLength') })
    .regex(/^[\d]{10}$/, getTranslatedStr('invalidPhone')),
  requiredStr: z
    .coerce
    .string()
    .min(1, { message: getTranslatedStr('required') }).max(30,{ message: getTranslatedStr('inputLength') }),
  requiredStrWithMinMax: (min: number, max: number) => z
    /* .coerce */
    .string()
    .min(min, { message: getTranslatedStr('required') }).max(max,{ message: getTranslatedStr('inputLength') }),
  requiredNumeric: z
  .string()
  .min(1, { message: getTranslatedStr('required') })
  .pipe(z.coerce.number().positive(getTranslatedStr('invalidNumber')))
  .refine((val) => {
    return val
  })
  .or(z.coerce.number().refine((val) => val === null, { message: getTranslatedStr('required') }))
  .or(z.number()),
  requiredAddress: z
    .string()
    .trim()
    .min(1, { message: getTranslatedStr('required') }).max(200,{ message: getTranslatedStr('addressLength') }),
  requiredName: z
    .string()
    /* .trim() */
    .min(1, { message: getTranslatedStr('required') })
    .regex(/^[a-zA-Z'._-]+$/, getTranslatedStr('nameGenericCharCheck')).max(50,{ message: getTranslatedStr('nameLength') }),
  emptyAlphabetText: z
  .string().nullable()
  .refine(val =>val ===null ||val ==='' || /^[a-zA-Z'._-]+$/.test(val),{message: getTranslatedStr('nameGenericCharCheck')})
  .refine(val =>val ===null || val ==='' || val.length<=50,{message: getTranslatedStr('nameLength')})
  ,
  requiredFileWithSizeAndType: (maxSize: number, acceptedTypes: string[]) =>
    z
      .any()
      .refine((file) => file, getTranslatedStr('required'))
      .refine((file) => file[0]?.size <= maxSize, getTranslatedStr('maxFile'))
      .refine((file) => acceptedTypes.includes(file[0]?.type), getTranslatedStr('acceptedFiles')),
  requiredStrWithLen: (min: number) =>
    z
      .string()
      .trim()
      .min(1, { message: getTranslatedStr('required') })
      .min(min, { message: getTranslatedStr('required') }),
  requiredNumber: z.coerce
    .number({
      required_error: getTranslatedStr('requiredNumber'),
    })
    .positive(getTranslatedStr('validNumber')),
  requiredNumberWithMinMaxLen: (min: number, max: number, minTrnsltnPath: string, maxTrnsltnPath: string) =>
    z
      .string()
      .min(min, { message: getTranslatedStr(minTrnsltnPath) })
      .max(max, { message: getTranslatedStr(maxTrnsltnPath) })
      .pipe(z.coerce.number())
      .refine((val) => {
        return val
      }),
  requiredCheckbox: z
    .string()
    .array()
    .nonempty({ message: getTranslatedStr('required') }),
  requiredConsent: z.coerce.string().refine((val) => val === 'true', {
    message: getTranslatedStr('required'),
  }),
  requiredPincode: z
  .coerce
  .string()
  .min(1, { message: getTranslatedStr('required') })
  .min(6, { message: getTranslatedStr('pincodeLength') })
  .max(6,{ message: getTranslatedStr('pincodeLength') })
  .pipe(z.coerce.number())
  .refine((val) => {
    return val
  }),
}

export const formatRequestPayload = (data: Patient) => {
  return {
    ...data,
    consentProvided: Boolean(data.consentProvided),
    dob: formatDateForPayload(data.dob),
    registrationDate: formatDateForPayload(data.registrationDate),
    uhid: data.uhid ? Number(data.uhid) : null
  }
}
