import axios from "axios";
import { apiRoutes } from "../utils/routes";
import { AppUtil } from "../utils/app.utils";
import { HandwrittenNotesRequestPayload } from "../models/HandwrittenNotes";

export const getLumiseekFiles = async (patientId:string) =>
{
  const response = await axios.post(apiRoutes.handwrittenNotes.getLumiseekFiles,
    {
      patientId: Number(patientId)
    },
    AppUtil.getCommonAPIConfig())
  return response;
}

export const getLumiseekImageData = async (url: string) => {
  const response = await axios.post(apiRoutes.handwrittenNotes.getImageData,
    {
      url: url
    },
    AppUtil.getCommonAPIConfig())
    return response;
}

export const getHandwrittenNotes = async (id: string, signal?: AbortSignal,  value?: string) => {

  const response = await axios.post(
    apiRoutes.handwrittenNotes.get,
    { patientId: Number(id), searchString: value},
    {...AppUtil.getCommonAPIConfig(), signal}
  )

  return response
}

export const getHandwrittenNotesByTags = async (id: string) => {

  const response = await axios.post(
    apiRoutes.handwrittenNotes.getTags,
    { patientId: Number(id)},
    AppUtil.getCommonAPIConfig()
  )

  return response
}

export const addHandwrittenNotes = async (requestBody: HandwrittenNotesRequestPayload) => {
  const response = await axios.post(
    apiRoutes.handwrittenNotes.add,
    {
      ...requestBody
    },
    AppUtil.getCommonAPIConfig()
  )

  return response
}

export const getHandwrittenNotesUploadURLs = async(patientId: string, fileName: string) => {
  const response = await axios.post(apiRoutes.handwrittenNotes.upload, 
    { patientId: patientId, noteName: fileName}, 
    AppUtil.getCommonAPIConfig()
  )
  return response
}

export const uploadHandwrittenNoteData = async(uploadURL: string, pdfBytesData: File) => {
  const contentType = 'application/*'
  const fileBlob = new Blob([pdfBytesData],  {type: contentType})

  const response = await axios.put(
    uploadURL,
    fileBlob,
    {
      headers: {
        "Content-Type": contentType,
      }
    }
  )

  return response
}

export const getHandwrittenNotesById = async (id: string) => {

  const response = await axios.post(
    apiRoutes.handwrittenNotes.getById ,
    {id: Number(id)},
    AppUtil.getCommonAPIConfig()
  )

  return response
}

export const getHandwrittenNotesDownloadURLs = async(patientId: string, fileName: string) => {
  const response = await axios.post(apiRoutes.handwrittenNotes.download, 
    { patientId: patientId, noteName: fileName}, 
    AppUtil.getCommonAPIConfig()
  )
  return response
}

export const updateHandwrittenNoteDetails = async (requestBody: HandwrittenNotesRequestPayload) => {

  const response = await axios.post(
    apiRoutes.handwrittenNotes.update,
    {
      ...requestBody
    },
    AppUtil.getCommonAPIConfig()
  )

  return response
}


export const convertImageToText = async(patientId: string, fileName: string) => {
  const response = await axios.post(apiRoutes.handwrittenNotes.convertImgToTxt,
    { "s3": { "bucket": { "name": "vcrpark-qa-val-pp-bkt" }, "object": { "key": `s3%2Fpatients%2F${patientId}%2Fnotes%2F${fileName}` } } }, { "headers": { 'x-api-key': "Yb8C0QBKEp7vjNU55Ba8A7zN9DP51EEg8TUJY0Oq" } }
  )
  return response
}
