import React from 'react'
import Button from 'react-bootstrap/Button'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { appRoutes } from '../../utils/routes'
import './NotFoundPage.scss'

export const NotFoundPage = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const translatn = 'notFound'
  const parentClass = 'vcr-pp-not-found-page'

  const handleGoHome = () => {
    navigate(appRoutes.home)
  }

  return (
    <div className={`${parentClass}-container`}>
      <h1>{t(`${translatn}.error`)}</h1>
      <h4>{t(`${translatn}.message`)}</h4>
      <Button onClick={handleGoHome}>{t(`${translatn}.goHome`)}</Button>
    </div>
  )
}
