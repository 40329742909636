import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { useFormContext } from 'react-hook-form'

import {
  NumberInput,
  DateInput,
  MultiSelectDropdown,
  SelectInput,
  RadioInput,
  TextInput,
} from '../../InputComponents'
import { setAgeAtEvent } from '../helpers'
import { MASTER_DATA_KEYS as MDK, MASTER_DATA_ID as MDI } from '../../../utils/constants'
import { MasterDataContext } from '../../../contexts'
import { AppUtil, AppUtil as _ } from '../../../utils/app.utils'
import { MultiSelectItem } from '../../../models/Sample'
import { getDateOfBirth } from '../helpers/AddSampleInfoHelpers'

export const ImagingData = () => {
  const { t } = useTranslation()
  const { setValue, getValues, watch, resetField } = useFormContext()
  const { masterData } = useContext(MasterDataContext)
  const translatn = 'sampleInfoForm'
  const parentClass = 'vcr-pp-sample-info-'
  const dob = getValues('dob')

  const handleRemoveImagingTypeOfScan = (value: string | number) => {
    setValue(
      'imagingTypeOfScan',
      getValues('imagingTypeOfScan')?.filter(
        (item: { value: string; label: string }) => item.value?.toString() !== value?.toString(),
      ),
    )
    resetField('imagingTypeOfScanDetails', {defaultValue: {}})
  }
  
  const handleImagingData=(selectedItems:MultiSelectItem[],selectedItem:MultiSelectItem)=>
  {
    if(selectedItem.value == MDI.IMAGING_TYPE_OF_SCAN_NONE ) {
      setValue( 'imagingTypeOfScan', [{...selectedItem}]);
    } else {
      resetField('imagingTypeOfScanDetails', {defaultValue: {}})
      setValue( 'imagingTypeOfScan', selectedItems.filter((item:MultiSelectItem) => item.value != MDI.IMAGING_TYPE_OF_SCAN_NONE));
    }
  }

  return (
    <div className={`${parentClass}input-field`}>
      <h5 className={`${parentClass}form-heading`}> {t(`${translatn}.imagingData`)}</h5>
      <Row lg={4} md={4} sm={4}>
        <Col>
          <MultiSelectDropdown
            name={`imagingTypeOfScan`}
            id={'typeOfScan'}
            title={t(`${translatn}.typeOfScan`)}
            placeholder={t(`${translatn}.typeOfScanPlaceholder`)}
            disabled={false}
            required={false}
            hideSelectedValues={false}
            onCustomRemove={handleRemoveImagingTypeOfScan}
            optionsValueArray={_.getOptions(MDK.IMAGING_SCAN_TYPE, masterData)}
            onCustomChange={handleImagingData}
          />
        </Col>
        {watch('imagingTypeOfScan')
          ?.filter((item: MultiSelectItem) => item.textBox)
          ?.map((type: MultiSelectItem) => {
            return (
              <Col key={type.value}>
                <TextInput
                  type="text"
                  name={`imagingTypeOfScanDetails.type${type.value}`}
                  id={`imagingTypeOfScanDetails.${type.value}`}
                  title={`${type.label}`}
                  placeholder={t(`${translatn}.imagingTypeOfScanDetailsPlaceholder`)}
                  disabled={false}
                  readOnly={false}
                  required={false}
                />
              </Col>
            )
          })}
          </Row>
          <Row lg={4} md={4} sm={4}>
        <Col>
          <SelectInput
            name={`imagingModalityMethod`}
            id={'imagingMethod'}
            title={t(`${translatn}.imagingMethod`)}
            placeholder={t(`${translatn}.imagingMethodPlaceholder`)}
            disabled={false}
            required={false}
            optionsValueArray={_.getOptions(MDK.IMAGING_MODALITY_METHOD, masterData)}
          />
        </Col>
        <Col>
          <DateInput
            name={`dateOfImagingScan`}
            id={'dateOfImagingScan'}
            title={t(`${translatn}.dateOfImagingScan`)}
            placeholder={t(`common.datePlaceholder`)}
            disabled={false}
            readOnly={false}
            min={new Date(getDateOfBirth(dob)) ?? undefined}
            max={new Date()}
            required={false}
            onChange={(date: Date) => {
              setAgeAtEvent(dob, date, `ageAtImagingScan`, setValue)
            } } isClearable={true}          />
        </Col>
        <Col>
          <NumberInput
            name={`ageAtImagingScan`}
            id={'ageAtImagingScan'}
            title={t(`${translatn}.ageAtImagingScan`)}
            placeholder={t(`${translatn}.ageAtImagingScanPlaceholder`)}
            disabled={false}
            readOnly={true}
            required={false}
          />
        </Col>
        </Row>
        <Row lg={4} md={4} sm={4}>
        <Col>
          <SelectInput
            name={`imagingSiteForScan`}
            id={'siteForScan'}
            title={t(`${translatn}.siteForScan`)}
            placeholder={t(`${translatn}.siteForScanPlaceholder`)}
            disabled={false}
            required={false}
            optionsValueArray={_.getOptions(MDK.IMAGING_SCAN_SITE, masterData)}
            onCustomChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
              AppUtil.resetOtherField(
                event.target.value,
                MDI.IMAGING_SCAN_SITE_OTHER,
                resetField,
                'imagingSiteForScanOther',
              )
            }
          />
        </Col>
        {watch('imagingSiteForScan') === MDI.IMAGING_SCAN_SITE_OTHER && (
          <Col>
            <TextInput
              type="text"
              name={`imagingSiteForScanOther`}
              id={'siteForScanOther'}
              title={t(`${translatn}.siteForScanOther`)}
              placeholder={t(`${translatn}.siteForScanOtherPlaceholder`)}
              disabled={false}
              readOnly={false}
              required={false}
            />
          </Col>
          
        )}
         <Col>
          <TextInput
            name={`imagingScanSiteDetails`}
            id={'imagingScanSiteDetails'}
            title={t(`${translatn}.imagingScanSiteDetails`)}
            placeholder={t(`${translatn}.imagingScanSiteDetailsPlaceHolder`)}
            disabled={false}
            required={false}
          />
        </Col>
        </Row>
        <Row lg={4} md={4} sm={4}>
        <Col>
          <RadioInput
            name={`measurableDiseasePresent`}
            id={'measurableDiseasePresent'}
            title={t(`${translatn}.measurableDiseasePresent`)}
            disabled={false}
            required={false}
            inline={true}
            valueArray={_.sortOptionsDesc(_.getOptions(MDK.MEASURABLE_DISEASE_PRESENT, masterData))}
          />
        </Col>
        <Col>
          <RadioInput
            name={`newLesions`}
            id={'newLesions'}
            title={t(`${translatn}.newLesions`)}
            disabled={false}
            required={false}
            inline={true}
            valueArray={_.sortOptionsDesc(_.getOptions(MDK.NEW_LESIONS, masterData))}
          />
        </Col>
        </Row>
        <Row lg={4} md={4} sm={4}>
        <Col>
          <SelectInput
            name={`site`}
            id={'site'}
            title={t(`${translatn}.site`)}
            placeholder={t(`${translatn}.sitePlaceholder`)}
            disabled={false}
            required={false}
            optionsValueArray={_.getOptions(MDK.SITE, masterData)}
            onCustomChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
              AppUtil.resetOtherField(event.target.value, MDI.SITE_OTHER, resetField, 'siteOther')
            }
          />
        </Col>
        {watch('site') === MDI.SITE_OTHER && (
          <Col>
            <TextInput
              type="text"
              name={`siteOther`}
              id={'siteOther'}
              title={t(`${translatn}.siteOther`)}
              placeholder={t(`${translatn}.siteOtherPlaceholder`)}
              disabled={false}
              readOnly={false}
              required={false}
            />
          </Col>
        )}
      </Row>
    </div>
  )
}
