import React from 'react'
import { Medications } from './Medications'
import { StemCellTransplant } from './StemCellTransplant'
import { RadiationTreatment } from './RadiationTreatment'
import { OutcomesOfTreatment } from './OutcomesOfTreatment'
import { VitalStatus } from './VitalStatus'

export const TabFive = () => {
  return (
    <div>
      <Medications />
      <StemCellTransplant />
      <RadiationTreatment />
      <OutcomesOfTreatment />
      <VitalStatus />    
    </div>
  )
}
