import React, { useContext } from 'react'
import Container from 'react-bootstrap/Container'
import { useTranslation } from 'react-i18next'
import { TabProps } from '../../../models/Sample'
import { ViewSampleDetailsRow } from './ViewSampleDetailsRow'
import { AppUtil } from '../../../utils/app.utils'
import { MASTER_DATA_KEYS as MDK } from '../../../utils/constants'
import { MasterDataContext } from '../../../contexts'
import _ from 'lodash'

export const TumorSequencing = (props: TabProps) => {
  const { sampleDetails } = props
  const { masterData } = useContext(MasterDataContext)
  const { t } = useTranslation()

  const translatn = 'viewSample.'
  const parentClass = ''

  const typeOfTumorSequencing = AppUtil.convertStrIntoArr(_.isString(sampleDetails?.typeOfTumorSequencing) && sampleDetails?.typeOfTumorSequencing?.length  ? sampleDetails?.typeOfTumorSequencing : '')

  const typeOfTumorSequencingDetails = JSON.parse(_.isString(sampleDetails?.typeOfTumorSequencingDetails) && sampleDetails?.typeOfTumorSequencingDetails?.length ? sampleDetails?.typeOfTumorSequencingDetails : "{}")

  const detailsArr = [
    {
      label: t(`${translatn}typeOfTest`),
      value: AppUtil.getMultiselectLabels(typeOfTumorSequencing, MDK.TUMOR_SEQUENCING_TEST_TYPE, masterData),
      show: true
    },
    {
      label: t(`${translatn}otherTumorSequencingTestTypes`),
      value: sampleDetails?.otherTumorSequencing,
      show: true
    },
    {
      label: t(`${translatn}dateOfTumorSequencing`),
      value: sampleDetails?.dateOfTumorSequencing,
      show: true
    },
    {
      label: t(`${translatn}ageAtTumorSequencing`),
      value: sampleDetails?.ageAtTumorSequencing,
      show: true
    },
  ]

  AppUtil.createObject(typeOfTumorSequencingDetails, MDK.TUMOR_SEQUENCING_TEST_TYPE, masterData, detailsArr, 1)

  const data = detailsArr.filter((details) => details.show === true)

  const rowCount = _.chunk(data, 5)

  return (
    <Container fluid>
      <h5 className="fw-bold pt-3"> {t(`${translatn}tumorSequencing`)}</h5>
      {rowCount.map((row, index) => (
        <ViewSampleDetailsRow
          key={index}
          index={index}
          rowArr={rowCount[index]}
          parentClass={parentClass}
        />
      ))}
    </Container>
  )
}
