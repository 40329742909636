export const HandwrittenNotesDefaultData = {
    id: '',
    patientId: '',
    file: null,
    predefined_Tags: [],
    custom_Tags: '',
    dataSource: 'PP',
    notes: '',
    original_Converted_Text: '',
    createdDate: '',
    lastModified: '',
    image_Path: '',
    lumiseekfile:''
}