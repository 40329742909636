import React, { useContext } from "react";
import { PencilSquare } from "react-bootstrap-icons";
import { HandwrittenNotes } from "../../../models/HandwrittenNotes";
import { useTranslation } from "react-i18next";
import { AppUtil } from "../../../utils/app.utils";
import { AuthUtil } from "../../../utils/auth.utils";
import { PRIVILEGES as P, MASTER_DATA_KEYS as MDK } from '../../../utils/constants'
import { MasterDataContext } from "../../../contexts";

type ViewHandwrittenNotesDetailsTypes = {
  patientID: string
  note: HandwrittenNotes
  index: number
  handleEdit: (id: any) => void
}

export const ViewHandwrittenNotesDetails = (props: Readonly<ViewHandwrittenNotesDetailsTypes>) => {
  const { t } = useTranslation()
  const translatn = 'viewHandwrittenNotes.'
  const parentClass = 'view-handwritten-notes-details'
  const hasEditHandwrittenNotesPrivilege = AuthUtil.checkUserAccess(P.EDIT_NOTE)
  const { masterData } = useContext(MasterDataContext)
  return <>
    <div className={`${parentClass}-wrapper`}>
      <p><b>Tag:</b> {AppUtil.getMultiselectLabels(props.note.predefined_Tags.map(item => item.value?.toString()) ?? [], MDK.NOTE_TAG, masterData)} <br />
        <b>Custom Tag:</b> {props.note.custom_Tags} </p>
      {hasEditHandwrittenNotesPrivilege && AppUtil.formatDateForPayload(new Date()) === AppUtil.formatDateForPayload(props.note.lastModified ?? '') && <PencilSquare size={18} title={t(`${translatn}editNotes`)} className={`${parentClass}-icon-cursor`} onClick={() => props.handleEdit(props.note.id)} />}
    </div>
    <div dangerouslySetInnerHTML={{ __html: props.note.original_Converted_Text }} className={`${parentClass}-content`} />
  </>
}