import React, { useContext, useState } from 'react'
import Container from 'react-bootstrap/Container'
import { useTranslation } from 'react-i18next'
import { TabProps } from '../../../models/Sample'
import { ViewSampleDetailsRow } from './ViewSampleDetailsRow'
import { AppUtil } from '../../../utils/app.utils'
import { MASTER_DATA_KEYS as MDK, MASTER_DATA_ID as MDI  } from '../../../utils/constants'
import { MasterDataContext } from '../../../contexts'
import _, { isString } from 'lodash'
import { Button } from 'react-bootstrap'
import { ValueItemSubCategory } from '../../../models/MasterData'
import { ViewSampleMarkerDetailsContainer } from './ViewSampleMarkerDetailsContainer'
import { EyeFill } from 'react-bootstrap-icons'

export const OutcomesOfTreatment = (props: TabProps) => {
  const { sampleDetails } = props
  const { t } = useTranslation()
  const { masterData } = useContext(MasterDataContext)
  const [categoryName, setCategoryName] = useState('')
  const [outcomesOfTreatmentSubCategoryFields, setOutcomesOfTreatmentSubCategoryFields] = useState([{}])
  const [showViewModal, setShowViewModal] = useState(false)
  const parentClass = 'view-details-column'
  const translatn = 'viewSample.'

  const handleSubCategoriesFields =(key:string,categorytypeData:ValueItemSubCategory[])=>{

    const typeData = sampleDetails?.outcomesOfTreatmentSubCategoryFields[key] || {};
    
    const subCategoryFieldsValue= Object.keys(typeData).map((typeDatakey: string) => {
      const item = typeData[typeDatakey];
      setCategoryName(AppUtil.getOption(MDK.PROGRESSION_SOURCE, masterData, key[key.length - 1]).label)
      if (isString(item)) {
        const itemData = (AppUtil.getOption(MDK.PROGRESSION_SOURCE, masterData, key[key.length - 1])?.subCategory?.find((sc) => sc.value?.toString() == typeDatakey[typeDatakey.length - 1]))
        return {
          label: itemData?.label,
          value: item,
          show: true
        }
      } 
      else {
        return (Object.keys(item).map((itemkey: string) => {
          if (item[itemkey]) {
            const itemData = (AppUtil.getOption(MDK.PROGRESSION_SOURCE, masterData, key[key.length - 1])?.subCategory?.find((sc) => sc.value?.toString() == typeDatakey[typeDatakey.length - 1]))
            const itemDataValue = itemData?.subCategoryFields?.find(f => f.value?.toString() == itemkey[itemkey.length - 1])
            return {
              label: `${itemDataValue?.label}`,
              value:item[itemkey],
              show: true,
              helperText:itemDataValue?.helperText
            }
          }
        })).filter(obj => obj != undefined )
      }
  })

    const objectsOfArray=subCategoryFieldsValue?.reduce((acc: any,value: Object,index:number)=>{
      acc[categorytypeData[index]?.label]=value
      return acc;
      },{})
      setOutcomesOfTreatmentSubCategoryFields(objectsOfArray) 
}

const handleViewModalClose = () => { setShowViewModal(false) }
const handleView = (key :string,categorytypeData:ValueItemSubCategory[]) => {  handleSubCategoriesFields(key,categorytypeData);  setShowViewModal(true)}

  const outcomesOfTreatmentSubCategoryDetails = Object.fromEntries(
    Object.keys(sampleDetails?.outcomesOfTreatmentSubCategory || {}).map((key: string) => {
      const categorytypeData = sampleDetails?.outcomesOfTreatmentSubCategory[key] || [];
      return [key, <Button key={key} className={`${parentClass}-action-btn`} onClick={()=>{handleView(key,categorytypeData);}}><EyeFill/></Button>];
    })
  );

  const detailsArr = [
    {
      label: t(`${translatn}sourceUsedToIdentifyTheProgression`),
      value: AppUtil.getMultiselectLabels(sampleDetails?.outcomesOfTreatmentCategory?.map(item => item.value?.toString()) ?? [], MDK.PROGRESSION_SOURCE, masterData),
      show: true
    },
    {
      label: t(`${translatn}beyond4Months`),
      value: sampleDetails?.progressionSourceBeyond4MonthsDate ?? 'No',
      show: true,
    },  
    {
      label: t(`${translatn}progressionRecurrenceDone`),
      value: AppUtil.getLabel(MDK.PROGRESSION_RECURRENCE_DONE, masterData, sampleDetails?.progressionRecurrenceDone),
      show: true,
    },
    {
      label: t(`${translatn}dateOfProgressionRecurrence`),
      value: sampleDetails?.dateOfProgressionRecurrence,
      show: sampleDetails?.progressionRecurrenceDone == MDI.PROGRESSION_RECURRENCE_OPTION_YES
    },
    {
      label: t(`${translatn}ageAtProgressionRecurrence`),
      value: sampleDetails?.ageAtProgressionRecurrence,
      show: sampleDetails?.progressionRecurrenceDone == MDI.PROGRESSION_RECURRENCE_OPTION_YES
    },
    {
      label: t(`${translatn}typeOfRecurrence`),
      value: AppUtil.getLabel(MDK.RECURRENCE_TYPE, masterData, sampleDetails?.typeOfRecurrence),
      show: sampleDetails?.progressionRecurrenceDone == MDI.PROGRESSION_RECURRENCE_OPTION_YES
    },
    {
      label: t(`${translatn}mostRecentPerformanceStatus`),
      value: AppUtil.getLabel(MDK.RECURRENCE_PERFORMANCE_STATUS, masterData, sampleDetails?.recurrencePerformanceStatus),
      show: true
    },
    {
      label: t(`${translatn}typeOfStatusScaleUsedRECISTChemotherapy`),
      value: AppUtil.getLabel(MDK.TYPE_OF_STATUS_SCALE_USED_RECIST_CHEMOTHERAPY, masterData, sampleDetails?.typeOfStatusScaleUsedRECISTChemotherapy),
      show: true
    },
    {
      label: t(`${translatn}typeOfStatusScaleUsedIRRECISTImmunotherapy`),
      value: AppUtil.getLabel(MDK.TYPE_OF_STATUS_SCALE_USED_IRRECIST_IMMUNOTHERAPY, masterData, sampleDetails?.typeOfStatusScaleUsedIRRECISTImmunotherapy),
      show: true
    },
    {
      label: t(`${translatn}dateOfPerformanceStatus`),
      value: sampleDetails?.dateOfPerformanceStatus,
      show: true
    },
    {
      label: t(`${translatn}ageAtPerformanceStatusScale`),
      value: sampleDetails?.ageAtPerformanceStatus,
      show: true
    },
    {
      label: t(`${translatn}bestResponseByDiseaseGroup`),
      value: AppUtil.getLabel(MDK.RESPONSE_BY_DISEASE_GROUP, masterData, sampleDetails?.responseByDiseaseGroup),
      show: true
    },
    {
      label: t(`${translatn}bestResponseByBaselineDiseaseStatus`),
      value: AppUtil.getLabel(MDK.RESPONSE_BY_BASELINE_DISEASE_STATUS, masterData, sampleDetails?.responseByBaselineDiseaseStatus),
      show: true
    },
    {
      label: t(`${translatn}bestResponseByResponseAssessment`),
      value:  AppUtil.getLabel(MDK.RESPONSE_BY_RESPONSE_ASSESSMENT, masterData,sampleDetails?.responseByResponseAssessment),
      show: true
    },
    {
      label: t(`${translatn}responseByDateOfAssessment`),
      value: sampleDetails?.responseByDateOfAssessment,
      show: true
    },
    {
      label: t(`${translatn}responseByDurationInMonths`),
      value: (sampleDetails?.responseByDurationInMonths ?? '0') + ' Months ' + (sampleDetails?.responseByDurationInDays ?? '0') + ' Days',
      show: true
    },
    {
      label: t(`${translatn}responseByPhysicianOrInvestigatorAssessment`),
      value: sampleDetails?.responseByPhysicianOrInvestigatorAssessment,
      show: true
    },
    {
      label: t(`${translatn}recistScoreDetails`),
      value: AppUtil.getLabel(MDK.CURRENT_DISEASE_STATUS, masterData, sampleDetails?.recistScoreDetails),
      show: true
    },
    {
      label: t(`${translatn}recistScore`),
      value: sampleDetails?.recistScore,
      show: true
    },
    {
      label: t(`${translatn}dateOfDisease`),
      value: sampleDetails?.dateOfDiseaseStatus,
      show: true
    },
    {
      label: t(`${translatn}ageAtDiseaseStatus`),
      value: sampleDetails?.ageAtDiseaseStatus,
      show: true
    },
  ]

  AppUtil.createObject(outcomesOfTreatmentSubCategoryDetails, MDK.PROGRESSION_SOURCE, masterData, detailsArr, 2)

  const data = detailsArr.filter((details) => details.show === true)
  const rowCount = _.chunk(data, 5)
  
  return (
    <Container fluid>
      <h5 className="fw-bold "> {t(`${translatn}outcomesOfTreatment`)}</h5>
      {rowCount.map((row, index) => (
        <ViewSampleDetailsRow
          key={index}
          index={index}
          rowArr={rowCount[index]}
          parentClass={parentClass}
        />
      ))}
      <ViewSampleMarkerDetailsContainer open={showViewModal} handleClose={handleViewModalClose} categoryName={categoryName} subCategoryFields={outcomesOfTreatmentSubCategoryFields}/>
   
    </Container>
  )
}
