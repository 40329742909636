import React, { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import { TextInput, SelectInput, DateInput, NumberInput, RadioInput } from '../../InputComponents'
import { MASTER_DATA_KEYS as MDK, MASTER_DATA_ID as MDI } from '../../../utils/constants'
import { MasterDataContext } from '../../../contexts'
import { AppUtil as _ } from '../../../utils/app.utils'
import { useFormContext } from 'react-hook-form'
import { FFPEFields, bloodFields, getDateOfBirth, hAndEFields } from '../helpers/AddSampleInfoHelpers'

export const SampleInformation = (props: any) => {
  const { t } = useTranslation()
  const { isEditAllowed } = props
  const { masterData } = useContext(MasterDataContext)
  const { resetField, getValues, watch, clearErrors } = useFormContext()
  const dob = getValues('dob')
  const translatn = 'sampleInfoForm'
  const parentClass = 'vcr-pp-sample-info-'

  const resetFields = (fields: string[]) => {
    fields.forEach((field) => resetField(field))
  }
  const handleSampleTypeChange = () => {
    FFPEFields.forEach((field) => {
      if(field === 'blockNumber') {
        resetField(field, { defaultValue: null })
      }else {
        resetField(field, { defaultValue: '' })
      }
      clearErrors(field)
    })

    hAndEFields.forEach((field) => {
      resetField(field, { defaultValue: null })      
      clearErrors(field)
    })

    bloodFields.forEach((field) => {
      resetField(field, { defaultValue: null })      
      clearErrors(field)
    })
  }

  useEffect(() => {
    if (watch('hAndeStaining') !== MDI.H_E_OPTION_YES)
      hAndEFields.forEach((field) => {
        resetField(field, { defaultValue: null })
        clearErrors(field)
      })
  }, [watch('hAndeStaining')])

  return (
    <div className={`${parentClass}input-field`}>
      <h5 className={`${parentClass}form-heading`}> {t(`${translatn}.sampleInformation`)}</h5>
      <Row lg={4} md={4} sm={4}>
        <Col>
          <DateInput
            name={`sampleCollectionDate`}
            id={'sampleCollectionDate'}
            title={t(`${translatn}.sampleCollectionDate`)}
            placeholder={t(`common.datePlaceholder`)}
            disabled={!isEditAllowed}
            readOnly={false}
            min={new Date(getDateOfBirth(dob)) ?? undefined}
            max={new Date()}
            required={true}
          />
        </Col>
        <Col>
          <TextInput
            type="text"
            name={`sampleCollectedBy`}
            id={'sampleCollectedBy'}
            title={t(`${translatn}.sampleCollectedBy`)}
            placeholder={t(`${translatn}.sampleCollectedByPlaceholder`)}
            disabled={true}
            readOnly={true}
            required={true}
          />
        </Col>
        <Col>
          <NumberInput
            name={`employeeId`}
            id={'employeeId'}
            title={t(`${translatn}.employeeId`)}
            placeholder={t(`${translatn}.employeeIdPlaceholder`)}
            disabled={true}
            readOnly={true}
            required={true}
          />
        </Col>
      </Row>
      <Row lg={4} md={4} sm={4}>
        <Col>
          <SelectInput
            name={`typeOfSample`}
            id={'typeOfSample'}
            title={t(`${translatn}.sampleType`)}
            placeholder={t(`${translatn}.sampleTypePlaceholder`)}
            disabled={!isEditAllowed}
            defaultValue={getValues('typeOfSample')}
            required={true}
            optionsValueArray={_.getOptions(MDK.SAMPLE_TYPE, masterData)}
            onCustomChange={handleSampleTypeChange}
          />
        </Col>
        {watch('typeOfSample') === MDI.SAMPLE_TYPE_FFPE ? (
          <>
            <Col>
              <NumberInput
                name={`blockNumber`}
                id={'blockNumber'}
                title={t(`${translatn}.blockNumber`)}
                placeholder={t(`${translatn}.blockNumberPlaceholder`)}
                disabled={!isEditAllowed}
                readOnly={false}
                required={true}
                min={1}
              />
            </Col>
          </>
        ) : null}

        {watch('typeOfSample') === MDI.SAMPLE_TYPE_FFPE ? (
          <>
            <Col>
              <TextInput
                type="text"
                name={`fixativeUsed`}
                id={'fixativeUsed'}
                title={t(`${translatn}.fixativeUsed`)}
                placeholder={t(`${translatn}.fixativeUsedPlaceholder`)}
                disabled={!isEditAllowed}
                readOnly={false}
                required={true}
              />
            </Col>
            <Col>
              <TextInput
                type="text"
                name={`fixationDuration`}
                id={'fixationDuration'}
                title={t(`${translatn}.fixationDuration`)}
                placeholder={t(`${translatn}.fixationDurationPlaceholder`)}
                disabled={!isEditAllowed}
                readOnly={false}
                required={true}
              />
            </Col>
            <Col>
              <TextInput
                type="text"
                name={`coldIschemiaTime`}
                id={'coldIschemiaTime'}
                title={t(`${translatn}.coldIschemiaTime`)}
                placeholder={t(`${translatn}.coldIschemiaTimePlaceholder`)}
                disabled={!isEditAllowed}
                readOnly={false}
                required={true}
              />
            </Col>

            <Col>
              <RadioInput
                name={`hAndeStaining`}
                id={'hAndeStaining'}
                title={t(`${translatn}.hAndeStaining`)}
                disabled={!isEditAllowed}
                required={true}
                valueArray={_.sortOptionsDesc(_.getOptions(MDK.H_E_STAINING, masterData))}
                inline={true}
              />
            </Col>
            {watch('hAndeStaining') === MDI.H_E_OPTION_YES ? (
              <>
                <Col>
                  <NumberInput
                    name={`tumorTissue`}
                    id={'tumorTissue'}
                    title={t(`${translatn}.tumorTissue`)}
                    placeholder={t(`${translatn}.tumorTissuePlaceholder`)}
                    disabled={!isEditAllowed}
                    readOnly={false}
                    required={true}
                    min={1}
                  />
                </Col>

                <Col>
                  <NumberInput
                    name={`necrosisTissues`}
                    id={'necrosisTissues'}
                    title={t(`${translatn}.necrosisTissues`)}
                    placeholder={t(`${translatn}.necrosisTissuePlaceholder`)}
                    disabled={!isEditAllowed}
                    readOnly={false}
                    required={true}
                    min={1}
                  />
                </Col>
              </>
            ) : null}

          </>
        ) : null}
        {MDI.SAMPLE_TYPE_BLOOD.includes(watch('typeOfSample')) ? (

          <Col>
            <TextInput
              type="text"
              name={`tubeType`}
              id={'tubeType'}
              title={t(`${translatn}.tubeType`)}
              placeholder={t(`${translatn}.tubeTypePlaceholder`)}
              disabled={!isEditAllowed}
              readOnly={false}
              required={true}
            />
          </Col>

        ) : null}
      </Row>
      <Row><Col lg={3}>
        <TextInput
          name={`replicateNumber`}
          id={'replicateNumber'}
          title={t(`${translatn}.sampleReplicateNumber`)}
          placeholder={t(`${translatn}.sampleReplicateNumberPlaceHolder`)}
          disabled={!isEditAllowed}
          readOnly={false}
          required={true}
        />
      </Col></Row>

    </div>
  )
}
