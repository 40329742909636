import React, { useContext, useEffect, useState } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import { ViewSampleNav } from './components'
import './ViewSampleContainer.scss'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { appRoutes } from '../../utils/routes'
import { trackPromise } from 'react-promise-tracker'
import { Sample } from '../../models/Sample'
import { AxiosResponse } from 'axios'
import { AppUtil } from '../../utils/app.utils'
import { AuthUtil } from '../../utils/auth.utils'
import { getSampleDetails } from '../../services/Sample'
import { HTTP_STATUS_CODE, PRIVILEGES, MASTER_DATA_KEYS as MDK } from '../../utils/constants'
import { PersonSquare, PencilFill, ArrowLeftShort } from 'react-bootstrap-icons'
import { Patient } from '../../models/RegisterPatient'
import { getPatientDetails } from '../../services'
import { MasterDataContext } from '../../contexts'
import { formatCategoryData, formatSubCategoryData, formatSubCategoryFieldsData } from '../SampleInformationForm/helpers/AddSampleInfoHelpers'

export const ViewSampleContainer = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { patientId, id } = useParams()
  const translatn = 'viewSample.'
  const parentClass = 'vcr-pp-view-sample-'
  const { masterData } = useContext(MasterDataContext)

  const hasEditSamplePrivilege = AuthUtil.checkUserAccess(PRIVILEGES.EDIT_SAMPLE)
  const hasEditBeforeShippmentPrivilege = AuthUtil.checkUserAccess(PRIVILEGES.EDIT_BEFORE_SHIPMENT)
  const hasEditAfterShippmentPrivilege = AuthUtil.checkUserAccess(PRIVILEGES.EDIT_AFTER_SHIPMENT)

  const [isEditAllowed, setIsEditAllowed] = useState(false)
  const [sampleDetails, setSampleDetails] = useState<Sample | null>(null)
  const [patientDetails, setPatientDetails] = useState<Patient | null>(null)

  useEffect(() => {
    if (id) {
      trackPromise(
        getSampleDetails(id, patientId ?? '').then(
          (res: AxiosResponse<Sample>) => {
            setSampleDetails({...res?.data, biomarkerTestCategory: formatCategoryData(res?.data?.sampleMarkerDetails ?? [], masterData, MDK.BIOMARKER_TEST), biomarkerTestSubCategory: formatSubCategoryData(res?.data?.sampleMarkerDetails ?? [], masterData, MDK.BIOMARKER_TEST), biomarkerTestSubCategoryFields: formatSubCategoryFieldsData(res?.data?.sampleMarkerDetails, masterData, MDK.BIOMARKER_TEST), labTypeCategory: formatCategoryData(res?.data?.sampleMarkerDetails ?? [], masterData, MDK.LAB_TYPE),
              labTypeSubCategory: formatSubCategoryData(res?.data?.sampleMarkerDetails ?? [], masterData, MDK.LAB_TYPE),
              labTypeSubCategoryFields: formatSubCategoryFieldsData(res?.data?.sampleMarkerDetails ?? [], masterData, MDK.LAB_TYPE),
              outcomesOfTreatmentCategory: formatCategoryData(res?.data?.sampleMarkerDetails ?? [], masterData, MDK.PROGRESSION_SOURCE),
              outcomesOfTreatmentSubCategory: formatSubCategoryData(res?.data?.sampleMarkerDetails ?? [], masterData, MDK.PROGRESSION_SOURCE),
              outcomesOfTreatmentSubCategoryFields: formatSubCategoryFieldsData(res?.data?.sampleMarkerDetails ?? [], masterData, MDK.PROGRESSION_SOURCE),
             })
            if (hasEditSamplePrivilege && (
              hasEditAfterShippmentPrivilege && [2, 3].includes(res?.data?.sampleStatus ?? -1) ||
              hasEditBeforeShippmentPrivilege && [0, 1].includes(res?.data?.sampleStatus ?? -1) 
            )) {
              setIsEditAllowed(true)
            }          
          },
          (error) => {
            AppUtil.logError(error)
            if (error.response.status === HTTP_STATUS_CODE.NOT_FOUND) {
              setTimeout(() => {
                navigate(appRoutes.home)
              }, 2000)
            }
          },
        ),
      )
    }
  }, [id,masterData])

  useEffect(() => {
    if (patientId) {
      trackPromise(
        getPatientDetails(patientId).then(
          (res: AxiosResponse<Patient>) => {
            setPatientDetails(res.data)
          },
          (error) => AppUtil.logError(error),
        ),
      )
    }
  }, [patientId])

  return (
    <Container className={`${parentClass}container`} fluid>
      <Row className={`${parentClass}header`}>
        <Col className="d-flex align-items-center justify-content-start">
          <ArrowLeftShort size={26} onClick={() => navigate(-1)} />
          <span className={`${parentClass}vertical-line`}></span>
          <PersonSquare color="#1C9AA3" size={26} className="mr-3 ml-3" />
          <span className={`${parentClass}profile-id`}>
            {[
              AppUtil.combineNamesString(patientDetails?.name, patientDetails?.firstName, patientDetails?.middleName, patientDetails?.lastName), 
              (AppUtil.getLabel(MDK.GENDER, masterData, patientDetails?.gender) + (patientDetails?.genderOther ? ` - ${patientDetails?.genderOther}` : '')),
              patientDetails?.age
            ].join(', ')}
            {' (' + t(`${translatn}patientId`) + ' ' + patientId + ') - Sample ID: ' + id}
          </span>
        </Col>
        <Col className="d-flex align-items-center justify-content-end">
          {isEditAllowed && (
            <Button
              className={`${parentClass}editbtn`}
              onClick={() => 
                navigate(
                  generatePath(appRoutes.editSample, {
                    patientId,
                    id,
                  }),
                  { state: sampleDetails },
                )
              }
            >
              <PencilFill color="#075883" />
              <span className="ps-2">{t(`${translatn}editInfo`)}</span>
            </Button>
          )}
        </Col>
      </Row>
      <Row>
        <Col>
          <ViewSampleNav sampleDetails={sampleDetails} />
        </Col>
      </Row>
    </Container>
  )
}
