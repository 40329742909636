import React from 'react'
import { useTranslation } from 'react-i18next'
import { Col, Container, Modal, Row } from 'react-bootstrap'
import { SignaturePad } from '../../PDFOperations'
import "./styles/PDFSignModal.scss"

type PDFSignModalProps = {
  show: boolean
  localPDFURL?: string
  pdfBytesData: Uint8Array | undefined
  reportName: string
  sampleID: string
  testID: string
  primaryTestTagID: string
  setpdfBytesData: (pdfData: Uint8Array) => void
  handleClose: () => void
}

export const PDFSignModal = (props: PDFSignModalProps) => {
  const { show, localPDFURL, pdfBytesData, reportName, sampleID, testID, primaryTestTagID, setpdfBytesData, handleClose } = props
  const { t } = useTranslation()
  const translatn = 'viewSample.'
  const parentClass = 'vcr-pp-pdf-sign'

  const cancelAndHandleClose = function () {
    handleClose()
  }

  return (
    <Container className={`${parentClass}-container`} fluid>
      <Modal className={`${parentClass}`} size='lg' show={show} onHide={cancelAndHandleClose} centered backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>{t(`${translatn}header`)}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <div className={`${parentClass}-label`}> {t(`${translatn}reportName`)}</div>
              <div  className={`${parentClass}-value`}>{reportName ? reportName?.toString() : '-'}</div>
            </Col>
            <Col> 
              <div className={`${parentClass}-label`}> {t(`${translatn}sampleId`)}</div>
              <div  className={`${parentClass}-value`}>{sampleID ? sampleID?.toString() : '-'}</div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className={`${parentClass}-label`}>{t(`${translatn}testId`)}</div>
              <div className={`${parentClass}-value`}> {testID ? testID?.toString() : '-'}</div>
            </Col>
            <Col>
              <div className={`${parentClass}-label`}>{t(`${translatn}primaryTestTag`)}</div>
              <div className={`${parentClass}-value`}>{primaryTestTagID ? primaryTestTagID?.toString() : '-'}</div>
            </Col>
          </Row>
          <Row>
            <SignaturePad modalClose={cancelAndHandleClose} pdfURL={localPDFURL} pdfBytesData={pdfBytesData} setpdfBytesData={setpdfBytesData} />
          </Row>
        </Modal.Body>
      </Modal>
    </Container>
  )
}
