import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Container, Row, Tab, Tabs } from 'react-bootstrap';
import { ArrowLeftShort, JournalPlus, PersonSquare } from 'react-bootstrap-icons';
import { ViewHandwrittenNotes } from './ViewHandwrittenNotes';
import './ViewHandwrittenNotesContainer.scss'
import { AppUtil } from '../../../utils/app.utils';
import { MasterDataContext } from '../../../contexts';
import { MASTER_DATA_KEYS as MDK, PRIVILEGES as P } from '../../../utils/constants';
import { appRoutes } from '../../../utils/routes';
import { trackPromise } from 'react-promise-tracker';
import { getPatientDetails } from '../../../services';
import { AxiosResponse } from 'axios';
import { Patient } from '../../../models/RegisterPatient';
import { AuthUtil } from '../../../utils/auth.utils';

export const ViewHandwrittenNotesContainer = () => {
    const { id } = useParams()
    const navigate = useNavigate()
    const { t } = useTranslation()
    const { masterData } = useContext(MasterDataContext)

    const translatn = 'viewHandwrittenNotesContainer.'
    const parentClass = 'pp-view-handwritten-notes-container-'

    const [activeTab, setActiveTab] = useState('handwrittenNotes')
    const [patientDetails, setPatientDetails] = useState<Patient | null>(null)

    const hasAddHandwrittenNotesPrivilege = AuthUtil.checkUserAccess(P.CREATE_NOTE)

    const tabs = [
        { id: 'handwrittenNotes', title: t(`${translatn}viewHandwrittenNotes`), component: <ViewHandwrittenNotes /> },
    ]

    useEffect(() => {
        if (id) {
            trackPromise(
                getPatientDetails(id).then(
                    (res: AxiosResponse<Patient>) => {
                        setPatientDetails(res.data)
                    },
                    (error) => AppUtil.logError(error),
                ),
            )
        }
    }, [id])

    return (
        <Container className={`${parentClass}container`} fluid>
            <Row className={`${parentClass}header `}>
                <Col className="d-flex align-items-center justify-content-start">
                    <ArrowLeftShort className={`${parentClass}cursor-pointer`} size={26} onClick={() => navigate(appRoutes.home)} />
                    <span className={`${parentClass}vertical-line`}></span>
                    <PersonSquare color="#1C9AA3" size={26} className="mr-3 ml-3" />

                    <span className={`${parentClass}profile-id`}>
                        {[
                            AppUtil.combineNamesString(patientDetails?.name, patientDetails?.firstName, patientDetails?.middleName, patientDetails?.lastName),
                            (AppUtil.getLabel(MDK.GENDER, masterData, patientDetails?.gender) + (patientDetails?.genderOther ? ` - ${patientDetails?.genderOther}` : '')),
                            patientDetails?.age,
                        ].join(', ')}
                        {' (' + t(`${translatn}patientId`) + ' ' + patientDetails?.id + ')'}
                    </span>
                </Col>
                {hasAddHandwrittenNotesPrivilege && <Col className="d-flex align-items-center justify-content-end">
                    <Button title={t(`${translatn}addHandwrittenNotesToolTip`)} className={`${parentClass}editbtn`} onClick={() =>
                        navigate(
                            generatePath(appRoutes.addHandwrittenNotes, {
                                patientId: id,
                            })
                        )
                    }>
                        <JournalPlus size={15} color="#075883" />
                        <span className="ps-2">{t(`${translatn}add`)}</span>
                    </Button>
                </Col>}
            </Row>
            <Tabs className={`${parentClass}tabs`} activeKey={activeTab} onSelect={(key) => setActiveTab(key ?? '')}>
                {tabs.map((tab) => (
                    <Tab eventKey={tab.id} title={tab.title} key={tab.title} id={tab.id}>
                        {tab.component}
                    </Tab>
                ))}
            </Tabs>
        </Container>
    )
}