
import React from 'react'
import { Medications } from './Medications'
import { StemCellTransplant } from './StemCellTransplant'
import { RadiationTreatment } from './RadiationTreatment'
import { OutcomesOfTreatment } from './OutcomesOfTreatment'
import { VitalStatus } from './VitalStatus'
import { Sample } from '../../../models/Sample'

type Props = {
  sampleDetails: Sample | null
}

export const TabFive = ({ sampleDetails }: Props) => {
  return (
    <div >
      <Medications sampleDetails={sampleDetails} />
      <StemCellTransplant sampleDetails={sampleDetails} />
      <RadiationTreatment sampleDetails={sampleDetails} />
      <OutcomesOfTreatment sampleDetails={sampleDetails} />
      <VitalStatus sampleDetails={sampleDetails} />
    </div>
  )
}