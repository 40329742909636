export const AddSampleInfoDefaultData = {
  doctorsName: '',
  doctorContactNumber: '',
  doctorsId: '',
  doctorEmail: '',
  siteName: '',

  dateOfPhysical: null,
  ageAtPhysical: null,
  weight: null,
  height: null,
  bmi: 0,

  smokingStatus: '',
  alcoholUse: '',
  multiplePremalignantOrComorbidIllnesses: [],
  multiplePremalignantOrComorbidIllnessesCapturedHereDetails: {},
  organTransplantDone:'',
  dateOfOrganTransplant: null,
  ageAtOrganTransplant: null,
  siteAtOrganTransplant: '',
  siteAtOrganTransplantOther: '',

  familyHistoryOfCancer: '',  
  familyHistoryDetails: [],

  dateOfFirstContact: null,
  ageAtFirstContact: null,
  primarySiteOfDiagnosis: '',
  primarySiteOfDiagnosisOther: '',
  laterality: '',
  treatmentStatus: '',
  histology: '',
  physicianComment:'',
  otherStagingSystemAndGradeData: '',
  otherStagingSystemAndGradeDataOther: '',
  otherStagingGradeOrScoreDetails:'',
  dateOfDiagnosis: null,
  ageAtDiagnosis: null,
  clinicalT: '',
  clinicalN: '',
  clinicalM: '',
  clinicalGroupStage: '',
  clinicalGroupStageOther: '',
  pathologicalT: '',
  pathologicalN: '',
  pathologicalM: '',
  pathologicalGroupStage: '',
  pathologicalGroupStageOther: '',
  performanceStatusAtDiagnosis: '',
  typeOfPerformanceStatusScaleUsedAtDiagnosis: '',
  dateOfDiagnosticPerformanceStatus: null,
  ageAtDiagnosticPerformanceStatus: null,
  
  originalPrimarySiteOfDiagnosis: '',
  siteOfMetastasis: '',
  siteOfMetastasisOther: '',
  dateOfMetastasis: null,
  ageAtMetastasis: null,

  imagingTypeOfScan: [],
  imagingTypeOfScanDetails: {},
  imagingModalityMethod: '',
  dateOfImagingScan: null,
  ageAtImagingScan: null,
  imagingSiteForScan: '',
  imagingSiteForScanOther: '',
  imagingScanSiteDetails:'',
  measurableDiseasePresent: '',
  newLesions: '',
  site: '',
  siteOther: '',

  biomarkerTestCategory: [],
  isBiomarkerBeyond4Months: false,
  biomarkerBeyond4MonthsDate: null,
  biomarkerTestSubCategory: {},
  biomarkerTestSubCategoryFields: {},
  sampleMarkerDetails: [],
  specifyOtherTumor: '',
  dateOfResults: null,
  ageAtResults: null,
  markerType: [],
  markerTypeDetails: {},

  labTypeCategory: [],
  isLabTypeBeyond4Months: false,
  labTypeBeyond4MonthsDate: null,
  labTypeSubCategory: {},
  labTypeSubCategoryFields: {},
  dateOfLabResults: null,
  labAgeAtResults: null,

  labHighLowRef: '',
  labUnitOfMeasure: '',

  cytogeneticsTestingPerformance: '',
  dateOfProcedureForCytogeneticsAbnormalities: null,
  ageAtProcedureForCytogeneticsAbnormalities: null,
  testTypeDeletionsTranslocations: '',

  typeOfTumorSequencing: [],
  typeOfTumorSequencingDetails: {}, 
  otherTumorSequencing: '',
  dateOfTumorSequencing: null,
  ageAtTumorSequencing: null,

  surgeryBiopsyDone: '',
  originalPrimarySiteOfDiagnosisForSurgeryPerformed: '',
  originalPrimarySiteOfDiagnosisForSurgeryPerformedOther: '',
  locationForSurgeryBiopsy: '',
  locationForSurgeryBiopsyOther:'',
  surgeryType: '',
  surgeryTypeDetails: '',
  biopsyType: '',
  biopsyTypeDetails: '',
  anesthesiaType: '',
  anethesiaTypeDetails: '',
  surgicalApproach: '',
  surgicalApproachDetails: '',
  dateOfSurgeryBiopsy: null,
  ageAtSurgeryBiopsy: null,

  typeOfCancerOrDiagnosis: '',
  ffpeBlocksAvailable: '',
  histopathologyReportDone:'',
  histopathologyReportDate: null,
  onChemotherapy: '',
  chemotherapyDrugName: '',
  lastChemotherapyDate: null,
  chemotherapyCycles: '',
  otherDrugsPrescribed: '',
  otherDrugName: '',
  otherDrugDuration: '',
  bloodTransfusionDone:'',
  bloodTransfusionHistory: '',
  lastTransfusionDate: null,
  boneMarrowTransplantDone:'',
  boneMarrowTransplantHistory: '',
  lastTransplantDate: null,
  testedForInfectiousDiseases: '',
  infectiousDiseaseTestDate: null,
  infectiousDiseaseResult: '',
  infectiousDiseaseNotesOrRemarks: '',
  diagnosis: '',

  medication:[],

  cellularTherapy: '',
  dateOfCellularTherapy: null,
  ageAtCellularTherapy: null,
  stemCellTransplantType: '',
  sourceOfStemCells: '',
  transplantationCenter: '',
  conditioningRegimen: '',
  graftVersusHostDiseaseStatus: '',
  acuteGVHDGrade: '',
  chronicGVHDStatus: '',
  engraftmentStatus: '',
  donorType: '',
  hlaMatching: '',
  responseToSCT: '',

  radiationTherapy: '',
  originalSiteOfDiagnosisForRadiationTherapy: '',
  radiationDisease: '',
  radiationRegimen: '',
  radiationModality: '',
  radiationDose: '',
  radiationStart: null,
  radiationStop: null,

  isProgressionSourceBeyond4Months: false,
  progressionSourceBeyond4MonthsDate: null,
  outcomesOfTreatmentCategory: [],
  outcomesOfTreatmentSubCategory: {},
  outcomesOfTreatmentSubCategoryFields: {},
  progressionRecurrenceDone: '',
  dateOfProgressionRecurrence: null,
  ageAtProgressionRecurrence: null,
  typeOfRecurrence: '',
  recurrencePerformanceStatus: '',
  typeOfStatusScaleUsedRECISTChemotherapy: '',
  typeOfStatusScaleUsedIRRECISTImmunotherapy: '',
  dateOfPerformanceStatus: null,
  ageAtPerformanceStatus: null,
  responseByDiseaseGroup: '',
  responseByBaselineDiseaseStatus: '',
  responseByResponseAssessment: '',
  responseByDateOfAssessment: null,
  responseByDurationInMonths: null,
  responseByDurationInDays: null,
  responseByPhysicianOrInvestigatorAssessment: '',
  recistScoreDetails: '',
  recistScore:'',
  dateOfDiseaseStatus: null,
  ageAtDiseaseStatus: null,

  vitalWeight: null,
  vitalHeight: null,
  vitalBMI: null,
  vitalTemperature: null,
  dateOfLastContactActivity: null,
  ageAtLastContactActivity: null,
  dateOfDeath: null,
  ageAtDeath: null,
  causeOfDeathContributingFactors: '',
  causeOfDeathAutopsyFindings: '',
  causeOfDeathMedicalHistory: '',
  causeOfDeathDocumentationOfTerminalEvents: '',

  sampleCollectionDate: null,
  sampleCollectedBy: '',
  employeeId: null,
  typeOfSample: '',
  blockNumber: null,
  fixativeUsed: '',
  fixationDuration: '',
  coldIschemiaTime: '',
  hAndeStaining: '',
  tumorTissue: null,
  necrosisTissues: null,
  tubeType: '',
  replicateNumber:'1',  

  qcCheck: false,
  pathogenFreeReport: false,

  testType: [],
}
