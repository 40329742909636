import { z } from 'zod'

const getTranslatedStr = (path: string): string => {
  const translatn = 'userLogin.errors'
  return `${translatn}.${path}`
}

export const UserLoginFormSchema = z.object({
  userName: z
    .string()
    .regex(/^\w+$/, getTranslatedStr('usernameCharCheck'))
    .min(4, { message: getTranslatedStr('usernameMinLength') }),
  password: z
    .string()

    .min(8, { message: getTranslatedStr('passwordMinLength') }),
})
