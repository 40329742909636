import React, { useContext, useState } from 'react'
import Container from 'react-bootstrap/Container'
import { useTranslation } from 'react-i18next'
import { TabProps } from '../../../models/Sample'
import { ViewSampleDetailsRow } from './ViewSampleDetailsRow'
import { AppUtil } from '../../../utils/app.utils'
import { MASTER_DATA_KEYS as MDK } from '../../../utils/constants'
import { MasterDataContext } from '../../../contexts'
import _, { isString } from 'lodash'
import { Button } from 'react-bootstrap'
import { EyeFill } from 'react-bootstrap-icons'
import { ValueItemSubCategory } from '../../../models/MasterData'
import { ViewSampleMarkerDetailsContainer } from './ViewSampleMarkerDetailsContainer'

export const Labs = (props: TabProps) => {
  const { sampleDetails } = props
  const { t } = useTranslation()
  const translatn = 'viewSample.'
  const { masterData } = useContext(MasterDataContext)
  const [categoryName, setCategoryName] = useState('')
  const [labSubCategoryFields, setLabSubCategoryFields] = useState([{}])
  const [showViewModal, setShowViewModal] = useState(false)
  const parentClass = 'view-details-column'
 

  const handleSubCategoriesFields =(key:string,categorytypeData:ValueItemSubCategory[])=>{

    const typeData = sampleDetails?.labTypeSubCategoryFields[key] || {};
    
    const subCategoryFieldsValue= Object.keys(typeData).map((typeDatakey: string) => {
      const item = typeData[typeDatakey];
      setCategoryName(AppUtil.getOption(MDK.LAB_TYPE, masterData, key[key.length - 1]).label)
      if (isString(item)) {
        const itemData = (AppUtil.getOption(MDK.LAB_TYPE, masterData, key[key.length - 1])?.subCategory?.find((sc) => sc.value?.toString() == typeDatakey[typeDatakey.length - 1]))
        return {
          label: itemData?.label,
          value: item,
          show: true
        }
      } 
      else {
        return (Object.keys(item).map((itemkey: string) => {
          if (item[itemkey]) {
            const itemData = (AppUtil.getOption(MDK.LAB_TYPE, masterData, key[key.length - 1])?.subCategory?.find((sc) => sc.value?.toString() == typeDatakey[typeDatakey.length - 1]))
            const itemDataValue = itemData?.subCategoryFields?.find(f => f.value?.toString() == itemkey[itemkey.length - 1])
            return {
              label: `${itemDataValue?.label}`,
              value:item[itemkey],
              show: true,
              helperText:itemDataValue?.helperText
            }
          }
        })).filter(obj => obj != undefined )
      }
  })

    const objectsOfArray=subCategoryFieldsValue?.reduce((acc: any,value: Object,index:number)=>{
      acc[categorytypeData[index]?.label]=value
      return acc;
      },{})
      setLabSubCategoryFields(objectsOfArray) 
}

const handleViewModalClose = () => { setShowViewModal(false) }
const handleView = (key :string,categorytypeData:ValueItemSubCategory[]) => {  handleSubCategoriesFields(key,categorytypeData);  setShowViewModal(true)}


  const labTypeSubCategoryDetails = Object.fromEntries(
    Object.keys(sampleDetails?.labTypeSubCategory || {}).map((key: string) => {
      const categorytypeData = sampleDetails?.labTypeSubCategory[key] || [];
     return [key, <Button key={key} className={`${parentClass}-action-btn`} onClick={()=>{handleView(key,categorytypeData);}}><EyeFill/></Button>];
  })
);

  
  const detailsArr = [
    {
      label: t(`${translatn}labType`),
      value: AppUtil.getMultiselectLabels(sampleDetails?.labTypeCategory?.map(item => item.value?.toString()) ?? [], MDK.LAB_TYPE, masterData),
      show: true
    },
    {
      label: t(`${translatn}beyond4Months`),
      value: sampleDetails?.labTypeBeyond4MonthsDate ?? 'No',
      show: true,
    },    
    {
      label: t(`${translatn}dateOfLabResults`),
      value: sampleDetails?.dateOfLabResults,
      show: true
    },
    {
      label: t(`${translatn}ageAtResult`),
      value: sampleDetails?.labAgeAtResults,
      show: true
    },
  ]

  AppUtil.createObject(labTypeSubCategoryDetails, MDK.LAB_TYPE, masterData, detailsArr, 2)

  const data = detailsArr.filter((details) => details.show === true)

  const rowCount = _.chunk(data, 5)

  return (
    <Container fluid>
      <h5 className="fw-bold "> {t(`${translatn}labs`)}</h5>
      {rowCount.map((row, index) => (
        <ViewSampleDetailsRow
          key={index}
          index={index}
          rowArr={rowCount[index]}
          parentClass={parentClass}
        />
      ))}
      <ViewSampleMarkerDetailsContainer open={showViewModal} handleClose={handleViewModalClose} categoryName={categoryName} subCategoryFields={labSubCategoryFields}/>
    </Container>
  )
}
