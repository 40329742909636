import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { useFormContext } from 'react-hook-form'

import {
  TextInput,
  NumberInput,
  DateInput,
  MultiSelectDropdown,
  TextInputWithAddOn,
} from '../../InputComponents'
import { setAgeAtEvent } from '../helpers'
import { MASTER_DATA_KEYS as MDK } from '../../../utils/constants'
import { MasterDataContext } from '../../../contexts'
import { AppUtil as _ } from '../../../utils/app.utils'
import { MultiSelectItem } from '../../../models/Sample'
import { getDateOfBirth } from '../helpers/AddSampleInfoHelpers'

export const TumorSequencing = () => {
  const { t } = useTranslation()
  const { setValue, getValues, resetField, watch } = useFormContext()
  const { masterData } = useContext(MasterDataContext)
  const translatn = 'sampleInfoForm'
  const parentClass = 'vcr-pp-sample-info-'
  const dob = getValues('dob')

  const handleRemoveTypeOfTumorSequencing = (value: string | number) => {
    setValue(
      'typeOfTumorSequencing',
      getValues('typeOfTumorSequencing')?.filter(
        (item: { value: string; label: string }) => item.value?.toString() !== value?.toString(),
      ),
    )
    resetField(`typeOfTumorSequencingDetails.type${value}`,{defaultValue:''})
  }

  return (
    <div className={`${parentClass}input-field`}>
      <h5 className={`${parentClass}form-heading`}> {t(`${translatn}.tumorSequencing`)}</h5>
      <Row lg={4} md={4} sm={4}>
        <Col>
          <MultiSelectDropdown
            name={`typeOfTumorSequencing`}
            id={'typeOfTest'}
            title={t(`${translatn}.typeOfTest`)}
            placeholder={t(`${translatn}.typeOfTestPlaceholder`)}
            disabled={false}
            required={false}
            optionsValueArray={_.getOptions(MDK.TUMOR_SEQUENCING_TEST_TYPE, masterData)}
            hideSelectedValues={false}
            onCustomRemove={handleRemoveTypeOfTumorSequencing}
          />
        </Col>
        {watch('typeOfTumorSequencing')
          ?.filter((item: MultiSelectItem) => item.textBox)
          ?.map((type: MultiSelectItem) => {
            return (
              <Col key={type.value}>
                <TextInputWithAddOn
                  type="text"
                  name={`typeOfTumorSequencingDetails.type${type.value}`}
                  id={`typeOfTumorSequencingDetails.${type.value}`}
                  title={`${type.label}`}
                  placeholder={t(`${translatn}.typeOfTumorSequencingDetailsPlaceholder`)}
                  disabled={false}
                  readOnly={false}
                  required={false}
                  helperText={type.helperText}
                />
              </Col>
            )
          })}
      </Row>
      <Row lg={4} md={4} sm={4}>
        <Col>
          <TextInput
            type="text"
            name={`otherTumorSequencing`}
            id={'otherTypeOfTumorSequencing'}
            title={t(`${translatn}.otherTumorSequencingTestTypes`)}
            placeholder={t(`${translatn}.otherTumorSequencingTestTypesPlaceholder`)}
            disabled={false}
            readOnly={false}
            required={false}
          />
        </Col>
        <Col>
          <DateInput
            name={`dateOfTumorSequencing`}
            id={'dateOfTumorSequencing'}
            title={t(`${translatn}.dateOfTumorSequencing`)}
            placeholder={t(`common.datePlaceholder`)}
            disabled={false}
            readOnly={false}
            min={new Date(getDateOfBirth(dob)) ?? undefined}
            max={new Date()}
            required={false}
            onChange={(date: Date) => {
              setAgeAtEvent(dob, date, `ageAtTumorSequencing`, setValue)
            }} />
        </Col>
        <Col>
          <NumberInput
            name={`ageAtTumorSequencing`}
            id={'ageAtTumorSequencing'}
            title={t(`${translatn}.ageAtTumorSequencing`)}
            placeholder={t(`${translatn}.ageAtTumorSequencingPlaceholder`)}
            disabled={false}
            readOnly={true}
            required={false}
          />
        </Col>
      </Row>
    </div>
  )
}
