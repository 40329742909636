import React, { useState, useEffect, useContext } from 'react'
import { ReactTable } from '../CommonComponents'
import { Button, Dropdown, Form, InputGroup } from 'react-bootstrap'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { trackPromise } from 'react-promise-tracker'
import Container from 'react-bootstrap/Container'
import { generatePath, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { appRoutes } from '../../utils/routes'
import { searchPatients } from '../../services'
import { AppUtil } from '../../utils/app.utils'
import { AuthUtil } from '../../utils/auth.utils'
import { BsFileEarmarkMedicalFill, BsEyeFill, BsSearch } from 'react-icons/bs'
import { MdEditNote } from 'react-icons/md'
import { VscNewFile } from 'react-icons/vsc'
import { PRIVILEGES as P, MASTER_DATA_KEYS as MDK, MASTER_DATA_ID as MDI, PP_LOGGED_IN_USER_DETAIL } from '../../utils/constants'
import { JournalPlus, JournalText, PersonSquare } from 'react-bootstrap-icons'
import './PatientList.scss'
import { MasterDataContext } from '../../contexts'
import { MasterDataItem } from '../../models/MasterData'
import { useDebounce } from '../../hooks/useDebounce'
import { WarningModal } from './WarningModal'
import { Sample } from '../../models/Sample'
import { importSample } from '../../services/Sample'
import { AxiosResponse } from 'axios'

const parentClass = 'patient-list'

type actionPropsTypes = {
  patientId: string
  patientBasicInfoObject: any
}

type AddSampleInfoProps = {
  patientId: string
  sampleIds: string[] | number[]
  isAllowed: boolean
  handleModal: () => void
}

function AddSampleInfo(props: AddSampleInfoProps) {
  const { patientId, sampleIds, handleModal, isAllowed } = props
  const { t } = useTranslation()
  const navigate = useNavigate()
  const translatn = 'patientList.'

  const importSampleData = () => {
    const sampleId = sampleIds?.[0]
    if (sampleId) {
      trackPromise(
        importSample(sampleId?.toString()).then(
          (res: AxiosResponse<Sample>) => {
            if (res?.status == 200) {
              navigate(generatePath(appRoutes.addSample, { patientId: patientId }),
                { state: res.data })
            }
          },
          (error) => AppUtil.logError(error),
        ),)
    }
  }

  return (
    <Dropdown>
      <Dropdown.Toggle variant="white" id="dropdown-basic" >
        <Button className={`${parentClass}-action-btn`}
          size="sm"
          variant="outline-primary"
          id="addSample"
          title={t(`${translatn}addSampleToolTip`)}
          onClick={() => handleModal()}
        >
          <VscNewFile size={20} />
        </Button>
      </Dropdown.Toggle>
      {isAllowed && <Dropdown.Menu >
        <Dropdown.Item onClick={() => navigate(generatePath(appRoutes.addSample, { patientId: patientId }))}>
          {t(`${translatn}newSample`)}
        </Dropdown.Item>
        <Dropdown.Item onClick={() => importSampleData()} disabled={sampleIds.length === 0}>
          {t(`${translatn}importSample`)}
        </Dropdown.Item>
      </Dropdown.Menu>
      }
    </Dropdown>

  )

}

function ActionColumn(props: Readonly<actionPropsTypes>) {
  const navigate = useNavigate()
  const { patientId, patientBasicInfoObject } = props

  const { t } = useTranslation()
  const translatn = 'patientList.'
  const hasEditPatientPrivilege = AuthUtil.checkUserAccess(P.EDIT_PATIENT)
  const hasAddSamplePrivilege = AuthUtil.checkUserAccess(P.ADD_SAMPLE)
  const hasViewPatientPrivilege = AuthUtil.checkUserAccess(P.VIEW_PATIENT)
  const hasViewSamplePrivilege = AuthUtil.checkUserAccess(P.VIEW_SAMPLE)
  const hasViewHandwrittenNotesPrivilege = AuthUtil.checkUserAccess(P.VIEW_NOTE)
  const hasEditAfterShippmentPrivilege = AuthUtil.checkUserAccess(P.EDIT_AFTER_SHIPMENT)
  const hasEditBeforeShippmentPrivilege = AuthUtil.checkUserAccess(P.EDIT_BEFORE_SHIPMENT)
  

  const showEditPatient = (hasEditBeforeShippmentPrivilege && patientBasicInfoObject?.allowEdit) || (hasEditAfterShippmentPrivilege && !patientBasicInfoObject?.allowEdit)

  const [showFormModal, setShowFormModal] = useState(false)
  const [isAllowed, setIsAllowed] = useState<boolean>(false)

  const handleFormModalClose = () => {
    setShowFormModal(false);
  }
  const handleModal = () => {
    const requiredFields = {
      'addressLine1': patientBasicInfoObject?.addressLine1,
      'nationality': patientBasicInfoObject?.nationality,
      'consentProvided': patientBasicInfoObject?.consentProvided,
      'ethnicity': patientBasicInfoObject?.ethnicity,
      'race': patientBasicInfoObject?.race,
      'state': patientBasicInfoObject?.state,
      'city': patientBasicInfoObject?.city,
      'pinCode': patientBasicInfoObject?.pincode,
      'uhid': patientBasicInfoObject?.uhid,
      'diagnosis': patientBasicInfoObject?.diagnosis
    }

    if (Object.values(requiredFields).some(val => val === null || val === '')) {
      setShowFormModal(true);
      setIsAllowed(false)
    } else {
      setIsAllowed(true)
      setShowFormModal(false);
    }

  }

  const handleEdit = () => {
    navigate(generatePath(appRoutes.editPatient, { id: patientId }), {
      state: { ...patientBasicInfoObject },
    })
  }

  return (
    <Row className="flex-nowrap">
      {hasViewPatientPrivilege && (
        <Col>
          <Button
            className={`${parentClass}-action-btn`}
            size="sm"
            id="viewPatient"
            title={t(`${translatn}viewPatientToolTip`)}
            onClick={() => navigate(generatePath(appRoutes.viewPatientDetails, { id: patientId }))}
          >
            <BsEyeFill size={20} />
          </Button>
        </Col>
      )}
      {hasEditPatientPrivilege && (
        <>
          <span className={`${parentClass}-vertical-line`}></span>
          <Col>
            <Button
              disabled={!showEditPatient}
              className={`${parentClass}-action-btn`}
              size="sm"
              id="editPatient"
              title={t(`${translatn}editPatientToolTip`)}
              onClick={() =>
                navigate(generatePath(appRoutes.editPatient, { id: patientId }), {
                  state: { ...patientBasicInfoObject },
                })
              }
            >
              <MdEditNote size={20} />
            </Button>
          </Col>
        </>
      )}
      {hasViewSamplePrivilege && (
        <>
          <span className={`${parentClass}-vertical-line`}></span>
          <Col>
            <Button
              className={`${parentClass}-action-btn`}
              size="sm"
              id="viewSamples"
              title={t(`${translatn}viewSamplesToolTip`)}
              onClick={() => navigate(generatePath(appRoutes.viewPatientDetails, { id: patientId }) + '#samples')}
            >
              <BsFileEarmarkMedicalFill size={20} />
            </Button>
          </Col>
        </>
      )}
      {hasAddSamplePrivilege && (
        <>
          <span className={`${parentClass}-vertical-line`}></span>
          <Col>
            <AddSampleInfo patientId={patientId} sampleIds={patientBasicInfoObject?.sampleIds || []} handleModal={handleModal} isAllowed={isAllowed} />
          </Col>
          <WarningModal
            open={showFormModal}
            handleClose={handleFormModalClose}
            handleEdit={handleEdit}
          />

        </>
      )}
      {hasViewHandwrittenNotesPrivilege && (
        <>
          <span className={`${parentClass}-vertical-line`}></span>
          <Col>
            <Button
              className={`${parentClass}-action-btn`}
              size="sm"
              id="viewHandwrittenNotes"
              title={t(`${translatn}viewHandwrittenNotesToolTip`)}
              onClick={() => navigate(generatePath(appRoutes.viewHandwrittenNotes, { id: patientId }))}
            >
              <JournalText size={20} />
            </Button>
          </Col>
        </>
      )}
    </Row>
  )
}

type Person = {
  id: number
  siteName: string
  name: string
  gender: string
  registrationDate: string
}

const tableData: Person[] = []

type FormatRowDataProps = {
  rawData: any
  masterData: MasterDataItem[]
}

export function formatRowData({ rawData }: FormatRowDataProps) {
  const rowData = rawData.map(function (singleRow: any) {
    return {
      patientId: singleRow.id || '',
      name: AppUtil.combineNamesString(singleRow.name, singleRow.firstName, singleRow.middleName, singleRow.lastName),
      gender: singleRow.gender,
      genderOther: singleRow?.genderOther,
      age: singleRow.age || '',
      registrationDate: AppUtil.formatDateToString(singleRow.registrationDate || ''),
      siteName: singleRow.siteName || '',
      allowEdit: singleRow.allowEdit || false,
      consentProvided: singleRow.consentProvided || false,
      addressLine1: singleRow.addressLine1 || '',
      race: singleRow.race,
      ethnicity: singleRow.ethnicity,
      nationality: singleRow.nationality || '',
      state: singleRow.state || '',
      city: singleRow.city || '',
      pinCode: singleRow.pinCode || '',
      uhid: singleRow.uhid || '',      
      diagnosis: singleRow?.diagnosis || '',
      sampleIds: singleRow.sampleIds || []
    }
  })
  return rowData
}

const getPatientsData = async (signal: AbortSignal, searchVal = '', pageNo = 1) => {
  const response = await searchPatients(searchVal, pageNo, signal)
    .then(
      (searchResponse) => {
        if (searchResponse?.status == 200) {
          return searchResponse?.data
        }
      },
      (error) => {
        console.log(error)
        return {}
      },
    )
    .catch((error) => {
      console.log(error)
      return {}
    })
  return response
}

const sortColumnData = (rowA: any, rowB: any, columnId: any) => {
  const valueA = rowA.getValue(columnId).toLowerCase()
  const valueB = rowB.getValue(columnId).toLowerCase()
  return valueA.localeCompare(valueB)
}

export function PatientTableInfo() {
  const { t } = useTranslation()
  const translatn = 'patientList.'

  const navigate = useNavigate()
  const { masterData } = useContext(MasterDataContext)
  const loggedInUserData = JSON.parse(localStorage.getItem(PP_LOGGED_IN_USER_DETAIL) ?? "{}")

  const hasSearchPrivilege = AuthUtil.checkUserAccess(P.SEARCH_PATIENT)
  const hasCreatePrivilege = AuthUtil.checkUserAccess(P.CREATE_PATIENT)

  const [searchValue, setSearchValue] = useState('')

  const [currentPage, setCurrentPage] = useState(1)

  let controller = new AbortController()
  let signal = controller.signal

  function createPatient() {
    navigate(appRoutes.registerPatient)
  }

  const [pageData, setPageData] = useState({
    rowData: tableData,
    totalPages: 0,
    totalElements: 0,
    size: 10,
    pageIndex: 0,
  })

  function searchPatient(searchValue: string, signal: AbortSignal) {
    if (!searchValue) {
      searchValue = ''
    }
    setPageData((prevState) => ({
      ...prevState,
      rowData: tableData,
    }))

    setCurrentPage(1)
    trackPromise(
      getPatientsData(signal, searchValue, 1).then((apiData) => {
        setPageData({
          rowData: formatRowData({ rawData: apiData?.content, masterData }),
          totalPages: apiData?.totalPages,
          totalElements: apiData?.totalElements,
          size: apiData?.size,
          pageIndex: apiData?.number,
        })
      }),
    )
  }

  function searchPatientClick() {
    controller = new AbortController()
    signal = controller.signal
    searchPatient(searchValue, signal)
  }

  const debouncedSearch = useDebounce(searchValue, 2000)

  useEffect(() => {
    controller = new AbortController()
    signal = controller.signal
    searchPatient(searchValue, signal)

    return () => {
      controller.abort()
    }
  }, [debouncedSearch])

  useEffect(() => {
    setPageData((prevState) => ({
      ...prevState,
      rowData: tableData,
    }))

    trackPromise(
      getPatientsData(signal, searchValue, currentPage).then((apiData) => {
        setPageData({
          rowData: formatRowData({ rawData: apiData?.content, masterData }),
          totalPages: apiData?.totalPages,
          totalElements: apiData?.totalElements,
          size: apiData?.size,
          pageIndex: apiData?.number,
        })
      }),
    )
  }, [masterData, currentPage])

  const columns = [
    {
      id: 'patientId',
      header: t(`${translatn}patientId`),
      accessorKey: 'patientId',
      enableSorting: true,
      cell: (info: any) => info.getValue(),
      maxSize: 125
    },
    {
      id: 'name',
      header: t(`${translatn}patientName`),
      accessorKey: 'name',
      enableSorting: true,
      cell: (info: any) => {
        const name = info.getValue();
        return (
          <div title={name} className={`${parentClass}-text-container`}>
            {name}
          </div>
        );

      },
      headerStyle: { width: '50%', colSpan: '5', textAlign: 'right' },
      sortingFn: (rowA: any, rowB: any, columnId: any) => sortColumnData(rowA, rowB, columnId),
    },
    {
      id: 'gender',
      header: t(`${translatn}gender`),
      accessorKey: 'gender',
      enableSorting: false,
      cell: (info: any) => {
        const otherGender = info?.row?.original?.genderOther
        return (
          <div title={AppUtil.getLabel(MDK.GENDER, masterData, info.getValue()) + (otherGender ? ` - ${otherGender}` : '')}
            className={`${parentClass}-text-container`}>
            {AppUtil.getLabel(MDK.GENDER, masterData, info.getValue()) + (otherGender ? ` - ${otherGender}` : '')}
          </div>);
      },
    },
    {
      id: 'registrationDate',
      header: t(`${translatn}registrationDate`),
      header2: t(`common.dateFormat`),
      accessorKey: 'registrationDate',
      enableSorting: false,
      cell: (info: any) => info.renderValue(),
    },
    {
      id: 'siteName',
      header: t(`${translatn}siteName`),
      accessorKey: 'siteName',
      enableSorting: true,
      cell: (info: any) => info.getValue() || 'NA',
    },
    {
      id: 'action',
      header: t(`${translatn}action`),
      accessorKey: 'action',
      enableSorting: false,
      cell: (info: any) => (
        <ActionColumn
          patientBasicInfoObject={JSON.parse(JSON.stringify(info.row.original))}
          patientId={String(info.row.getValue('patientId'))}
        />
      ),
    },
  ]

  return (
    <Container fluid className={`${parentClass}`}>
      <Row className={`${parentClass}-header`}>
        <Col className="d-flex align-items-center justify-content-start">
          <h4 className={`${parentClass}-form-heading`}>{t(`${translatn}formHeading`)}</h4>
        </Col>
        <Col className="d-flex align-items-center justify-content-center">
          {hasSearchPrivilege && (
            <InputGroup className={`${parentClass}-search-inputgroup`}>
              <Form.Control
                className={`${parentClass}-search-form`}
                placeholder={t(`${translatn}searchInputPlaceholder`)}
                type="text"
                onChange={(event) => setSearchValue(event.target.value.trim())}
              />

              <Button
                className={`${parentClass}-search-btn`}
                type="submit"
                id="searchPatient"
                onClick={() => searchPatientClick()}
              >
                <BsSearch color={'#075883'} size={20} />
              </Button>
            </InputGroup>
          )}
        </Col>
        <Col className="d-flex align-items-center justify-content-end">
          {hasCreatePrivilege && (
            <Button className={`${parentClass}-create-patient-btn`} onClick={() => createPatient()}>
              <PersonSquare color={'#075883'} size={25} className="pe-2" />
              {t(`${translatn}createPatient`)}{' '}
            </Button>
          )}
        </Col>
      </Row>
      <Row>
        <Col lg={12} md={12} sm={12}>
          <div className={`${parentClass}-react-table-container`}>
            <ReactTable
              pageChangeHandler={setCurrentPage}
              data={pageData.rowData}
              columns={loggedInUserData.userRoleId == MDI.CRC_ROLE ? columns.filter(col => col.id !== 'siteName') : columns}
              pageSize={pageData.size}
              pageIndex={pageData.pageIndex}
              totalRows={pageData.totalElements}
              pageCount={pageData.totalPages}
              tableClassName="patient-list"
            />
          </div>
        </Col>
      </Row>
    </Container>
  )
}

