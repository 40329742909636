import React from 'react'
import { useTranslation } from 'react-i18next'
import Modal from 'react-bootstrap/Modal'


type AboutModalProps = {
  show: boolean
  handleClose: () => void
}

export function AboutModal(props: Readonly<AboutModalProps>) {
  const { show, handleClose } = props
  const translatn = 'aboutModal'
  const parentClass = ''
  const { t } = useTranslation()

  return (

    <Modal className={t(`${parentClass}`)} show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>{t(`${translatn}.title`)}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>{t(`${translatn}.version`)}</div>
        <div>{t(`${translatn}.copyright`)}</div>
        <div>{t(`${translatn}.text`)}</div>
      </Modal.Body>
      <Modal.Footer>
      </Modal.Footer>
    </Modal>

  )
}
