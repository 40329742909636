import React, { useEffect, useState, useContext } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import { useForm, FormProvider, UseFormSetValue } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { AppUtil } from '../../utils/app.utils'
import { MASTER_DATA_ID, MASTER_DATA_KEYS } from '../../utils/constants'
import { MasterDataContext } from '../../contexts'
import { TextInput, SelectInput, NumberInput } from '../InputComponents'
import { addUser, editUser } from '../../services'
import { UserCreateFormSchema, UserCreateDefaultData } from './helpers'
import { UserDataType } from '../../models/Auth'
import './UserCreate.scss'
import { Col, Row } from 'react-bootstrap'
import { Site } from '../../models/MasterData'

type UserCreateModalProps = {
  show: boolean
  handleClose: (userAPIState?: string) => void
  userInfo: UserDataType | null 
  siteList: Site[]
}

let isPasswordRequired = true
let schema = UserCreateFormSchema(isPasswordRequired)

type ValidationSchema = z.infer<typeof schema>

function mapUserInfo(userInfo: UserDataType | null, formDefaultValues: any) {
  formDefaultValues.userId = userInfo?.userId?.toString()
  formDefaultValues.username = userInfo?.username
  formDefaultValues.firstName = userInfo?.firstName
  formDefaultValues.lastName = userInfo?.lastName
  formDefaultValues.email = userInfo?.emailId
  formDefaultValues.roleId = userInfo?.userRole?.toString()
  formDefaultValues.userStatus = userInfo?.userState?.toString()
  formDefaultValues.siteId = userInfo?.siteId
  formDefaultValues.siteName = userInfo?.siteName
  formDefaultValues.employeeId = userInfo?.employeeId?.toString()
}

const setSiteId = (
  selectedEvent: React.ChangeEvent<HTMLSelectElement>,
  siteList: Site[],
  setValue: UseFormSetValue<ValidationSchema>,
) => {
  const selectedSite = selectedEvent?.target?.value
  const correspondingId = siteList.find((state: Site) => state.siteName == selectedSite)?.siteId
  setValue('siteId', correspondingId ?? '')
}

const handleError = (error: any, setUserFormError: any) => {
  AppUtil.logError(error)
  setUserFormError(error?.response?.data?.message || '')
  return { status: false, message: error?.response?.data?.message || '' }
}

const onAddEditUser = (
  translatn: string,
  addUser: any,
  editUser: any,
  userInfo: UserDataType | null,
  t: (path: string) => void,
) => {
  let submitButtonCaption = t(`${translatn}.userCreateBtn`)
  let apiCaller = addUser
  let formDefaultValues = JSON.parse(JSON.stringify(UserCreateDefaultData))
  let modalSubTitle = ''
  if (userInfo) {
    submitButtonCaption = t(`${translatn}.userEditBtn`)
    apiCaller = editUser
    mapUserInfo(userInfo, formDefaultValues)
    isPasswordRequired = false
    modalSubTitle = " (" + t(`${translatn}.userId`) + " - " + userInfo?.userId + ")"
  } else {
    isPasswordRequired = true
  }
  return { modalSubTitle, submitButtonCaption, apiCaller, formDefaultValues }
}

export function UserCreateModal(props: Readonly<UserCreateModalProps>) {
  const { show, userInfo, handleClose, siteList } = props
  const translatn = 'userCreateForm'
  const parentClass = 'vcr-pp-usr-form'
  const { t } = useTranslation()
  const [userFormError, setUserFormError] = useState('')
  const { masterData } = useContext(MasterDataContext)


  const { modalSubTitle, submitButtonCaption, apiCaller, formDefaultValues } = onAddEditUser(
    translatn,
    addUser,
    editUser,
    userInfo,
    t,
  )

  schema = UserCreateFormSchema(isPasswordRequired)

  const formMethods = useForm<ValidationSchema>({
    resolver: zodResolver(schema),
    mode: 'all',
    defaultValues: formDefaultValues,
  })

  const { handleSubmit, reset, setValue, watch, resetField } = formMethods

  useEffect(() => {
    reset(formDefaultValues)
  }, [userInfo])

  useEffect(() => {
    if(watch('roleId') !== MASTER_DATA_ID.CRC_ROLE) {
        resetField('siteId', { defaultValue: null })
        resetField('siteName', { defaultValue: null })
    }
  }, [watch('roleId')])

  const UserCreateSubmit = function (userFormdata: ValidationSchema) {
    setUserFormError('')
    const response = apiCaller(userFormdata)
      .then(
        (apiResponse: any) => {
          if (apiResponse.status == 200) {
            let userAPIState = 'userCreated'
            if (userInfo) userAPIState = 'userUpdated'
            handleClose(userAPIState)
            return { status: true, message: apiResponse?.data?.message || '' }
          }
        },
        (error: any) => {
          return handleError(error, setUserFormError)
        },
      )
      .catch((error: any) => {
        return handleError(error, setUserFormError)
      })
    return response
  }

  return (
    <FormProvider {...formMethods}>
      <Modal className={parentClass} show={show} onHide={() => handleClose()} centered backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>{t(`${translatn}.userDetails`)}{modalSubTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate>
            <Row>
              <Col>
                <TextInput
                  type="text"
                  name="username"
                  id="user-form-username"
                  title={t(`${translatn}.username`)}
                  placeholder={t(`${translatn}.usernamePlaceholder`)}
                  disabled={!!userInfo}
                  readOnly={false}
                  required={true}
                  onChange={() => setUserFormError('')}
                  className={`${parentClass}-text-input`}
                />
              </Col>
              <Col>
                {' '}
                <TextInput
                  type="password"
                  name="password"
                  id="user-form-password"
                  title={t(`${translatn}.password`)}
                  placeholder={t(`${translatn}.passwordPlaceholder`)}
                  disabled={false}
                  readOnly={false}
                  required={!userInfo}
                  onChange={() => setUserFormError('')}
                  className={`${parentClass}-text-input`}
                  onPaste={(e: any) => e.preventDefault()}
                  autoComplete="new-password"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <TextInput
                  type="text"
                  name="firstName"
                  id="user-form-firstName"
                  title={t(`${translatn}.firstName`)}
                  placeholder={t(`${translatn}.firstNamePlaceholder`)}
                  disabled={false}
                  readOnly={false}
                  required={true}
                  onChange={() => setUserFormError('')}
                  className={`${parentClass}-text-input`}
                />
              </Col>
              <Col>
                {' '}
                <TextInput
                  type="text"
                  name="lastName"
                  id="user-form-lastName"
                  title={t(`${translatn}.lastName`)}
                  placeholder={t(`${translatn}.lastNamePlaceholder`)}
                  disabled={false}
                  readOnly={false}
                  required={true}
                  onChange={() => setUserFormError('')}
                  className={`${parentClass}-text-input`}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <SelectInput
                  name="roleId"
                  id="user-form-role"
                  title={t(`${translatn}.userRole`)}
                  placeholder={t(`${translatn}.userRolePlaceholder`)}
                  disabled={!!userInfo}
                  required={true}
                  optionsValueArray={AppUtil.getOptions(MASTER_DATA_KEYS.ROLES, masterData)}
                />
              </Col>
              <Col>
                {' '}
                <SelectInput
                  name="userStatus"
                  id="user-form-userStatus"
                  title={t(`${translatn}.userStatus`)}
                  placeholder={t(`${translatn}.userStatusPlaceholder`)}
                  disabled={false}
                  required={true}
                  optionsValueArray={AppUtil.getOptions(MASTER_DATA_KEYS.USER_STATE, masterData)}
                />
              </Col>
            </Row>
            {watch('roleId') === MASTER_DATA_ID.CRC_ROLE && 
            <Row>
              <Col>
                <SelectInput
                  name={`siteName`}
                  id="user-form-siteName"
                  title={t(`${translatn}.siteName`)}
                  placeholder={t(`${translatn}.siteNamePlaceholder`)}
                  disabled={!!userInfo}
                  required={true}
                  onCustomChange={(e: React.ChangeEvent<HTMLSelectElement>) => setSiteId(e, siteList, setValue)}
                  optionsValueArray={AppUtil.formatOptionsIntoValueLabel(siteList.map((site) => site.siteName))}
                />
              </Col>
              <Col>
                <TextInput
                  name={`siteId`}
                  id="user-form-siteId"
                  title={t(`${translatn}.siteId`)}
                  placeholder={t(`${translatn}.siteIdPlaceholder`)}
                  disabled={true}
                  required={true}
                  readOnly={true}
                />
              </Col>
            </Row>
            }
            <Row>
              <Col>
                {' '}
                <TextInput
                  type="email"
                  name="email"
                  id="user-form-email"
                  title={t(`${translatn}.userEmail`)}
                  placeholder={t(`${translatn}.emailPlaceholder`)}
                  disabled={false}
                  readOnly={false}
                  required={true}
                  onChange={() => setUserFormError('')}
                  className={`${parentClass}-text-input`}
                />
              </Col>
              <Col>
                {' '}
                <NumberInput
                  name="employeeId"
                  id="user-form-employeeId"
                  title={t(`${translatn}.employeeId`)}
                  placeholder={t(`${translatn}.employeeIdPlaceholder`)}
                  disabled={false}
                  readOnly={false}
                  required={true}
                  onChange={() => setUserFormError('')}
                  className={`${parentClass}-text-input`}
                  min={1}
                />
              </Col>
            </Row>

            <div className={parentClass + '-error-message'}>{userFormError}</div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleClose()}>
            {t(`${translatn}.cancelBtn`)}
          </Button>
          <Button variant="primary" className={`${parentClass}-save-btn`} onClick={handleSubmit(UserCreateSubmit)}>
            {submitButtonCaption}
          </Button>
        </Modal.Footer>
      </Modal>
    </FormProvider>
  )
}
