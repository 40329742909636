import axios, { AxiosResponse } from 'axios'
import { PP_API_TIMEOUT, PP_API_URL } from '../utils/constants'
import { apiRoutes } from '../utils/routes'
import { AppUtil } from '../utils/app.utils'
import { Buffer } from 'buffer'


interface AuthResponse {
  accessToken: string
  message: string
}

export const getAuthToken = async (userName: string, password: string): Promise<AxiosResponse<AuthResponse>> => {
  let encodedPW = Buffer.from(password, 'utf8').toString('base64')
  const response = await axios.post(
    apiRoutes.user.login,
    {
      userName: userName,
      password: encodedPW,
    },
    {
      baseURL: PP_API_URL,
      timeout: Number(PP_API_TIMEOUT),
      headers: {
        'Content-Type': 'application/json',
      },
    },
  )

  return response
}

export const logoutUser = async (): Promise<AxiosResponse<AuthResponse>> => {
  const response = await axios.post(apiRoutes.user.logout, {}, AppUtil.getCommonAPIConfig())

  return response
}

export const changePassword = async (password: string, newPassword: string) => {
  let encodedPW = Buffer.from(password, 'utf8').toString('base64')
  let encodedNewPW = Buffer.from(newPassword, 'utf8').toString('base64')
  const response = await axios.post(
    apiRoutes.user.changePassword,
    { oldpassword: encodedPW, newpassword: encodedNewPW },
    AppUtil.getCommonAPIConfig(),
  )

  return response
}

export const refreshUserAccessToken = async (refreshToken: string) => {
  const response = await axios.post(apiRoutes.user.refreshAccessToken, 
    {},
    {
      baseURL: PP_API_URL,
      timeout: Number(PP_API_TIMEOUT),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + refreshToken,
      },
    }
  )

  return response
}
