import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { useFormContext } from 'react-hook-form'

import { NumberInput, DateInput, TextInput } from '../../InputComponents'
import { setAgeAtEvent } from '../helpers'
import { ageAtDeathFields, ageAtLastContactActivityFields, dateOfDeathFields, dateOfLastContactFields, getDateOfBirth } from '../helpers/AddSampleInfoHelpers'
import { AppUtil } from '../../../utils/app.utils'

export const VitalStatus = () => {
  const { t } = useTranslation()
  const { setValue, getValues, watch, resetField, clearErrors } = useFormContext()
  const translatn = 'sampleInfoForm'
  const parentClass = 'vcr-pp-sample-info-'
  const dob = getValues('dob')
  const [bmiData, setBmiData] = useState({
    weight: getValues('vitalWeight') ?? 0,
    height: getValues('vitalHeight') ?? 0,
  })

  useEffect(() => {
    const bmi = AppUtil.getBMI(bmiData.weight, bmiData.height)
    setValue('vitalBMI', bmi)
  }, [bmiData])

  const isDateOfDeath = !!watch('dateOfDeath')

  useEffect(() => {
    if (!isDateOfDeath) {
      dateOfDeathFields.forEach((field) => {
        resetField(field, { defaultValue: '' })
        clearErrors(field)
      })
      ageAtDeathFields.forEach((field) => {
        resetField(field, { defaultValue: null })
      })
    }
  }, [isDateOfDeath])

  return (
    <div className={`${parentClass}input-field`}>
      <h5 className={`${parentClass}form-heading`}> {t(`${translatn}.vitalStatus`)}</h5>
      <Row lg={4} md={4} sm={4}>
        <Col>
          <TextInput
            name={`vitalWeight`}
            id={'vitalWeight'}
            title={t(`${translatn}.vitalWeight`)}
            placeholder={t(`${translatn}.vitalWeightPlaceholder`)}
            disabled={false}
            readOnly={false}
            required={true}
            min={1}
            onChange={(event: any) => setBmiData({ weight: event.target.value.trim(), height: bmiData.height })}
          
          />
        </Col>
        <Col>
          <TextInput
            name={`vitalHeight`}
            id={'vitalHeight'}
            title={t(`${translatn}.vitalHeight`)}
            placeholder={t(`${translatn}.vitalHeightPlaceholder`)}
            disabled={false}
            readOnly={false}
            required={true}
            min={1}
            onChange={(event: any) => setBmiData({ weight: bmiData.weight, height: event.target.value.trim() })}
          />
        </Col>
        <Col>
          <TextInput
            name={`vitalBMI`}
            id={'vitalBMI'}
            title={t(`${translatn}.vitalBMI`)}
            placeholder={t(`${translatn}.vitalBMIPlaceholder`)}
            disabled={true}
            readOnly={false}
            required={true}
          />
        </Col>
      </Row>
      <Row lg={4} md={4} sm={4}>
        <Col>
          <TextInput
            name={`vitalTemperature`}
            id={'vitalTemperature'}
            title={t(`${translatn}.vitalTemperature`)}
            placeholder={t(`${translatn}.vitalTemperaturePlaceholder`)}
            disabled={false}
            readOnly={false}
            required={true}
          />
        </Col>
        <Col>
            <DateInput
              name={`dateOfLastContactActivity`}
              id={'dateOfLastContact'}
              title={t(`${translatn}.dateOfLastContact`)}
              placeholder={t(`common.datePlaceholder`)}
              disabled={false}
              readOnly={false}
              min={new Date(getDateOfBirth(dob)) ?? undefined}
              max={new Date()}
              required={true}
              onChange={(date: Date) => {
                setAgeAtEvent(dob, date, `ageAtLastContactActivity`, setValue)
              }}
            />
          </Col>
          <Col>
            <NumberInput
              name={`ageAtLastContactActivity`}
              id={'ageAtLastContact'}
              title={t(`${translatn}.ageAtLastContact`)}
              placeholder={t(`${translatn}.ageAtLastContactPlaceholder`)}
              disabled={false}
              readOnly={true}
              required={true}
            />
          </Col>
      </Row>
      <Row>
      <Col lg={3}>
          <DateInput
            name={`dateOfDeath`}
            id={'dateOfDeath'}
            title={t(`${translatn}.dateOfDeath`)}
            placeholder={t(`common.datePlaceholder`)}
            disabled={false}
            readOnly={false}
            min={new Date(getDateOfBirth(dob)) ?? undefined}
            max={new Date()}
            required={false}
            onChange={(date: Date) => {
              if(date) {
                setAgeAtEvent(dob, date, `ageAtDeath`, setValue)
              } else {
                ageAtDeathFields.forEach((field) => {
                  resetField(field, { defaultValue: null })
                })
              }
            }} isClearable={true} />
        </Col>
        
        {isDateOfDeath &&
          <Col lg={3}>
            <NumberInput
              name={`ageAtDeath`}
              id={'ageAtDeath'}
              title={t(`${translatn}.ageAtDeath`)}
              placeholder={t(`${translatn}.ageAtDeathPlaceholder`)}
              disabled={false}
              readOnly={true}
              required={true}
            />
          </Col>
        }

      </Row>
      {isDateOfDeath && <>
        <h6 className='mt-4'> {t(`${translatn}.causeOfDeath`)}</h6>
        <Row>
          <Col lg={5} md={5} sm={5}>
            <TextInput
              type="text"
              name={`causeOfDeathContributingFactors`}
              id={'causeOfDeathContributingFactors'}
              title={t(`${translatn}.causeOfDeathContributingFactors`)}
              placeholder={t(`${translatn}.causeOfDeathContributingFactorsPlaceholder`)}
              disabled={false}
              readOnly={false}
              required={true}
            />
          </Col>
          <Col lg={5} md={5} sm={5}>
            <TextInput
              type="text"
              name={`causeOfDeathAutopsyFindings`}
              id={'causeOfDeathAutopsyFindings'}
              title={t(`${translatn}.causeOfDeathAutopsyFindings`)}
              placeholder={t(`${translatn}.causeOfDeathAutopsyFindingsPlaceholder`)}
              disabled={false}
              readOnly={false}
              required={true}
            />
          </Col>
        </Row>
      </>}
      {isDateOfDeath &&
        <Row>
          <Col lg={5} md={5} sm={5}>
            <TextInput
              type="text"
              name={`causeOfDeathMedicalHistory`}
              id={'causeOfDeathMedicalHistory'}
              title={t(`${translatn}.causeOfDeathMedicalHistory`)}
              placeholder={t(`${translatn}.causeOfDeathMedicalHistoryPlaceholder`)}
              disabled={false}
              readOnly={false}
              required={true}
            />
          </Col>
          <Col lg={5} md={5} sm={5}>
            <TextInput
              type="text"
              name={`causeOfDeathDocumentationOfTerminalEvents`}
              id={'causeOfDeathDocumentationOfTerminalEvents'}
              title={t(`${translatn}.causeOfDeathDocumentationOfTerminalEvents`)}
              placeholder={t(`${translatn}.causeOfDeathDocumentationOfTerminalEventsPlaceholder`)}
              disabled={false}
              readOnly={false}
              required={true}
            />
          </Col>
        </Row>
      }
    </div>
  )
}
