import React from "react";
import { MultiSelectItem, MultiSelectItemSubCatergory } from "../../../models/Sample";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from 'react-bootstrap/Form'
import { MultiSelectDropdown, NumberInput, TextInput } from "../../InputComponents";
import { isArray } from "lodash";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import {MASTER_DATA_ID as MDI } from '../../../utils/constants'

interface DependentFieldsProps {
  name: string
  categories: MultiSelectItemSubCatergory[]
  subCategories: {
    [key: string]: (MultiSelectItem & { subCategoryFields: MultiSelectItem[] })[] | undefined
  }
}

export const DependentFields = (props: DependentFieldsProps) => {
  const { t } = useTranslation()
  const { name = '', categories = [], subCategories = {} } = props
  const { setValue, getValues } = useFormContext()

  const removePropertyByIndex = (object: any, value: string | number, parentValue?: string | number) => {

    let parentKey = `type${parentValue}`;
    let childKey = `type${value}`
    delete object?.[parentKey]?.[childKey];
    return object;
  }

  const handleRemoveSubCategory = (parentValue: string | number, value: string | number) => {
    setValue(`${name}SubCategoryFields`, removePropertyByIndex(getValues(`${name}SubCategoryFields`), value, parentValue))

  }

  return <>
    {categories && categories.length > 0 &&
      <Row style={{gap: "0rem"}}>
        {categories?.map((categoryItem: MultiSelectItemSubCatergory, index) => {
          return (  
            <>{
              index > 2 && <hr className='mt-3 pl-1 pr-1'/>
            }        
            <Col lg={4} md={4} sm={4} key={`${categoryItem.label} + '_' + ${categoryItem.value}`}>
              {categoryItem.textBox ? (<TextInput
                type="text"
                name={`${name}SubCategory.type${categoryItem.value}`}
                id={`${name}SubCategory.type${categoryItem.value}`}
                title={`${categoryItem.label}`}
                placeholder={(subCategories?.[`type` + categoryItem?.value])?.length ? "" : t(`Enter ${categoryItem.label}`)}
                disabled={false}
                required={false}
              />) : <MultiSelectDropdown
                name={`${name}SubCategory.type${categoryItem.value}`}
                id={`${name}SubCategory.type${categoryItem.value}`}
                title={`${categoryItem.label}`}
                placeholder={(subCategories?.[`type` + categoryItem.value])?.length ? "" : t(`Select ${categoryItem.label}`)}
                disabled={false}
                required={false}
                optionsValueArray={categoryItem.subCategory || []}
                hideSelectedValues={false}
                onCustomRemove={(event: any) => handleRemoveSubCategory(categoryItem.value, event)}
              />}

              {subCategories && isArray(subCategories[`type${categoryItem.value}`]) && subCategories?.[`type${categoryItem?.value}`]?.map((subCategoriesItem: (MultiSelectItem & { subCategoryFields: MultiSelectItem[] })) => {
                return <>
                  {subCategoriesItem?.subCategoryFields?.length > 0 ? 
                  <Row className="m-auto" key={`${subCategoriesItem.label}+ '_' +${subCategoriesItem.value}`}>
                    <Card className='p-0 mt-2'>
                    <Card.Body>
                      <Form.Label>{subCategoriesItem.label}</Form.Label>
                      {subCategoriesItem?.subCategoryFields?.map((subCategoryFieldItem: MultiSelectItem) => {
                        return <Row key={`${subCategoryFieldItem.label} + '_' +${subCategoryFieldItem.value}`} className="d-flex justify-content-between">
                          <Col sm={6}>
                            <label>{subCategoryFieldItem.label}
                            </label><br />
                            <Form.Text>{subCategoryFieldItem.helperText}</Form.Text>
                          </Col>
                          <Col sm={4}>
                          {(categoryItem.value==MDI.BIOMARKER_TEST_VALUE && subCategoriesItem.value==MDI.WBC_VALUE)?( 
                            <TextInput
                              name={`${name}SubCategoryFields.type${`type${categoryItem.value}`[`type${categoryItem.value}`.length - 1]}.type${subCategoriesItem.value}.type${subCategoryFieldItem.value}`}
                              id={`${name}SubCategoryFields.type${`type${categoryItem.value}`[`type${categoryItem.value}`.length - 1]}.type${subCategoriesItem.value}.type${subCategoryFieldItem.value}`}
                              disabled={false}
                              readOnly={false}
                              required={false}
                            />):( <NumberInput
                              name={`${name}SubCategoryFields.type${`type${categoryItem.value}`[`type${categoryItem.value}`.length - 1]}.type${subCategoriesItem.value}.type${subCategoryFieldItem.value}`}
                              id={`${name}SubCategoryFields.type${`type${categoryItem.value}`[`type${categoryItem.value}`.length - 1]}.type${subCategoriesItem.value}.type${subCategoryFieldItem.value}`}
                              disabled={false}
                              readOnly={false}
                              required={false}
                              min={0}
                            />)}
                           
                          </Col>
                        </Row>
                      })}
                    </Card.Body>
                  </Card></Row> 
                  :
                   <Row key={`${subCategoriesItem.value}`} className="m-auto"><Card className='p-0 mt-2'>
                    <Card.Body>
                      <Row className="d-flex justify-content-between mt-0">
                        <Col sm={6}>
                          <Form.Label>{subCategoriesItem.label}</Form.Label>
                        </Col>
                        <Col sm={4}>
                          <TextInput
                            type="text"
                            name={`${name}SubCategoryFields.type${`type${categoryItem.value}`[`type${categoryItem.value}`.length - 1]}.type${subCategoriesItem.value}`}
                            id={`${name}SubCategoryFields.type${`type${categoryItem.value}`[`type${categoryItem.value}`.length - 1]}.type${subCategoriesItem.value}`}
                            disabled={false}
                            readOnly={false}
                            required={false}
                          /></Col>
                      </Row>
                    </Card.Body>
                  </Card></Row>
                  }</>
              })}
            </Col>
            </>  
          )
        })}</Row>}
  </>
}