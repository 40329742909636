import React from 'react'
import { useTranslation } from 'react-i18next'
import Form from 'react-bootstrap/Form'
import { access, renderRequiredFieldLabel } from './InputComponentHelpers'
import { useFormContext } from 'react-hook-form'
import { InputGroup } from 'react-bootstrap'

export const TextInputWithAddOn = function (props: any) {
  const { name, id, title, required, addOn, helperText, ...otherProps } = props
  const { t } = useTranslation()
  const formMethods = useFormContext()
  const {
    register,
    formState: { errors },
  } = formMethods
  const error = access(name, errors)

  return (
    <div>
      <Form.Label htmlFor={id}>{required ? renderRequiredFieldLabel(title) : title}</Form.Label>
      <InputGroup>
        <Form.Control
          as="input"
          id={id}
          {...register(name)}
          isInvalid={error}
          aria-describedby={`${name}Helper`}
          {...otherProps}
        />
        {addOn}
        <Form.Control.Feedback type="invalid">{t(error?.message)}</Form.Control.Feedback>
      </InputGroup>
      <Form.Text id={`${name}Helper`} muted>
        {helperText}

      </Form.Text>

    </div>
  )
}
