import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import { NumberInput, SelectInput, TextInput } from '../../InputComponents'
import { MASTER_DATA_KEYS as MDK } from '../../../utils/constants'
import { MasterDataContext } from '../../../contexts'
import { AppUtil as _ } from '../../../utils/app.utils'
import { useFormContext } from 'react-hook-form'
import { getTestIDs } from '../../../services/MasterData'
import { TestIdMaster } from '../../../models/MasterData'
import { TrashFill } from 'react-bootstrap-icons'

export const TestDetails = (props: any) => {
  const { t } = useTranslation()
  const { masterData } = useContext(MasterDataContext)
  const { getValues, setValue } = useFormContext()
  const { isEditAllowed } = props
  const translatn = 'sampleInfoForm'
  const parentClass = 'vcr-pp-sample-info-'
  const [testCount, setTestCount] = useState<number>(1)
  const [testIDs, setTestIDs] = useState<TestIdMaster[]>([])
  const [testArr, setTestArr] = useState<number[]>(
    getValues('testType')?.length ? [] : Array.from({ length: testCount }, (_, i) => i),
  )

  useEffect(() => {
    setTestCount(getValues('testType')?.length)
  }, [getValues('testType')])

  useEffect(() => {
    getTestIDs().then(
      (resp) => {
        setTestIDs(resp?.data ?? [])
        if (getValues('testType')?.length) {
          setTestArr(Array.from({ length: getValues('testType')?.length }, (_, i) => i))
        }
      },
      (err) => _.logError(err),
    )
  }, [getValues('testType')])

  useEffect(() => {
    if (testIDs.length && getValues('testType')?.length) {
      getValues('testType').map((testType: any, index: number) => {
        const payloadBasePath = `testType.${index}.`
        const selectedTestID = testIDs.find((tID) => tID.codeId == testType.testId)
        if (selectedTestID) {
          setValue(`${payloadBasePath}tat`, selectedTestID.tat)
        }
      })
    }
  }, [testIDs, getValues('testType'), setValue])

  /* const handleAddBtn = () => {
    setTestCount((prev) => prev + 1)
    setTestArr(Array.from({ length: testArr.length + 1 }, (_, i) => i))
  } */

  const handleDelete = (index: number) => {
    const testTypeValue = getValues('testType')
    setTestCount((prevTestCount) => prevTestCount - 1)
    setTestArr((prevTestArr) => prevTestArr.filter((_, i) => i !== index))
    setValue(
      'testType',
      testTypeValue.filter((_: any, i: number) => i !== index),
    )
  }

  const addTestRow = (count: number) => {
    const payloadBasePath = `testType.${count}.`

    const setTestIdDetails = (selectedEvent: React.ChangeEvent<HTMLSelectElement>) => {
      const selectedTestID = testIDs.find((tID) => tID.codeId == selectedEvent?.target?.value)
      setValue(`${payloadBasePath}tat`, selectedTestID?.tat)
    }

    return (
      <div key={count}>
        <Row lg={4} md={4} sm={4}>
          <Col>
            <SelectInput
              name={`${payloadBasePath}primaryTestTag`}
              id={'primaryTestTag'}
              title={t(`${translatn}.primaryTestTag`)}
              placeholder={t(`${translatn}.primaryTestTagPlaceholder`)}
              disabled={!isEditAllowed}
              required={true}
              optionsValueArray={_.getOptions(MDK.TEST_TYPE, masterData)}
            />
          </Col>
          <Col>
            <SelectInput
              name={`${payloadBasePath}testId`}
              id={'testId'}
              title={t(`${translatn}.testId`)}
              placeholder={t(`${translatn}.testIdPlaceholder`)}
              disabled={!isEditAllowed}
              required={true}
              optionsValueArray={testIDs.map((tID) => {
                return {
                  label: tID.testID,
                  value: tID.codeId,
                }
              })}
              onCustomChange={setTestIdDetails}
            />
          </Col>
          <Col>
            <TextInput
              type="text"
              name={`${payloadBasePath}ppn`}
              id={'ppn'}
              title={t(`${translatn}.ppn`)}
              placeholder={t(`${translatn}.ppnPlaceholder`)}
              disabled={!isEditAllowed}
              required={false}
              readOnly={true}
            />
          </Col>
          <Col>
            <NumberInput
              name={`${payloadBasePath}tat`}
              id={'tat'}
              title={t(`${translatn}.tat`)}
              placeholder={t(`${translatn}.tatPlaceholder`)}
              disabled={!isEditAllowed}
              readOnly={true}
              required={true}
            />
          </Col>
          {isEditAllowed && (
            <Col className="d-flex align-items-end">
              {testCount > 1 ? <TrashFill size={22} onClick={() => handleDelete(count)} className="mb-2" /> : <></>}
            </Col>
          )}
        </Row>
        {/* We need this in Phase 2*/}
        {/* {isEditAllowed && (
          <Row lg={4} md={4} sm={4}>
            <Col className={`${parentClass}add-text-btn-col`}>
              {count === testArr[testArr.length - 1] ? (
                <Button className={`${parentClass}add-text-btn`} variant="outline-primary" onClick={handleAddBtn}>
                  {t(`${translatn}.addTestBtn`)}
                </Button>
              ) : (
                <></>
              )}
            </Col>
          </Row>
        )} */}
      </div>
    )
  }

  return (
    <div className={`${parentClass}input-field`}>
      <h5 className={`${parentClass}form-heading`}> {t(`${translatn}.tests`)}</h5>
      {testArr.map((item) => addTestRow(item))}
    </div>
  )
}
