
import { AppUtil } from '../../../utils/app.utils'


export const RegisterPatientDefaultData = {
  registrationDate: AppUtil.formatDateForForm(new Date()),
  siteID: '',
  siteName:'',
  consentProvided: false,
  consentFile: '',
  firstName: '',
  middleName: '',
  lastName: '',
  gender: '',
  genderOther:'',
  dob: null,
  age: null,
  race: '',
  raceOther: '',
  ethnicity: '',  
  ethnicityOther: '',
  nationality: '',
  countryCode:"+91",
  phoneNumber: '',
  email: '',
  diagnosis:'',
  addressLine1: '',
  addressLine2: '',  
  state: '',
  city: '',
  pinCode: '',
  file: {},
  uhid: null
}
