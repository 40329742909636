import React from 'react'
import { useTranslation } from 'react-i18next'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'

type WarningDialogProps = {
  show: boolean
  handleClose: () => void
}

export function WarningDialog(props: Readonly<WarningDialogProps>) {
  const { t } = useTranslation()
  const { show, handleClose } = props
  const translatn = 'sampleInfoForm.warningModal'  

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header>
        <Modal.Title>{t(`${translatn}.title`)}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {t(`${translatn}.description`)}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleClose}>
          {t(`${translatn}.affirmation`)}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
