export const apiRoutes = {
  user: {
    login: '/user/login',
    logout: '/user/logout',
    changePassword: '/user/changepassword',
    list: '/user/list',
    add: '/user/register',
    edit: '/user/update',
    detailsByUsername: '/user/username',
    refreshAccessToken: '/user/refresh',
  },
  patient: {
    add: '/patient/add',
    update: '/patient/update',
    get: '/patient/id',
    search: '/patient/record',    
    uploadConsent: '/patient/upload',
    countries: 'https://countriesnow.space/api/v0.1/countries',
    states: 'https://countriesnow.space/api/v0.1/countries/states',
    cities: 'https://countriesnow.space/api/v0.1/countries/state/cities'
  },
  sample: {
    add: '/sample/add',
    update: '/sample/update',
    import:'/sample/import',
    get: '/sample/id',
    barcode: '/sample/label',
    shipped: '/sample/shipped'
  },
  masterData: {
    get: '/master/list',
    getSites: '/doctor/site/list',
    getDrs: '/doctor/site/id',
    getTestIds: '/testmaster/list',
  },
  reports: {
    uploadURLs: '/sample/presigned/upload',
    downloadURLs: '/sample/presigned/download',
    statusUpdate: '/sample/report/signed',
  },
  handwrittenNotes: {
    get: '/notes/list',
    getTags : '/notes/list/tags',
    getById: '/notes/id',
    add: '/notes/add',
    update: '/notes/update',
    upload: '/notes/upload/presigned', 
    download: '/notes/download/presigned',
    convertImgToTxt: 'https://hi5yda7tu4.execute-api.ap-south-1.amazonaws.com/qaval/HandWrittentNotesExtract',
    getLumiseekFiles:'/notes/lumiseek/notes',
    getImageData: '/notes/lumiseek/imagedata'
  }
}

export const appRoutes = {
  login: '/login',
  home: '/',
  registerPatient: '/patients/new',
  editPatient: '/patients/:id/edit',
  addSample: `/patients/:patientId/samples/new`,
  editSample: `/patients/:patientId/samples/:id/edit`,
  viewSample: `/patients/:patientId/samples/:id/details`,
  unauthorized: '/unauthorized',
  viewPatientDetails: '/patients/details/:id',
  users: '/users',
  viewHandwrittenNotes: '/patients/handwrittenNotes/:id',
  addHandwrittenNotes: '/patients/:patientId/handwrittenNotes/new',
  editHandwrittenNotes: '/patients/:patientId/handwrittenNotes/:id/edit'
}

export const showNoSuccessToastArr = [
  apiRoutes.user.login,
  apiRoutes.user.list,
  apiRoutes.user.refreshAccessToken,
  apiRoutes.patient.uploadConsent,
  apiRoutes.patient.get,
  apiRoutes.sample.get,
  apiRoutes.patient.search,
  apiRoutes.patient.countries,
  apiRoutes.patient.states,
  apiRoutes.patient.cities,
  apiRoutes.masterData.get,
  apiRoutes.masterData.getSites,
  apiRoutes.masterData.getDrs,
  apiRoutes.masterData.getTestIds,
  apiRoutes.user.detailsByUsername,
  apiRoutes.reports.downloadURLs,
  apiRoutes.reports.uploadURLs,
  apiRoutes.sample.import,
  apiRoutes.sample.barcode,
  apiRoutes.handwrittenNotes.get,
  apiRoutes.handwrittenNotes.getTags,
  apiRoutes.handwrittenNotes.getById,
  apiRoutes.handwrittenNotes.upload,
  apiRoutes.handwrittenNotes.download,
  apiRoutes.handwrittenNotes.convertImgToTxt,
  apiRoutes.handwrittenNotes.getLumiseekFiles,
  apiRoutes.handwrittenNotes.getImageData
]

export const showNoErrorToastArr = [
  apiRoutes.user.login,
  apiRoutes.user.logout,
  apiRoutes.user.detailsByUsername,
  apiRoutes.sample.get,
  apiRoutes.patient.countries,
  apiRoutes.patient.states,
  apiRoutes.patient.cities,
  apiRoutes.handwrittenNotes.get,
  apiRoutes.handwrittenNotes.getTags,
  apiRoutes.handwrittenNotes.getLumiseekFiles,  
  apiRoutes.handwrittenNotes.getImageData
]
