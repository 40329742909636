import React, { useEffect, useContext, useState } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { Button, Form } from 'react-bootstrap'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { trackPromise } from 'react-promise-tracker'
import Container from 'react-bootstrap/Container'
import { RegisterPatientDefaultData, RegisterPatientFormSchema, formatRequestPayload } from './helpers'
import './RegisterPatientContainer.scss'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { appRoutes } from '../../utils/routes'
import { getPatientDetails, registerPatient, updatePatientDetails } from '../../services'
import { Patient, RegisterPatientResponse } from '../../models/RegisterPatient'
import { AxiosResponse } from 'axios'
import { AppUtil } from '../../utils/app.utils'
import { PatientIdentification } from './components'
import { ArrowLeftShort } from 'react-bootstrap-icons'
import { AuthContext } from '../../contexts'
import { getPateintConsentUploadURLs, uploadConsentFileData } from '../../services/Patient'
import { isEmpty } from 'lodash'

export const RegisterPatientContainer = () => {  
  const { id } = useParams()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const translatn = 'registerPatient.'
  const parentClass = 'vcr-pp-register-patient-'

  const auth = useContext(AuthContext)
  const userSiteId = auth?.authenticatedUser?.siteId ?? ''
  const userSiteName = auth?.authenticatedUser?.siteName ?? ''

  let formDefaultValues = JSON.parse(JSON.stringify(RegisterPatientDefaultData))
  formDefaultValues.siteID = userSiteId
  formDefaultValues.siteName = userSiteName

  const [consentFileData, setConsentFileData] = useState<File | null>(null)
  const isEdit = !!id

  const formMethods = useForm<Patient>({
    resolver: zodResolver(RegisterPatientFormSchema),
    mode: 'all',
    defaultValues: formDefaultValues,
  })

  const savePatient = async function () {
    let data: Patient = formatRequestPayload(formMethods.getValues())
    if (!id) {
      delete data.file;
      await trackPromise(
        registerPatient(data).then(
          (res: AxiosResponse<RegisterPatientResponse>) => {
            getPateintConsentUploadURLs(res.data.id ?? '', data.consentFile ?? '').then(
              (resp: AxiosResponse) => {
                uploadConsentFileData(resp.data?.[0]?.path ?? '', consentFileData as File).then(
                  (response: AxiosResponse) => {
                    if(response.status == 200) {                     
                      setConsentFileData(null)
                      navigate(appRoutes.home)
                    }                   
                  },
                  (error: any) => {
                    AppUtil.logError(error)
                  },
                )
              },
              (error: any) => {
                AppUtil.logError(error)
              },
            )           
          },
          (error) => AppUtil.logError(error),
        ),
      )
    } else {
      // delete data.file;
      // delete data.consentFile;
      await trackPromise(
        updatePatientDetails({ id, ...data }).then(
          () => {
            if(!isEmpty(data.file)){      
            getPateintConsentUploadURLs(id ?? '', data.consentFile ?? '').then(
              (resp: AxiosResponse) => {
                uploadConsentFileData(resp.data?.[0]?.path ?? '', consentFileData as File).then(
                  (response: AxiosResponse) => {
                    if(response.status == 200) {                     
                      setConsentFileData(null)
                      navigate(appRoutes.home)
                    }                   
                  },
                  (error: any) => {
                    AppUtil.logError(error)
                  },
                )
              },
              (error: any) => {
                AppUtil.logError(error)
              },
            )     
          } else {                    
              navigate(appRoutes.home)
          } 
          },
          (error) => AppUtil.logError(error),
        ),
      )
    }
  }

  useEffect(() => {
    if (id && id !== 'new') {
      trackPromise(
        getPatientDetails(id).then(
          (res: AxiosResponse<Patient>) => {
            const formatData = {
              ...res?.data,
              firstName: res?.data?.name || res?.data?.firstName,
              registrationDate: AppUtil.formatDateForForm(res?.data?.registrationDate),
              dob: AppUtil.formatDateForForm(res?.data?.dob),
              siteName: res?.data?.siteName,
              siteId: res?.data?.siteID,
              gender: res?.data?.gender?.toString(),
              race: res?.data?.race?.toString(),
              ethnicity: res?.data?.ethnicity?.toString(),
              nationality: res?.data?.nationality ?? '',              
              addressLine1: res?.data?.addressLine1 ?? '',
              addressLine2: res?.data?.addressLine2 ?? '',
              diagnosis: res?.data?.diagnosis ?? '',
              state: res?.data?.state ?? '',
              city: res?.data?.city ?? '',
              file: {},
              consentFile: res?.data?.consentFilePath?.split('/')?.pop() ?? '',
              countryCode: res?.data?.countryCode ?? '+91'
            }
            formMethods.reset(formatData)
          },
          (error) => AppUtil.logError(error),
        ),
      )
    }
  }, [id])

  return (
    <Container className={`${parentClass}container`} fluid>
      <Row className={`${parentClass}header `}>
        <Col className={`${parentClass}header-left-content`}>
          <ArrowLeftShort size={36} onClick={() => navigate(-1)} />
          <h4 className={`${parentClass}header-text `}>
            {id ? t(`${translatn}edit`) : t(`${translatn}new`)} {t(`${translatn}formHeading`)}
            {id ? ` (${t(`${translatn}patientId`)}: ${id})` : ''}
          </h4>
        </Col>
      </Row>
      <Row className={`${parentClass}form`}>
        <Col lg={12} md={12} sm={12}>
          <FormProvider {...formMethods}>
            <Form noValidate>
              <PatientIdentification setConsentFileData={setConsentFileData} isEdit={isEdit}/>
            </Form>
          </FormProvider>
        </Col>
      </Row>
      <Row className={`${parentClass}save`}>
        <Col lg={1} md={1} sm={1}>
          <Button className={`${parentClass}save-btn`} type="submit" onClick={formMethods.handleSubmit(savePatient)}>
            {t(`${translatn}save`)}{' '}
          </Button>
        </Col>
      </Row>
    </Container>
  )
}
