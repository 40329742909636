import React from 'react'
import { BarcodeGenerate } from '../../SampleBarcodePage'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'


export const TabSeven = (props:any) => {
  const { t } = useTranslation()
 const {sampleReplicateNumber}= props
  const { getValues, setValue } = useFormContext()
  const parentClass = 'vcr-pp-sample-info-'
  const translatn = 'sampleInfoForm'

  return (
    <div className={`${parentClass}input-field`}>
      <h5 className={`${parentClass}form-heading`}> {t(`${translatn}.barcode`)}</h5>
      <BarcodeGenerate sampleReplicateNumber={sampleReplicateNumber} buttonClass={`${parentClass}save-create-barcode`} setValue={setValue} getValues={getValues}/>
    </div>
  )
}
