import React from 'react'
import Form from 'react-bootstrap/Form'
import { renderRequiredFieldLabel } from './InputComponentHelpers'

export const TextInputNoForm = function (props: any) {
  const { name, id, title, required, errorMessage, ...otherProps } = props
  
  return (
    <>
      {title && <Form.Label htmlFor={id}>{required ? renderRequiredFieldLabel(title) : title}</Form.Label>}
      <Form.Control
          as="input"
          id={id}
          name={name}
          {...otherProps}
        />
      <Form.Control.Feedback type="invalid">{errorMessage? errorMessage: ''}</Form.Control.Feedback>
    </>
  )
}
