import React, { useContext } from 'react'
import Container from 'react-bootstrap/Container'
import { useTranslation } from 'react-i18next'
import { TabProps } from '../../../models/Sample'
import { ViewSampleDetailsRow } from './ViewSampleDetailsRow'
import { AppUtil } from '../../../utils/app.utils'
import { MASTER_DATA_KEYS as MDK, MASTER_DATA_ID as MDI } from '../../../utils/constants'
import { MasterDataContext } from '../../../contexts'
import _ from 'lodash'

export const SurgeryBiopsy = (props: TabProps) => {
  const { sampleDetails } = props
  const { t } = useTranslation()
  const { masterData } = useContext(MasterDataContext)

  const translatn = 'viewSample.'
  const parentClass = ''
  const detailsArr = [
    {
      label: t(`${translatn}surgeryBiopsyDone`),
      value: AppUtil.convertBooleanToStr(sampleDetails?.surgeryBiopsyDone ?? null),
      show: true
    },
    {
      label: t(`${translatn}originalPrimarySiteOfDiagnosis`),
      value: AppUtil.getFormattedValueWithOther(
        MDK.DIAGNOSIS_PRIMARY_SITE_FOR_SURGERY,
        masterData,
        sampleDetails?.originalPrimarySiteOfDiagnosisForSurgeryPerformed,
        sampleDetails?.originalPrimarySiteOfDiagnosisForSurgeryPerformedOther,
      ),
      show: sampleDetails?.surgeryBiopsyDone == MDI.SURGERY_BIOPSY_DONE_OPTION_YES,
    },
    {
      label: t(`${translatn}locationForSurgery`),
      value: AppUtil.getFormattedValueWithOther(
        MDK.SURGERY_BIOPSY_LOCATION,
        masterData,
        sampleDetails?.locationForSurgeryBiopsy,
        sampleDetails?.locationForSurgeryBiopsyOther,
      ),
      show: sampleDetails?.surgeryBiopsyDone == MDI.SURGERY_BIOPSY_DONE_OPTION_YES,
    },
    {
      label: t(`${translatn}surgeryType`),
      value: AppUtil.getLabel(MDK.SURGERY_TYPE, masterData, sampleDetails?.surgeryType),
      show: sampleDetails?.surgeryBiopsyDone == MDI.SURGERY_BIOPSY_DONE_OPTION_YES,
    },
    {
      label: t(`${translatn}surgeryTypeDetails`),
      value: sampleDetails?.surgeryTypeDetails,
      show: sampleDetails?.surgeryBiopsyDone == MDI.SURGERY_BIOPSY_DONE_OPTION_YES,
    },
    {
      label: t(`${translatn}biopsyType`),
      value: AppUtil.getLabel(MDK.BIOPSY_TYPE, masterData, sampleDetails?.biopsyType),
      show: sampleDetails?.surgeryBiopsyDone == MDI.SURGERY_BIOPSY_DONE_OPTION_YES,
    },
    {
      label: t(`${translatn}biopsyTypeDetails`),
      value: sampleDetails?.biopsyTypeDetails,
      show: sampleDetails?.surgeryBiopsyDone == MDI.SURGERY_BIOPSY_DONE_OPTION_YES,
    },
    {
      label: t(`${translatn}anesthesiaType`),
      value: AppUtil.getLabel(MDK.ANETHESIA_TYPE, masterData, sampleDetails?.anesthesiaType),
      show: sampleDetails?.surgeryBiopsyDone == MDI.SURGERY_BIOPSY_DONE_OPTION_YES,
    },
    {
      label: t(`${translatn}anesthesiaTypeDetails`),
      value: sampleDetails?.anethesiaTypeDetails,
      show: sampleDetails?.surgeryBiopsyDone == MDI.SURGERY_BIOPSY_DONE_OPTION_YES,
    },
    {
      label: t(`${translatn}surgicalApproach`),
      value: AppUtil.getLabel(MDK.SURGICAL_APPROACH, masterData, sampleDetails?.surgicalApproach),
      show: sampleDetails?.surgeryBiopsyDone == MDI.SURGERY_BIOPSY_DONE_OPTION_YES,
    },
    {
      label: t(`${translatn}surgicalApproachDetails`),
      value: sampleDetails?.surgicalApproachDetails,
      show: sampleDetails?.surgeryBiopsyDone == MDI.SURGERY_BIOPSY_DONE_OPTION_YES,
    },
    {
      label: t(`${translatn}dateOfSurgery`),
      value: sampleDetails?.dateOfSurgeryBiopsy,
      show: sampleDetails?.surgeryBiopsyDone == MDI.SURGERY_BIOPSY_DONE_OPTION_YES,
    },
    {
      label: t(`${translatn}ageAtSurgeryBiopsy`),
      value: sampleDetails?.ageAtSurgeryBiopsy,
      show: sampleDetails?.surgeryBiopsyDone == MDI.SURGERY_BIOPSY_DONE_OPTION_YES,
    },
  ]

  const data = detailsArr.filter((details) => details.show === true)

  const rowCount = _.chunk(data, 5)

  return (
    <Container fluid>
      <h5 className="fw-bold "> {t(`${translatn}surgeryBiopsy`)}</h5>
      {rowCount.map((row, index) => (
        <ViewSampleDetailsRow key={index} index={index} rowArr={rowCount[index]} parentClass={parentClass} />
      ))}
    </Container>
  )
}
