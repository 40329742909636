import React from 'react'
import { useTranslation } from 'react-i18next'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
type Props = {
    open: boolean
    handleClose: () => void
    handleEdit: () => void
}

export const WarningModal = (props: Props) => {

    const { open, handleClose,handleEdit} = props
    const { t } = useTranslation()
    const translatn = 'patientList'
    const parentClass = ''

    return (
        <>
            <Modal className={`${parentClass}`} show={open} onHide={handleClose} centered backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>{t(`${translatn}.requiredPatientDetails`)}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                {t(`${translatn}.warningtext`)}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {t(`${translatn}.cancelBtn`)}
                    </Button>
                    <Button variant="primary" className={`${parentClass}-save-btn`} onClick={handleEdit}>{t(`${translatn}.editPatient`)}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
