import React, { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import { TextInput, DateInput, RadioInput } from '../../InputComponents'
import { MASTER_DATA_KEYS as MDK, MASTER_DATA_ID as MDI } from '../../../utils/constants'
import { MasterDataContext } from '../../../contexts'
import { AppUtil as _ } from '../../../utils/app.utils'
import { useFormContext } from 'react-hook-form'
import { getDateOfBirth, radiationTherapyFields, radiationTherapyStartStopFields } from '../helpers/AddSampleInfoHelpers'

export const RadiationTreatment = () => {
  const { t } = useTranslation()
  const { masterData } = useContext(MasterDataContext)
  const translatn = 'sampleInfoForm'
  const parentClass = 'vcr-pp-sample-info-'
  const { watch, resetField, getValues, clearErrors } = useFormContext()
  const dob = getValues('dob')
  const startDate = watch(`radiationStart`)
  let isRadiationTherapy = watch('radiationTherapy') === MDI.RADIATION_THERAPY_OPTION_YES

  useEffect(() => {
    if (!isRadiationTherapy) {
      radiationTherapyFields.forEach((field) => {
        resetField(field, { defaultValue: '' })
        clearErrors(field)
      })
      radiationTherapyStartStopFields.forEach((field) => {
        resetField(field, { defaultValue: null })
        clearErrors(field)
      })
    }
  }, [isRadiationTherapy])

  return (
    <div className={`${parentClass}input-field`}>
      <h5 className={`${parentClass}form-heading`}> {t(`${translatn}.radiationTreatment`)}</h5>
      <Row lg={4} md={4} sm={4}>
        <Col>
          <RadioInput
            name={`radiationTherapy`}
            id="therapyDone"
            valueArray={_.sortOptionsDesc(_.getOptions(MDK.RADIATION_THERAPY, masterData))}
            title={t(`${translatn}.therapyDone`)}
            disabled={false}
            inline={true}
            required={true}
          />
        </Col>
      </Row>
      {isRadiationTherapy && <>
        <Row lg={4} md={4} sm={4}>
          <Col>
            <TextInput
              type="text"
              name={`originalSiteOfDiagnosisForRadiationTherapy`}
              id={'originalPrimarySite'}
              title={t(`${translatn}.originalPrimarySite`)}
              placeholder={t(`${translatn}.originalSiteOfDiagnosisForRadiationPlaceholder`)}
              disabled={false}
              readOnly={false}
              required={isRadiationTherapy}
            />
          </Col>
          <Col>
            <TextInput
              type="text"
              name={`radiationDisease`}
              id={'disease'}
              title={t(`${translatn}.disease`)}
              placeholder={t(`${translatn}.diseasePlaceholder`)}
              disabled={false}
              required={isRadiationTherapy}
            />
          </Col>
        </Row>
        <Row lg={4} md={4} sm={4}>
          <Col>
            <TextInput
              type="text"
              name={`radiationRegimen`}
              id={'regimen'}
              title={t(`${translatn}.regimen`)}
              placeholder={t(`${translatn}.regimenPlaceholder`)}
              disabled={false}
              required={isRadiationTherapy}
            />
          </Col>
          <Col>
            <TextInput
              type="text"
              name={`radiationModality`}
              id={'modality'}
              title={t(`${translatn}.modality`)}
              placeholder={t(`${translatn}.modalityPlaceholder`)}
              disabled={false}
              required={isRadiationTherapy}
            />
          </Col>
          <Col>
            <TextInput
              type=""
              name={`radiationDose`}
              id={'dose'}
              title={t(`${translatn}.dose`)}
              placeholder={t(`${translatn}.dosePlaceholder`)}
              disabled={false}
              readOnly={false}
              required={isRadiationTherapy}
            />
          </Col>
        </Row>
        <Row lg={4} md={4} sm={4}>
          <Col>
            <DateInput
              name={`radiationStart`}
              id={'start'}
              title={t(`${translatn}.start`)}
              placeholder={t(`common.datePlaceholder`)}
              min={new Date(getDateOfBirth(dob)) ?? undefined}
              max={new Date()}
              disabled={false}
              readOnly={false}
              required={isRadiationTherapy}
              onChange={() => resetField(`radiationStop`)}
            />
          </Col>
          <Col>
            <DateInput
              name={`radiationStop`}
              id={'stop'}
              title={t(`${translatn}.stop`)}
              placeholder={t(`common.datePlaceholder`)}
              min={startDate ? new Date(startDate) : (new Date(getDateOfBirth(dob)) ?? undefined)}
              disabled={!startDate}
              readOnly={false}
              required={isRadiationTherapy}
            />
          </Col>
        </Row>
      </>}


    </div>
  )
}
