import React, { useContext } from 'react'
import Container from 'react-bootstrap/Container'
import { useTranslation } from 'react-i18next'
import { TabProps } from '../../../models/Sample'
import { ViewSampleDetailsRow } from './ViewSampleDetailsRow'
import { AppUtil } from '../../../utils/app.utils'
import { MASTER_DATA_KEYS as MDK } from '../../../utils/constants'
import { MasterDataContext } from '../../../contexts'
import _ from 'lodash'

export const ImagingData = (props: TabProps) => {
  const { sampleDetails } = props
  const { t } = useTranslation()
  const { masterData } = useContext(MasterDataContext)

  const translatn = 'viewSample.'
  const parentClass = ''

  const imagingTypeOfScan = AppUtil.convertStrIntoArr(
    _.isString(sampleDetails?.imagingTypeOfScan) && sampleDetails?.imagingTypeOfScan?.length
      ? sampleDetails?.imagingTypeOfScan
      : '',
  )

  const imagingTypeOfScanDetails = JSON.parse(
    _.isString(sampleDetails?.imagingTypeOfScanDetails) && sampleDetails?.imagingTypeOfScanDetails?.length
      ? sampleDetails?.imagingTypeOfScanDetails
      : '{}',
  )

  const detailsArr = [
    {
      label: t(`${translatn}typeOfScan`),
      value: AppUtil.getMultiselectLabels(imagingTypeOfScan, MDK.IMAGING_SCAN_TYPE, masterData),
      show: true,
    },
    {
      label: t(`${translatn}imagingMethod`),
      value: AppUtil.getLabel(MDK.IMAGING_MODALITY_METHOD, masterData, sampleDetails?.imagingModalityMethod),
      show: true,
    },
    {
      label: t(`${translatn}dateOfImagingScan`),
      value: sampleDetails?.dateOfImagingScan,
      show: true,
    },
    {
      label: t(`${translatn}ageAtImagingScan`),
      value: sampleDetails?.ageAtImagingScan,
      show: true,
    },
    {
      label: t(`${translatn}siteForScan`),
      value: AppUtil.getFormattedValueWithOther(
        MDK.IMAGING_SCAN_SITE,
        masterData,
        sampleDetails?.imagingSiteForScan,
        sampleDetails?.imagingSiteForScanOther,
      ),
      show: true,
    },
    {
      label: t(`${translatn}imagingScanSiteDetails`),
      value: sampleDetails?.imagingScanSiteDetails,
      show: true,
    },
    {
      label: t(`${translatn}measurableDiseasePresent`),
      value: AppUtil.getLabel(MDK.MEASURABLE_DISEASE_PRESENT, masterData, sampleDetails?.measurableDiseasePresent),
      show: true,
    },
    {
      label: t(`${translatn}newLesions`),
      value: AppUtil.getLabel(MDK.NEW_LESIONS, masterData, sampleDetails?.newLesions),
      show: true,
    },
    {
      label: t(`${translatn}site`),
      value: AppUtil.getFormattedValueWithOther(MDK.SITE, masterData, sampleDetails?.site, sampleDetails?.siteOther),
      show: true,
    },
  ]

  AppUtil.createObject(imagingTypeOfScanDetails, MDK.IMAGING_SCAN_TYPE, masterData, detailsArr, 1)

  const data = detailsArr.filter((details) => details.show === true)

  const rowCount = _.chunk(data, 5)

  return (
    <Container fluid>
      <h5 className="fw-bold "> {t(`${translatn}imagingData`)}</h5>
      {rowCount.map((row, index) => (
        <ViewSampleDetailsRow key={index} index={index} rowArr={rowCount[index]} parentClass={parentClass} />
      ))}
    </Container>
  )
}
