import React, { useContext, useState } from 'react'
import Container from 'react-bootstrap/Container'
import { useTranslation } from 'react-i18next'
import { TabProps } from '../../../models/Sample'
import { ViewSampleDetailsRow } from './ViewSampleDetailsRow'
import { AppUtil } from '../../../utils/app.utils'
import { MASTER_DATA_KEYS as MDK } from '../../../utils/constants'
import { MasterDataContext } from '../../../contexts'
import _, { isString } from 'lodash'
import { Button } from 'react-bootstrap'
import { ViewSampleMarkerDetailsContainer } from './ViewSampleMarkerDetailsContainer'
import { EyeFill } from 'react-bootstrap-icons'
import "../../CommonComponents/styles/ViewDetailsColumn.scss"
import { ValueItemSubCategory } from '../../../models/MasterData'


export const TumorMarkers = (props: TabProps) => {
  const { sampleDetails } = props
  const [categoryName, setCategoryName] = useState('')
  const [biomarkerSubCategoryFields, setBiomarkerSubCategoryFields] = useState([{}])
  const [showViewModal, setShowViewModal] = useState(false)
  const parentClass = 'view-details-column'
  const { t } = useTranslation()
  const translatn = 'viewSample.'
  const { masterData } = useContext(MasterDataContext)

  const handleSubCategoriesFields =(key:string,categorytypeData:ValueItemSubCategory[])=>{

      const typeData = sampleDetails?.biomarkerTestSubCategoryFields[key] || {};
      
      const subCategoryFieldsValue= Object.keys(typeData).map((typeDatakey: string) => {
        const item = typeData[typeDatakey];
        setCategoryName(AppUtil.getOption(MDK.BIOMARKER_TEST, masterData, key[key.length - 1]).label)
        if (isString(item)) {
          const itemData = (AppUtil.getOption(MDK.BIOMARKER_TEST, masterData, key[key.length - 1])?.subCategory?.find((sc) => sc.value?.toString() == typeDatakey[typeDatakey.length - 1]))
          return {
            label: itemData?.label,
            value: item,
            show: true
          }
        } 
        else {
          return (Object.keys(item).map((itemkey: string) => {
            if (item[itemkey]) {
              const itemData = (AppUtil.getOption(MDK.BIOMARKER_TEST, masterData, key[key.length - 1])?.subCategory?.find((sc) => sc.value?.toString() == typeDatakey[typeDatakey.length - 1]))
              const itemDataValue = itemData?.subCategoryFields?.find(f => f.value?.toString() == itemkey[itemkey.length - 1])
              return {
                label: `${itemDataValue?.label}`,
                value:item[itemkey],
                show: true,
                helperText:itemDataValue?.helperText
              }
            }
          })).filter(obj => obj != undefined )
        }
    })
  
      const objectsOfArray=subCategoryFieldsValue?.reduce((acc: any,value: Object,index:number)=>{
        acc[categorytypeData[index]?.label]=value
        return acc;
        },{})
        setBiomarkerSubCategoryFields(objectsOfArray) 
  }

  const handleViewModalClose = () => { setShowViewModal(false) }
  const handleView = (key :string,categorytypeData:ValueItemSubCategory[]) => {  handleSubCategoriesFields(key,categorytypeData);  setShowViewModal(true)}

  const biomarkerTestSubCategoryDetails = Object.fromEntries(
      Object.keys(sampleDetails?.biomarkerTestSubCategory || {}).map((key: string) => {
       const categorytypeData = sampleDetails?.biomarkerTestSubCategory[key] || [];
       return [key, <Button key={key} className={`${parentClass}-action-btn`} onClick={()=>{handleView(key,categorytypeData);}}><EyeFill/></Button>];
    })
  );

  const markerType = AppUtil.convertStrIntoArr(
    _.isString(sampleDetails?.markerType) && sampleDetails?.markerType?.length ? sampleDetails?.markerType : '',
  )

  const markerTypeDetails = JSON.parse(
    _.isString(sampleDetails?.markerTypeDetails) && sampleDetails?.markerTypeDetails?.length
      ? sampleDetails?.markerTypeDetails
      : '{}',
  )

  const detailsArr = [    
    {
      label: t(`${translatn}biomarkerTest`),
      value: AppUtil.getMultiselectLabels(sampleDetails?.biomarkerTestCategory?.map(item => item.value?.toString()) ?? [], MDK.BIOMARKER_TEST, masterData),
      show: true,
    },
    {
      label: t(`${translatn}beyond4Months`),
      value: sampleDetails?.biomarkerBeyond4MonthsDate ?? 'No',
      show: true,
    },    
    {
      label: t(`${translatn}otherTumor`),
      value: sampleDetails?.specifyOtherTumor,
      show: true,
    },
    {
      label: t(`${translatn}dateOfResults`),
      value: sampleDetails?.dateOfResults,
      show: true,
    },
    {
      label: t(`${translatn}ageAtResult`),
      value: sampleDetails?.ageAtResults,
      show: true,
    },
    {
      label: t(`${translatn}markerType`),
      value: AppUtil.getMultiselectLabels(markerType, MDK.MARKER_TYPE, masterData),
      show: true,
    },
  ]
  const arr = [
    {
      data: biomarkerTestSubCategoryDetails,
      key: MDK.BIOMARKER_TEST,
      position: 2,
    },
    {
      data: markerTypeDetails,
      key: MDK.MARKER_TYPE,
      position: detailsArr.length + Object.keys(biomarkerTestSubCategoryDetails).length,
    },
  ]

  arr.map((a) => {
    return AppUtil.createObject(a.data, a.key, masterData, detailsArr, a.position)
  })

  const data = detailsArr.filter((details) => details.show === true)
  const rowCount = _.chunk(data, 5)

  return (
    <Container fluid>
      <h5 className="fw-bold pt-3"> {t(`${translatn}tumorMarkers`)}</h5>
      {rowCount.map((row, index) => (
        <ViewSampleDetailsRow key={index} index={index} rowArr={rowCount[index]} parentClass={parentClass} />
      ))}
       <ViewSampleMarkerDetailsContainer open={showViewModal} handleClose={handleViewModalClose} categoryName={categoryName} subCategoryFields={biomarkerSubCategoryFields}/>
    </Container>
  )
}
