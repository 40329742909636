import { isEmpty } from 'lodash';
import { z } from 'zod'

const getTranslatedStr = (path: string): string => {
  const translatn = 'registerPatient.errors'
  return `${translatn}.${path}`
}

const MIN_FILE_SIZE = 2000000
const MAX_FILE_SIZE = 5000000
const ACCEPTED_IMAGE_TYPES = ['image/jpeg', 'image/jpg', 'image/png']

const HandwrittenNotesSchema = z.object({
  label: z.string(),
  value: z.string().or(z.number()),
})

export const HandwrittenNotesFormSchema = z.object({
  id: z.any().nullable(), 
  patientId: z.any().nullable(),
  dataSource: z
  .string({
    invalid_type_error: 'This field is required',
  })
  .min(1, { message: 'This field is required' }),
  predefined_Tags: z.array(HandwrittenNotesSchema).optional(),
  custom_Tags: z.coerce.string().trim().min(0, {
    message: 'This field is required',
  })
    .max(100, { message: 'Maximum 100 characters is allowed' }).nullable(),
  file: z.any().nullable().optional(),
  lumiseekfile: z.string().optional(),
  original_Converted_Text: z.string().nullable()
}).superRefine((values: any, ctx) => {
  if(values.dataSource === 'LUMISEEK' && values.lumiseekfile === '') {
    ctx.addIssue({
      message: `This field is required`,
      code: z.ZodIssueCode.custom,
      path: ['lumiseekfile'],
    });
  }

  if (!values.id && (!values.original_Converted_Text || values.original_Converted_Text == '<p><br></p>')) {
    ctx.addIssue({
      message: `Please click on the "Upload & Convert" button to upload the image and make this field editable.`,
      code: z.ZodIssueCode.custom,
      path: ['original_Converted_Text'],
    });
  }

  if (values.id && (!values.original_Converted_Text || values.original_Converted_Text == '<p><br></p>')) {
    ctx.addIssue({
      message: `This field is required`,
      code: z.ZodIssueCode.custom,
      path: ['original_Converted_Text'],
    });
  }
})