import React from 'react'
import { useTranslation } from 'react-i18next'
import Form from 'react-bootstrap/Form'
import { access, renderRequiredFieldLabel } from './InputComponentHelpers'
import './styles/CheckboxInput.scss'
import { useFormContext } from 'react-hook-form'

type CheckboxItem = {
  label: string
  value: any
}

type CheckboxInputFieldsTypes = {
  name: string
  id: string
  title: string
  checkboxValueArray: CheckboxItem[]
  inline: boolean
  disabled: boolean
  required: boolean
}

export const CheckboxInput = function (params: CheckboxInputFieldsTypes) {
  const { name, id, title, inline, checkboxValueArray, disabled, required } = params
  const { t } = useTranslation()
  const formMethods = useFormContext()
  const {
    register,
    formState: { errors },
  } = formMethods
  const error = access(name, errors)

  return (
    <div className="checkbox-input">
      <Form.Group className={errors[name]?.message ? 'is-invalid' : ''}>
        <Form.Label htmlFor={id}>{required && title? renderRequiredFieldLabel(title) : title}</Form.Label>
        {checkboxValueArray?.map(({ value, label }: CheckboxItem) => (
          <Form.Check
            key={`${id}-${label}`}
            id={`${id}-${label}`}
            value={value}
            type="checkbox"
            label={required && label && !title ? renderRequiredFieldLabel(label) : label}
            inline={inline}
            disabled={disabled}
            {...register(name)}
            isInvalid={error}
          />
        ))}
      </Form.Group>
      <Form.Control.Feedback type="invalid" className="invalid-check">
        {t(error?.message)}
      </Form.Control.Feedback>
    </div>
  )
}
