import React from 'react'
import { MetastaticDiagnosis } from './MetastaticDiagnosis'
import { ImagingData } from './ImagingData'
import { CancerDiagnosis } from './CancerDiagnosis'


export const TabTwo = () => {
    return (
        <div >
            <CancerDiagnosis />
            <MetastaticDiagnosis />
            <ImagingData />
        </div>
    )
}