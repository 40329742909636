import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { useFormContext } from 'react-hook-form'
import { TextInput, SelectInput } from '../../InputComponents'
import { getDrsListForSite } from '../../../services/MasterData'
import { AppUtil as _ } from '../../../utils/app.utils'
import { Site } from '../../../models/MasterData'

export const ReferralDetails = () => {
  const { t } = useTranslation()
  const { setValue, getValues, watch, trigger, formState: { errors } } = useFormContext()
  const translatn = 'sampleInfoForm'
  const parentClass = 'vcr-pp-sample-info-'
  const [doctors, setDoctors] = useState<Site[]>([])
  const siteId = getValues('siteId')
  const doctorName = getValues('doctorsName');

  const setDrDetails = (selectedEvent: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedDr = doctors.find((dr) => dr.doctorName == selectedEvent?.target?.value)
    setValue(`doctorsId`, selectedDr?.doctorId)
    setValue(`siteName`, selectedDr?.siteName)
    setValue('doctorEmail', selectedDr?.doctorEmail)
    trigger('doctorEmail')
  }

  useEffect(() => {
    if (siteId) {
      getDrsListForSite(siteId).then(
        (resp) => setDoctors(resp.data),
        (err) => _.logError(err),
      )
    }
  }, [watch('siteId')])

  useEffect(()=>{
    if(doctors.length) {
      setValue('doctorsName', doctorName)
    }
  }, [doctors])

  return (
    <div className={`${parentClass}input-field`}>
      <h5 className={`${parentClass}form-heading`}> {t(`${translatn}.referralDetails`)}</h5>
      <Row lg={4} md={4} sm={4}>
        <Col>
          <SelectInput
            name={`doctorsName`}
            id={'doctorsName'}
            title={t(`${translatn}.doctorName`)}
            placeholder={t(`${translatn}.doctorNamePlaceholder`)}
            disabled={false}
            required={true}
            optionsValueArray={_.formatOptionsIntoValueLabel(doctors.map((dr) => dr.doctorName))}
            onCustomChange={setDrDetails}
          />
        </Col>
        <Col>
          <TextInput
            type="text"
            name={`doctorsId`}
            id={'doctorsId'}
            title={t(`${translatn}.doctorId`)}
            placeholder={t(`${translatn}.doctorIdPlaceholder`)}
            disabled={true}
            readOnly={true}
            required={true}
          />
        </Col>
        <Col>
          <TextInput
            type="text"
            name={`doctorContactNumber`}
            id={'doctorContact'}
            title={t(`${translatn}.doctorContact`)}
            placeholder={t(`${translatn}.doctorContactPlaceholder`)}
            disabled={false}
            readOnly={false}
            required={true}
          />
        </Col>
      </Row>
      <Row lg={4} md={4} sm={4}>
        <Col>
          <TextInput
            type="text"
            name={`doctorEmail`}
            id={'doctorEmail'}
            title={t(`${translatn}.doctorEmail`)}
            placeholder={t(`${translatn}.doctorEmailPlaceholder`)}
            disabled={false}
            readOnly={false}
            required={true}
          />
        </Col>
        <Col>
          <TextInput
            type="text"
            name={`siteName`}
            id={'siteName'}
            title={t(`${translatn}.siteName`)}
            placeholder={t(`${translatn}.siteNamePlaceholder`)}
            disabled={true}
            required={true}
            readOnly={true}
          />
        </Col>
      </Row>
    </div>
  )
}
