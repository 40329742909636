import React from 'react'
import { useTranslation } from 'react-i18next'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import { DateInput, TextInput } from '../../InputComponents'
import "./styles/Medication.scss"
import { useFormContext } from 'react-hook-form'
import { getDateOfBirth } from '../helpers/AddSampleInfoHelpers'

type Props = {
    count: number
}

export const MedicationModalBody = (props: Props) => {
    const { count } = props
    const { t } = useTranslation()
    const translatn = 'sampleInfoForm'
    const payloadBasePath = `medication.${count}.`
    const { getValues, resetField, watch } = useFormContext()
    const dob = getValues('dob')
    const startDate = watch(`${payloadBasePath}medicationStartDate`)
    return (
        <>
            <Row>
                <Col>
                    <TextInput
                        type="text"
                        name={`${payloadBasePath}medicationName`}
                        id={'medicationName'}
                        title={t(`${translatn}.medicationName`)}
                        placeholder={t(`${translatn}.medicationNamePlaceholder`)}
                        disabled={false}
                        readOnly={false}
                        required={true}
                    />
                </Col>

                <Col>
                    <TextInput
                        type="text"
                        name={`${payloadBasePath}medicationDosage`}
                        id={'medicationDosage'}
                        title={t(`${translatn}.medicationDosage`)}
                        placeholder={t(`${translatn}.medicationDosagePlaceholder`)}
                        disabled={false}
                        readOnly={false}
                        required={true}
                    />
                </Col>
                <Col>
                    <TextInput
                        type="text"
                        name={`${payloadBasePath}medicationFrequency`}
                        id={'medicationFrequency'}
                        title={t(`${translatn}.medicationFrequency`)}
                        placeholder={t(`${translatn}.medicationFrequencyPlaceholder`)}
                        disabled={false}
                        readOnly={false}
                        required={true}
                    />
                </Col>
            </Row><Row>
                <Col>
                    <DateInput
                        name={`${payloadBasePath}medicationStartDate`}
                        id={'medicationStartDate'}
                        title={t(`${translatn}.medicationStartDate`)}
                        placeholder={t(`common.datePlaceholder`)}
                        disabled={false}
                        readOnly={false}
                        min={new Date(getDateOfBirth(dob)) ?? undefined}
                        max={new Date()}
                        required={true}
                        onChange={() => resetField(`${payloadBasePath}medicationStopDate`)}
                    />
                </Col>
                <Col>
                    <DateInput
                        name={`${payloadBasePath}medicationStopDate`}
                        id={'medicationStopDate'}
                        title={t(`${translatn}.medicationStopDate`)}
                        placeholder={t(`common.datePlaceholder`)}
                        disabled={!startDate}
                        readOnly={false}
                        min={startDate ? new Date(startDate) : (new Date(getDateOfBirth(dob)) ?? undefined)}
                        required={true}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <TextInput
                        type="text"
                        name={`${payloadBasePath}medicationPrescribingPhysician`}
                        id={'medicationPrescribingPhysician'}
                        title={t(`${translatn}.medicationPrescribingPhysician`)}
                        placeholder={t(`${translatn}.medicationPrescribingPhysicianPlaceholder`)}
                        disabled={false}
                        readOnly={false}
                        required={true}
                    />
                </Col>

                <Col>
                    <TextInput
                        type="text"
                        name={`${payloadBasePath}medicationBrandOrManufacturer`}
                        id={'medicationBrandOrManufacturer'}
                        title={t(`${translatn}.medicationBrandOrManufacturer`)}
                        placeholder={t(`${translatn}.medicationBrandOrManufacturerPlaceholder`)}
                        disabled={false}
                        readOnly={false}
                        required={true}
                    />
                </Col>
            </Row><Row>
                <Col>
                    <TextInput
                        type="text"
                        name={`${payloadBasePath}medicationAdverseEffects`}
                        id={'medicationAdverseEffects'}
                        title={t(`${translatn}.medicationAdverseEffects`)}
                        placeholder={t(`${translatn}.medicationAdverseEffectsPlaceholder`)}
                        disabled={false}
                        readOnly={false}
                        required={true}
                    />
                </Col>
                <Col>
                    <TextInput
                        type="text"
                        name={`${payloadBasePath}medicationClass`}
                        id={'medicationClass'}
                        title={t(`${translatn}.medicationClass`)}
                        placeholder={t(`${translatn}.medicationClassPlaceholder`)}
                        disabled={false}
                        readOnly={false}
                        required={true}
                    />
                </Col>
            </Row><Row>
                <Col>
                    <TextInput
                        name={`${payloadBasePath}medicationDisease`}
                        id={'disease'}
                        title={t(`${translatn}.disease`)}
                        placeholder={t(`${translatn}.diseasePlaceholder`)}
                        disabled={false}
                        required={true}
                        type="text"
                        readOnly={false}
                    />
                </Col>
            </Row>
            <Row >
                <Col>
                    <TextInput
                        name={`${payloadBasePath}medicationRegimen`}
                        id={'regimen'}
                        title={t(`${translatn}.regimen`)}
                        placeholder={t(`${translatn}.regimenPlaceholder`)}
                        disabled={false}
                        required={true}
                        type="text"
                        readOnly={false}
                    />
                </Col>

                <Col>
                    <TextInput
                        name={`${payloadBasePath}medicationMissedDoses`}
                        id={'medicationMissedDoses'}
                        title={t(`${translatn}.medicationMissedDoses`)}
                        placeholder={t(`${translatn}.medicationMissedDosesPlaceholder`)}
                        disabled={false}
                        required={true}
                        type="text"
                        readOnly={false}
                    />
                </Col>

            </Row>
        </>
    )
}
