import React from 'react'
import { useTranslation } from 'react-i18next'
import Modal from 'react-bootstrap/Modal'
import { ViewMedicationDetailsBody } from './components/ViewMedicationDetailsBody'
import { Medication } from '../../models/Sample'
import './ViewMedicationDetailsContainer.scss'

type Props = {
    open: boolean
    handleClose: () => void
    medication?: Medication
}
export const ViewMedicationDetailsContainer = (props: Props) => {
    const { open, handleClose, medication } = props
    const { t } = useTranslation()
    const translatn = 'viewSample.'
    const parentClass = 'vcr-pp-sample-info-medication'

    return (
        <>
            <Modal className={parentClass} show={open} onHide={handleClose} centered backdrop="static" keyboard={false} size='lg'>
                <Modal.Header closeButton>
                    <Modal.Title>{t(`${translatn}medication`)}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ViewMedicationDetailsBody medication={medication} />
                </Modal.Body>
            </Modal>
        </>
    )
}
