import React from 'react'
import { Sample } from '../../../models/Sample'
import Row from 'react-bootstrap/Row'
import { useTranslation } from 'react-i18next'
import { ReactTable, ViewDetailsColumn } from '../../CommonComponents'
import { Clipboard2CheckFill } from 'react-bootstrap-icons'
import { Button, Col } from 'react-bootstrap'
import { SampleStatusMasterData } from '../../../utils/sampleStatusMasterData'
import { AuthUtil } from '../../../utils/auth.utils'
import { PRIVILEGES } from '../../../utils/constants'
import { BsEyeFill, BsVectorPen } from 'react-icons/bs'
import { useNavigate, generatePath } from 'react-router-dom'
import { appRoutes } from '../../../utils/routes'
import * as _ from 'lodash'

const parentClass = 'vcr-pp-view-sample'
const translatn = 'viewSample.'

type actionPropsTypes = {
  testBasicInfo: any
  patientID: string
  sample: Sample
  hasViewReportPrivilege: boolean
  hasDownloadReportPrivilege: boolean
  hasSignReportPrivilege: boolean
}

type viewSamplePropsTypes = {
  patientID: string
  sample: Sample
  index: number
}

function ActionColumn(props: Readonly<actionPropsTypes>) {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const {
    testBasicInfo,
    patientID, 
    sample, 
    hasViewReportPrivilege,
    hasSignReportPrivilege
  } = props
  
  const reportsPagePrivilege = hasSignReportPrivilege || hasViewReportPrivilege
  const buttonId = hasSignReportPrivilege? "signReport": "viewQiagenReport"
  const buttonTitle = hasSignReportPrivilege? t(`${translatn}signReportToolTip`): t(`${translatn}viewReportToolTip`)
  const buttonIcon = hasSignReportPrivilege? <BsVectorPen size={20} />:<BsEyeFill size={20} />

  return (
    <>
      {reportsPagePrivilege && (
          <Button
            variant='link'
            className={`${parentClass}-action-btn mx-3`}
            size="sm"
            id={buttonId}
            title={buttonTitle}
            disabled={testBasicInfo?.status !== "report_ready" && testBasicInfo?.status !== "signed_and_uploaded"}
            onClick={() =>
              navigate(
                generatePath(appRoutes.viewSample, {
                  patientId: patientID,
                  id: sample.id,
                }), {state: {tabName: 'sampleTests'}}
              )
            }
          >
            {buttonIcon}
          </Button>
      )}
    </>
  )
}

export function ViewSampleDetails(props: Readonly<viewSamplePropsTypes>) {
  const { patientID, sample, index } = props
  const { t } = useTranslation()

  const hasViewReportPrivilege = AuthUtil.checkUserAccess(PRIVILEGES.VIEW_QIAGEN_REPORT)
  const hasDownloadReportPrivilege = AuthUtil.checkUserAccess(PRIVILEGES.DOWNLOAD_QIAGEN_REPORT)
  const hasSignReportPrivilege = AuthUtil.checkUserAccess(PRIVILEGES.E_SIGN_REPORT)

  const sampleRowArr = [
    {
      label: t(`${translatn}sampleCollectionDate`),
      value: sample?.sampleCollectionDate,
      row: 1,
    },
    {
      label: t(`${translatn}sampleCollectedBy`),
      value: sample?.sampleCollectedBy,
      row: 1,
    },
    {
      label: t(`${translatn}employeeId`),
      value: sample?.employeeId,
      row: 1,
    },
    {
      label: t(`${translatn}sampleType`),
      value: sample?.typeOfSample,
      row: 1,
    },
    {
      label: t(`${translatn}doctorName`),
      value: sample?.doctorsName,
      row: 1,
    },
    {
      label: t(`${translatn}sampleStatusLabel`),
      value: t(`sampleStatus.${SampleStatusMasterData.getValue(sample?.sampleStatus)}`),
      row: 1,
    },
  ]

  const columns = [
    {
      id: 'primaryTestTag',
      header: t(`${translatn}primaryTestTag`),
      accessorKey: 'primaryTestTag',
      enableSorting: false,
      cell: (info: any) => info.getValue(),
    },
    {
      id: 'testId',
      header: t(`${translatn}testId`),
      accessorKey: 'testId',
      enableSorting: false,
      cell: (info: any) => info.getValue(),
    },
    {
      id: 'tat',
      header: t(`${translatn}tat`),
      accessorKey: 'tat',
      enableSorting: false,
      cell: (info: any) => info.getValue() ?? '-',
    },
    {
      id: 'ppn',
      header: t(`${translatn}ppn`),
      accessorKey: 'ppn',
      enableSorting: false,
      cell: (info: any) => info.getValue() ?? '-',
      maxSize: 400,
      minSize: 300,
    },
    {
      id: 'testStatus',
      header: t(`${translatn}testStatus`),
      accessorKey: 'status',
      enableSorting: false,
      cell: (info: any) => info.getValue()? t(`sampleStatus.${info.getValue()}`): t(`sampleStatus.invalid`),
    },
  ]

  if((hasViewReportPrivilege || hasDownloadReportPrivilege || hasSignReportPrivilege) && !(_.some(columns, { 'id': 'action' })) && SampleStatusMasterData.sampleStatusIDValues().sampleStatusValueMap['report_ready'] === sample?.sampleStatus) {
    columns.push({
      id: 'action',
      header: t(`${translatn}action`),
      accessorKey: 'action',
      enableSorting: false,
      cell: (info: any) => 
        <ActionColumn testBasicInfo={info.row.original} patientID={patientID} sample={sample} hasViewReportPrivilege={hasViewReportPrivilege} hasDownloadReportPrivilege={hasDownloadReportPrivilege} hasSignReportPrivilege={hasSignReportPrivilege}/>,
      maxSize: 150,
      minSize: 100,
    })
  }

  return (
    <>
      <Row className={`${parentClass}-row mt-4 `} border="dark" key={index}>
        {sampleRowArr?.map((col) => (
          <ViewDetailsColumn
            key={col.label}
            label={col.label}
            value={col.value}
            parentClass={parentClass}
          />
        ))}
      </Row>
      <hr />
      <Row>
        <Col>
          <Clipboard2CheckFill width={25} height={20} />
          <span className={`${parentClass}-test-conducted ps-2`}>{t(`${translatn}testsConducted`)}</span>
        </Col>
      </Row>
      <ReactTable
        data={sample?.testType ?? []}
        columns={columns}
        pageChangeHandler={() => {
          return
        }}
        tableClassName={'view-sample-table'}
        showPagination={false}
      />

      {!sample.testType.length && (
        <div className={`${parentClass}-no-test-available`}>{t(`${translatn}noTestAvailable`)}</div>
      )}
    </>
  )
}
