import React, { useContext } from 'react'
import Container from 'react-bootstrap/Container'
import { useTranslation } from 'react-i18next'
import { TabProps } from '../../../models/Sample'

import { ReactTable } from '../../CommonComponents'
import { AppUtil } from '../../../utils/app.utils'
import { MASTER_DATA_KEYS as MDK } from '../../../utils/constants'
import { MasterDataContext } from '../../../contexts'

export const FamilyHistoryDetails = (props: TabProps) => {
  const { sampleDetails } = props
  const { t } = useTranslation()
  const translatn = 'viewSample.'
  const { masterData } = useContext(MasterDataContext)

  const columns = [
    {
      id: 'relationToPatient',
      header: t(`${translatn}relationToPatient`),
      accessorKey: 'relationToPatient',
      enableSorting: false,
      cell: (info: any) => {
        return AppUtil.getFormattedValueWithOther(
            MDK.RELATION_TO_PATIENT,
            masterData,
            info.getValue(),
            info.row.original?.relationToPatientOther,
          )
      },
    },
    {
      id: 'familyMemberAgeAtDiagnosis',
      header: t(`${translatn}ageAtDiagnosis`),
      accessorKey: 'familyMemberAgeAtDiagnosis',
      enableSorting: false,
      cell: (info: any) => {
        return AppUtil.getLabel(MDK.AGE_AT_DIAGNOSIS, masterData, info.getValue())
      },
    },
    {
      id: 'familyMemberCancerSite',
      header: t(`${translatn}cancerSite`),
      accessorKey: 'familyMemberCancerSite',
      enableSorting: false,
      cell: (info: any) => {
        return AppUtil.getFormattedValueWithOther(
            MDK.FAMILY_MEMBER_CANCER_SITE,
            masterData,
            info.getValue(),
            info.row.original?.familyMemberCancerSiteOther,
          )
      },
    },
  ]

  return (
    <Container fluid>
      <ReactTable
        data={sampleDetails?.familyHistoryDetails ?? []}
        columns={columns}
        pageChangeHandler={() => {
          return
        }}
        tableClassName={''}
        showPagination={false}
      />
    </Container>
  )
}
