import React, { useContext } from 'react'
import Container from 'react-bootstrap/Container'
import { useTranslation } from 'react-i18next'
import { TabProps } from '../../../models/Sample'
import { ViewSampleDetailsRow } from './ViewSampleDetailsRow'
import { AppUtil } from '../../../utils/app.utils'
import { MASTER_DATA_KEYS as MDK } from '../../../utils/constants'
import { MasterDataContext } from '../../../contexts'
import _ from 'lodash'

export const MetastaticDiagnosis = (props: TabProps) => {
  const { sampleDetails } = props
  const { t } = useTranslation()
  const { masterData } = useContext(MasterDataContext)

  const translatn = 'viewSample.'

  const parentClass = ''
  const detailsArr = [
    {
      label: t(`${translatn}originalPrimarySite`),
      value: AppUtil.getLabel(MDK.ORIGINAL_PRIMARY_SITE_OF_DIAGNOSIS, masterData,sampleDetails?.originalPrimarySiteOfDiagnosis),
      show: true
    },
    {
      label: t(`${translatn}siteOfMetastasis`),
      value: AppUtil.getLabel(MDK.METASTASIS_SITE, masterData,sampleDetails?.siteOfMetastasis),
      show: true
    },
    {
      label: t(`${translatn}dateOfMetastasis`),
      value: sampleDetails?.dateOfMetastasis,
      show: true
    },
    {
      label: t(`${translatn}ageAtMetastasis`),
      value: sampleDetails?.ageAtMetastasis,
      show: true
    },
  ]

  const data = detailsArr.filter((details) => details.show === true)

  const rowCount = _.chunk(data, 5)

  return (
    <Container fluid>
      <h5 className="fw-bold "> {t(`${translatn}metastaticDisease`)}</h5>
      {rowCount.map((row, index) => (
        <ViewSampleDetailsRow
          key={index}
          index={index}
          rowArr={rowCount[index]}
          parentClass={parentClass}
        />
      ))}
    </Container>
  )
}
