import React, { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { DateInput, TextInput, RadioInput, NumberInput } from '../../InputComponents'
import { MASTER_DATA_KEYS as MDK, MASTER_DATA_ID as MDI } from '../../../utils/constants'
import { MasterDataContext } from '../../../contexts'
import { AppUtil as _ } from '../../../utils/app.utils'
import { useFormContext } from 'react-hook-form'
import { bloodTransfusionDoneFields, boneMarrowTransplantDoneFields, getDateOfBirth, histopathologyReportDoneFields, infectiousDiseaseTestDateFields, lastChemotherapyDateFields, lastTransfusionDateFields, lastTransplantDateFields, onChemotherapyFields, otherDrugsPrescribedFields, testedForInfectiousDiseasesFields } from '../helpers/AddSampleInfoHelpers'

export const ClinicalConditions = () => {

  const { t } = useTranslation()
  const { masterData } = useContext(MasterDataContext)
  const { watch, resetField, getValues, clearErrors } = useFormContext()

  const translatn = 'sampleInfoForm'
  const parentClass = 'vcr-pp-sample-info-'

  const dob = getValues('dob')

  let isHistopathologyReportDone = watch(`histopathologyReportDone`) === MDI.HISTOPATHOLOGY_REPORT_OPTION_YES
  let isChemotherapy = watch('onChemotherapy') === MDI.WHETHER_ON_CHEMOTHERAPY_OPTION_YES
  let isOtherDrugsPrescribed = watch('otherDrugsPrescribed') === MDI.OTHER_DRUGS_PRESCRIBED_OPTION_YES
  let isBloodTransfusionDone = watch('bloodTransfusionDone') === MDI.BLOOD_TRANSFUSION_OPTION_YES
  let isBoneMarrowTransplantDone = watch('boneMarrowTransplantDone') === MDI.BONE_MARROW_TRANSPLANT_OPTION_YES
  let isTestedForInfectiousDiseases = watch('testedForInfectiousDiseases') === MDI.RECENTLY_TESTED_INFECTIOUS_DISEASE_OPTION_YES

  useEffect(() => {
    if (!isHistopathologyReportDone) {
      histopathologyReportDoneFields.forEach((field) => {
        resetField(field, { defaultValue: null })
        clearErrors(field)
      })
    }

    if (!isChemotherapy) {
      onChemotherapyFields.forEach((field) => {
        resetField(field, { defaultValue: '' })
        clearErrors(field)
      })
      lastChemotherapyDateFields.forEach((field) => {
        resetField(field, { defaultValue: null })
        clearErrors(field)
      })
    }

    if (!isOtherDrugsPrescribed) {
      otherDrugsPrescribedFields.forEach((field) => {
        resetField(field, { defaultValue: '' })
        clearErrors(field)
      })
    }

    if (!isBloodTransfusionDone) {
      bloodTransfusionDoneFields.forEach((field) => {
        resetField(field, { defaultValue: '' })
        clearErrors(field)
      })
      lastTransfusionDateFields.forEach((field) => {
        resetField(field, { defaultValue: null })
        clearErrors(field)
      })
    }

    if (!isBoneMarrowTransplantDone) {
      boneMarrowTransplantDoneFields.forEach((field) => {
        resetField(field, { defaultValue: '' })
        clearErrors(field)
      })
      lastTransplantDateFields.forEach((field) => {
        resetField(field, { defaultValue: null })
        clearErrors(field)
      })
    }

    if (!isTestedForInfectiousDiseases) {
      infectiousDiseaseTestDateFields.forEach((field) => {
        resetField(field, { defaultValue: null })
        clearErrors(field)
      })
      testedForInfectiousDiseasesFields.forEach((field) => {
        resetField(field, { defaultValue: '' })
        clearErrors(field)
      })
    }

  }, [isHistopathologyReportDone, isChemotherapy, isOtherDrugsPrescribed, isBloodTransfusionDone, isBoneMarrowTransplantDone, isTestedForInfectiousDiseases])

  return (
    <div className={`${parentClass}input-field`}>
      <h5 className={`${parentClass}form-heading`}> {t(`${translatn}.clinicalConditions`)}</h5>
      <Row lg={4} md={4} sm={4}>
        <Col>
          <TextInput
            type="text"
            name={`typeOfCancerOrDiagnosis`}
            id={'typeOfCancerOrDiagnosis'}
            title={t(`${translatn}.typeOfCancerOrDiagnosis`)}
            placeholder={t(`${translatn}.typeOfCancerOrDiagnosisPlaceholder`)}
            disabled={false}
            readOnly={false}
            required={true}
          />
        </Col>
        <Col>
          <RadioInput
            name={`ffpeBlocksAvailable`}
            id={'ffpeBlocksAvailable'}
            title={t(`${translatn}.ffpeBlocksAvailable`)}
            inline={true}
            disabled={false}
            required={false}
            valueArray={_.sortOptionsDesc(_.getOptions(MDK.FFPEBLOCKS_AVAILABLE, masterData))}
          />
        </Col>
      </Row>
      <Row lg={4} md={4} sm={4}>
        <Col>
          <RadioInput
            name={`histopathologyReportDone`}
            id="histopathologyReportDone"
            valueArray={_.sortOptionsDesc(_.getOptions(MDK.HISTOPATHOLOGY_REPORT, masterData))}
            title={t(`${translatn}.histopathologyReportDone`)}
            disabled={false}
            inline={true}
            required={true}
          />
        </Col>
        {isHistopathologyReportDone &&
          <Col>
            <DateInput
              name={`histopathologyReportDate`}
              id={'histopathologyReportDate'}
              title={t(`${translatn}.histopathologyReportDate`)}
              placeholder={t(`common.datePlaceholder`)}
              disabled={false}
              readOnly={false}
              min={new Date(getDateOfBirth(dob)) ?? undefined}
              max={new Date()}
              required={isHistopathologyReportDone}
            />
          </Col>}
      </Row>
      <Row lg={4} md={4} sm={4}>
        <Col>
          <RadioInput
            name={`onChemotherapy`}
            id={'onChemotherapy'}
            title={t(`${translatn}.onChemotherapy`)}
            disabled={false}
            valueArray={_.sortOptionsDesc(_.getOptions(MDK.WHETHER_ON_CHEMOTHERAPY, masterData))}
            required={true}
            inline={true}
          />
        </Col>
      </Row>
      <Row lg={4} md={4} sm={4}>
        {isChemotherapy && (<>
          <Col>
            <TextInput
              type="text"
              name={`chemotherapyDrugName`}
              id={'chemotherapyDrugName'}
              title={t(`${translatn}.chemotherapyDrugName`)}
              placeholder={t(`${translatn}.chemotherapyDrugNamePlaceholder`)}
              disabled={!isChemotherapy}
              readOnly={false}
              required={isChemotherapy}
            />
          </Col>
          <Col>
            <DateInput
              name={`lastChemotherapyDate`}
              id={'lastChemotherapyDate'}
              title={t(`${translatn}.lastChemotherapyDate`)}
              placeholder={t(`common.datePlaceholder`)}
              disabled={!isChemotherapy}
              readOnly={false}
              min={new Date(getDateOfBirth(dob)) ?? undefined}
              max={new Date()}
              required={isChemotherapy}
            />
          </Col>
          <Col>
            <NumberInput
              name={`chemotherapyCycles`}
              id={'chemotherapyCycles'}
              title={t(`${translatn}.chemotherapyCycles`)}
              placeholder={t(`${translatn}.chemotherapyCyclesPlaceholder`)}
              disabled={!isChemotherapy}
              required={isChemotherapy}
              readOnly={false}
              min={0}
            />
          </Col></>)}

      </Row>
      <Row lg={4} md={4} sm={4}>
        <Col>
          <RadioInput
            name={`otherDrugsPrescribed`}
            id={'otherDrugsPrescribed'}
            title={t(`${translatn}.otherDrugsPrescribed`)}
            disabled={false}
            valueArray={_.sortOptionsDesc(_.getOptions(MDK.OTHER_DRUGS_PRESCRIBED, masterData))}
            required={true}
            inline={true}
          />
        </Col>
      </Row>
      <Row lg={4} md={4} sm={4}>
        {isOtherDrugsPrescribed && (<> <Col>
          <TextInput
            type="text"
            name={`otherDrugName`}
            id={'otherDrugName'}
            title={t(`${translatn}.otherDrugName`)}
            placeholder={t(`${translatn}.otherDrugNamePlaceholder`)}
            disabled={!isOtherDrugsPrescribed}
            readOnly={false}
            required={isOtherDrugsPrescribed}
          />
        </Col>
          <Col>
            <TextInput
              type="text"
              name={`otherDrugDuration`}
              id={'otherDrugDuration'}
              title={t(`${translatn}.otherDrugDuration`)}
              placeholder={t(`${translatn}.otherDrugDurationPlaceholder`)}
              disabled={!isOtherDrugsPrescribed}
              readOnly={false}
              required={isOtherDrugsPrescribed}
            />
          </Col></>)}

      </Row>
      <Row lg={4} md={4} sm={4}>
        <Col>
          <RadioInput
            name={`bloodTransfusionDone`}
            id={'bloodTransfusionDone'}
            title={t(`${translatn}.bloodTransfusionDone`)}
            disabled={false}
            valueArray={_.sortOptionsDesc(_.getOptions(MDK.BLOOD_TRANSFUSION, masterData))}
            required={true}
            inline={true}
          />
        </Col>
        {isBloodTransfusionDone &&
          <><Col>
            <TextInput
              type="text"
              name={`bloodTransfusionHistory`}
              id={'bloodTransfusionHistory'}
              title={t(`${translatn}.bloodTransfusionHistory`)}
              placeholder={t(`${translatn}.bloodTransfusionHistoryPlaceholder`)}
              disabled={false}
              required={true}
              readOnly={false}
            />
          </Col>
            <Col>
              <DateInput
                name={`lastTransfusionDate`}
                id={'lastTransfusionDate'}
                title={t(`${translatn}.lastTransfusionDate`)}
                placeholder={t(`common.datePlaceholder`)}
                disabled={false}
                readOnly={false}
                min={new Date(getDateOfBirth(dob)) ?? undefined}
                max={new Date()}
                required={true}
              />
            </Col></>
        }

      </Row>
      <Row lg={4} md={4} sm={4}>
        <Col>
          <RadioInput
            name={`boneMarrowTransplantDone`}
            id={'boneMarrowTransplantDone'}
            title={t(`${translatn}.boneMarrowTransplantDone`)}
            disabled={false}
            valueArray={_.sortOptionsDesc(_.getOptions(MDK.BONE_MARROW_TRANSPLANT, masterData))}
            required={true}
            inline={true}
          />
        </Col>
        {isBoneMarrowTransplantDone && <>
          <Col>
            <TextInput
              type="text"
              name={`boneMarrowTransplantHistory`}
              id={'boneMarrowTransplantHistory'}
              title={t(`${translatn}.boneMarrowTransplantHistory`)}
              placeholder={t(`${translatn}.boneMarrowTransplantHistoryPlaceholder`)}
              disabled={false}
              required={true}
              readOnly={false}
            />
          </Col>
          <Col>
            <DateInput
              name={`lastTransplantDate`}
              id={'lastTransplantDate'}
              title={t(`${translatn}.lastTransplantDate`)}
              placeholder={t(`common.datePlaceholder`)}
              disabled={false}
              readOnly={false}
              min={new Date(getDateOfBirth(dob)) ?? undefined}
              max={new Date()}
              required={true}
            />
          </Col>
        </>}
      </Row>
      <Row lg={4} md={4} sm={4}>
        <Col>
          <RadioInput
            name={`testedForInfectiousDiseases`}
            id={'testedForInfectiousDiseases'}
            title={t(`${translatn}.testedForInfectiousDiseases`)}
            disabled={false}
            valueArray={_.sortOptionsDesc(_.getOptions(MDK.RECENTLY_TESTED_INFECTIOUS_DISEASE, masterData))}
            required={true}
            inline={true}
          />
        </Col>
      </Row>
      <Row lg={4} md={4} sm={4}>
        {isTestedForInfectiousDiseases && (<>
          <Col>
            <DateInput
              name={`infectiousDiseaseTestDate`}
              id={'infectiousDiseaseTestDate'}
              title={t(`${translatn}.infectiousDiseaseTestDate`)}
              placeholder={t(`common.datePlaceholder`)}
              disabled={!isTestedForInfectiousDiseases}
              readOnly={false}
              min={new Date(getDateOfBirth(dob)) ?? undefined}
              max={new Date()}
              required={isTestedForInfectiousDiseases}
            />
          </Col>
          <Col>
            <TextInput
              type="text"
              name={`infectiousDiseaseResult`}
              id={'infectiousDiseaseResult'}
              title={t(`${translatn}.infectiousDiseaseResult`)}
              placeholder={t(`${translatn}.infectiousDiseaseResultPlaceholder`)}
              disabled={!isTestedForInfectiousDiseases}
              readOnly={false}
              required={isTestedForInfectiousDiseases}
            />
          </Col>
          <Col>
            <TextInput
              type="text"
              name={`infectiousDiseaseNotesOrRemarks`}
              id={'infectiousDiseaseNotesOrRemarks'}
              title={t(`${translatn}.infectiousDiseaseNotesOrRemarks`)}
              placeholder={t(`${translatn}.infectiousDiseaseNotesOrRemarksPlaceholder`)}
              disabled={!isTestedForInfectiousDiseases}
              readOnly={false}
              required={isTestedForInfectiousDiseases}
            />
          </Col></>)}

      </Row>
      <Row lg={4} md={4} sm={4}>
        <Col>
          <TextInput
            type="text"
            name={`diagnosis`}
            id={'diagnosis'}
            title={t(`${translatn}.diagnosis`)}
            placeholder={t(`${translatn}.diagnosisPlaceholder`)}
            disabled={false}
            readOnly={false}
            required={true}
          />
        </Col>
      </Row>
    </div>
  )
}
