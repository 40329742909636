import React from 'react'
import { TumorMarkers } from './TumorMarkers'
import { Labs } from './Labs'
import { CytogenicAbnormalities } from './CytogenicAbnormalities'

export const TabThree = () => {
    return (
        <div >
          <TumorMarkers/>
          <Labs/>
          <CytogenicAbnormalities/>
        </div>
    )
}