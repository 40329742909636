import React, { useEffect, useState, useContext} from 'react'
import { getSampleDetails, getSamplesListForPatient } from '../../../services/Sample'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { Sample } from '../../../models/Sample'
import { AppUtil } from '../../../utils/app.utils'
import { AuthUtil } from '../../../utils/auth.utils'
import Accordion from 'react-bootstrap/Accordion'
import { ViewSampleDetails } from './ViewSampleDetails'
import { appRoutes } from '../../../utils/routes'
import './styles/ViewSamples.scss'
import { useTranslation } from 'react-i18next'
import { FolderX } from 'react-bootstrap-icons'
import { SampleStatusMasterData } from '../../../utils/sampleStatusMasterData'
import {  PRIVILEGES, MASTER_DATA_KEYS as MDK } from '../../../utils/constants'

export const ViewSamples = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const translatn = 'viewSample.'
  const parentClass = 'view-samples'
  const [sampleList, setSampleList] = useState<Sample[]>([])
  const hasEditSamplePrivilege = AuthUtil.checkUserAccess(PRIVILEGES.EDIT_SAMPLE)
  const hasEditBeforeShippmentPrivilege = AuthUtil.checkUserAccess(PRIVILEGES.EDIT_BEFORE_SHIPMENT)
  const hasEditAfterShippmentPrivilege = AuthUtil.checkUserAccess(PRIVILEGES.EDIT_AFTER_SHIPMENT)

  useEffect(() => {
    getSamplesListForPatient(id ?? '').then(
      (resp) => {
        setSampleList(resp.data)
      },
      (error) => AppUtil.logError(error),
    )
  }, [id])

  const renderSampleAccordion = (sample: Sample, index: number) => {

    const key = sample?.id?.toString() ?? ''
    const handleEditSample=()=>{
      if (hasEditSamplePrivilege && (
        hasEditAfterShippmentPrivilege && [2, 3].includes(sample?.sampleStatus ?? -1) ||
        hasEditBeforeShippmentPrivilege && [0, 1].includes(sample?.sampleStatus ?? -1) 
      )) {
        navigate(
          generatePath(appRoutes.editSample, {
            patientId: id,
            id: sample.id,
          })
        )
      }       
    }
    return (
      <div className={`${parentClass}`} key={key}>
        <Accordion.Item eventKey={key} key={key}>
          <Accordion.Header>
            <span
              className={`${parentClass}-accordion-left-text`}
              onClick={() =>
                navigate(
                  generatePath(appRoutes.viewSample, {
                    patientId: id,
                    id: sample.id,
                  }),
                )
              }
            >
              {t(`${translatn}sampleId`) + ' : ' + sample.id}
            </span>
            <span className={`${parentClass}-accordion-right-text`}
             onClick={()=>handleEditSample()}>
              {t(`${translatn}sampleStatus`)}
              {' : '}
              {t(`sampleStatus.${SampleStatusMasterData.getValue(sample?.sampleStatus)}`)}
            </span>
          </Accordion.Header>
          <Accordion.Body>
            <ViewSampleDetails patientID={id ?? ''} sample={sample} index={index} />
          </Accordion.Body>
        </Accordion.Item>
      </div>
    )
  }

  return (
    <div className={`${parentClass}`}>
      {sampleList.length ? (
        <Accordion defaultActiveKey={sampleList.length === 1 ? sampleList[0]?.id?.toString():null}>{sampleList.map((sample, index) => renderSampleAccordion(sample, index))}</Accordion>
      ) : (
        <div className={`${parentClass}-empty-container`}>
          <FolderX size={'32'} />
          <h5>{t(`${translatn}noSamples`)}</h5>
        </div>
      )}
    </div>
  )
}
