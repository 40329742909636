import React, { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { useFormContext } from 'react-hook-form'
import { NumberInput, DateInput, MultiSelectDropdown, CheckboxInput } from '../../InputComponents'
import { getDateOfBirth, setAgeAtEvent } from '../helpers/AddSampleInfoHelpers'
import { MASTER_DATA_KEYS as MDK } from '../../../utils/constants'
import { MasterDataContext } from '../../../contexts'
import { AppUtil as _ } from '../../../utils/app.utils'
import { DependentFields } from './DependentFields'

export const Labs = () => {
  const { t } = useTranslation()
  const { setValue, getValues, watch, resetField, clearErrors } = useFormContext()
  const { masterData } = useContext(MasterDataContext)
  const translatn = 'sampleInfoForm'
  const parentClass = 'vcr-pp-sample-info-'
  const dob = getValues('dob')


  const removeProperty = (object: any, value: string | number) => {
    let childKey = `type${value}`
    delete object[childKey];
    return object;
  }

  const handleRemoveLabType = (value: string | number) => {
    setValue(
      'labTypeCategory',
      getValues('labTypeCategory')?.filter(
        (item: { value: string; label: string }) => item.value?.toString() !== value?.toString(),
      ),
    )
    setValue('labTypeSubCategory', removeProperty(getValues('labTypeSubCategory'), value));
    setValue('labTypeSubCategoryFields', removeProperty(getValues('labTypeSubCategoryFields'), value));
  }

  const getLabTypeCategoryValues = watch('labTypeCategory')
  const getLabTypeSubCategoryValues = watch('labTypeSubCategory');

  const isLabTypeBeyond4Months = watch('isLabTypeBeyond4Months');

  useEffect(() => {
    if (isLabTypeBeyond4Months !== 'true') {
      resetField('labTypeBeyond4MonthsDate')
      clearErrors
    }
  }, [isLabTypeBeyond4Months, resetField]);

  return (
    <div className={`${parentClass}input-field`}>
      <h5 className={`${parentClass}form-heading`}> {t(`${translatn}.labs`)}</h5>
      <Row>
        <Col>
          <MultiSelectDropdown
            name={`labTypeCategory`}
            id={'labTypeCategory'}
            title={t(`${translatn}.labTypeCategory`)}
            placeholder={getLabTypeCategoryValues.length > 0 ? "" : t(`${translatn}.labTypeCategoryPlaceholder`)}
            disabled={false}
            required={false}
            optionsValueArray={_.getOptions(MDK.LAB_TYPE, masterData)}
            hideSelectedValues={false}
            onCustomRemove={handleRemoveLabType}
            tooltipText={t(`${translatn}.testDetailsTooltip`)}
          />
        </Col>
        <Col>
          <CheckboxInput
            name={`isLabTypeBeyond4Months`}
            id={`isLabTypeBeyond4Months`}
            title={''}
            disabled={false}
            inline={true}
            required={false}
            checkboxValueArray={[{ label: `Beyond 4 months`, value: true }]}
          />
          {(watch('isLabTypeBeyond4Months') == "true") && <DateInput
            name={`labTypeBeyond4MonthsDate`}
            id={'labTypeBeyond4MonthsDate'}
            title=''
            placeholder={t(`common.datePlaceholder`)}
            disabled={false}
            readOnly={false}
            max={_.fourMonthsFromNow()}
            required={false}
            isClearable={true} />}
        </Col>
      </Row>
      <DependentFields
        name={'labType'}
        categories={getLabTypeCategoryValues}
        subCategories={getLabTypeSubCategoryValues}
      /> 
      <Row lg={4} md={4} sm={4}>
        <Col>
          <DateInput
            name={`dateOfLabResults`}
            id={'dateOfLabResults'}
            title={t(`${translatn}.dateOfLabResults`)}
            placeholder={t(`common.datePlaceholder`)}
            disabled={false}
            readOnly={false}
            min={new Date(getDateOfBirth(dob)) ?? undefined}
            max={new Date()}
            required={false}
            onChange={(date: Date) => {
              setAgeAtEvent(dob, date, `labAgeAtResults`, setValue)
            } } 
            isClearable={true}       />

        </Col>
        <Col>
          <NumberInput
            name={`labAgeAtResults`}
            id={'ageAtLabResults'}
            title={t(`${translatn}.ageAtLabResults`)}
            placeholder={t(`${translatn}.ageAtLabResultsPlaceholder`)}
            disabled={false}
            readOnly={true}
            required={false}
          />
        </Col>
      </Row>
    </div>
  )
}
