export const UserCreateDefaultData = {
  userId: '',
  username: '',
  password: '',
  firstName: '',
  lastName: '',
  email: '',
  roleId: '',
  userStatus: '',
  siteId: null,
  siteName: null,
  employeeId: ''
}
