import React, { useContext, ReactElement } from 'react'
import { AuthContext } from '../../contexts'
import Header from './components/Header'
import SessionTimeout from './components/SessionTimeout'

type Props = { children: ReactElement }

function Layout({ children }: Readonly<Props>) {
  const auth = useContext(AuthContext)
  const userName = auth?.authenticatedUser?.sub

  return (
    <>
      {userName && <SessionTimeout/>}
      <Header />
      <div className="vcr-pp-container">{children}</div>
    </>
  )
}

export default Layout
