import React, { useContext } from 'react'
import Container from 'react-bootstrap/Container'
import { useTranslation } from 'react-i18next'
import { TabProps } from '../../../models/Sample'
import { ViewSampleDetailsRow } from './ViewSampleDetailsRow'
import { AppUtil } from '../../../utils/app.utils'
import { MASTER_DATA_KEYS as MDK, MASTER_DATA_ID as MDI } from '../../../utils/constants'
import { MasterDataContext } from '../../../contexts'
import _ from 'lodash'

export const ClinicalConditions = (props: TabProps) => {
  const { sampleDetails } = props
  const { t } = useTranslation()
  const { masterData } = useContext(MasterDataContext)
  const translatn = 'viewSample.'
  const parentClass = ''

  const detailsArr = [
    {
      label: t(`${translatn}typeOfCancerOrDiagnosis`),
      value: sampleDetails?.typeOfCancerOrDiagnosis,
      show: true
    },
    {
      label: t(`${translatn}ffpeBlocksAvailable`),
      value: AppUtil.getLabel(MDK.FFPEBLOCKS_AVAILABLE, masterData, sampleDetails?.ffpeBlocksAvailable),
      show: true
    },
    {
      label: t(`${translatn}histopathologyReportDone`),
      value: AppUtil.getLabel(MDK.HISTOPATHOLOGY_REPORT, masterData, sampleDetails?.histopathologyReportDone),
      show: true
    },
    {
      label: t(`${translatn}histopathologyReportDate`),
      value: sampleDetails?.histopathologyReportDate,
      show: sampleDetails?.histopathologyReportDone == MDI.HISTOPATHOLOGY_REPORT_OPTION_YES
    },
    {
      label: t(`${translatn}onChemotherapy`),
      value: AppUtil.getLabel(MDK.WHETHER_ON_CHEMOTHERAPY, masterData, sampleDetails?.onChemotherapy),
      show: true
    },
    {
      label: t(`${translatn}chemotherapyDrugName`),
      value: sampleDetails?.chemotherapyDrugName,
      show: sampleDetails?.onChemotherapy == MDI.WHETHER_ON_CHEMOTHERAPY_OPTION_YES
    },
    {
      label: t(`${translatn}lastChemotherapyDate`),
      value: sampleDetails?.lastChemotherapyDate,
      show: sampleDetails?.onChemotherapy == MDI.WHETHER_ON_CHEMOTHERAPY_OPTION_YES
    },
    {
      label: t(`${translatn}chemotherapyCycles`),
      value: sampleDetails?.chemotherapyCycles,
      show: sampleDetails?.onChemotherapy == MDI.WHETHER_ON_CHEMOTHERAPY_OPTION_YES
    },
    {
      label: t(`${translatn}otherDrugsPrescribed`),
      value: AppUtil.getLabel(MDK.OTHER_DRUGS_PRESCRIBED, masterData, sampleDetails?.otherDrugsPrescribed),
      show: true
    },
    {
      label: t(`${translatn}otherDrugName`),
      value: sampleDetails?.otherDrugName,
      show: sampleDetails?.otherDrugsPrescribed == MDI.OTHER_DRUGS_PRESCRIBED_OPTION_YES
    },
    {
      label: t(`${translatn}otherDrugDuration`),
      value: sampleDetails?.otherDrugDuration,
      show: sampleDetails?.otherDrugsPrescribed == MDI.OTHER_DRUGS_PRESCRIBED_OPTION_YES
    },
    {
      label: t(`${translatn}bloodTransfusionDone`),
      value: AppUtil.getLabel(MDK.BLOOD_TRANSFUSION, masterData, sampleDetails?.bloodTransfusionDone),
      show: true
    },
    {
      label: t(`${translatn}bloodTransfusionHistory`),
      value: sampleDetails?.bloodTransfusionHistory,
      show: sampleDetails?.bloodTransfusionDone == MDI.BLOOD_TRANSFUSION_OPTION_YES
    },
    {
      label: t(`${translatn}lastTransfusionDate`),
      value: sampleDetails?.lastTransfusionDate,
      show: sampleDetails?.bloodTransfusionDone == MDI.BLOOD_TRANSFUSION_OPTION_YES
    },
    {
      label: t(`${translatn}boneMarrowTransplantDone`),
      value: AppUtil.getLabel(MDK.BONE_MARROW_TRANSPLANT, masterData, sampleDetails?.boneMarrowTransplantDone),
      show: true
    },
    
    {
      label: t(`${translatn}boneMarrowTransplantHistory`),
      value: sampleDetails?.boneMarrowTransplantHistory,
      show: sampleDetails?.boneMarrowTransplantDone == MDI.BONE_MARROW_TRANSPLANT_OPTION_YES
    },
    {
      label: t(`${translatn}lastTransplantDate`),
      value: sampleDetails?.lastTransplantDate,
      show: sampleDetails?.boneMarrowTransplantDone == MDI.BONE_MARROW_TRANSPLANT_OPTION_YES
    },
    {
      label: t(`${translatn}testedForInfectiousDiseases`),
      value: AppUtil.getLabel(
        MDK.RECENTLY_TESTED_INFECTIOUS_DISEASE,
        masterData,
        sampleDetails?.testedForInfectiousDiseases,
      ),
      show: true
    },
    {
      label: t(`${translatn}infectiousDiseaseTestDate`),
      value: sampleDetails?.infectiousDiseaseTestDate,
      show: sampleDetails?.testedForInfectiousDiseases == MDI.RECENTLY_TESTED_INFECTIOUS_DISEASE_OPTION_YES
    },
    {
      label: t(`${translatn}infectiousDiseaseResult`),
      value: sampleDetails?.infectiousDiseaseResult,
      show: sampleDetails?.testedForInfectiousDiseases == MDI.RECENTLY_TESTED_INFECTIOUS_DISEASE_OPTION_YES
    },
    {
      label: t(`${translatn}infectiousDiseaseNotesOrRemarks`),
      value: sampleDetails?.infectiousDiseaseNotesOrRemarks,
      show: sampleDetails?.testedForInfectiousDiseases == MDI.RECENTLY_TESTED_INFECTIOUS_DISEASE_OPTION_YES
    },
    {
      label: t(`${translatn}diagnosis`),
      value: sampleDetails?.diagnosis,
      show: true
    },
  ]

  const data = detailsArr.filter((details) => details.show === true)

  const rowCount = _.chunk(data, 5)

  return (
    <Container fluid>
      <h5 className="pt-3"> {t(`${translatn}clinicalConditions`)}</h5>
      {rowCount.map((row, index) => (
        <ViewSampleDetailsRow
          key={index}
          index={index}
          rowArr={rowCount[index]}
          parentClass={parentClass}
        />
      ))}
    </Container>
  )
}
