import React from 'react';
import { usePromiseTracker } from 'react-promise-tracker';
import Spinner from 'react-bootstrap/Spinner'
import './styles/LoadingIndicator.scss'

export const LoadingIndicator = (): React.ReactElement => {
    const { promiseInProgress } = usePromiseTracker();
    
    if (
      promiseInProgress 
    ) {
      return (
        <div className="loading-css">
          <Spinner className="spinner"/>
        </div>
      );
    } else {
      return <></>;
    }
  };