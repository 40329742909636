import { HandwrittenNotes, HandwrittenNotesRequestPayload, MultiSelectItem } from "../../../models/HandwrittenNotes"
import { MasterDataItem } from "../../../models/MasterData"
import { AppUtil } from "../../../utils/app.utils"
import { MASTER_DATA_KEYS as MDK} from "../../../utils/constants"

const formatMultiselectPayload = (data: MultiSelectItem[]) => {
  return data?.map((item) => item.value)?.join(',')
}

const formatMultiSelectForForm = (data: string, masterData: MasterDataItem[], key: string) => {
  return data
    ? data?.split(',')?.map((item) => {
      return AppUtil.getOption(key, masterData, item)
    })
    : []
}

export const formatRequestPayload = (data: HandwrittenNotes) => {
  return {
    ...data,
    predefined_Tags: formatMultiselectPayload(data.predefined_Tags),
  }
}

export const formatFormValues = (data: HandwrittenNotesRequestPayload, masterData: MasterDataItem[]) => {
  const formattedData = {
    ...data,
    predefined_Tags: formatMultiSelectForForm(
      data?.predefined_Tags,
      masterData,
      MDK.NOTE_TAG,
    )
  }

  return {
    ...formattedData,
  }
}

function renameFileInFileList(fileList: FileList) {
  // Convert FileList to an array
  const filesArray = Array.from(fileList);

  // Create a new array to hold the modified files
  const updatedFiles = filesArray.map(file => {
    return new File([file], file.name.substring(0, file.name.lastIndexOf('.')) + "_" + AppUtil.formatDateToStringWithTimestamp(new Date()) + file.name.substring(file.name.lastIndexOf('.')), { type: file.type }); // Return the original file if the name doesn't match
  });

  // Create a new DataTransfer object to hold the new FileList
  const dataTransfer = new DataTransfer();

  // Add the updated files to the DataTransfer object
  updatedFiles.forEach(file => dataTransfer.items.add(file));

  // Return the new FileList
  return dataTransfer.files;
}


export const formatPayloadData = (data: HandwrittenNotes) => {
  const formattedData = {
    ...data,
    file: renameFileInFileList(data.file ?? {} as FileList)
  }
  return {
    ...formattedData
  }
}