import React from 'react'
import { useTranslation } from 'react-i18next'
import { Medication } from '../../../models/Sample'
import _ from 'lodash'
import { ViewSampleDetailsRow } from '../../ViewSample/components/ViewSampleDetailsRow'
import { AppUtil } from '../../../utils/app.utils'

type Props = {
    medication?: Medication
}
export const ViewMedicationDetailsBody = (props: Props) => {
    const { medication } = props
    const { t } = useTranslation()
    const translatn = 'viewSample.'
    const parentClass = 'vcr-pp-sample-info-medication'

    const detailsArr = [
        {
            label: t(`${translatn}medicationName`),
            value: medication?.medicationName,
            show: true
        },
        {
            label: t(`${translatn}medicationDosage`),
            value: medication?.medicationDosage,
            show: true
        },
        {
            label: t(`${translatn}medicationFrequency`),
            value: medication?.medicationFrequency,
            show: true
        },
        {
            label: t(`${translatn}medicationStartDate`),
            value: medication ? AppUtil.formatDateToString(medication?.medicationStartDate) : '',
            show: true
        },
        {
            label: t(`${translatn}medicationStopDate`),
            value: medication ? AppUtil.formatDateToString(medication?.medicationStopDate) : '',
            show: true
        },
        {
            label: t(`${translatn}medicationPrescribingPhysician`),
            value: medication?.medicationPrescribingPhysician,
            show: true
        },
        {
            label: t(`${translatn}medicationBrandOrManufacturer`),
            value: medication?.medicationBrandOrManufacturer,
            show: true
        },
        {
            label: t(`${translatn}medicationAdverseEffects`),
            value: medication?.medicationAdverseEffects,
            show: true
        },
        {
            label: t(`${translatn}medicationClass`),
            value: medication?.medicationClass,
            show: true
        },
        {
            label: t(`${translatn}medicationDisease`),
            value: medication?.medicationDisease,
            show: true
        },
        {
            label: t(`${translatn}medicationRegimen`),
            value: medication?.medicationRegimen,
            show: true
        },
        {
            label: t(`${translatn}medicationMissedDoses`),
            value: medication?.medicationMissedDoses,
            show: true
        },
    ]

    const data = detailsArr.filter((details) => details.show === true)

    const rowCount = _.chunk(data, 3)
    return (
        <>
            {rowCount.map((row, index) => (
                <ViewSampleDetailsRow
                    key={index}
                    index={index}
                    rowArr={rowCount[index]}
                    parentClass={parentClass}
                />
            ))}
        </>
    )
}
