import React from 'react'
import { TumorSequencing } from './TumorSequencing'
import { SurgeryBiopsy } from './SurgeryBiopsy'

import { Sample } from '../../../models/Sample'
import { ClinicalConditions } from './ClinicalConditions'

type Props = {
  sampleDetails: Sample | null
}

export const TabFour = ({ sampleDetails }: Props) => {
  return (
    <div >
      <TumorSequencing sampleDetails={sampleDetails}/>
      <SurgeryBiopsy sampleDetails={sampleDetails}/>
      <ClinicalConditions sampleDetails={sampleDetails}/>
    </div>
  )
}