import React from 'react'
import { QC } from './QC'
import { TestDetails } from './TestDetails'
import { Sample } from '../../../models/Sample'
import { SampleInformation } from './SampleInformation'

type Props = {
  sampleDetails: Sample | null
}

export const TabSix = ({ sampleDetails }: Props) => {
  return (
    <div>
      <SampleInformation sampleDetails={sampleDetails} />
      <QC sampleDetails={sampleDetails} />
      <TestDetails sampleDetails={sampleDetails} />
    </div>
  )
}
