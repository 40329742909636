import React from 'react'
import { useFormContext } from 'react-hook-form'
import { QC } from './QC'
import { TestDetails } from './TestDetails'
import { SampleInformation } from './SampleInformation'
import { PRIVILEGES } from '../../../utils/constants'
import { AuthUtil } from '../../../utils/auth.utils'

export const TabSix = () => {
  const { getValues } = useFormContext()
  let sampleStatus = getValues('sampleStatus')

  const hasEditSamplePrivilege = AuthUtil.checkUserAccess(PRIVILEGES.EDIT_SAMPLE)
  // const hasEditBeforeShippmentPrivilege = AuthUtil.checkUserAccess(PRIVILEGES.EDIT_BEFORE_SHIPMENT)
  // const hasEditAfterShippmentPrivilege = AuthUtil.checkUserAccess(PRIVILEGES.EDIT_AFTER_SHIPMENT)

  let isEditAllowed = true

  if (hasEditSamplePrivilege && [1, 2, 3].includes(sampleStatus ?? -1)
    //  (
    //   hasEditAfterShippmentPrivilege && [2, 3].includes(sampleStatus ?? -1) ||
    //   hasEditBeforeShippmentPrivilege && [0, 1].includes(sampleStatus ?? -1) 
    // )
  ) {
    isEditAllowed = false
  }

  return (
    <div>
      <SampleInformation isEditAllowed={isEditAllowed}/>
      <QC isEditAllowed={isEditAllowed}/>
      <TestDetails isEditAllowed={isEditAllowed}/>
    </div>
  )
}
