import React, { useEffect, useRef, useState } from 'react'
import moment from 'moment'
import SignatureCanvas from "react-signature-canvas";
import { PDFDocument, StandardFonts } from "pdf-lib";
import { useTranslation } from 'react-i18next'
import { FileUploadNoForm, RadioInput, TextInputNoForm } from '../InputComponents'
import { getLoggedInUserDetail } from '../../contexts'
import './PDFStyles.scss'
import { Button, Col, Row } from 'react-bootstrap';
function updatePDFFormTextField(form: any, fontSize: number, textFieldName: string, fieldContent: string){
  const textField = form.getTextField(textFieldName)
  textField.setFontSize(fontSize)
  textField.setText(fieldContent)
  textField.enableReadOnly()
}
async function signPDFForm(pdfURL: string, pdfBytesData: Uint8Array, signatureImage: any, overallComment: string) {  
  let pdfData = pdfBytesData //new Uint8Array()
  if (pdfURL) {
    const response = await fetch(pdfURL)
    let pdfArrayBuffer = await response.arrayBuffer()
    pdfData = new Uint8Array(pdfArrayBuffer)
  }
  const pdfDoc = await PDFDocument.load(pdfData)
  
  const timesRomanFont = await pdfDoc.embedFont(StandardFonts.TimesRoman)
  const form = pdfDoc.getForm()
  form.updateFieldAppearances(timesRomanFont)
  const pngImage = await pdfDoc.embedPng(signatureImage)
  const signField = form.getButton('signField')
  signField.setImage(pngImage)
  signField.enableReadOnly()
  updatePDFFormTextField(form, 10, "overallComment",'Overall Comment :-'+overallComment)
  
  const userinfo = getLoggedInUserDetail()
  updatePDFFormTextField(form, 7, "signNoteField", 'Signed on ' + moment().format("DD MMM YYYY, h:mm:ss a") + ' by ' + (userinfo?.name ?? ''))
  const pdfBytes = await pdfDoc.save()
  return pdfBytes
}
export function SignaturePad(props: any) {
  const { t } = useTranslation()
  const signPadRef = useRef<any>(null)
  const { modalClose, pdfURL, pdfBytesData, setpdfBytesData } = props
  const [signature, setSignature] = useState<string>("")
  const [signImage, setSignImage] = useState("")
  const [overallComment, setOverallComment] = useState("")
  const [overallCommentError, setOverallCommentError] = useState("")
  const [fileError,setFileError] = useState("")
  const [imageFileKey, setImageFileKey] = useState("")
  const [selectedValue,setSelectedValue] = useState("");
  const [noValueSelected,setNoValueSelected] = useState("");

  const translatn = 'pdf.'
  const parentClass = 'vcr-pp-view-pdf-signpad'
  const node = signPadRef.current
  node?.isEmpty()

  const clearSignature = () => {
    node.clear()
    setSignature("")
    setSignImage("")
    setImageFileKey(Math.random().toString(36))
  };

  function signImageUpload(event: any){
    node.clear()
    setNoValueSelected('')
    setFileError('')
    if (!event.target.files) 
    {
      setFileError(t(`${translatn}fileError`))
      return
    }

    if(event.target.files[0].size>=(2*1024*1024) || !event.target.files[0].name.includes('.png'))
    {
      setFileError(t(`${translatn}fileError`))
    }
    setSignImage(URL.createObjectURL(event.target.files[0]))
    node.fromDataURL(URL.createObjectURL(event.target.files[0]))
  }
  const signPDFFile = async () => {
    if (!overallComment) {
      setOverallCommentError(t(`${translatn}emptyOverallCommentError`))
    }
    if((signature=='' && signImage=='') || selectedValue==='')
    {
      setNoValueSelected(t(`${translatn}signNotPresent`)) 

    }
    if((signature=='' && signImage=='') || selectedValue==='' || !overallComment || fileError)
      return

    const sign=await signPadRef.current.getTrimmedCanvas().toDataURL();
    const pdfBytes =await signPDFForm(pdfURL, pdfBytesData, sign, overallComment)

    setpdfBytesData(pdfBytes)
    if (modalClose) modalClose()
  };
  return (
    <div className={parentClass}>
    <Row >
      <Col lg={12} className={`${parentClass}-input-label`}>{t(`${translatn}signature`)}
      <span className='text-danger'> * </span>
      </Col>
      <Col lg={4} className={`px-0`}><label className={`${parentClass}-label`} > 
        <input className={'py-2'} type="radio" value="option1" checked={selectedValue === 'option1'} onChange={() => {setSelectedValue("option1"); setNoValueSelected(''); setFileError(''); if(node) node.clear(); setSignature('');}} />
        
         {" "+t(`${translatn}signImageUploadInfo`)} </label>
      </Col><Col lg={6} className={`px-0`}> 
      <label className={`${parentClass}-label`}> 
        <input  type="radio" value="option2" checked={selectedValue === 'option2'} onChange={() => {setSelectedValue("option2"); setNoValueSelected(''); setFileError(''); if(node) node.clear(); }} />
        {" "+t(`${translatn}drawSignInfo`)} </label>
      </Col>
      </Row><Row>
      <Col lg={12} className={'px-0'}>{selectedValue === 'option1' && (<div className={`${parentClass}-input`}>
        <FileUploadNoForm 
          name={`signImageFileUpload`} 
          id={"signImageFileUpload"} 
          size={"sm"} 
          key={imageFileKey} 
          onChange={signImageUpload} 
          title="" 
          readOnly={false} 
          disabled={false}/>
          
      </div>)
      }</Col>
      <Col lg={12} className={parentClass + '-invalid-feedback px-0'}>{fileError? fileError: ''}</Col>
      <Col lg={12} className={'px-0'}>
      <div className={selectedValue=='option2'? parentClass + '-canvas-display' : parentClass + '-canvas-display-none'}>
        <SignatureCanvas 
          ref={signPadRef} 
          penColor="black" 
          canvasProps={{ width: "500",height: "150",className: `${parentClass}-canvas`}} 
          onBegin={()=>{ setNoValueSelected(''); setSignature(signPadRef.current.getTrimmedCanvas().toDataURL())}}
        />
      </div>
      </Col>
    <Col lg={12} className={parentClass + '-invalid-feedback px-0'}>{noValueSelected ? noValueSelected: ''}</Col>
    </Row>
    <Row>
      <Col className={`${parentClass}-label px-0`}>
      <TextInputNoForm 
        name={`overallComment`} 
        id={"overallComment"} 
        title={t(`${translatn}overallComment`)}
        onChange={(event: any) => { 
            setOverallComment(event.target.value.trim());
            setOverallCommentError('');
          }} required/>
      </Col>
      <Col lg={12} className={parentClass + '-invalid-feedback px-0'}>{overallCommentError? overallCommentError: ''}</Col>
    </Row>
    <Row>
       <Col className={'px-0'}><Button variant="secondary" onClick={clearSignature}>{t(`${translatn}clearSign`)}</Button>
       <Button  className={`${parentClass}-btn m-2`} onClick={signPDFFile}>{t(`${translatn}confirmSign`)}</Button></Col>
    </Row>
    </div>
  );
}
