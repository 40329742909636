import React, { createContext, useState, ReactNode, useEffect, useContext } from 'react'
import { AppUtil } from '../utils/app.utils'
import { AxiosResponse } from 'axios'
import { trackPromise } from 'react-promise-tracker'
import { MasterDataItem } from '../models/MasterData'
import { getMasterData } from '../services/MasterData'
import { AuthContext } from './AuthContext'

type MasterDataContextProps = {
  masterData: MasterDataItem[]
}

type MasterDataProps = {
  children: ReactNode
}

const defaultProps = {
  masterData: [],
}

export const MasterDataContext = createContext<MasterDataContextProps>({ ...defaultProps })

export const MasterDataProvider = ({ children }: MasterDataProps) => {
  const [masterData, setMasterData] = useState<MasterDataItem[]>([])
  const auth = useContext(AuthContext)

  useEffect(() => {
    if (auth?.authenticatedUser?.sub) {
      trackPromise(
        getMasterData().then(
          (res: AxiosResponse<MasterDataItem[]>) => {
            setMasterData(res.data)
          },
          (error) => AppUtil.logError(error),
        ),
      )
    }
  }, [auth?.authenticatedUser?.sub])


  return <MasterDataContext.Provider value={{masterData}}>{children}</MasterDataContext.Provider>
}

export const MasterDataConsumer = MasterDataContext.Consumer
