import React, { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { useFormContext } from 'react-hook-form'

import { NumberInput, SelectInput, DateInput, TextInput } from '../../InputComponents'
import { setAgeAtEvent } from '../helpers'
import { MASTER_DATA_KEYS as MDK, MASTER_DATA_ID as MDI } from '../../../utils/constants'
import { MasterDataContext } from '../../../contexts'
import { AppUtil, AppUtil as _ } from '../../../utils/app.utils'
import { getDateOfBirth } from '../helpers/AddSampleInfoHelpers'

export const CancerDiagnosis = () => {
  const { t } = useTranslation()
  const { setValue, getValues, watch, resetField } = useFormContext()
  const { masterData } = useContext(MasterDataContext)
  const translatn = 'sampleInfoForm'
  const parentClass = 'vcr-pp-sample-info-'
  const dob = getValues('dob')
  let isHistology = watch('histology')
  useEffect(()=>{
    if(!isHistology)
      resetField('physicianComment',{defaultValue:''})
  },[ watch('histology')])

  return (
    <div className={`${parentClass}input-field`}>
      <h5 className={`${parentClass}form-heading`}> {t(`${translatn}.cancerDiagnosis`)}</h5>
      <Row lg={5} md={5} sm={5}>
        <Col>
          <DateInput
            name={`dateOfFirstContact`}
            id={'dateOfFirstContact'}
            title={t(`${translatn}.dateOfFirstContact`)}
            placeholder={t(`common.datePlaceholder`)}
            disabled={false}
            readOnly={false}
            min={new Date(getDateOfBirth(dob)) ?? undefined}
            max={new Date()}
            required={true}
            onChange={(date: Date) => {
              setAgeAtEvent(dob, date, `ageAtFirstContact`, setValue)
            }}
          />
        </Col>
        <Col>
          <NumberInput
            name={`ageAtFirstContact`}
            id={'ageAtFirstContact'}
            title={t(`${translatn}.ageAtFirstContact`)}
            placeholder={t(`${translatn}.ageAtFirstContactPlaceholder`)}
            disabled={false}
            readOnly={true}
            required={true}
            min={1}
          />
        </Col>
        <Col>
          <SelectInput
            name={`primarySiteOfDiagnosis`}
            id={'primarySiteOfDiagnosis'}
            title={t(`${translatn}.primarySiteOfDiagnosis`)}
            placeholder={t(`${translatn}.primarySiteOfDiagnosisPlaceholder`)}
            disabled={false}
            required={true}
            optionsValueArray={_.getOptions(MDK.DIAGNOSIS_PRIMARY_SITE, masterData)}
            onCustomChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
              AppUtil.resetOtherField(
                event.target.value,
                MDI.DIAGNOSIS_PRIMARY_SITE_OTHER,
                resetField,
                'primarySiteOfDiagnosisOther',
              )
            }
          />
        </Col>
        {watch('primarySiteOfDiagnosis') === MDI.DIAGNOSIS_PRIMARY_SITE_OTHER && (
          <Col>
            <TextInput
              type="text"
              name={`primarySiteOfDiagnosisOther`}
              id={'primarySiteOfDiagnosisOther'}
              title={t(`${translatn}.primarySiteOfDiagnosisOther`)}
              placeholder={t(`${translatn}.primarySiteOfDiagnosisOtherPlaceholder`)}
              disabled={false}
              readOnly={false}
              required={false}
            />
          </Col>
        )}
        </Row>
        <Row lg={5} md={5} sm={5}>
        <Col>
          <SelectInput
            name={`laterality`}
            id={'laterality'}
            title={t(`${translatn}.laterality`)}
            placeholder={t(`${translatn}.lateralityPlaceholder`)}
            disabled={false}
            required={true}
            optionsValueArray={_.getOptions(MDK.LATERALITY, masterData)}
          />
        </Col>
        <Col>
          <TextInput
            type="text"
            name={`treatmentStatus`}
            id={'treatmentStatus'}
            title={t(`${translatn}.treatmentStatus`)}
            placeholder={t(`${translatn}.treatmentStatusPlaceholder`)}
            disabled={false}
            readOnly={false}
            required={true}
          />
        </Col>
        <Col>
          <SelectInput
            name={`histology`}
            id={'histology'}
            title={t(`${translatn}.histology`)}
            placeholder={t(`${translatn}.histologyPlaceholder`)}
            disabled={false}
            required={true}
            optionsValueArray={_.getOptions(MDK.HISTOLOGY, masterData)}
          />
        </Col>
        {isHistology && <Col>
          <TextInput
            type="text"
            name={`physicianComment`}
            id={'physicianComment'}
            title={t(`${translatn}.physicianComment`)}
            placeholder={t(`${translatn}.physicianCommentPlaceholder`)}
            disabled={false}
            readOnly={false}
            required={false}
          />
        </Col>}
        </Row>
        <Row>
        <Col lg={6} md={6} sm={6}>
          <SelectInput
            name={`otherStagingSystemAndGradeData`}
            id={'otherStagingSystemAndGradeData'}
            title={t(`${translatn}.otherStagingSystemAndGradeData`)}
            placeholder={t(`${translatn}.otherStagingSystemAndGradeDataPlaceholder`)}
            disabled={false}
            required={true}
            optionsValueArray={_.getOptions(MDK.OTHER_STAGING_SYSTEM_GRADE_DATA, masterData)}
            onCustomChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
              AppUtil.resetOtherField(
                event.target.value,
                MDI.OTHER_STAGING_SYSTEM_GRADE_DATA_OTHER,
                resetField,
                'otherStagingSystemAndGradeDataOther',
              )
            }
          />
        </Col>
        {watch('otherStagingSystemAndGradeData') === MDI.OTHER_STAGING_SYSTEM_GRADE_DATA_OTHER && (
          <Col lg={3} md={3} sm={3}>
            <TextInput
              type="text"
              name={`otherStagingSystemAndGradeDataOther`}
              id={'otherStagingSystemAndGradeDataOther'}
              title={t(`${translatn}.otherStagingSystemAndGradeDataOther`)}
              placeholder={t(`${translatn}.otherStagingSystemAndGradeDataOtherPlaceholder`)}
              disabled={false}
              readOnly={false}
              required={false}
            />
          </Col>
        )}
        <Col lg={3} md={3} sm={3}>
          <TextInput
            name={`otherStagingGradeOrScoreDetails`}
            id={'otherStagingGradeOrScoreDetails'}
            title={t(`${translatn}.otherStagingGradeOrScoreDetails`)}
            placeholder={t(`${translatn}.otherStagingGradeOrScoreDetailsPlaceholder`)}
            disabled={false}
            required={false}
          />
        </Col>
        </Row>
        <Row lg={4} md={4} sm={4}>
        <Col>
          <DateInput
            name={`dateOfDiagnosis`}
            id={'dateOfDiagnosis'}
            title={t(`${translatn}.dateOfDiagnosis`)}
            placeholder={t(`common.datePlaceholder`)}
            disabled={false}
            readOnly={false}
            min={new Date(getDateOfBirth(dob)) ?? undefined}
            max={new Date()}
            required={true}
            onChange={(date: Date) => {
              setAgeAtEvent(dob, date, `ageAtDiagnosis`, setValue)
            }}
          />
        </Col>
        <Col>
          <NumberInput
            name={`ageAtDiagnosis`}
            id={'ageAtDiagnosis'}
            title={t(`${translatn}.ageAtDiagnosis`)}
            placeholder={t(`${translatn}.ageAtDiagnosisPlaceholder`)}
            disabled={false}
            readOnly={true}
            required={true}
          />
        </Col>
      </Row>
      <Row lg={4} md={4} sm={4}>
        <Col>
          <SelectInput
            name={`clinicalT`}
            id={'clinicalT'}
            title={t(`${translatn}.clinicalT`)}
            placeholder={t(`${translatn}.clinicalTPlaceholder`)}
            disabled={false}
            required={true}
            optionsValueArray={_.getOptions(MDK.CLINICAL_T, masterData)}
          />
        </Col>
        <Col>
          <SelectInput
            name={`clinicalN`}
            id={'clinicalN'}
            title={t(`${translatn}.clinicalN`)}
            placeholder={t(`${translatn}.clinicalNPlaceholder`)}
            disabled={false}
            required={true}
            optionsValueArray={_.getOptions(MDK.CLINICAL_N, masterData)}
          />
        </Col>
        <Col>
          <SelectInput
            name={`clinicalM`}
            id={'clinicalM'}
            title={t(`${translatn}.clinicalM`)}
            placeholder={t(`${translatn}.clinicalMPlaceholder`)}
            disabled={false}
            required={true}
            optionsValueArray={_.getOptions(MDK.CLINICAL_M, masterData)}
          />
        </Col>
        <Col>
          <SelectInput
            name={`clinicalGroupStage`}
            id={'clinicalGroupStage'}
            title={t(`${translatn}.clinicalGroupStage`)}
            placeholder={t(`${translatn}.clinicalGroupStagePlaceholder`)}
            disabled={false}
            required={true}
            optionsValueArray={_.getOptions(MDK.CLINICAL_GROUP_STAGE, masterData)}
            onCustomChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
              AppUtil.resetOtherField(
                event.target.value,
                MDI.CLINICAL_GROUP_STAGE_OTHER,
                resetField,
                'clinicalGroupStageOther',
              )
            }
          />
        </Col>
        {watch('clinicalGroupStage') === MDI.CLINICAL_GROUP_STAGE_OTHER && (
          <Col>
            <TextInput
              type="text"
              name={`clinicalGroupStageOther`}
              id={'clinicalGroupStageOther'}
              title={t(`${translatn}.clinicalGroupStageOther`)}
              placeholder={t(`${translatn}.clinicalGroupStageOtherPlaceholder`)}
              disabled={false}
              readOnly={false}
              required={false}
            />
          </Col>
        )}
      </Row>
      <Row lg={4} md={4} sm={4}>
        <Col>
          <SelectInput
            name={`pathologicalT`}
            id={'pathologicalT'}
            title={t(`${translatn}.pathologicalT`)}
            placeholder={t(`${translatn}.pathologicalTPlaceholder`)}
            disabled={false}
            required={true}
            optionsValueArray={_.getOptions(MDK.PATHOLOGICAL_T, masterData)}
          />
        </Col>
        <Col>
          <SelectInput
            name={`pathologicalN`}
            id={'pathologicalN'}
            title={t(`${translatn}.pathologicalN`)}
            placeholder={t(`${translatn}.pathologicalNPlaceholder`)}
            disabled={false}
            required={true}
            optionsValueArray={_.getOptions(MDK.PATHOLOGICAL_N, masterData)}
          />
        </Col>
        <Col>
          <SelectInput
            name={`pathologicalM`}
            id={'pathologicalM'}
            title={t(`${translatn}.pathologicalM`)}
            placeholder={t(`${translatn}.pathologicalMPlaceholder`)}
            disabled={false}
            required={true}
            optionsValueArray={_.getOptions(MDK.PATHOLOGICAL_M, masterData)}
          />
        </Col>
        <Col>
          <SelectInput
            name={`pathologicalGroupStage`}
            id={'pathologicalGroupStage'}
            title={t(`${translatn}.pathologicalGroupStage`)}
            placeholder={t(`${translatn}.pathologicalGroupStagePlaceholder`)}
            disabled={false}
            required={true}
            optionsValueArray={_.getOptions(MDK.PATHOLOGICAL_GROUP_STAGE, masterData)}
            onCustomChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
              AppUtil.resetOtherField(
                event.target.value,
                MDI.PATHOLOGICAL_GROUP_STAGE_OTHER,
                resetField,
                'pathologicalGroupStageOther',
              )
            }
          />
        </Col>
        {watch('pathologicalGroupStage') === MDI.PATHOLOGICAL_GROUP_STAGE_OTHER && (
          <Col>
            <TextInput
              type="text"
              name={`pathologicalGroupStageOther`}
              id={'pathologicalGroupStageOther'}
              title={t(`${translatn}.pathologicalGroupStageOther`)}
              placeholder={t(`${translatn}.pathologicalGroupStageOtherPlaceholder`)}
              disabled={false}
              readOnly={false}
              required={false}
            />
          </Col>
        )}
      </Row>
      <Row lg={4} md={4} sm={4}>
        <Col>
          <SelectInput
            name={`performanceStatusAtDiagnosis`}
            id={'performanceStatusOfDiagnosis'}
            title={t(`${translatn}.performanceStatusOfDiagnosis`)}
            placeholder={t(`${translatn}.performanceStatusOfDiagnosisPlaceholder`)}
            disabled={false}
            required={true}
            optionsValueArray={_.getOptions(MDK.PERFORMANCE_STATUS_AT_DIAGNOSIS, masterData)}
          />
        </Col>
        <Col lg={7} md={6} sm={6}>
          <SelectInput
            name={`typeOfPerformanceStatusScaleUsedAtDiagnosis`}
            id={'typeOfPerformanceStatusScaleUsedAtDiagnosis'}
            title={t(`${translatn}.typeOfPerformanceStatusScaleUsedAtDiagnosis`)}
            placeholder={t(`${translatn}.typeOfPerformanceStatusScaleUsedAtDiagnosisPlaceholder`)}
            disabled={false}
            optionsValueArray={_.getOptions(MDK.TYPE_OF_PERFORMANCE_STATUS_SCALE_FOR_DIAGNOSIS, masterData)}
            required={true}
          />
        </Col>
        </Row>
        <Row lg={4} md={4} sm={4}>
        <Col>
          <DateInput
            name={`dateOfDiagnosticPerformanceStatus`}
            id={'dateOfDiagnosticPerformanceStatus'}
            title={t(`${translatn}.dateOfDiagnosticPerformanceStatus`)}
            placeholder={t(`common.datePlaceholder`)}
            disabled={false}
            readOnly={false}
            required={true}
            min={new Date(getDateOfBirth(dob)) ?? undefined}
            max={new Date()}
            onChange={(date: Date) => {
              setAgeAtEvent(dob, date, `ageAtDiagnosticPerformanceStatus`, setValue)
            }}
          />
        </Col>
        <Col>
          <NumberInput
            name={`ageAtDiagnosticPerformanceStatus`}
            id={'ageAtDiagnosisPerformanceStatus'}
            title={t(`${translatn}.ageAtDiagnosisPerformanceStatus`)}
            placeholder={t(`${translatn}.ageAtDiagnosisPerformanceStatusPlaceholder`)}
            disabled={false}
            readOnly={true}
            required={true}
          />
        </Col>
      </Row>
    </div>
  )
}
