import moment from 'moment'
import { UseFormSetValue } from 'react-hook-form/dist/types'
import { z } from 'zod'
import { DetailsItem, FamilyHistory, MultiSelectItem, Sample, SampleMarkerDetails, SampleRequestPayload } from '../../../models/Sample'
import { AppUtil } from '../../../utils/app.utils'
import { APP_DATE_FORMAT, MASTER_DATA_KEYS as MDK } from '../../../utils/constants'
import { MasterDataItem, ValueItem } from '../../../models/MasterData'
import _, { filter, isObject } from 'lodash'

const { formatDateForPayload, formatDateForForm, getIdByKeyName } = AppUtil

export const setAgeAtEvent = (dob: string, eventDate: Date, name: string, setValue: UseFormSetValue<any>) => {
  const formattedDob = formatDateForForm(dob)
  const diff = moment(eventDate).diff(formattedDob, 'years')
  setValue(name, diff)
}

export const getDateOfBirth = (dob: string) => {
  return moment(dob, APP_DATE_FORMAT).format('ddd MMM DD YYYY HH:mm:ss [GMT]ZZ [(India Standard Time)]')
}

export const getTranslatedStr = (path: string): string => {
  const translatn = 'sampleInfoForm.errors'
  return `${translatn}.${path}`
}

export const FFPEFields = [
  'blockNumber',
  'fixativeUsed',
  'fixationDuration',
  'coldIschemiaTime',
  'hAndeStaining',
]
export const bloodFields = ['tubeType']
export const hAndEFields = ['tumorTissue', 'necrosisTissues']

export const organTransplantFields = ['dateOfOrganTransplant']

export const siteAtOrganTransplantFields = ['siteAtOrganTransplant']

export const ageAtOrganTransplantFields = ['ageAtOrganTransplant']

export const siteAtOrganTransplantOtherFields = ['siteAtOrganTransplantOther']

export const familyHistoryFields = ['familyHistoryDetails']
// export const familyHistoryFields = ['relationToPatient', 'familyMemberCancerSite', 'familyMemberAgeAtDiagnosis']

export const relationToPatientFields = ['relationToPatientOther']

export const familyMemberCancerSiteFields = ['familyMemberCancerSiteOther']

export const siteOfMetastasisFields = ['dateOfMetastasis']

export const cytogeneticsTestingPerformanceFields = ['dateOfProcedureForCytogeneticsAbnormalities','ageAtProcedureForCytogeneticsAbnormalities']

export const testTypeDeletionsTranslocationsFields = ['testTypeDeletionsTranslocations']

export const surgeryBiopsyDoneFields=['surgeryType','biopsyType','anesthesiaType','surgicalApproach']
export const surgeryBiopsyDoneDateFields=['dateOfSurgeryBiopsy']
export const surgeryBiopsyDoneTextFields =['originalPrimarySiteOfDiagnosisForSurgeryPerformed','originalPrimarySiteOfDiagnosisForSurgeryPerformedOther','locationForSurgeryBiopsy','locationForSurgeryBiopsyOther','surgeryTypeDetails','biopsyTypeDetails','anethesiaTypeDetails','surgicalApproachDetails']
export const surgeryBiopsyDoneAgeFields =['ageAtSurgeryBiopsy']

export const histopathologyReportDoneFields=['histopathologyReportDate']

export const bloodTransfusionDoneFields=['bloodTransfusionHistory']
export const lastTransfusionDateFields=['lastTransfusionDate']

export const boneMarrowTransplantDoneFields=['boneMarrowTransplantHistory']
export const lastTransplantDateFields=['lastTransplantDate']

export const onChemotherapyFields = ['chemotherapyDrugName', 'chemotherapyCycles']
export const lastChemotherapyDateFields = ['lastChemotherapyDate']

export const otherDrugsPrescribedFields = ['otherDrugName', 'otherDrugDuration']

export const testedForInfectiousDiseasesFields = ['infectiousDiseaseResult', 'infectiousDiseaseNotesOrRemarks']
export const infectiousDiseaseTestDateFields = ['infectiousDiseaseTestDate']

export const dateOfCellularTherapy = ['dateOfCellularTherapy']
export const ageAtCellularTherapyFields = ['ageAtCellularTherapy']
export const cellularTherapyFields = ['stemCellTransplantType', 'sourceOfStemCells', 'transplantationCenter']
export const cellularTheraphyRemainingFields = ['conditioningRegimen','graftVersusHostDiseaseStatus', 'acuteGVHDGrade', 'chronicGVHDStatus', 'engraftmentStatus', 'donorType', 'hlaMatching', 'responseToSCT']

export const radiationTherapyFields = ['originalSiteOfDiagnosisForRadiationTherapy', 'radiationDisease', 'radiationRegimen', 'radiationModality', 'radiationDose']
export const radiationTherapyStartStopFields = ['radiationStart', 'radiationStop']

export const dateOfDeathFields = ['causeOfDeathContributingFactors', 'causeOfDeathAutopsyFindings', 'causeOfDeathMedicalHistory', 'causeOfDeathDocumentationOfTerminalEvents']
export const ageAtDeathFields = ['ageAtDeath']
export const dateOfLastContactFields = ['dateOfLastContactActivity']
export const ageAtLastContactActivityFields = ['ageAtLastContactActivity']

export const progressionSourceBeyond4MonthsDate = ['progressionSourceBeyond4MonthsDate']

export const progressionRecurrenceFields = ['dateOfProgressionRecurrence', 'ageAtProgressionRecurrence']
export const progressionRecurrenceType = ['typeOfRecurrence']

const MultiSelectObjectSchema = z.object({
  label: z.string(),
  value: z.string().or(z.number()),
})
export const schemaHelpers = {
  date: z.coerce.date(),
  string: z.string(),
  number: z.coerce.number(),
  nullableDate: z.coerce.date().nullable(),
  nullableStr: z.string().max(100, { message: getTranslatedStr('maxTextLength') }).nullable(),
  nullableNumber: z.coerce.number().nullable(),
  nullableSelect: z.coerce.string().nullable(),
  requiredNum: z.coerce.number().or(z.literal('')),
  requiredSelect: z
    .string({
      invalid_type_error: getTranslatedStr('required'),
    })
    .min(1, { message: getTranslatedStr('required') }),
  requiredRadio: z
    .string({
      invalid_type_error: getTranslatedStr('required'),
    })
    .min(1, { message: getTranslatedStr('required') }),
  multiselect: z.array(MultiSelectObjectSchema).optional(),
  select: z.string(),
  radio: z.string(),
  nullableRadio: z.string().nullable(),
  requiredDate: z.coerce.string().transform((dateString, ctx) => {
    if (!dateString || dateString == 'null') {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: getTranslatedStr('required'),
      })
    }
    const date = new Date(dateString)
    if (!z.date().safeParse(date).success) {
      ctx.addIssue({
        code: z.ZodIssueCode.invalid_date,
      })
    }
    return date
  }),
  requiredMultiselect: z.array(MultiSelectObjectSchema).nonempty({ message: getTranslatedStr('required') }),
  requiredEmail: z
    .string({
      invalid_type_error: getTranslatedStr('required'),
    })
    .min(2, { message: getTranslatedStr('required') })
    .max(100, { message: getTranslatedStr('maxEmailLength') })
    .email(getTranslatedStr('invalidEmail')),
  requiredPhone: z
    .string()
    .trim()
    .min(1, { message: getTranslatedStr('required') })
    .min(10, { message: getTranslatedStr('minPhoneLength') })
    .regex(/^[\d]{10}$/, getTranslatedStr('invalidPhone')),
  requiredStr: z
    .string({
      invalid_type_error: getTranslatedStr('required'),
    })
    .trim()
    .min(1, { message: getTranslatedStr('required') })
    .max(100, { message: getTranslatedStr('maxTextLength') }),
  requiredFileWithSizeAndType: (maxSize: number, acceptedTypes: string[]) =>
    z
      .any()
      .refine((file) => file, getTranslatedStr('required'))
      .refine((file) => file[0]?.size <= maxSize, getTranslatedStr('maxFile'))
      .refine((file) => acceptedTypes.includes(file[0]?.type), getTranslatedStr('acceptedFiles')),
  requiredStrWithLen: (min: number) =>
    z
      .string()
      .trim()
      .min(1, { message: getTranslatedStr('required') })
      .min(min, { message: getTranslatedStr('required') }),

  requiredNumber: z
    .string()
    .min(1, { message: getTranslatedStr('required') })
    .pipe(z.coerce.number().positive(getTranslatedStr('invalidNumber')))
    .refine((val) => {
      return val
    })
    .or(z.coerce.number().refine((val) => val === null, { message: getTranslatedStr('required') }))
    .or(z.number()),
  requiredMonth:
  z
  .string()
  .min(1, { message: getTranslatedStr('required') })
  .regex(/^(?!0\d)\d+$/, { message: getTranslatedStr('invalidNumber') })
  .pipe(z.coerce.number().positive(getTranslatedStr('invalidMonth')))
  .refine((val) => 
    val<=120 && val>=0, { message: getTranslatedStr('invalidMonth') }
  )
  .or(z.coerce.number().refine((val) => val === null, { message: getTranslatedStr('required') }))
  .or(z.number()),
  requiredTemperatureNumber: z
  .string()
  .min(1, { message: getTranslatedStr('required') })
  .regex(/^(?!0\d)(\d+(\.\d+)?)$//* /^(?!0\d)\d+$/ */, { message: getTranslatedStr('invalidNumber') })
  .pipe(z.coerce.number().positive(getTranslatedStr('invalidTemperatureLength')))
  .refine((val) => 
    val<=110 && val>=0, { message: getTranslatedStr('invalidTemperatureLength') }
  )
  .or(z.coerce.number().refine((val) => val === null, { message: getTranslatedStr('required') }))
  .or(z.number()),
  
  requiredReplicateNumber: z.string()
  .min(1, { message: getTranslatedStr('required') })
  .regex(/^(?!0\d)\d+$/, { message: getTranslatedStr('invalidNumber') })
  .transform(val => {
    const num = parseInt(val, 10);
    if (Number.isNaN(num)) {
      throw new Error(getTranslatedStr('invalidNumber'));
    }
    return num;
  })
  .refine(val => Number.isInteger(val), { message: getTranslatedStr('invalidNumber') })
  .refine(val => val >= 1 && val <= 20, { message: getTranslatedStr('invalidNumber') }),

  requiredNumberWithMinMaxLen: (min: number, max: number, minTrnsltnPath: string, maxTrnsltnPath: string) =>
    z
      .string()
      .min(min, { message: getTranslatedStr(minTrnsltnPath) })
      .max(max, { message: getTranslatedStr(maxTrnsltnPath) })
      .pipe(z.coerce.number())
      .refine((val) => {
        return val
      }),
  requiredCheckbox: z.coerce.string().refine((val) => val === 'true', {
    message: getTranslatedStr('required'),
  }),
  strWithMinMaxLen: (min: number, max: number, maxTrnsltnPath: string) => z.string().trim().min(min, {
    message: getTranslatedStr('required'),
  })
    .max(max, { message: getTranslatedStr(maxTrnsltnPath) }).nullable(),
  strWithMinMaxLenForMedication: (min: number, max: number, maxTrnsltnPath: string) => z.coerce.string().trim().min(min, {
      message: getTranslatedStr('required'),
    })
      .max(max, { message: getTranslatedStr(maxTrnsltnPath) }),
  dynamicFields: z.record(z.string(), z.string().max(100, { message: getTranslatedStr('maxTextLength') })),
}

export const formatMultiselectPayload = (data: MultiSelectItem[]) => {
  return data?.map((item) => item.value)?.join(',')
}

const formatDetailsForPayload = (data: DetailsItem) => {
  return data ? JSON.stringify(data) : ''
}

const formatDependentFieldsPayload = (masterDataKey: string, subCategory: {
  [key: string]: undefined | (MultiSelectItem & { subCategoryFields: MultiSelectItem[] })[]
}, categoryFields: {
  [key: string]: {
    [key: string]: string | {
      [key: string]: string
    }
  }
}, masterData: MasterDataItem[]) => {

  const dependentFieldsData: SampleMarkerDetails[] = []
  if (Object.keys(categoryFields ?? {}).length) {
    Object.keys(categoryFields ?? {})?.map((categoryFieldsItemKey) => {


      Object.keys(categoryFields?.[categoryFieldsItemKey] ?? {})?.map((categoryFieldsItemNestedKey) => {


        if (isObject(categoryFields?.[categoryFieldsItemKey]?.[categoryFieldsItemNestedKey])) {
          const filteredObj = _.omitBy(categoryFields?.[categoryFieldsItemKey]?.[categoryFieldsItemNestedKey], value => value === '');
          if (Object.keys(filteredObj).length) {
            Object.keys(filteredObj || {})?.map((key: any) => {
              dependentFieldsData.push({
                categoryId: getIdByKeyName(masterDataKey, masterData),
                category: categoryFieldsItemKey[categoryFieldsItemKey.length - 1],
                subCategory: categoryFieldsItemNestedKey[categoryFieldsItemNestedKey.length - 1],
                subCategoryOption: key[key.length - 1],
                subCategoryValue: filteredObj?.[key] ?? null,
              })
            })
          }
        } else {
          if ((categoryFields?.[categoryFieldsItemKey]?.[categoryFieldsItemNestedKey]) != '') {
            dependentFieldsData.push({
              categoryId: getIdByKeyName(masterDataKey, masterData),
              category: categoryFieldsItemKey[categoryFieldsItemKey.length - 1],
              subCategory: categoryFieldsItemNestedKey[categoryFieldsItemNestedKey.length - 1],
              subCategoryOption: null,
              subCategoryValue: String(categoryFields?.[categoryFieldsItemKey]?.[categoryFieldsItemNestedKey]) ?? null,
            })
          }
        }
      })
    })
  }
  if (Object.keys(subCategory)?.length) {
    Object.keys(subCategory)?.map((subCategorykey: string) => {
      if (subCategory[subCategorykey] === undefined) {
        dependentFieldsData.push({
          categoryId: getIdByKeyName(masterDataKey, masterData),
          category: subCategorykey[subCategorykey.length - 1] ?? null,
          subCategory: null,
          subCategoryOption: null,
          subCategoryValue: null,
        })
      }
    })
  }
  /* if (category?.length) {
    (category || [])?.map((categoryItem: MultiSelectItemSubCatergory) => {
      dependentFieldsData.push({
        categoryId: getIdByKeyName(masterDataKey, masterData),
        category: String(categoryItem.value) ?? null,
        subCategory: null,
        subCategoryOption: null,
        subCategoryValue: null,
      })
    })
  } */
  return dependentFieldsData
}

export const formatRequestPayload = (data: Sample, masterData: MasterDataItem[]): SampleRequestPayload => {
  const dateFields = [
    'dateOfPhysical',
    'dateOfOrganTransplant',
    'dateOfFirstContact',
    'dateOfDiagnosis',
    'dateOfDiagnosticPerformanceStatus',
    'dateOfMetastasis',
    'dateOfImagingScan',
    'dateOfLabResults',
    'dateOfProcedureForCytogeneticsAbnormalities',
    'dateOfTumorSequencing',
    'dateOfSurgeryBiopsy',
    'dateOfCellularTherapy',
    'dateOfResults',
    'radiationStart',
    'radiationStop',
    'dateOfPerformanceStatus',
    'responseByDateOfAssessment',
    'dateOfDiseaseStatus',
    'dateOfProgressionRecurrence',
    'dateOfLastContactActivity',
    'dateOfDeath',
    'sampleCollectionDate',
    'lastChemotherapyDate',
    'histopathologyReportDate',
    'lastTransfusionDate',
    'lastTransplantDate',
    'infectiousDiseaseTestDate',
    'biomarkerBeyond4MonthsDate',
    'labTypeBeyond4MonthsDate',
    'progressionSourceBeyond4MonthsDate',
  ]
  const numFields = [
    'smokingStatus',
    'alcoholUse',
    'organTransplantDone',
    'familyHistoryOfCancer',
    'siteAtOrganTransplant',
    'primarySiteOfDiagnosis',
    'laterality',
    'histology',
    'otherStagingSystemAndGradeData',

    'clinicalT',
    'clinicalN',
    'clinicalM',
    'clinicalGroupStage',
    'pathologicalT',
    'pathologicalN',
    'pathologicalM',
    'pathologicalGroupStage',
    'performanceStatusAtDiagnosis',
    'typeOfPerformanceStatusScaleUsedAtDiagnosis',
    'originalPrimarySiteOfDiagnosis',
    'siteOfMetastasis',
    'imagingSiteForScan',
    'measurableDiseasePresent',
    'newLesions',
    'site',
    'cytogeneticsTestingPerformance',
    'originalPrimarySiteOfDiagnosisForSurgeryPerformed',
    'locationForSurgeryBiopsy',
    'surgeryType',
    'ffpeBlocksAvailable',
    'histopathologyReportDone',
    'bloodTransfusionDone',
    'boneMarrowTransplantDone',
    'onChemotherapy',
    'testedForInfectiousDiseases',
    'cellularTherapy',
    'surgeryBiopsyDone',
    'radiationTherapy',
    'typeOfRecurrence',
    'recurrencePerformanceStatus',
    'typeOfStatusScaleUsedRECISTChemotherapy',
    'typeOfStatusScaleUsedIRRECISTImmunotherapy',
    'responseByDiseaseGroup',
    'responseByBaselineDiseaseStatus',
    'responseByResponseAssessment',
    'recistScoreDetails',
    'imagingModalityMethod',
    'otherDrugsPrescribed',
    'progressionRecurrenceDone'
  ]
  dateFields.forEach((field: string) => {
    (data as any)[field] = formatDateForPayload((data as any)[field])
  })
  numFields.forEach((field: string) => {
    (data as any)[field] = (data as any)[field] ? Number((data as any)[field]) : null
  })

  const familyHistory = data.familyHistoryDetails.map((item: FamilyHistory) => {
    const formattedObj: FamilyHistory = { ...item }
    formattedObj['relationToPatient'] =  Number([formattedObj['relationToPatient']])
    formattedObj['familyMemberCancerSite'] = Number([formattedObj['familyMemberCancerSite']])
    formattedObj['familyMemberAgeAtDiagnosis'] = Number([formattedObj['familyMemberAgeAtDiagnosis']])
    return _.cloneDeep(formattedObj)
  })

  const medication = data.medication.map((item) => {
    const formattedObj = { ...item }
    formattedObj['medicationStartDate'] = formatDateForPayload(formattedObj['medicationStartDate'])
    formattedObj['medicationStopDate'] = formatDateForPayload(formattedObj['medicationStopDate'])
    return _.cloneDeep(formattedObj)
  })


  const biomarkerData = formatDependentFieldsPayload(MDK.BIOMARKER_TEST, data.biomarkerTestSubCategory ?? {}, data.biomarkerTestSubCategoryFields as {
    [key: string]: {
      [key: string]: string | {
        [key: string]: string
      }
    }
  }, masterData) || []


  const labTypeData = formatDependentFieldsPayload(MDK.LAB_TYPE, data.labTypeSubCategory ?? {}, data.labTypeSubCategoryFields as {
    [key: string]: {
      [key: string]: string | {
        [key: string]: string
      }
    }
  }, masterData) || []

  const outcomesOfTreatmentData = formatDependentFieldsPayload(MDK.PROGRESSION_SOURCE, data.outcomesOfTreatmentSubCategory ?? {}, data.outcomesOfTreatmentSubCategoryFields as {
    [key: string]: {
      [key: string]: string | {
        [key: string]: string
      }
    }
  }, masterData) || []

  return {
    ...data,
    familyHistoryDetails: [...familyHistory],
    medication: [...medication],
    sampleMarkerDetails: [...biomarkerData, ...labTypeData, ...outcomesOfTreatmentData],
    typeOfTumorSequencing: formatMultiselectPayload(data.typeOfTumorSequencing),
    typeOfTumorSequencingDetails: formatDetailsForPayload(data.typeOfTumorSequencingDetails),
    markerTypeDetails: formatDetailsForPayload(data.markerTypeDetails),
    imagingTypeOfScan: formatMultiselectPayload(data.imagingTypeOfScan),
    imagingTypeOfScanDetails: formatDetailsForPayload(data.imagingTypeOfScanDetails),
    multiplePremalignantOrComorbidIllnesses: formatMultiselectPayload(data.multiplePremalignantOrComorbidIllnesses),
    multiplePremalignantOrComorbidIllnessesCapturedHereDetails: formatDetailsForPayload(
      data.multiplePremalignantOrComorbidIllnessesCapturedHereDetails,
    ),
    markerType: formatMultiselectPayload(data.markerType),
  }
}

const formatMultiSelectForForm = (data: string, masterData: MasterDataItem[], key: string) => {
  return data
    ? data?.split(',')?.map((item) => {
      return AppUtil.getOption(key, masterData, item)
    })
    : []
}

export const formatCategoryData = (data: SampleMarkerDetails[], masterData: MasterDataItem[], key: string) => {
  if (data?.length) {
    const uniqueMatchedData: ValueItem[] = []
    const id = getIdByKeyName(key, masterData)

    const getMasterData: ValueItem[] = AppUtil.getOptions(key, masterData)

    const categoryData: SampleMarkerDetails[] = filter(data, { categoryId: id });

    _.map(categoryData, (dataItem: SampleMarkerDetails) => {
      const isItemFound: ValueItem | undefined = getMasterData.find((item: ValueItem) => item.value === dataItem.category);
      if (isItemFound) {
        const isExist = uniqueMatchedData.find((item: ValueItem) => item.value === isItemFound?.value);
        if (isExist) {
          return null
        } else {
          return uniqueMatchedData.push(isItemFound)
        }
      }
    })

    return uniqueMatchedData
  }
  return [];
}

export const formatSubCategoryData = (data: SampleMarkerDetails[], masterData: MasterDataItem[], keyName: string) => {
  if (data?.length) {
    const id = getIdByKeyName(keyName, masterData)
    const getMasterData: ValueItem[] = AppUtil.getOptions(keyName, masterData)

    const categoryData: SampleMarkerDetails[] = filter(data, { categoryId: id });

    const transformData = (data: SampleMarkerDetails[]) => {
      const result = data.reduce((acc, item) => {
        // Use type[category] as the key
        const categoryKey = `type${item.category}`;

        // Initialize the category key if not present
        if (!acc[categoryKey]) {
          acc[categoryKey] = [];
        }

        // Find the corresponding subcategory details in the master data
        const categoryItem = getMasterData.find(cat => cat.value === item.category);
        if (categoryItem && categoryItem.subCategory) {
          const subCategoryItem = categoryItem.subCategory.find(subCat => subCat.value === item.subCategory);
          if (subCategoryItem) {
            // Check if the subCategoryItem is already present in the array

            const existingSubCategory = acc[categoryKey].find(subCat => subCat.value === subCategoryItem.value);
            if (!existingSubCategory) {
              acc[categoryKey].push({ ...subCategoryItem, subCategoryFields: subCategoryItem.subCategoryFields || [] });
            }
          }
        }

        return acc;
      }, {} as { [key: string]: (ValueItem & { subCategoryFields: ValueItem[] })[] });

      return result;
    };

    const formattedOutput = transformData(categoryData);
    return formattedOutput
  }
  else {
    return {}
  }
}

export const formatSubCategoryFieldsData = (data: SampleMarkerDetails[], masterData: MasterDataItem[], keyName: string) => {
  if (data?.length) {
    const id = getIdByKeyName(keyName, masterData)

    const getMasterData: ValueItem[] = AppUtil.getOptions(keyName, masterData)

    const categoryData: SampleMarkerDetails[] = filter(data, { categoryId: id });

    const getAllSubCategoryFields = (category: string | null, subCategory: string | null) => {
      const categoryItem = getMasterData.find(item => item.value === category);
      if (categoryItem && categoryItem.subCategory) {
        const subCategoryItem = categoryItem.subCategory.find(item => item.value === subCategory);
        if (subCategoryItem && subCategoryItem.subCategoryFields) {
          return subCategoryItem.subCategoryFields.reduce((acc, field) => {
            acc[`type${field.value}`] = '';
            return acc;
          }, {} as { [key: string]: string });
        }
      }
      return {};
    };

    // Main transformation function
    const transformData = (data: SampleMarkerDetails[]) => {
      const result = data.reduce((acc, item) => {
        // Use type[category] as the key
        const categoryKey = `type${item.category}`;

        // Initialize the category key if not present
        if (!acc[categoryKey]) {
          acc[categoryKey] = {};
        }

        // Use type[subCategory] as the subkey
        const subCategoryKey = `type${item.subCategory}`;

        // Initialize the subcategory key with all subcategory fields
        if (!acc[categoryKey][subCategoryKey]) {
          acc[categoryKey][subCategoryKey] = item.subCategoryOption ? getAllSubCategoryFields(item.category, item.subCategory) : item.subCategoryValue || '';
        }

        // Set the value for the subCategoryOption if present
        if (item && item.subCategoryOption !== null) {
          (acc[categoryKey][subCategoryKey] as { [key: string]: string })[`type${item.subCategoryOption}`] = item.subCategoryValue || '';
        }

        return acc;
      }, {} as { [key: string]: { [key: string]: string | { [key: string]: string } } });

      return result;
    };

    // Example usage
    const formattedOutput = transformData(categoryData);
    return formattedOutput
  }
  return {}
}

export const formatFormValues = (data: SampleRequestPayload, masterData: MasterDataItem[]) => {
  const strFields = [
    'doctorsName',
    'doctorsId',
    'doctorContactNumber',
    'doctorEmail',
    'siteName',
    
    'smokingStatus',
    'alcoholUse',
    'organTransplantDone',
    'siteAtOrganTransplant',
    'siteAtOrganTransplantOther',

    'familyHistoryOfCancer',
    'primarySiteOfDiagnosis',
    'primarySiteOfDiagnosisOther',
    'laterality',
    'treatmentStatus',
    'histology',
    'physicianComment',
    'otherStagingSystemAndGradeData',
    'otherStagingSystemAndGradeDataOther',
    'otherStagingGradeOrScoreDetails',
    'clinicalT',
    'clinicalN',
    'clinicalM',
    'clinicalGroupStage',
    'clinicalGroupStageOther',
    'pathologicalT',
    'pathologicalN',
    'pathologicalM',
    'pathologicalGroupStage',
    'pathologicalGroupStageOther',
    'performanceStatusAtDiagnosis',
    'typeOfPerformanceStatusScaleUsedAtDiagnosis',

    'originalPrimarySiteOfDiagnosis',
    'siteOfMetastasis',
    'siteOfMetastasisOther',

    'imagingModalityMethod',
    'imagingSiteForScan',
    'imagingSiteForScanOther',
    'imagingScanSiteDetails',
    'measurableDiseasePresent',
    'newLesions',
    'site',
    'siteOther',

    'specifyOtherTumor',

    'cytogeneticsTestingPerformance',
    'testTypeDeletionsTranslocations',

    'otherTumorSequencing',
    
    'surgeryBiopsyDone',
    'originalPrimarySiteOfDiagnosisForSurgeryPerformed',
    'originalPrimarySiteOfDiagnosisForSurgeryPerformedOther',
    'locationForSurgeryBiopsy',
    'locationForSurgeryBiopsyOther',    
    'surgeryType',
    'surgeryTypeDetails',
    'biopsyType',
    'biopsyTypeDetails',
    'anesthesiaType',
    'anethesiaTypeDetails',
    'surgicalApproach',
    'surgicalApproachDetails',

    'typeOfCancerOrDiagnosis',
    'ffpeBlocksAvailable',
    'histopathologyReportDone',    
    'onChemotherapy',
    'chemotherapyDrugName',
    'chemotherapyCycles',
    'otherDrugsPrescribed',
    'otherDrugName',
    'otherDrugDuration',
    'bloodTransfusionDone',
    'bloodTransfusionHistory',
    'boneMarrowTransplantDone',
    'boneMarrowTransplantHistory',
    'testedForInfectiousDiseases',
    'infectiousDiseaseResult',
    'infectiousDiseaseNotesOrRemarks',
    'diagnosis',

    'cellularTherapy',
    'stemCellTransplantType',
    'sourceOfStemCells',
    'transplantationCenter', 
    'conditioningRegimen',
    'graftVersusHostDiseaseStatus',
    'acuteGVHDGrade',
    'chronicGVHDStatus',
    'engraftmentStatus',
    'donorType',
    'hlaMatching',
    'responseToSCT',
    
    'radiationTherapy',
    'originalSiteOfDiagnosisForRadiationTherapy',
    'radiationDisease',
    'radiationRegimen',
    'radiationModality',
    'radiationDose',
    
    'progressionRecurrenceDone',
    'typeOfRecurrence',    
    'recurrencePerformanceStatus',    
    'typeOfStatusScaleUsedRECISTChemotherapy',
    'typeOfStatusScaleUsedIRRECISTImmunotherapy',
    'responseByDiseaseGroup',
    'responseByBaselineDiseaseStatus',    
    'responseByResponseAssessment', 
    'responseByPhysicianOrInvestigatorAssessment',
    'recistScoreDetails',
    'recistScore',

    'causeOfDeathContributingFactors',
    'causeOfDeathAutopsyFindings',
    'causeOfDeathMedicalHistory',
    'causeOfDeathDocumentationOfTerminalEvents',

    'sampleCollectedBy',
    'typeOfSample',
    'fixativeUsed',
    'fixationDuration',
    'coldIschemiaTime',
    'hAndeStaining',
    'tubeType',
    'replicateNumber'
  ]
  const dateFields = [
    'dateOfPhysical',
    'dateOfOrganTransplant',
    'dateOfFirstContact',
    'dateOfDiagnosis',
    'dateOfDiagnosticPerformanceStatus',    
    'dateOfMetastasis',
    'dateOfImagingScan',
    'dateOfResults',    
    'biomarkerBeyond4MonthsDate',
    'labTypeBeyond4MonthsDate',
    'dateOfLabResults',
    'dateOfProcedureForCytogeneticsAbnormalities',    
    'dateOfTumorSequencing',
    'dateOfSurgeryBiopsy',
    'histopathologyReportDate',
    'lastChemotherapyDate',    
    'lastTransfusionDate',
    'lastTransplantDate',
    'infectiousDiseaseTestDate',
    'dateOfCellularTherapy',
    'radiationStart',
    'radiationStop',
    'progressionSourceBeyond4MonthsDate',    
    'dateOfProgressionRecurrence',
    'dateOfPerformanceStatus',
    'responseByDateOfAssessment',
    'dateOfDiseaseStatus',    
    'dateOfLastContactActivity',
    'dateOfDeath',
    'sampleCollectionDate',
  ]

  strFields.forEach((field: string) => {
    (data as any)[field] = `${(data as any)[field] ?? ''}`
  })

  dateFields.forEach((field: string) => {
    (data as any)[field] = formatDateForForm((data as any)[field])
  })

  const familyHistory = data.familyHistoryDetails?.map((item: FamilyHistory) => {
    const formattedObj: FamilyHistory = { ...item }
    formattedObj['relationToPatient'] =  String([formattedObj['relationToPatient']])
    formattedObj['familyMemberCancerSite'] = String([formattedObj['familyMemberCancerSite']])
    formattedObj['familyMemberAgeAtDiagnosis'] = String([formattedObj['familyMemberAgeAtDiagnosis']])
    return _.cloneDeep(formattedObj)
  }) ?? [{
    sampleId: data.id ?? undefined,
    relationToPatient: '',
    relationToPatientOther: '',
    familyMemberCancerSite: '',
    familyMemberCancerSiteOther: '',
    familyMemberAgeAtDiagnosis: ''
  }]

  const medication = data.medication.map((item, index) => {
    item['medicationName'] = item['medicationName'] ? String(item['medicationName']) : ''
    item['medicationDosage'] = item['medicationDosage'] ? String(item['medicationDosage']) : ''
    item['medicationFrequency'] = item['medicationFrequency'] ? String(item['medicationFrequency']) : ''
    item['medicationStartDate'] = formatDateForForm(item['medicationStartDate'])
    item['medicationStopDate'] = formatDateForForm(item['medicationStopDate'])
    item['medicationPrescribingPhysician'] = item['medicationPrescribingPhysician'] ? String(item['medicationPrescribingPhysician']) : ''
    item['medicationBrandOrManufacturer'] = item['medicationBrandOrManufacturer'] ? String(item['medicationBrandOrManufacturer']) : ''
    item['medicationAdverseEffects'] = item['medicationAdverseEffects'] ? String(item['medicationAdverseEffects']) : ''
    item['medicationClass'] = item['medicationClass'] ? String(item['medicationClass']) : ''
    item['medicationDisease'] = item['medicationDisease'] ? String(item['medicationDisease']) : ''
    item['medicationRegimen'] = item['medicationRegimen'] ? String(item['medicationRegimen']) : ''
    item['medicationMissedDoses'] = item['medicationMissedDoses'] ? String(item['medicationMissedDoses']) : ''
    item['medId'] = index
    return _.cloneDeep(item)
  })

  const formattedData = {
    ...data,
    medication: [...medication],
    familyHistoryDetails: [...familyHistory],
    biomarkerTestCategory: formatCategoryData(data.sampleMarkerDetails, masterData, MDK.BIOMARKER_TEST),
    biomarkerTestSubCategory: formatSubCategoryData(data.sampleMarkerDetails, masterData, MDK.BIOMARKER_TEST),
    biomarkerTestSubCategoryFields: formatSubCategoryFieldsData(data.sampleMarkerDetails, masterData, MDK.BIOMARKER_TEST),
    labTypeCategory: formatCategoryData(data.sampleMarkerDetails, masterData, MDK.LAB_TYPE),
    labTypeSubCategory: formatSubCategoryData(data.sampleMarkerDetails, masterData, MDK.LAB_TYPE),
    labTypeSubCategoryFields: formatSubCategoryFieldsData(data.sampleMarkerDetails, masterData, MDK.LAB_TYPE),
    outcomesOfTreatmentCategory: formatCategoryData(data.sampleMarkerDetails, masterData, MDK.PROGRESSION_SOURCE),
    outcomesOfTreatmentSubCategory: formatSubCategoryData(data.sampleMarkerDetails, masterData, MDK.PROGRESSION_SOURCE),
    outcomesOfTreatmentSubCategoryFields: formatSubCategoryFieldsData(data.sampleMarkerDetails, masterData, MDK.PROGRESSION_SOURCE),
    typeOfTumorSequencing: formatMultiSelectForForm(
      data?.typeOfTumorSequencing,
      masterData,
      MDK.TUMOR_SEQUENCING_TEST_TYPE,
    ),
    typeOfTumorSequencingDetails: data?.typeOfTumorSequencingDetails
      ? JSON.parse(data?.typeOfTumorSequencingDetails)
      : {},
    markerTypeDetails: data?.markerTypeDetails ? JSON.parse(data?.markerTypeDetails) : {},
    imagingTypeOfScanDetails: data?.imagingTypeOfScanDetails ? JSON.parse(data?.imagingTypeOfScanDetails) : {},
    imagingTypeOfScan: formatMultiSelectForForm(data?.imagingTypeOfScan, masterData, MDK.IMAGING_SCAN_TYPE),
    multiplePremalignantOrComorbidIllnessesCapturedHereDetails:
      data?.multiplePremalignantOrComorbidIllnessesCapturedHereDetails
        ? JSON.parse(data?.multiplePremalignantOrComorbidIllnessesCapturedHereDetails)
        : {},
    multiplePremalignantOrComorbidIllnesses: formatMultiSelectForForm(
      data?.multiplePremalignantOrComorbidIllnesses,
      masterData,
      MDK.PRE_MALIGNANT_COMORBID_ILLNESS,
    ),
    markerType: formatMultiSelectForForm(data?.markerType, masterData, MDK.MARKER_TYPE),
    sampleCollectionDate: formatDateForForm(data.sampleCollectionDate),
  }

  return {
    ...formattedData,
  }
}
