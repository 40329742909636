import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { useFormContext } from 'react-hook-form'
import { setAgeAtEvent } from '../helpers'
import { AppUtil as _ } from '../../../utils/app.utils'
import { MASTER_DATA_KEYS as MDK, MASTER_DATA_ID as MDI, DATA_SOURCE } from '../../../utils/constants'
import { MasterDataContext } from '../../../contexts'
import { TextInput, NumberInput, DateInput, CheckboxInput, SelectInput, FileUploadNoForm } from '../../InputComponents'

import '../RegisterPatientContainer.scss'
import { getCountriesList, getStatesList } from '../../../services/Patient'
import { trackPromise } from 'react-promise-tracker'
import { AxiosResponse } from 'axios'
import { useParams } from 'react-router-dom'

type SelectItem = {
  label: string
  value: string | number
}

export const PatientIdentification = (props: any) => {
  const { id } = useParams()
  const { t } = useTranslation()
  const formMethods = useFormContext()
  const { masterData } = useContext(MasterDataContext)
  const { setValue, watch, resetField, getValues } = formMethods
  const translatn = 'registerPatient'
  // const hasConsentCheckPrivilege = AuthUtil.checkUserAccess(PRIVILEGES.PATIENT_CONSENT)
  const parentClass = 'vcr-pp-register-patient-'

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      props.setConsentFileData(file)
      setValue('consentFile', file.name);
    } else {
      props.setConsentFileData(null)
      setValue('consentFile', "");
    }
  }

  useEffect(() => {
    if (watch('consentProvided') === false || watch('consentProvided') === "false") {
      setValue('consentFile', "");
      props.setConsentFileData(null)
    }
  }, [watch('consentProvided')])

  const [states, setStates] = useState<SelectItem[]>([]);
  const [countries, setCountries] = useState<SelectItem[]>([]);

  let isNationalitySelected = watch('nationality');

  useEffect(() => {
    trackPromise(
      getCountriesList().then(
        (res: AxiosResponse) => {
          if (res.status === 200) {
            const countriesNames = res?.data?.data?.map((c: any) => ({ label: c.country, value: c.country }))
            setCountries(countriesNames)
          }
        },
        (error) => _.logError(error),
      )
      ,
    )
  }, [])

  const fetchStatesList = (country: string) => {
    getStatesList(country).then(
      (res: AxiosResponse) => {
        if (res.status === 200) {
          const stateNames = res?.data?.data?.states?.map((s: any) => ({ label: s.name, value: s.name }))
          if (stateNames.length === 0) {
            setStates([{ label: country, value: country }])
          } else {
            setStates(stateNames)
           
          }
        }
      },
      (error) => _.logError(error),
    )
  }

  useEffect(() => {
    if(countries.length > 0) {
      setValue('nationality', getValues('nationality'))
    }
  }, [countries])

  useEffect(() => {
    if(states.length > 0) {
      setValue('state', getValues('state'))
    }
  }, [states])

  useEffect(() => {
    if (isNationalitySelected) {
      const country = isNationalitySelected
      if (country)
        fetchStatesList(country)
    }
  }, [isNationalitySelected])

  useEffect(() => {
    if(id && masterData.length) {
      setValue('gender', getValues('gender'))
    }
  }, [masterData])

  return (
    <>
      <Row className={`${parentClass}form-data-input`}>
        <Col>
          <DateInput
            name={`registrationDate`}
            id={'dateOfRequest'}
            title={t(`${translatn}.dateOfRequest`)}
            placeholder=""
            disabled={true}
            readOnly={true}
            max={new Date()}
            required={true}
          />
        </Col>
        <Col>
          <TextInput
            type="text"
            name={`siteID`}
            id={'siteID'}
            title={t(`${translatn}.siteId`)}
            placeholder={t(`${translatn}.siteIdPlaceholder`)}
            disabled={true}
            required={true}
            readOnly={true}
          />
        </Col>
        <Col>
          <TextInput
            type="text"
            name={`siteName`}
            id={'siteName'}
            title={t(`${translatn}.siteName`)}
            placeholder={t(`${translatn}.siteNamePlaceholder`)}
            disabled={true}
            required={true}
            readOnly={true}
          />
        </Col>
      </Row>
      <Row className={`${parentClass}form-data-input`}>
        <Col>
          <CheckboxInput
            name={`consentProvided`}
            id={`consentProvided`}
            title={''}
            disabled={props.isEdit && getValues('consentProvided') && getValues('consentFilePath')}
            inline={true}
            required={true}
            checkboxValueArray={[{ label: t(`${translatn}.consentProvided`), value: true }]}
          />
        </Col>
      </Row>
      {(watch('consentProvided') === "true" || watch('consentProvided') === true) && !(props.isEdit && getValues('consentProvided') && getValues('consentFilePath')) && <Row>
        <Row className='mt-0'>
        <Col>
          <FileUploadNoForm name={`file`} id={"file"} size={"md"} onChange={handleFileChange} title="" readOnly={false} disabled={false} />
        </Col>
        <Col>
        </Col>
        <Col>
        </Col>
        </Row>
      </Row>}
      <hr />
      <Row className={`${parentClass}form-data-input`}>
        <Col>
          <TextInput
            type="text"
            name={`firstName`}
            id={'firstName'}
            title={t(`${translatn}.firstName`)}
            placeholder={t(`${translatn}.firstNamePlaceholder`)}
            disabled={false}
            readOnly={false}
            required={true}
          />
        </Col>
        <Col>
          <TextInput
            type="text"
            name={`middleName`}
            id={'middleName'}
            title={t(`${translatn}.middleName`)}
            placeholder={t(`${translatn}.middleNamePlaceholder`)}
            disabled={false}
            readOnly={false}
            required={false}
          />
        </Col>
        <Col>
          <TextInput
            type="text"
            name={`lastName`}
            id={'lastName'}
            title={t(`${translatn}.lastName`)}
            placeholder={t(`${translatn}.lastNamePlaceholder`)}
            disabled={false}
            readOnly={false}
            required={true}
          />
        </Col>
      </Row>
      <Row className={`${parentClass}form-data-input`}>
        <Col>
          <SelectInput
            name={`gender`}
            id="gender"
            optionsValueArray={_.getOptions(MDK.GENDER, masterData)}
            title={t(`${translatn}.gender`)}
            disabled={false}
            required={true}
            placeholder={t(`${translatn}.genderPlaceholder`)}
            onCustomChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
              _.resetOtherField(
                event.target.value,
                MDI.GENDER_OTHER,
                resetField,
                'genderOther',
              )}
          />
        </Col>
        {watch('gender') === MDI.GENDER_OTHER && (
          <Col>
            <TextInput
              type="text"
              name={`genderOther`}
              id={'genderOther'}
              title={t(`${translatn}.genderOther`)}
              placeholder={t(`${translatn}.genderOtherPlaceholder`)}
              disabled={false}
              readOnly={false}
              required={false}
            />
          </Col>
        )}
        <Col>
          <DateInput
            name={`dob`}
            id={'dob'}
            title={t(`${translatn}.dob`)}
            placeholder={t(`common.datePlaceholder`)}
            disabled={false}
            readOnly={false}
            required={true}
            onChange={(date: Date) => {
              setAgeAtEvent(date.toString(), new Date(), `age`, setValue)
            }}
            max={new Date()}
          />
        </Col>
        <Col>
          <NumberInput
            name={`age`}
            id={'age'}
            title={t(`${translatn}.age`)}
            placeholder={t(`${translatn}.agePlaceholder`)}
            disabled={false}
            readOnly={true}
            required={true}
          />
        </Col>
      </Row>
      <Row className={`${parentClass}form-data-input`}>
        <Col>
          <SelectInput
            name={`race`}
            id={'race'}
            title={t(`${translatn}.race`)}
            placeholder={t(`${translatn}.racePlaceholder`)}
            disabled={false}
            optionsValueArray={_.getOptions(MDK.RACE, masterData)}
            required={true}
            onCustomChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
              _.resetOtherField(
                event.target.value,
                MDI.RACE_OTHER,
                resetField,
                'raceOther',
              )}
          />
        </Col>
        {watch('race') === MDI.RACE_OTHER && (
          <Col>
            <TextInput
              type="text"
              name={`raceOther`}
              id={'raceOther'}
              title={t(`${translatn}.raceOther`)}
              placeholder={t(`${translatn}.raceOtherPlaceholder`)}
              disabled={false}
              readOnly={false}
              required={false}
            />
          </Col>
        )}
        <Col>
          <SelectInput
            name={`ethnicity`}
            id={'ethnicity'}
            title={t(`${translatn}.ethnicity`)}
            placeholder={t(`${translatn}.ethnicityPlaceholder`)}
            disabled={false}
            required={true}
            optionsValueArray={_.getOptions(MDK.ETHNICITY, masterData)}
            onCustomChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
              _.resetOtherField(
                event.target.value,
                MDI.ETHNICITY_OTHER,
                resetField,
                'ethnicityOther',
              )} />
        </Col>
        {watch('ethnicity') === MDI.ETHNICITY_OTHER && (
          <Col>
            <TextInput
              type="text"
              name={`ethnicityOther`}
              id={'ethnicityOther'}
              title={t(`${translatn}.ethnicityOther`)}
              placeholder={t(`${translatn}.ethnicityOtherPlaceholder`)}
              disabled={false}
              readOnly={false}
              required={false}
            />
          </Col>
        )}
        <Col>
          <SelectInput
            name={`nationality`}
            id="nationality"
            optionsValueArray={countries}
            title={t(`${translatn}.nationality`)}
            disabled={false}
            required={true}
            placeholder={t(`${translatn}.nationalityPlaceholder`)}
            onCustomChange={() => {
              resetField('state')
              setStates([])
            }
            }
          />
        </Col>
      </Row>
      <Row className={`${parentClass}form-data-input`}>
      <Col lg={2}>
      <SelectInput
            name={`countryCode`}
            id="countryCode"
            optionsValueArray={[{label:"+91",value:"+91"}]}
            title={t(`${translatn}.countryCode`)}
            disabled={true}
            required={true}
            placeholder={t(`${translatn}.countryCodePlaceholder`)}
          />
        </Col>
        <Col>
          <TextInput
            type=""
            name={`phoneNumber`}
            id={'phoneNum'}
            title={t(`${translatn}.phoneNumber`)}
            placeholder={t(`${translatn}.phoneNumberPlaceholder`)}
            disabled={false}
            readOnly={false}
            required={true}
          />
        </Col>
        <Col>
          <TextInput
            type="text"
            name={`email`}
            id={'email'}
            title={t(`${translatn}.email`)}
            placeholder={t(`${translatn}.emailPlaceholder`)}
            disabled={false}
            readOnly={false}
            required={true}
          />
        </Col>
        <Col>
          <TextInput
            type="text"
            name={`diagnosis`}
            id={'diagnosis'}
            title={t(`${translatn}.diagnosis`)}
            placeholder={t(`${translatn}.diagnosisPlaceholder`)}
            disabled={false}
            readOnly={false}
            required={true}
          />
        </Col>
        
      </Row>
      <Row className={`${parentClass}form-data-input`}>
        <Col>
          <TextInput
            type="text"
            name={`addressLine1`}
            id={'addressLine1'}
            title={t(`${translatn}.address`)}
            placeholder={t(`${translatn}.addressPlaceholder`)}
            disabled={false}
            readOnly={false}
            required={true}
          />
        </Col>
        <Col>
          <TextInput
            type="text"
            name={`addressLine2`}
            id={'addressLine2'}
            title={t(`${translatn}.address2`)}
            placeholder={t(`${translatn}.address2Placeholder`)}
            disabled={false}
            readOnly={false}
            required={false}
          />
        </Col>
      </Row>
      <Row className={`${parentClass}form-data-input`}>
        <Col>
          <SelectInput
            name={`state`}
            id="state"
            optionsValueArray={states}
            title={t(`${translatn}.state`)}
            disabled={false}
            required={true}
            placeholder={t(`${translatn}.statePlaceholder`)}
            noOptionsMessage={states.length === 0 ? t(`${translatn}.noOptionsMessage`) : ''}
          /></Col>
        <Col>
          <TextInput
            name={`city`}
            type="text"
            id={'city'}
            title={t(`${translatn}.city`)}
            placeholder={t(`${translatn}.cityPlaceholder`)}
            disabled={false}
            required={true}
            readOnly={false}
          />
        </Col>
        <Col>
          <NumberInput
            name={`pinCode`}
            id={'pinCode'}
            title={t(`${translatn}.pinCode`)}
            placeholder={t(`${translatn}.pinCodePlaceholder`)}
            disabled={false}
            readOnly={false}
            required={true}
            min={1}
          />
        </Col>
      </Row>
      <Row className={`${parentClass}form-data-input`}>
      <Col >
          <TextInput
            type="text"
            name={`uhid`}
            id={'uhid'}
            title={t(`${translatn}.uhid`)}
            placeholder={t(`${translatn}.uhidPlaceholder`)}
            disabled={getValues('dataSource') == DATA_SOURCE}
            readOnly={false}
            required={true}
          />
        </Col>
        <Col>
        </Col>
        <Col>
        </Col>
      </Row>
    </>
  )
}
