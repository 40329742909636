import React, { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { useFormContext } from 'react-hook-form'

import { NumberInput, RadioInput, DateInput, TextInput } from '../../InputComponents'
import { ageAtCellularTherapyFields, cellularTheraphyRemainingFields, cellularTherapyFields, dateOfCellularTherapy, getDateOfBirth, setAgeAtEvent } from '../helpers/AddSampleInfoHelpers'
import { MASTER_DATA_KEYS as MDK, MASTER_DATA_ID as MDI } from '../../../utils/constants'
import { MasterDataContext } from '../../../contexts'
import { AppUtil as _ } from '../../../utils/app.utils'

export const StemCellTransplant = () => {
  const { t } = useTranslation()
  const { setValue, getValues, watch, resetField, clearErrors } = useFormContext()
  const { masterData } = useContext(MasterDataContext)
  const translatn = 'sampleInfoForm'
  const parentClass = 'vcr-pp-sample-info-'
  const dob = getValues('dob')

  let isCellularTherapy = watch('cellularTherapy') === MDI.CELLULAR_THERAPY_OPTION_YES

  useEffect(()=> {
    if(!isCellularTherapy){      
      dateOfCellularTherapy.forEach((field) => {
        resetField(field, { defaultValue: null })
        clearErrors(field)
      })
      ageAtCellularTherapyFields.forEach((field) => {
        resetField(field, { defaultValue: null })
        clearErrors(field)
      })
      cellularTherapyFields.forEach((field) => {
        resetField(field, { defaultValue: '' })
        clearErrors(field)
      })
      cellularTheraphyRemainingFields.forEach((field) => {
        resetField(field, { defaultValue: '' })
        clearErrors(field)
      })
    }
  }, [isCellularTherapy])

  return (
    <div className={`${parentClass}input-field`}>
      <h5 className={`${parentClass}form-heading`}> {t(`${translatn}.stemCellTransplant`)}</h5>
      <Row lg={4} md={4} sm={4}>
        <Col>
          <RadioInput
            name={`cellularTherapy`}
            id="cellularTherapy"
            valueArray={_.sortOptionsDesc(_.getOptions(MDK.CELLULAR_THERAPY, masterData))}
            title={t(`${translatn}.cellularTherapy`)}
            disabled={false}
            inline={true}
            required={true}
          />
        </Col>
        </Row>
      {isCellularTherapy && <>       
      <Row lg={4} md={4} sm={4}>
        <Col>
          <DateInput
            name={`dateOfCellularTherapy`}
            id={'dateOfCellularTherapy'}
            title={t(`${translatn}.dateOfCellularTherapy`)}
            placeholder={t(`common.datePlaceholder`)}
            disabled={false}
            readOnly={false}
            min={new Date(getDateOfBirth(dob)) ?? undefined}
            max={new Date()}
            required={isCellularTherapy}
            onChange={(date: Date) => {
              setAgeAtEvent(dob, date, `ageAtCellularTherapy`, setValue)
            }}
          />
        </Col>
        <Col>
          <NumberInput
            name={`ageAtCellularTherapy`}
            id={'ageAtCellularTherapy'}
            title={t(`${translatn}.ageAtCellularTherapy`)}
            placeholder={t(`${translatn}.ageAtCellularTherapyPlaceholder`)}
            disabled={false}
            readOnly={true}
            required={isCellularTherapy}
          />
        </Col></Row>
      <Row lg={4} md={4} sm={4}>
        <Col>
          <TextInput
            type="text"
            name={`stemCellTransplantType`}
            id={'stemCellTransplantType'}
            title={t(`${translatn}.stemCellTransplantType`)}
            placeholder={t(`${translatn}.stemCellTransplantTypePlaceholder`)}
            disabled={false}
            readOnly={false}
            required={isCellularTherapy}
          />
        </Col>
        <Col>
          <TextInput
            name={`sourceOfStemCells`}
            id={'sourceOfStemCells'}
            title={t(`${translatn}.sourceOfStemCells`)}
            placeholder={t(`${translatn}.sourceOfStemCellsPlaceholder`)}
            disabled={false}
            readOnly={false}
            required={isCellularTherapy}
          />
        </Col>
        <Col>
          <TextInput
            name={`transplantationCenter`}
            id={'transplantationCenter'}
            title={t(`${translatn}.transplantationCenter`)}
            placeholder={t(`${translatn}.transplantationCenterPlaceholder`)}
            disabled={false}
            readOnly={false}
            required={isCellularTherapy}
          />
        </Col>
      </Row>
      <Row lg={4} md={4} sm={4}>
        <Col>
          <TextInput
            name={`conditioningRegimen`}
            id={'conditioningRegimen'}
            title={t(`${translatn}.conditioningRegimen`)}
            placeholder={t(`${translatn}.conditioningRegimenPlaceholder`)}
            disabled={false}
            readOnly={false}
          />
        </Col>
        <Col>
          <TextInput
            name={`graftVersusHostDiseaseStatus`}
            id={'graftVersusHostDiseaseStatus'}
            title={t(`${translatn}.graftVersusHostDiseaseStatus`)}
            placeholder={t(`${translatn}.graftVersusHostDiseaseStatusPlaceholder`)}
            disabled={false}
            readOnly={false}
          />
        </Col>
        <Col>
          <TextInput
            name={`acuteGVHDGrade`}
            id={'acuteGVHDGrade'}
            title={t(`${translatn}.acuteGVHDGrade`)}
            placeholder={t(`${translatn}.acuteGVHDGradePlaceholder`)}
            disabled={false}
            readOnly={false}
          />
        </Col>
      </Row>
      <Row lg={4} md={4} sm={4}>
        <Col>
          <TextInput
            name={`chronicGVHDStatus`}
            id={'chronicGVHDStatus'}
            title={t(`${translatn}.chronicGVHDStatus`)}
            placeholder={t(`${translatn}.chronicGVHDStatusPlaceholder`)}
            disabled={false}
            readOnly={false}
          />
        </Col>
        <Col>
          <TextInput
            name={`engraftmentStatus`}
            id={'engraftmentStatus'}
            title={t(`${translatn}.engraftmentStatus`)}
            placeholder={t(`${translatn}.engraftmentStatusPlaceholder`)}
            disabled={false}
            readOnly={false}
          />
        </Col>
        <Col>
          <TextInput
            name={`donorType`}
            id={'donorType'}
            title={t(`${translatn}.donorType`)}
            placeholder={t(`${translatn}.donorTypePlaceholder`)}
            disabled={false}
            readOnly={false}
          />
        </Col>
      </Row>
      <Row lg={4} md={4} sm={4}>
        <Col>
          <TextInput
            name={`hlaMatching`}
            id={'hlaMatching'}
            title={t(`${translatn}.hlaMatching`)}
            placeholder={t(`${translatn}.hlaMatchingPlaceholder`)}
            disabled={false}
            readOnly={false}
          />
        </Col>
        <Col>
          <TextInput
            name={`responseToSCT`}
            id={'responseToSCT'}
            title={t(`${translatn}.responseToSCT`)}
            placeholder={t(`${translatn}.responseToSCTPlaceholder`)}
            disabled={false}
            readOnly={false}
          />
        </Col>
      </Row></>}
    </div>
  )
}
