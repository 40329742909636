import axios from "axios";
import { apiRoutes } from "../utils/routes";
import { AppUtil } from "../utils/app.utils";
import { Patient } from "../models/RegisterPatient";


export const registerPatient = async (requestBody: Patient) => {
  const response = await axios.post(
    apiRoutes.patient.add,
    {
      ...requestBody
    },
    AppUtil.getCommonAPIConfig()
  )

  return response
}

export const searchPatients = async (search: string, pageNumber: number, signal: AbortSignal) => {

  const response = await axios.post(
    apiRoutes.patient.search ,
    {page: pageNumber, searchString: search},
    {...AppUtil.getCommonAPIConfig(), signal}
  )

  return response
}

export const getPatientDetails = async (id: string) => {

  const response = await axios.post(
    apiRoutes.patient.get ,
    {id: Number(id)},
    AppUtil.getCommonAPIConfig()
  )

  return response
}

export const updatePatientDetails = async (requestBody: Patient) => {

  const response = await axios.put(
    apiRoutes.patient.update,
    {
      ...requestBody
    },
    AppUtil.getCommonAPIConfig()
  )

  return response
}

export const uploadConsentFileData = async(uploadURL: string, pdfBytesData: File) => {
  const contentType = 'application/*'
  const fileBlob = new Blob([pdfBytesData],  {type: contentType})

  const response = await axios.put(
    uploadURL,
    fileBlob,
    {
      headers: {
        "Content-Type": contentType,
      }
    }
  )

  return response
}

export const getPateintConsentUploadURLs = async(patientId: string, consentFile: string) => {
  const response = await axios.post(apiRoutes.patient.uploadConsent, 
    {id: patientId, consentFile: consentFile}, 
    AppUtil.getCommonAPIConfig()
  )
  return response
}
export const getCountriesList = async ()=>
{
  const response = await axios.get(apiRoutes.patient.countries)
  return response

}
export const getStatesList = async(country:string) => {
  const response = await axios.post(apiRoutes.patient.states, 
    {country : country}, 
)
  return response
}
export const getCityList = async(country:string,state:string) => {
  const response = await axios.post(apiRoutes.patient.cities, 
    {country : country, state:state}, 
)
  return response
}
