import { z } from 'zod'
import {
  schemaHelpers,
  getTranslatedStr,
  FFPEFields,
  hAndEFields,
  bloodFields,
  familyHistoryFields,
  organTransplantFields,
  onChemotherapyFields,
  otherDrugsPrescribedFields,
  cellularTherapyFields,
  dateOfDeathFields,
  dateOfLastContactFields,
  lastChemotherapyDateFields,
  testedForInfectiousDiseasesFields,
  infectiousDiseaseTestDateFields,
  dateOfCellularTherapy,
  radiationTherapyFields,
  radiationTherapyStartStopFields,
  progressionSourceBeyond4MonthsDate,
  surgeryBiopsyDoneFields,
  progressionRecurrenceFields,
  progressionRecurrenceType,
  histopathologyReportDoneFields,
  bloodTransfusionDoneFields,
  lastTransfusionDateFields,
  boneMarrowTransplantDoneFields,
  lastTransplantDateFields,
  siteAtOrganTransplantFields,
} from './AddSampleInfoHelpers'
import {MASTER_DATA_ID as MDI } from '../../../utils/constants'


const {
  requiredSelect,
  requiredRadio,
  number,
  multiselect,
  requiredEmail,
  requiredNumber,
  requiredMonth,
  requiredTemperatureNumber,
  requiredStr,
  requiredPhone,
  string,
  nullableDate,
  nullableNumber,
  nullableStr,
  requiredDate,
  nullableSelect,
  requiredCheckbox,
  requiredNum,
  dynamicFields,
  nullableRadio,
  strWithMinMaxLen,
  strWithMinMaxLenForMedication,
  requiredReplicateNumber,
  requiredMultiselect
} = schemaHelpers

//const MAX_FILE_SIZE = 5000000
//const ACCEPTED_IMAGE_TYPES = ['image/jpeg', 'image/jpg', 'image/png', 'image/webp']

const FamilyHistorySchema = z.object({
  relationToPatient: requiredStr,
  relationToPatientOther: nullableStr || strWithMinMaxLen(0, 100, 'maxTextLength'),
  familyMemberCancerSite: requiredStr,
  familyMemberCancerSiteOther: nullableStr || strWithMinMaxLen(0, 100, 'maxTextLength'),
  familyMemberAgeAtDiagnosis: requiredStr
})

export const StepOneSchema = z.object({
  doctorsName: requiredStr,
  doctorsId: string,
  doctorContactNumber: requiredPhone,
  doctorEmail: requiredEmail,
  siteName: string,

  dateOfPhysical: requiredDate,
  ageAtPhysical: number,
  weight: requiredNumber,
  height: requiredNumber,
  bmi: number,

  smokingStatus: requiredSelect,
  alcoholUse: requiredSelect,
  multiplePremalignantOrComorbidIllnesses: requiredMultiselect,
  multiplePremalignantOrComorbidIllnessesCapturedHereDetails: dynamicFields,
  organTransplantDone: requiredRadio,
  dateOfOrganTransplant: nullableDate,
  ageAtOrganTransplant: number,
  siteAtOrganTransplant: nullableStr,
  siteAtOrganTransplantOther: strWithMinMaxLen(0, 100, 'maxTextLength'),

  familyHistoryOfCancer: requiredRadio,
  familyHistoryDetails: z.array(FamilyHistorySchema).optional(),
}).superRefine((values: any, ctx) => {

  organTransplantFields.forEach((field: string) => {
    if (values.organTransplantDone === MDI.ORGAN_TRANSPLANTATION_DONE_OPTION_YES && values[field] == null) {
      ctx.addIssue({
        message: getTranslatedStr('required'),
        code: z.ZodIssueCode.custom,
        path: [field],
      })
    }
  })

  siteAtOrganTransplantFields.forEach((field: string) => {
    if (values.organTransplantDone === MDI.ORGAN_TRANSPLANTATION_DONE_OPTION_YES && !values[field]) {
      ctx.addIssue({
        message: getTranslatedStr('required'),
        code: z.ZodIssueCode.custom,
        path: [field],
      })
    }
  })

  familyHistoryFields.forEach((field: string) => {
    if (values.familyHistoryOfCancer == MDI.FAMILY_HISTORY_OF_CANCER_OPTION_YES && values[field].length === 0) {
      ctx.addIssue({
        message: getTranslatedStr('required'),
        code: z.ZodIssueCode.custom,
        path: [field],
      })
    }
  })
})

export const StepTwoSchema = z.object({
  dateOfFirstContact: requiredDate,
  ageAtFirstContact: number,
  primarySiteOfDiagnosis: requiredStr,
  primarySiteOfDiagnosisOther: strWithMinMaxLen(0, 100, 'maxTextLength'),
  laterality: requiredStr,
  histology: requiredStr,
  physicianComment: strWithMinMaxLen(0, 100, 'maxTextLength'),
  otherStagingSystemAndGradeData: requiredSelect,
  otherStagingSystemAndGradeDataOther: strWithMinMaxLen(0, 100, 'maxTextLength'),
  otherStagingGradeOrScoreDetails:nullableStr,
  ageAtDiagnosis: number,
  dateOfDiagnosis: requiredDate,
  clinicalT: requiredSelect,
  clinicalN: requiredSelect,
  clinicalM: requiredSelect,
  clinicalGroupStage: requiredSelect,
  clinicalGroupStageOther: strWithMinMaxLen(0, 100, 'maxTextLength'),
  pathologicalT: requiredSelect,
  pathologicalN: requiredSelect,
  pathologicalM: requiredSelect,
  pathologicalGroupStage: requiredSelect,
  pathologicalGroupStageOther: strWithMinMaxLen(0, 100, 'maxTextLength'),
  performanceStatusAtDiagnosis: requiredStr,
  typeOfPerformanceStatusScaleUsedAtDiagnosis: requiredStr,
  ageAtDiagnosticPerformanceStatus: number,
  dateOfDiagnosticPerformanceStatus: requiredDate,
  treatmentStatus: requiredStr,

  originalPrimarySiteOfDiagnosis: requiredStr,
  siteOfMetastasis: requiredStr,
  siteOfMetastasisOther: strWithMinMaxLen(0, 100, 'maxTextLength'),
  ageAtMetastasis: nullableNumber,
  dateOfMetastasis: nullableDate,

  imagingTypeOfScan: multiselect,
  imagingModalityMethod: nullableStr,
  imagingTypeOfScanDetails: dynamicFields,
  ageAtImagingScan: nullableNumber,
  dateOfImagingScan: nullableDate,
  imagingSiteForScan: nullableStr,
  imagingSiteForScanOther: strWithMinMaxLen(0, 100, 'maxTextLength'),
  imagingScanSiteDetails:nullableStr,
  measurableDiseasePresent: nullableStr,
  newLesions: nullableStr,
  site: nullableStr,
  siteOther: strWithMinMaxLen(0, 100, 'maxTextLength'),
}).refine(data => {
  if (data.siteOfMetastasis === MDI.METASTASIS_SITE_NOT_APPLICABLE) {
    return true; 
  } 
  return !!data.dateOfMetastasis; 
}, {
  message: getTranslatedStr('required'), 
  path: ['dateOfMetastasis'], 
})
export const StepThreeSchema = z.object({
  specifyOtherTumor: strWithMinMaxLen(0, 100, 'maxTextLength'),
  dateOfResults: nullableDate,
  ageAtResults: nullableNumber,
  markerType: multiselect,
  markerTypeDetails: dynamicFields,

  dateOfLabResults: nullableDate,
  labAgeAtResults: nullableNumber,

  labHighLowRef: nullableStr,
  labUnitOfMeasure: nullableStr,

  cytogeneticsTestingPerformance: requiredRadio,
  dateOfProcedureForCytogeneticsAbnormalities: nullableDate,
  ageAtProcedureForCytogeneticsAbnormalities: nullableNumber,
  testTypeDeletionsTranslocations: strWithMinMaxLen(0, 100, 'maxTextLength'),

  isBiomarkerBeyond4Months: z.union([z.string(), z.boolean()]).optional(),
  biomarkerBeyond4MonthsDate: nullableDate,
  isLabTypeBeyond4Months: z.union([z.string(), z.boolean()]).optional(),
  labTypeBeyond4MonthsDate: nullableDate
}).refine(data => {
  if (data.cytogeneticsTestingPerformance == MDI.CYTOGENETICS_TESTING_PERFORMANCE_OPTION_YES) {
    return data.dateOfProcedureForCytogeneticsAbnormalities != null; 
  } 
  return true; 
}, {
  message: getTranslatedStr('required'), 
  path: [ 'dateOfProcedureForCytogeneticsAbnormalities'], 
}).refine(data => {
  if (data.cytogeneticsTestingPerformance == MDI.CYTOGENETICS_TESTING_PERFORMANCE_OPTION_YES) {
    return data.testTypeDeletionsTranslocations != ''; 
  } 
  return true; 
}, {
  message: getTranslatedStr('required'), 
  path: [ 'testTypeDeletionsTranslocations'], 
}).refine(data => {
  if (data.isBiomarkerBeyond4Months === 'true' || data.isBiomarkerBeyond4Months === true) {
    return !!data.biomarkerBeyond4MonthsDate;
  }
  return true;
}, {
  message: "Date is required when 'Beyond 4 months' is checked",
  path: ['biomarkerBeyond4MonthsDate'], // specify which field to attach the error message to
}).refine(data => {
  if (data.isLabTypeBeyond4Months === 'true' || data.isLabTypeBeyond4Months === true) {
    return !!data.labTypeBeyond4MonthsDate;
  }
  return true;
}, {
  message: "Date is required when 'Beyond 4 months' is checked",
  path: ['labTypeBeyond4MonthsDate'], // specify which field to attach the error message to
});

export const StepFourSchema = z.object({
  typeOfTumorSequencing: multiselect,
  typeOfTumorSequencingDetails: dynamicFields,
  otherTumorSequencing: strWithMinMaxLen(0, 100, 'maxTextLength'),
  ageAtTumorSequencing: nullableNumber,
  dateOfTumorSequencing: nullableDate,

  surgeryBiopsyDone:requiredRadio,
  originalPrimarySiteOfDiagnosisForSurgeryPerformed: nullableStr,
  originalPrimarySiteOfDiagnosisForSurgeryPerformedOther: nullableStr,
  locationForSurgeryBiopsy: nullableStr,
  locationForSurgeryBiopsyOther:strWithMinMaxLen(0, 100, 'maxTextLength'),
  surgeryType: nullableSelect,
  surgeryTypeDetails: strWithMinMaxLen(0, 100, 'maxTextLength'),
  biopsyType: nullableSelect,
  biopsyTypeDetails: strWithMinMaxLen(0, 100, 'maxTextLength'),
  anesthesiaType: nullableSelect,
  anethesiaTypeDetails: strWithMinMaxLen(0, 100, 'maxTextLength'),
  surgicalApproach: nullableSelect,
  surgicalApproachDetails: strWithMinMaxLen(0, 100, 'maxTextLength'),
  dateOfSurgeryBiopsy: nullableDate,
  ageAtSurgeryBiopsy: nullableNumber,

  typeOfCancerOrDiagnosis: strWithMinMaxLen(1, 100, 'maxTextLength'),
  ffpeBlocksAvailable: nullableRadio,
  histopathologyReportDone:requiredRadio,
  histopathologyReportDate: nullableDate,
  onChemotherapy: requiredRadio,
  chemotherapyDrugName: nullableStr,
  lastChemotherapyDate: nullableDate,
  chemotherapyCycles: nullableNumber,
  otherDrugsPrescribed: requiredRadio,
  otherDrugDuration: nullableStr,
  otherDrugName: nullableStr,
  bloodTransfusionDone:requiredRadio,
  bloodTransfusionHistory: nullableStr,
  lastTransfusionDate: nullableDate,
  boneMarrowTransplantDone:requiredRadio,
  boneMarrowTransplantHistory: nullableStr,
  lastTransplantDate: nullableDate,
  testedForInfectiousDiseases: requiredRadio,
  infectiousDiseaseTestDate: nullableDate,
  infectiousDiseaseResult: nullableStr,
  infectiousDiseaseNotesOrRemarks: nullableStr,
  diagnosis: strWithMinMaxLen(1, 100, 'maxTextLength'),
}).superRefine((values: any, ctx) => { 

  surgeryBiopsyDoneFields.forEach((field: string) => {
    if (values.surgeryBiopsyDone === MDI.SURGERY_BIOPSY_DONE_OPTION_YES && !values[field]) {
      ctx.addIssue({
        message: getTranslatedStr('required'),
        code: z.ZodIssueCode.custom,
        path: [field],
      })
    }
  }) 

  histopathologyReportDoneFields.forEach((field: string) => {
    if (values.histopathologyReportDone === MDI.HISTOPATHOLOGY_REPORT_OPTION_YES && values[field]===null) {
      ctx.addIssue({
        message: getTranslatedStr('required'),
        code: z.ZodIssueCode.custom,
        path: [field],
      })
    }
  }) 

  onChemotherapyFields.forEach((field: string) => {
    if (values.onChemotherapy === MDI.WHETHER_ON_CHEMOTHERAPY_OPTION_YES && !values[field]) {
      ctx.addIssue({
        message: getTranslatedStr('required'),
        code: z.ZodIssueCode.custom,
        path: [field],
      })
    }
  }) 

  lastChemotherapyDateFields.forEach((field: string) => {
    if (values.onChemotherapy === MDI.WHETHER_ON_CHEMOTHERAPY_OPTION_YES && values[field] === null) {
      ctx.addIssue({
        message: getTranslatedStr('required'),
        code: z.ZodIssueCode.custom,
        path: [field],
      })
    }
  }) 

  otherDrugsPrescribedFields.forEach((field: string) => {
    if (values.otherDrugsPrescribed === MDI.OTHER_DRUGS_PRESCRIBED_OPTION_YES && !values[field]) {
      ctx.addIssue({
        message: getTranslatedStr('required'),
        code: z.ZodIssueCode.custom,
        path: [field],
      })
    }else if (values.otherDrugsPrescribed === MDI.OTHER_DRUGS_PRESCRIBED_OPTION_YES && values[field] && values[field].length > 100) {
      ctx.addIssue({
          message: getTranslatedStr('maxTextLength'),
          code: z.ZodIssueCode.custom,
          path: [field],
      });
  }
  })

  bloodTransfusionDoneFields.forEach((field: string) => {
    if (values.bloodTransfusionDone === MDI.BLOOD_TRANSFUSION_OPTION_YES && !values[field]) {
      ctx.addIssue({
        message: getTranslatedStr('required'),
        code: z.ZodIssueCode.custom,
        path: [field],
      })
    }else if (values.bloodTransfusionDone === MDI.BLOOD_TRANSFUSION_OPTION_YES && values[field] && values[field].length > 100) {
      ctx.addIssue({
          message: getTranslatedStr('maxTextLength'),
          code: z.ZodIssueCode.custom,
          path: [field],
      });
  }
  })

  lastTransfusionDateFields.forEach((field: string) => {
    if (values.bloodTransfusionDone === MDI.BLOOD_TRANSFUSION_OPTION_YES && values[field] === null) {
      ctx.addIssue({
        message: getTranslatedStr('required'),
        code: z.ZodIssueCode.custom,
        path: [field],
      })
    }
  }) 

  boneMarrowTransplantDoneFields.forEach((field: string) => {
    if (values.boneMarrowTransplantDone === MDI.BONE_MARROW_TRANSPLANT_OPTION_YES && !values[field]) {
      ctx.addIssue({
        message: getTranslatedStr('required'),
        code: z.ZodIssueCode.custom,
        path: [field],
      })
    }else if (values.boneMarrowTransplantDone === MDI.BONE_MARROW_TRANSPLANT_OPTION_YES && values[field] && values[field].length > 100) {
      ctx.addIssue({
          message: getTranslatedStr('maxTextLength'),
          code: z.ZodIssueCode.custom,
          path: [field],
      });
  }
  })

  lastTransplantDateFields.forEach((field: string) => {
    if (values.boneMarrowTransplantDone === MDI.BONE_MARROW_TRANSPLANT_OPTION_YES && values[field] === null) {
      ctx.addIssue({
        message: getTranslatedStr('required'),
        code: z.ZodIssueCode.custom,
        path: [field],
      })
    }
  }) 

  testedForInfectiousDiseasesFields.forEach((field: string) => {
    if (values.testedForInfectiousDiseases === MDI.RECENTLY_TESTED_INFECTIOUS_DISEASE_OPTION_YES && !values[field]) {
      ctx.addIssue({
        message: getTranslatedStr('required'),
        code: z.ZodIssueCode.custom,
        path: [field],
      })
    }else if (values.testedForInfectiousDiseases === MDI.RECENTLY_TESTED_INFECTIOUS_DISEASE_OPTION_YES && values[field] && values[field].length > 100) {
      ctx.addIssue({
          message: getTranslatedStr('maxTextLength'),
          code: z.ZodIssueCode.custom,
          path: [field],
      });
  }
  })

  infectiousDiseaseTestDateFields.forEach((field: string) => {
    if (values.testedForInfectiousDiseases === MDI.RECENTLY_TESTED_INFECTIOUS_DISEASE_OPTION_YES && values[field] === null) {
      ctx.addIssue({
        message: getTranslatedStr('required'),
        code: z.ZodIssueCode.custom,
        path: [field],
      })
    }
  }) 

 })

const MedicationSchema = z.object({
  medicationName: strWithMinMaxLenForMedication(1, 100, 'maxTextLength'),
  medicationDosage: strWithMinMaxLenForMedication(1, 100, 'maxTextLength'),
  medicationFrequency: strWithMinMaxLenForMedication(1, 100, 'maxTextLength'),
  medicationStartDate: requiredDate,
  medicationStopDate: requiredDate,
  medicationPrescribingPhysician: strWithMinMaxLenForMedication(1, 100, 'maxTextLength'),
  medicationBrandOrManufacturer: strWithMinMaxLenForMedication(1, 100, 'maxTextLength'),
  medicationAdverseEffects: strWithMinMaxLenForMedication(1, 100, 'maxTextLength'),
  medicationClass: strWithMinMaxLenForMedication(1, 100, 'maxTextLength'),
  medicationDisease: strWithMinMaxLenForMedication(1, 100, 'maxTextLength'),
  medicationRegimen: strWithMinMaxLenForMedication(1, 100, 'maxTextLength'),
  medicationMissedDoses: strWithMinMaxLenForMedication(1, 100, 'maxTextLength'),
  
})

export const StepFiveSchema = z.object({
  medication: z.array(MedicationSchema).min(1),
  
  cellularTherapy: requiredRadio,
  dateOfCellularTherapy: nullableDate,
  ageAtCellularTherapy: nullableNumber,
  stemCellTransplantType: nullableStr,
  sourceOfStemCells: nullableStr,
  transplantationCenter: nullableStr,
  conditioningRegimen: strWithMinMaxLen(0, 100, 'maxTextLength'),
  graftVersusHostDiseaseStatus: strWithMinMaxLen(0, 100, 'maxTextLength'),
  acuteGVHDGrade: strWithMinMaxLen(0, 100, 'maxTextLength'),
  chronicGVHDStatus: strWithMinMaxLen(0, 100, 'maxTextLength'),
  engraftmentStatus: strWithMinMaxLen(0, 100, 'maxTextLength'),
  donorType: strWithMinMaxLen(0, 100, 'maxTextLength'),
  hlaMatching: strWithMinMaxLen(0, 100, 'maxTextLength'),
  responseToSCT: strWithMinMaxLen(0, 100, 'maxTextLength'),

  radiationTherapy: requiredRadio,
  originalSiteOfDiagnosisForRadiationTherapy: nullableStr,
  radiationDisease: nullableStr,
  radiationRegimen: nullableStr,
  radiationModality: nullableStr,
  radiationDose: nullableStr,
  radiationStart: nullableDate,
  radiationStop: nullableDate,

  isProgressionSourceBeyond4Months: z.union([z.string(), z.boolean()]).optional(),
  progressionSourceBeyond4MonthsDate: nullableDate,
  outcomesOfTreatmentCategory : requiredMultiselect,
  progressionRecurrenceDone: requiredRadio,
  dateOfProgressionRecurrence: nullableDate,
  ageAtProgressionRecurrence: nullableNumber,
  typeOfRecurrence: nullableStr,
  recurrencePerformanceStatus: requiredStr,
  typeOfStatusScaleUsedRECISTChemotherapy: requiredStr,
  typeOfStatusScaleUsedIRRECISTImmunotherapy: requiredStr,
  dateOfPerformanceStatus: requiredDate,
  ageAtPerformanceStatus: number,
  responseByDiseaseGroup: requiredStr,
  responseByBaselineDiseaseStatus: requiredStr,
  responseByResponseAssessment: requiredStr,
  responseByDateOfAssessment: requiredDate,
  responseByDurationInMonths: requiredMonth,
  responseByDurationInDays: requiredNumber,
  responseByPhysicianOrInvestigatorAssessment: strWithMinMaxLen(1, 100, 'maxTextLength'),
  recistScoreDetails: requiredStr,
  recistScore: strWithMinMaxLen(1, 100, 'maxTextLength'),
  dateOfDiseaseStatus: requiredDate,
  ageAtDiseaseStatus: nullableNumber,

  vitalWeight: requiredNumber,
  vitalHeight: requiredNumber,
  vitalBMI: number,
  vitalTemperature: requiredTemperatureNumber,  
  dateOfLastContactActivity: requiredDate,  
  ageAtLastContactActivity: requiredNumber,
  dateOfDeath: nullableDate,
  ageAtDeath: nullableNumber,
  causeOfDeathContributingFactors: nullableStr,
  causeOfDeathAutopsyFindings: nullableStr,
  causeOfDeathMedicalHistory: nullableStr,
  causeOfDeathDocumentationOfTerminalEvents: nullableStr,

}).superRefine((values: any, ctx) => {

  dateOfCellularTherapy.forEach((field: string) => {
    if (values.cellularTherapy === MDI.CELLULAR_THERAPY_OPTION_YES && values[field] === null) {
      ctx.addIssue({
        message: getTranslatedStr('required'),
        code: z.ZodIssueCode.custom,
        path: [field],
      })
    }
  }) 

  cellularTherapyFields.forEach((field: string) => {
    if (values.cellularTherapy === MDI.CELLULAR_THERAPY_OPTION_YES && !values[field]) {
      ctx.addIssue({
        message: getTranslatedStr('required'),
        code: z.ZodIssueCode.custom,
        path: [field],
      })
    }else if (values.cellularTherapy === MDI.CELLULAR_THERAPY_OPTION_YES && values[field] && values[field].length > 100) {
      ctx.addIssue({
          message: getTranslatedStr('maxTextLength'),
          code: z.ZodIssueCode.custom,
          path: [field],
      });
  }
  })

  radiationTherapyFields.forEach((field: string) => {
    if (values.radiationTherapy === MDI.RADIATION_THERAPY_OPTION_YES && !values[field]) {
      ctx.addIssue({
        message: getTranslatedStr('required'),
        code: z.ZodIssueCode.custom,
        path: [field],
      })
    }else if (values.radiationTherapy === MDI.RADIATION_THERAPY_OPTION_YES && values[field] && values[field].length > 100) {
      ctx.addIssue({
          message: getTranslatedStr('maxTextLength'),
          code: z.ZodIssueCode.custom,
          path: [field],
      });
  }
  })

  radiationTherapyStartStopFields.forEach((field: string) => {
    if (values.radiationTherapy === MDI.RADIATION_THERAPY_OPTION_YES && values[field] === null) {
      ctx.addIssue({
        message: getTranslatedStr('required'),
        code: z.ZodIssueCode.custom,
        path: [field],
      })
    }
  }) 

  progressionSourceBeyond4MonthsDate.forEach((field: string) => {
    if (values.isProgressionSourceBeyond4Months === 'true' || values.isProgressionSourceBeyond4Months === true) {
      if(values[field] == null || values[field] == '') {
      ctx.addIssue({
        message: "Date is required when 'Beyond 4 months' is checked",
        code: z.ZodIssueCode.custom,
        path: [field],
      })
    } 
    }
  }) 

  progressionRecurrenceFields.forEach((field: string) => {
    if (values.progressionRecurrenceDone === MDI.PROGRESSION_RECURRENCE_OPTION_YES && !values[field]) {
      ctx.addIssue({
        message: getTranslatedStr('required'),
        code: z.ZodIssueCode.custom,
        path: [field],
      })
    }
  })

  progressionRecurrenceType.forEach((field: string) => {
    if (values.progressionRecurrenceDone === MDI.PROGRESSION_RECURRENCE_OPTION_YES && !values[field]) {
      ctx.addIssue({
        message: getTranslatedStr('required'),
        code: z.ZodIssueCode.custom,
        path: [field],
      })
    }
  })    

  dateOfDeathFields.forEach((field: string) => {
    if (values.dateOfDeath !== null && !values[field]) {
      ctx.addIssue({
        message: getTranslatedStr('required'),
        code: z.ZodIssueCode.custom,
        path: [field],
        
      })
    } else if (values.dateOfDeath !== null && values[field] && values[field].length > 100) {
      ctx.addIssue({
          message: getTranslatedStr('maxTextLength'),
          code: z.ZodIssueCode.custom,
          path: [field],
      });
  }
  })  
})

const TestSchema = z.object({
  primaryTestTag: requiredSelect,
  testId: requiredSelect,
  tat: requiredNum,
  ppn: nullableStr,
})

export const StepSixSchema = z
  .object({
    sampleCollectionDate: requiredDate,
    sampleCollectedBy: requiredStr,
    employeeId: requiredNumber,
    typeOfSample: requiredStr,

    blockNumber: nullableNumber,
    fixativeUsed: nullableStr,
    fixationDuration: nullableStr,
    coldIschemiaTime: nullableStr,

    hAndeStaining: nullableRadio,
    tumorTissue: nullableNumber,
    necrosisTissues: nullableNumber,

    tubeType: nullableStr,

    replicateNumber:requiredReplicateNumber, 
    
    qcCheck: requiredCheckbox,
    pathogenFreeReport: requiredCheckbox,

    testType: z.array(TestSchema).min(1),
  })
  .superRefine((values: any, ctx) => {
    FFPEFields.forEach((field: string) => {
      if (values.typeOfSample === MDI.SAMPLE_TYPE_FFPE && !values[field]) {
        ctx.addIssue({
          message: getTranslatedStr('required'),
          code: z.ZodIssueCode.custom,
          path: [field],
        })
      }
    })

    hAndEFields.forEach((field: string) => {
      if (values.hAndeStaining === MDI.H_E_OPTION_YES && !values[field]) {
        ctx.addIssue({
          message: getTranslatedStr('required'),
          code: z.ZodIssueCode.custom,
          path: [field],
        })
      }
    })

    bloodFields.forEach((field: string) => {
      if (MDI.SAMPLE_TYPE_BLOOD.includes(values.typeOfSample) && !values[field]) {
        ctx.addIssue({
          message: getTranslatedStr('required'),
          code: z.ZodIssueCode.custom,
          path: [field],
        })
      }
    })
  })

export const StepSevenSchema = z.object({})
