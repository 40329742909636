import Cookies from 'js-cookie'
import { APP_DATE_FORMAT, APP_DATE_FORMAT_WITH_TIMESTAMP, PP_API_TIMEOUT, PP_API_URL, PP_AUTH_COOKIE_NAME, PP_REFRESH_COOKIE_NAME } from './constants'
import { AxiosError } from 'axios'
import moment from 'moment'
import { MasterDataItem, ValueItem } from '../models/MasterData'
import { FieldValues, UseFormResetField } from 'react-hook-form'
import _ from 'lodash'
import { Details } from '../models/Sample'

function setCookie(cookieName: string, cookieValue: string, expiryTime: Date) {
  let cookieDomain: string | string[] = window.location.hostname

  // TODO - revert domain code removed(domain: cookieDomain) once solution found
  Cookies.set(cookieName, cookieValue, {
    expires: expiryTime,
    domain: cookieDomain
  })
  return cookieValue
}

function getCookie(cookieName: string) {
  const authCookie = Cookies.get(cookieName)
  
  if (authCookie && authCookie !== undefined && authCookie !== '' && authCookie !== `{{${cookieName}}}`) {
    return authCookie
  }

  return undefined
}

export const AppUtil = {
  setAuthCookie: (authCookie: string, refreshCookie: string, expiryTime: Date, refreshExpiryTime: Date) => {
    if (authCookie && authCookie !== undefined && authCookie !== '') {
      setCookie(PP_AUTH_COOKIE_NAME, authCookie, expiryTime)
      setCookie(PP_REFRESH_COOKIE_NAME, refreshCookie, refreshExpiryTime)
      return authCookie
    }
    return undefined
  },

  getAuthCookie: (): string | undefined => {
    return getCookie(PP_AUTH_COOKIE_NAME)
  },

  getRefreshCookie: (): string | undefined => {
    return getCookie(PP_REFRESH_COOKIE_NAME)
  },

  refreshAuthCookie: (
    authCookie: string,
    refreshCookie: string,
    expiryTime: Date,
    refreshExpiryTime: Date,
  ): string | undefined => {
    AppUtil.removeAuthCookie()
    return AppUtil.setAuthCookie(authCookie, refreshCookie, expiryTime, refreshExpiryTime)
  },

  removeAuthCookie: (): void => {
    let hostname: string | string[] = window.location.hostname
    let cookieDomain = hostname
    Cookies.remove(PP_AUTH_COOKIE_NAME, {
      domain: cookieDomain,
    })
    Cookies.remove(PP_REFRESH_COOKIE_NAME, {
      domain: cookieDomain,
    })
    Object.keys(Cookies.get()).forEach((cookieName) => {
      const cookieAttributes = {
        domain: cookieDomain,
      }
      Cookies.remove(cookieName, cookieAttributes)
    })
  },

  logError: (error: AxiosError<{ message: string }>) => {
    console.error('Error: ', error?.response?.data?.message)
  },

  formatDateForPayload: (date: Date | string | null) => {
    if (!date) return null
    return moment(date).format(APP_DATE_FORMAT)
  },

  getCommonAPIConfig: () => {
    return {
      baseURL: PP_API_URL,
      timeout: Number(PP_API_TIMEOUT),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${AppUtil.getAuthCookie()}`,
      },
    }
  },

  formatDateForForm: (date: Date | string | null) => {
    if (!date) return null
    return moment(date, APP_DATE_FORMAT).toDate()
  },

  formatDateToString: (date: Date | string | null) => {
    if (!date) return null
    if (typeof date === 'string' && date.length === 10) return date
    return moment(date).format(APP_DATE_FORMAT)
  },

  formatDateToStringWithTimestamp: (date: Date | string | null) => {
    if (!date) return null
    if (typeof date === 'string' && date.length === 10) return date
    return moment(date).format(APP_DATE_FORMAT_WITH_TIMESTAMP)
  },

  convertStrIntoArr: (dataVal: string[] | string | null) => {
    if (Array.isArray(dataVal)) {
      return dataVal
    }
    return dataVal ? dataVal.split(',') : []
  },

  convertBooleanToStr: (dataVal: boolean | string | null) => {
    if (dataVal == null) return null
    if (dataVal) return 'Yes'
    return 'No'
  },

  getIdByKeyName: (key: string, masterData: MasterDataItem[]) => {
    return masterData?.find((item) => item.key == key)?.id ?? null
  },

  getOptions: (key: string, masterData: MasterDataItem[]) => {
    return masterData?.find((item) => item.key == key)?.values ?? []
  },

  getLabel: (key: string, data: MasterDataItem[], value: string | number | undefined | null) => {
    return AppUtil.getOption(key, data, value)?.label
  },

  getHelperText: (key: string, data: MasterDataItem[], value: string | number | undefined | null) => {
    return AppUtil.getOption(key, data, value)?.helperText
  },

  getOption: (key: string, data: MasterDataItem[], value: string | number | undefined | null) => {
    return (
      AppUtil.getOptions(key, data)?.find((option) => option.value?.toString() == value?.toString()) || {
        label: '',
        value: '',
        textBox: false,
        helperText: '',
      }
    )
  },

  sortOptionsDesc: (values: ValueItem[]) => {
    return values.sort((a, b) => Number(b.value) - Number(a.value))
  },

  formatOptionsIntoValueLabel: (values: string[]) => {
    return values?.map((value) => {
      return { value, label: value }
    })
  },

  resetOtherField: (
    targetValue: string,
    otherId: number | string,
    resetField: UseFormResetField<FieldValues>,
    otherKey: string,
  ) => {
    if (targetValue !== otherId) {
      resetField(otherKey, { defaultValue: null })
    }
  },

  getBMI: (weight: number, height: number) => {
    if (!weight || !height) {
      return null
    }
    const bmi = weight / Math.pow(height / 100, 2)
    return Math.round((bmi + Number.EPSILON) * 100) / 100
  },

  nameStr: (nameStrValue: string | null | undefined) => {
    if (nameStrValue) {
      nameStrValue = ' ' + nameStrValue
    }
    return nameStrValue
  },

  combineNamesString: (name: any, firstName: any, middleName: any, lastName: any) => {
    return (name ?? firstName ?? '') + (AppUtil.nameStr(middleName) ?? '') + (AppUtil.nameStr(lastName) ?? '')
  },

  convertArrIntoStr: (dataVal: string[] | null) => {
    if (_.isString(dataVal)) {
      return dataVal
    }
    return dataVal ? dataVal.join(', ') : ''
  },

  getMultiselectLabels: (values: string[], key: string, data: MasterDataItem[]) => {
    const newData = values.map((value) => {
      return AppUtil.getLabel(key, data, value) || ''
    })
    return AppUtil.convertArrIntoStr(newData)
  },

  createObject: (
    value: object,
    masterDataKey: string,
    data: MasterDataItem[],
    arr: Details[],
    defaultIndex: number,
  ) => {
    let index = defaultIndex
    Object.entries(value).forEach(([key, value]) => {
      const keyText = key.slice(4)
      const labelText = AppUtil.getLabel(masterDataKey, data, keyText) || ''
      const helperText = AppUtil.getHelperText(masterDataKey, data, keyText)

      const exists = arr.some((obj) => obj.label === labelText)
      if (!exists) {
        const updatedArr = arr.splice(index, 0, {
          label: labelText,
          value,
          show: true,
          helperText: helperText || '',
        })

        index++
        return updatedArr
      }
    })
  },

  getFormattedValueWithOther: (
    masterDataKey: string,
    masterData: MasterDataItem[],
    value?: string | null,
    otherValue?: string | null,
  ) => {
    const mainValue: string = AppUtil.getLabel(masterDataKey, masterData, value)
    return otherValue ? `${mainValue} - ${otherValue}` : `${mainValue}`
  },  

  getMultiSelectLabelsWithOther: (
    masterDataKey: string,
    masterData: MasterDataItem[],
    values: string[],
    otherValue: {},
  ) => {
    const newData = values.map((value) => {
      const mainValue = AppUtil.getLabel(masterDataKey, masterData, value);
    
      if (Object.keys(otherValue).length === 0) {
        return mainValue || '';
      }
    
      const result = Object.entries(otherValue).map(([key, val]) => {
        const keyText = key.slice(4);
    
        if (keyText === value?.toString()) {
          return val ? `${mainValue} - ${val}` : `${mainValue}`;
        }
        
        return '';
      }).filter(Boolean);     
      return result.length > 0 ? result.join(', ') : mainValue || '';
    });
    
    return AppUtil.convertArrIntoStr(newData);
  },

  fourMonthsFromNow: () => {
    const date = new Date();
    date.setMonth(date.getMonth() - 4);
    return date;
  }
}
