import React from 'react'
import { useTranslation } from 'react-i18next'
import Form from 'react-bootstrap/Form'
import { access, renderRequiredFieldLabel } from './InputComponentHelpers'
import { useFormContext } from 'react-hook-form'
import {  InputGroup } from 'react-bootstrap'

export const NumberInputWithAddOn = function (props: any) {
  const { name, id, title, placeholder, disabled, readOnly, required, onChange, addOn, helperText, ...otherProps } = props
  const { t } = useTranslation()
  const formMethods = useFormContext()
  const {
    register,
    formState: { errors },
  } = formMethods
  const error = access(name, errors)
  const numberInputInvalidChars = ['-', '+', 'e']

  return (
    <>
      <Form.Label htmlFor={id}>{required ? renderRequiredFieldLabel(title) : title}</Form.Label>
      <InputGroup>
        <Form.Control
          disabled={disabled}
          readOnly={readOnly}
          id={id}
          type="number"
          placeholder={placeholder}
          {...register(name)}
          isInvalid={error}
          onChange={onChange}
          aria-describedby={`${name}Helper`}
          onKeyDown={(e) => {
            if (numberInputInvalidChars.includes(e.key.toLowerCase())) {
              e.preventDefault()
            }
          }}
          {...otherProps}
        />
        {addOn}
      </InputGroup>
      <Form.Text id={`${name}Helper`} muted>
      {helperText}

      </Form.Text>
      <Form.Control.Feedback type="invalid">{t(error?.message)}</Form.Control.Feedback>
    </>
  )
}
