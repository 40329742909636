import React from 'react'
import { useTranslation } from 'react-i18next'
import Form from 'react-bootstrap/Form'
import { Controller, useFormContext } from 'react-hook-form'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

import './styles/DateInput.scss'
import { access, renderRequiredFieldLabel } from './InputComponentHelpers'

type DateInputFieldsTypes = {
  name: string
  id: string
  title?: string
  title2?: string
  placeholder: string
  disabled: boolean
  readOnly: boolean
  onChange?: any
  min?: Date
  max?: Date
  required: boolean
  isClearable?:boolean 
}

export const DateInput = function ({
  name,
  id,
  title,
  title2,
  placeholder,
  disabled,
  readOnly,
  onChange,
  min,
  max,
  required,
  isClearable,

}: DateInputFieldsTypes) {
  const { t } = useTranslation()
  const formMethods = useFormContext()
  const {
    formState: { errors },
  } = formMethods
  const error = access(name, errors)

  const handleDateChangeRaw = (e: any) => {
    e.preventDefault()
  }
  return (
    <div className="date-input">
      <Controller
        name={name}
        render={({ field }) => (
          <>
            {title && <Form.Label htmlFor={id}>{required ? renderRequiredFieldLabel(title ?? '') : title}</Form.Label>}
            {title && <br />}
            {title2 &&  <Form.Label >{title2}</Form.Label>}
            { title2 && <br /> }
            <DatePicker
              dateFormat="dd-MM-yyyy"
              className={error ? 'form-control is-invalid' : 'form-control'}
              showYearDropdown={true}
              showMonthDropdown={true}
              minDate={min}
              maxDate={max}
              placeholderText={placeholder}
              yearDropdownItemNumber={75}
              scrollableYearDropdown
              onChange={(date: any) => {
                if (onChange) {
                  onChange(date)
                }
                field.onChange(date)
              }}
              selected={field.value}
              disabled={disabled}
              readOnly={readOnly}
              onChangeRaw={handleDateChangeRaw}   
              isClearable={isClearable}
              closeOnScroll={true}           
            />
            {error && <div className="invalid-date">{t(error?.message)} </div>}           
          </>
        )}
      />
    </div>
  )
}
