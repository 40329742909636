import React, { useContext, useEffect, useState } from 'react'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { AppUtil } from '../../../utils/app.utils'
import Accordion from 'react-bootstrap/Accordion'
import './ViewHandwrittenNotes.scss'
import { useTranslation } from 'react-i18next'
import { FolderX } from 'react-bootstrap-icons'
import { trackPromise } from 'react-promise-tracker'
import { getHandwrittenNotes } from '../../../services/HandwrittenNotes'
import { AxiosResponse } from 'axios'
import { ViewHandwrittenNotesDetails } from './ViewHandwrittenNotesDetails'
import { appRoutes } from '../../../utils/routes'
import { HandwrittenNotes, HandwrittenNotesRequestPayload } from '../../../models/HandwrittenNotes'
import { MasterDataContext } from '../../../contexts'
import { formatFormValues } from '../helpers/HandwrittenNotesHelpers'

export const ViewHandwrittenNotes = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { masterData } = useContext(MasterDataContext)
  const translatn = 'viewHandwrittenNotes.'
  const parentClass = 'view-handwritten-notes-'
  const [notes, setNotes] = useState<HandwrittenNotes[]>([])

  useEffect(() => {
    if (id && masterData.length) {
      trackPromise(
        getHandwrittenNotes(id).then(
          (res: AxiosResponse<HandwrittenNotesRequestPayload[]>) => {
            if (res.status == 200) {
              const data: HandwrittenNotes[] = res?.data?.map((item: HandwrittenNotesRequestPayload) => formatFormValues(item, masterData)
              )
              setNotes(data)
            }
          },
          (error) => AppUtil.logError(error),
        ),
      )
    }
  }, [id, masterData])

  const handleEdit = (noteId: number) => {
    if(noteId) {
      navigate(
        generatePath(appRoutes.editHandwrittenNotes, {
          patientId: id,
          id: noteId
        })
      )
    }    
  }

  const renderSampleAccordion = (note: HandwrittenNotes, index: number) => {
    const key = note.id?.toString() ?? ''
    return (
      <div className={`${parentClass}`} key={key}>
        <Accordion.Item eventKey={key} key={key}>
          <Accordion.Header>
            <span
              className={`${parentClass}accordion-left-text`}
            >
              {t(`${translatn}handwrittenNotesId`) + ' : ' + note.id}
            </span>
            <span className={`${parentClass}accordion-right-text`}>
              {t(`${translatn}createdOn`)}
              {' : '}
              {AppUtil.formatDateToStringWithTimestamp(note.lastModified ?? '')}
            </span>
          </Accordion.Header>
          <Accordion.Body>
            <ViewHandwrittenNotesDetails patientID={id ?? ''} note={note} index={index} handleEdit={handleEdit} />
          </Accordion.Body>
        </Accordion.Item>
      </div>
    )
  }

  return (
    <div className={`${parentClass}`}>
      {notes.length ? (
        <Accordion defaultActiveKey={notes.length === 1 ? notes[0]?.id?.toString() : null}> {notes.map((note: HandwrittenNotes, index: number) => renderSampleAccordion(note, index))}</Accordion>
      ) : (
        <div className={`${parentClass}empty-container`}>
          <FolderX size={'32'} />
          <h5>{t(`${translatn}noHandwrittenNotes`)}</h5>
        </div>
      )}
    </div>
  )
}
