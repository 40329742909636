import { AxiosResponse } from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { trackPromise } from 'react-promise-tracker'
import Container from 'react-bootstrap/Container'
import { useTranslation } from 'react-i18next'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import './ViewPatient.scss'
import { Patient } from '../../models/RegisterPatient'
import { getPatientDetails } from '../../services'
import { ViewPatientDetails, ViewSamples } from './components'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { AppUtil } from '../../utils/app.utils'
import { AuthUtil } from '../../utils/auth.utils'
import { PersonSquare, PencilFill, ArrowLeftShort } from 'react-bootstrap-icons'
import { VscNewFile } from 'react-icons/vsc'
import { appRoutes } from '../../utils/routes'
import { PRIVILEGES, MASTER_DATA_KEYS as MDK } from '../../utils/constants'
import { MasterDataContext } from '../../contexts'
import { WarningModal } from '../PatientList/WarningModal'
import { Dropdown } from 'react-bootstrap'
import { importSample } from '../../services/Sample'
import { Sample } from '../../models/Sample'

type actionPropsTypes = {
  patientDetails: any
}

type addSampleInfoTypes = {
  patientid: string | undefined
  sampleIds: string[] | number[]
  isAllowed: boolean
  handleModal: () => void
}

function AddSampleInfo(props: Readonly<addSampleInfoTypes>) {
  const { patientid, sampleIds, isAllowed, handleModal } = props
  const { t } = useTranslation()
  const navigate = useNavigate()
  const translatn = 'viewPatient.'
  const parentClass = 'vcr-pp-view-patient-'

  const importSampleData = () => {
    const sampleId = sampleIds?.[0]
    if (sampleId) {
      trackPromise(
        importSample(sampleId?.toString() ?? '').then(
          (res: AxiosResponse<Sample>) => {
            if (res?.status == 200) {
              navigate(generatePath(appRoutes.addSample, { patientId: patientid }),
                { state: res.data })
            }
          },
          (error) => AppUtil.logError(error),
        ),)
    }
  }

  return (<>
    <Dropdown>
      <Dropdown.Toggle variant="white" id="dropdown-basic">
        <Button className={`${parentClass}editbtn`} onClick={() => handleModal()}>
          <VscNewFile size={15} color="#075883" />
          <span className="ps-2">{t(`${translatn}addSample`)}</span>
        </Button>
      </Dropdown.Toggle>
      {isAllowed && <Dropdown.Menu>
        <Dropdown.Item onClick={() => navigate(generatePath(appRoutes.addSample, { patientId: patientid }))}>
          {t(`${translatn}newSample`)}
        </Dropdown.Item>
        <Dropdown.Item onClick={() => (importSampleData())} disabled={sampleIds.length === 0}>
          {t(`${translatn}importSample`)}
        </Dropdown.Item>
      </Dropdown.Menu>
      }
    </Dropdown>

  </>)

}

function ActionColumn(props: Readonly<actionPropsTypes>) {
  const { t } = useTranslation()
  const { id } = useParams()
  const navigate = useNavigate()
  const translatn = 'viewPatient.'
  const parentClass = 'vcr-pp-view-patient-'
  const { patientDetails } = props
  const hasAddSamplePrivilege = AuthUtil.checkUserAccess(PRIVILEGES.ADD_SAMPLE)
  const hasEditAfterShippmentPrivilege = AuthUtil.checkUserAccess(PRIVILEGES.EDIT_AFTER_SHIPMENT)
  const hasEditBeforeShippmentPrivilege = AuthUtil.checkUserAccess(PRIVILEGES.EDIT_BEFORE_SHIPMENT)
  const isEditPatientAllowed = patientDetails?.allowEdit
  const [showFormModal, setShowFormModal] = useState(false)
  const [isAllowed, setIsAllowed] = useState<boolean>(false)
  const showEditPatient = (hasEditBeforeShippmentPrivilege && isEditPatientAllowed) || (hasEditAfterShippmentPrivilege && !isEditPatientAllowed)

  const handleFormModalClose = () => {
    setShowFormModal(false);
  }

  const handleModal = () => {
    const requiredFields = {
      'addressLine1': patientDetails?.addressLine1,
      'nationality': patientDetails?.nationality,
      'consentProvided': patientDetails?.consentProvided,
      'ethnicity': patientDetails?.ethnicity,
      'race': patientDetails?.race,
      'state': patientDetails?.state,
      'city': patientDetails?.city,
      'pinCode': patientDetails?.pincode,
      'uhid': patientDetails?.uhid,      
      'diagnosis': patientDetails?.diagnosis
    }

    if (Object.values(requiredFields).some(val => val === null || val === '')) {
      setShowFormModal(true);
      setIsAllowed(false)
    } else {
      setIsAllowed(true)
      setShowFormModal(false);
    }
  }

  const handleEdit = () => {
    navigate(
      generatePath(appRoutes.editPatient, {
        id,
      }))
  }
  return (
    <>
      {showEditPatient && (
        <Button
          className={`${parentClass}editbtn`}
          onClick={() =>
            navigate(
              generatePath(appRoutes.editPatient, {
                id,
              })
            )
          }
        >
          <PencilFill color="#075883" />
          <span className="ps-2">{t(`${translatn}editInfo`)}</span>
        </Button>
      )}
      {hasAddSamplePrivilege && (
        <>
          <AddSampleInfo patientid={id} sampleIds={patientDetails?.sampleIds || []} handleModal={handleModal} isAllowed={isAllowed} />
          <WarningModal
            open={showFormModal}
            handleClose={handleFormModalClose}
            handleEdit={handleEdit}
          />
        </>
      )}
    </>
  )

}




export const PatientDetailsContainer = () => {
  const { t } = useTranslation()
  const { id } = useParams()
  const navigate = useNavigate()
  const translatn = 'viewPatient.'
  const parentClass = 'vcr-pp-view-patient-'
  const { masterData } = useContext(MasterDataContext)

  const [patientDetails, setPatientDetails] = useState<Patient | null>(null)

  const tabs = [
    {
      id: 'patient',
      title: t(`${translatn}patientDetails`),
      component: <ViewPatientDetails patientDetails={patientDetails} />,
    },
    { id: 'samples', title: t(`${translatn}viewSamples`), component: <ViewSamples /> },
  ]
  const [activeTab, setActiveTab] = useState('patient')
  const hasAddSamplePrivilege = AuthUtil.checkUserAccess(PRIVILEGES.ADD_SAMPLE)
  const hasEditAfterShippmentPrivilege = AuthUtil.checkUserAccess(PRIVILEGES.EDIT_AFTER_SHIPMENT)
  const hasEditBeforeShippmentPrivilege = AuthUtil.checkUserAccess(PRIVILEGES.EDIT_BEFORE_SHIPMENT)

  useEffect(() => {
    if (id) {
      trackPromise(
        getPatientDetails(id).then(
          (res: AxiosResponse<Patient>) => {
            setPatientDetails(res.data)
          },
          (error) => AppUtil.logError(error),
        ),
      )
    }
  }, [id])

  useEffect(() => {
    if (window.location.hash) {
      if (window.location.hash == '#samples') {
        setActiveTab('samples')
      }
    }
  }, [window.location.hash])

  /* const isEditPatientAllowed = patientDetails?.allowEdit
  const showEditPatient = (hasEditBeforeShippmentPrivilege && isEditPatientAllowed) || (hasEditAfterShippmentPrivilege && !isEditPatientAllowed) */

  return (
    <Container className={`${parentClass}container`} fluid>
      <Row className={`${parentClass}header `}>
        <Col className="d-flex align-items-center justify-content-start">
          <ArrowLeftShort size={26} onClick={() => navigate(-1)} />
          <span className={`${parentClass}vertical-line`}></span>
          <PersonSquare color="#1C9AA3" size={26} className="mr-3 ml-3" />

          <span className={`${parentClass}profile-id`}>
            {[
              AppUtil.combineNamesString(patientDetails?.name, patientDetails?.firstName, patientDetails?.middleName, patientDetails?.lastName),
              (AppUtil.getLabel(MDK.GENDER, masterData, patientDetails?.gender) + (patientDetails?.genderOther ? ` - ${patientDetails?.genderOther}` : '')),
              patientDetails?.age,
            ].join(', ')}
            {' (' + t(`${translatn}patientId`) + ' ' + patientDetails?.id + ')'}
          </span>
        </Col>
        <Col className="d-flex align-items-center justify-content-end">
          <ActionColumn
            patientDetails={patientDetails}
          />
        </Col>
      </Row>
      <Tabs className={`${parentClass}tabs`} activeKey={activeTab} onSelect={(key) => setActiveTab(key ?? '')}>
        {tabs.map((tab) => (
          <Tab eventKey={tab.id} title={tab.title} key={tab.title} id={tab.id}>
            {tab.component}
          </Tab>
        ))}
      </Tabs>
    </Container>
  )
}
