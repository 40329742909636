import React from 'react'
import { useTranslation } from 'react-i18next'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import { CheckboxInput } from '../../InputComponents'

export const QC = (props: any) => {
  const { t } = useTranslation()
  const translatn = 'sampleInfoForm'
  const parentClass = 'vcr-pp-sample-info-'
  const { isEditAllowed } = props

  return (
    <div className={`${parentClass}input-field`}>
      <h5 className={`${parentClass}form-heading`}> {t(`${translatn}.qc`)}</h5>
      <Row lg={4} md={4} sm={4}>
        <Col>
          <CheckboxInput
            name={`qcCheck`}
            id={`qcCheck`}
            title={''}
            inline={true}
            disabled={!isEditAllowed}
            required={true}
            checkboxValueArray={[{ label: t(`${translatn}.qcCheck`), value: true }]}
          />
        </Col>
        <Col>
          <CheckboxInput
            name={`pathogenFreeReport`}
            id={`pathogenFreeReport`}
            title={''}
            disabled={!isEditAllowed}
            inline={true}
            required={true}
            checkboxValueArray={[{ label: t(`${translatn}.pathogenFreeReport`), value: true }]}
          />
        </Col>
      </Row>
    </div>
  )
}
