import React, { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { useFormContext } from 'react-hook-form'

import {
  NumberInput,
  DateInput,
  SelectInput,
  TextInput,
  MultiSelectDropdown,
  CheckboxInput,
  RadioInput,
} from '../../InputComponents'
import { setAgeAtEvent } from '../helpers'
import { MASTER_DATA_KEYS as MDK, MASTER_DATA_ID as MDI } from '../../../utils/constants'
import { MasterDataContext } from '../../../contexts'
import { AppUtil as _ } from '../../../utils/app.utils'
import Form from 'react-bootstrap/Form'
import { renderRequiredFieldLabel } from '../../InputComponents/InputComponentHelpers'
import { DependentFields } from './DependentFields'
import { getDateOfBirth, progressionRecurrenceFields, progressionRecurrenceType } from '../helpers/AddSampleInfoHelpers'

export const OutcomesOfTreatment = () => {
  const { t } = useTranslation()
  const { setValue, getValues, resetField, watch, clearErrors } = useFormContext()
  const { masterData } = useContext(MasterDataContext)
  const translatn = 'sampleInfoForm'
  const parentClass = 'vcr-pp-sample-info-'
  const dob = getValues('dob')

  const removeProperty = (object: any, value: string | number) => {
    let childKey = `type${value}`
    delete object[childKey];
    return object;
  }

  const handleRemoveProgressionSource = (value: string | number) => {
    setValue(
      'outcomesOfTreatmentCategory',
      getValues('outcomesOfTreatmentCategory')?.filter(
        (item: { value: string; label: string }) => item.value?.toString() !== value?.toString(),
      ),
    )
    setValue('outcomesOfTreatmentSubCategory', removeProperty(getValues('outcomesOfTreatmentSubCategory'), value));
    setValue('outcomesOfTreatmentSubCategoryFields', removeProperty(getValues('outcomesOfTreatmentSubCategoryFields'), value));
  }

  const getOutcomesOfTreatmentCategoryValues = watch('outcomesOfTreatmentCategory')
  const getOutcomesOfTreatmentSubCategoryValues = watch('outcomesOfTreatmentSubCategory');

  const isProgressionSourceBeyond4Months = watch('isProgressionSourceBeyond4Months');

  useEffect(() => {
    if (isProgressionSourceBeyond4Months !== 'true') {
      resetField('progressionSourceBeyond4MonthsDate')
      clearErrors('progressionSourceBeyond4MonthsDate')
    }
  }, [isProgressionSourceBeyond4Months, resetField]);

  let isProgressionRecurrence = watch('progressionRecurrenceDone') === MDI.PROGRESSION_RECURRENCE_OPTION_YES

  useEffect(()=> {
    if(!isProgressionRecurrence){
      progressionRecurrenceFields.forEach((field) => {
        resetField(field, { defaultValue: null })
        clearErrors(field)
      })
      progressionRecurrenceType.forEach((field) => {
        resetField(field, { defaultValue: '' })
        clearErrors(field)
      })
    }
  }, [isProgressionRecurrence])

  return (
    <div className={`${parentClass}input-field`}>
      <h5 className={`${parentClass}form-heading`}> {t(`${translatn}.outcomesOfTreatment`)}</h5>
      <Row>
        <Col>
          <MultiSelectDropdown
            name={`outcomesOfTreatmentCategory`}
            id={'outcomesOfTreatmentCategory'}
            title={t(`${translatn}.outcomesOfTreatmentCategory`)}
            placeholder={getOutcomesOfTreatmentCategoryValues.length > 0 ? "" : t(`${translatn}.outcomesOfTreatmentCategoryPlaceholder`)}
            disabled={false}
            required={true}
            optionsValueArray={_.getOptions(MDK.PROGRESSION_SOURCE, masterData)}
            hideSelectedValues={false}
            onCustomRemove={handleRemoveProgressionSource}
            tooltipText={t(`${translatn}.testDetailsTooltip`)}
          />
        </Col>
        <Col>
          <CheckboxInput
            name={`isProgressionSourceBeyond4Months`}
            id={`isProgressionSourceBeyond4Months`}
            title={''}
            disabled={false}
            inline={true}
            required={false}
            checkboxValueArray={[{ label: `Beyond 4 months`, value: true }]}
          />
          {(watch('isProgressionSourceBeyond4Months') === "true" || watch('isProgressionSourceBeyond4Months') === true) && <DateInput
            name={`progressionSourceBeyond4MonthsDate`}
            id={'progressionSourceBeyond4MonthsDate'}
            title=''
            placeholder={t(`common.datePlaceholder`)}
            disabled={false}
            readOnly={false}
            max={_.fourMonthsFromNow()}
            required={false}
            isClearable={true} />}
        </Col>
      </Row>

      <DependentFields
        name={'outcomesOfTreatment'}
        categories={getOutcomesOfTreatmentCategoryValues}
        subCategories={getOutcomesOfTreatmentSubCategoryValues}
      />

      <Row lg={4} md={4} sm={4}>
        <Col>
          <RadioInput
            name={`progressionRecurrenceDone`}
            id="progressionRecurrenceDone"
            valueArray={_.sortOptionsDesc(_.getOptions(MDK.PROGRESSION_RECURRENCE_DONE, masterData))}
            title={t(`${translatn}.progressionRecurrenceDone`)}
            disabled={false}
            inline={true}
            required={true}
          />
        </Col>
      </Row>

      <Row lg={4} md={4} sm={4}>
        {isProgressionRecurrence && <>
          <Col>
            <DateInput
              name={`dateOfProgressionRecurrence`}
              id={'dateOfProgressionRecurrence'}
              title={t(`${translatn}.dateOfProgressionRecurrence`)}
              placeholder={t(`common.datePlaceholder`)}
              disabled={false}
              readOnly={false}
              min={new Date(getDateOfBirth(dob)) ?? undefined}
              max={new Date()}
              required={true}
              onChange={(date: Date) => {
                setAgeAtEvent(dob, date, `ageAtProgressionRecurrence`, setValue)
              }}
            />
          </Col>
          <Col>
            <NumberInput
              name={`ageAtProgressionRecurrence`}
              id={'ageAtProgressionRecurrence'}
              title={t(`${translatn}.ageAtProgressionRecurrence`)}
              placeholder={t(`${translatn}.ageAtProgressionRecurrencePlaceholder`)}
              disabled={false}
              readOnly={true}
              required={true}
            />
          </Col>
          <Col>
            <SelectInput
              name={`typeOfRecurrence`}
              id={'typeOfRecurrence'}
              title={t(`${translatn}.typeOfRecurrence`)}
              placeholder={t(`${translatn}.typeOfRecurrencePlaceholder`)}
              disabled={false}
              required={true}
              optionsValueArray={_.getOptions(MDK.RECURRENCE_TYPE, masterData)}
            />
          </Col></>}

        <Col>
          <SelectInput
            name={`recurrencePerformanceStatus`}
            id={'mostRecentPerformanceStatus'}
            title={t(`${translatn}.mostRecentPerformanceStatus`)}
            placeholder={t(`${translatn}.mostRecentPerformanceStatusPlaceholder`)}
            disabled={false}
            required={true}
            optionsValueArray={_.getOptions(MDK.RECURRENCE_PERFORMANCE_STATUS, masterData)}
          />
        </Col>
        <Col>
          <SelectInput
            name={`typeOfStatusScaleUsedRECISTChemotherapy`}
            id={'typeOfStatusScaleUsedRECISTChemotherapy'}
            title={t(`${translatn}.typeOfStatusScaleUsedRECISTChemotherapy`)}
            placeholder={t(`${translatn}.typeOfStatusScaleUsedRECISTChemotherapyPlaceholder`)}
            disabled={false}
            required={true}
            optionsValueArray={_.getOptions(MDK.TYPE_OF_STATUS_SCALE_USED_RECIST_CHEMOTHERAPY, masterData)}
          />
        </Col>
        <Col>
          <SelectInput
            name={`typeOfStatusScaleUsedIRRECISTImmunotherapy`}
            id={'typeOfStatusScaleUsedIRRECISTImmunotherapy'}
            title={t(`${translatn}.typeOfStatusScaleUsedIRRECISTImmunotherapy`)}
            placeholder={t(`${translatn}.typeOfStatusScaleUsedIRRECISTImmunotherapyPlaceholder`)}
            disabled={false}
            required={true}
            optionsValueArray={_.getOptions(MDK.TYPE_OF_STATUS_SCALE_USED_IRRECIST_IMMUNOTHERAPY, masterData)}
          />
        </Col>
      </Row>

      <Row lg={4} md={4} sm={4}>
        <Col>
          <DateInput
            name={`dateOfPerformanceStatus`}
            id={'dateOfPerformanceStatus'}
            title={t(`${translatn}.dateOfPerformanceStatus`)}
            placeholder={t(`common.datePlaceholder`)}
            disabled={false}
            readOnly={false}
            min={new Date(getDateOfBirth(dob)) ?? undefined}
            max={new Date()}
            required={true}
            onChange={(date: Date) => {
              setAgeAtEvent(dob, date, `ageAtPerformanceStatus`, setValue)
            }}
          />
        </Col>
        <Col>
          <NumberInput
            name={`ageAtPerformanceStatus`}
            id={'ageAtPerformanceStatusScale'}
            title={t(`${translatn}.ageAtPerformanceStatusScale`)}
            placeholder={t(`${translatn}.ageAtPerformanceStatusScalePlaceholder`)}
            disabled={false}
            readOnly={true}
            required={true}
          />
        </Col>
      </Row>
      <h6 className='mt-4'> {t(`${translatn}.bestResponseBy`)}</h6>
      <Row lg={4} md={4} sm={4}>
        <Col>
          <SelectInput
            name={`responseByDiseaseGroup`}
            id={'bestResponseByDiseaseGroup'}
            title={t(`${translatn}.bestResponseByDiseaseGroup`)}
            placeholder={t(`${translatn}.bestResponseByDiseaseGroupPlaceholder`)}
            disabled={false}
            required={true}
            optionsValueArray={_.getOptions(MDK.RESPONSE_BY_DISEASE_GROUP, masterData)}
          />
        </Col>
        <Col>
          <SelectInput
            name={`responseByBaselineDiseaseStatus`}
            id={'bestResponseByBaselineDiseaseStatus'}
            title={t(`${translatn}.bestResponseByBaselineDiseaseStatus`)}
            placeholder={t(`${translatn}.bestResponseByBaselineDiseaseStatusPlaceholder`)}
            disabled={false}
            required={true}
            optionsValueArray={_.getOptions(MDK.RESPONSE_BY_BASELINE_DISEASE_STATUS, masterData)}
          />
        </Col>
        <Col>
          <SelectInput
            name={`responseByResponseAssessment`}
            id={'bestResponseByResponseAssessment'}
            title={t(`${translatn}.bestResponseByResponseAssessment`)}
            placeholder={t(`${translatn}.bestResponseByResponseAssessmentPlaceholder`)}
            disabled={false}
            required={true}
            optionsValueArray={_.getOptions(MDK.RESPONSE_BY_RESPONSE_ASSESSMENT, masterData)}
          />
        </Col>
      </Row>
      <Row lg={4} md={4} sm={4}>
        <Col>
          <DateInput
            name={`responseByDateOfAssessment`}
            id={'responseByDateOfAssessment'}
            title={t(`${translatn}.responseByDateOfAssessment`)}
            placeholder={t(`common.datePlaceholder`)}
            disabled={false}
            readOnly={false}
            min={new Date(getDateOfBirth(dob)) ?? undefined}
            max={new Date()}
            required={true}
          />
        </Col>
        <Col className={`${parentClass}durationOfResponseContainer`}>
          <div>
            <div><Form.Label>{renderRequiredFieldLabel(t(`${translatn}.responseByDurationInMonths`))}</Form.Label></div>
            <Row className='g-0'>
              <Col className={`${parentClass}durationOfResponseColumn`}>
                <NumberInput
                  name={`responseByDurationInMonths`}
                  id={'responseByDurationInMonths'}
                  placeholder={t(`${translatn}.responseByDurationInMonthsPlaceholder`)}
                  disabled={false}
                  readOnly={false}
                  required={false}
                  min={0}
                />
              </Col>
              <Col className={`${parentClass}durationOfResponseColumn`}>
                <NumberInput
                  name={`responseByDurationInDays`}
                  id={'responseByDurationInDays'}
                  placeholder={t(`${translatn}.responseByDurationInDaysPlaceholder`)}
                  disabled={false}
                  readOnly={false}
                  required={false}
                  min={0}
                />
              </Col>
            </Row></div>

        </Col>
        <Col>
          <TextInput
            type="text"
            name={`responseByPhysicianOrInvestigatorAssessment`}
            id={'responseByPhysicianOrInvestigatorAssessment'}
            title={t(`${translatn}.responseByPhysicianOrInvestigatorAssessment`)}
            placeholder={t(`${translatn}.responseByPhysicianOrInvestigatorAssessmentPlaceholder`)}
            disabled={false}
            readOnly={false}
            required={true}
          />
        </Col>
      </Row>
      <Row lg={4} md={4} sm={4}>
        <Col>
          <SelectInput
            name={`recistScoreDetails`} // recistScoreDetails
            id={'recistScoreDetails'}
            title={t(`${translatn}.recistScoreDetails`)}
            placeholder={t(`${translatn}.recistScoreDetailsPlaceholder`)}
            disabled={false}
            required={true}
            optionsValueArray={_.getOptions(MDK.CURRENT_DISEASE_STATUS, masterData)}
          />
        </Col>
        <Col>
          <TextInput
            type="text"
            name={`recistScore`} // recistScore
            id={'recistScore'}
            title={t(`${translatn}.recistScore`)}
            placeholder={t(`${translatn}.recistScorePlaceholder`)}
            disabled={false}
            readOnly={false}
            required={true}
          />
        </Col>
      </Row>
      <Row lg={4} md={4} sm={4}>

        <Col>
          <DateInput
            name={`dateOfDiseaseStatus`}
            id={'dateOfDisease'}
            title={t(`${translatn}.dateOfDisease`)}
            placeholder={t(`common.datePlaceholder`)}
            disabled={false}
            readOnly={false}
            min={new Date(getDateOfBirth(dob)) ?? undefined}
            max={new Date()}
            required={true}
            onChange={(date: Date) => {
              setAgeAtEvent(dob, date, `ageAtDiseaseStatus`, setValue)
            }}
          />
        </Col>
        <Col>
          <NumberInput
            name={`ageAtDiseaseStatus`}
            id={'ageAtDiseaseStatus'}
            title={t(`${translatn}.ageAtDiseaseStatus`)}
            placeholder={t(`${translatn}.ageAtDiseaseStatusPlaceholder`)}
            disabled={false}
            readOnly={true}
            required={true}
          />
        </Col>
      </Row>
    </div>
  )
}
