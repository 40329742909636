import axios from 'axios'
import { apiRoutes } from '../utils/routes'
import { AppUtil } from '../utils/app.utils'
import { SampleRequestPayload } from '../models/Sample'

const {  getCommonAPIConfig} = AppUtil

export const addSample = async (requestBody: SampleRequestPayload, patientId: string) => {
  const response = await axios.post(
    apiRoutes.sample.add,
    {
      ...requestBody,
      patientId: Number(patientId),
    },
    getCommonAPIConfig()
  )
  return response
}

export const updateSample = async (requestBody: SampleRequestPayload, id: string, patientId: string) => {
  const response = await axios.put(
    apiRoutes.sample.update,
    {
      ...requestBody,
      id: Number(id),
      patientId: Number(patientId),
    },
    getCommonAPIConfig()
  )

  return response
}

export const importSample = async (sampleId: string) => {
  const response = await axios.post(
    apiRoutes.sample.import,
    {
      sampleId: Number(sampleId),
    },
    getCommonAPIConfig()
  )

  return response
}

export const getSampleDetails = async (sampleId: string, patientId?: string) => {
  const response = await axios.post(
    apiRoutes.sample.get,
    {
      id: Number(sampleId),
      patientId: Number(patientId),
    },
    getCommonAPIConfig()
  )

  return response
}

export const getSamplesListForPatient = async (patientId: string) => {
  const response = await axios.post(
    apiRoutes.sample.get,
    { patientId },
    getCommonAPIConfig()
  )

  return response
}

export const generateBarcode  = async (sampleId:  string) => {
  const response = await axios.post(
    apiRoutes.sample.barcode,
    {
      id: Number(sampleId)      
    },
    getCommonAPIConfig()
  )

  return response
}

export const shippedSample  = async (sampleId:  string) => {
  const response = await axios.post(
    apiRoutes.sample.shipped,
    {
      id: Number(sampleId)      
    },
    getCommonAPIConfig()
  )

  return response
}