import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { appRoutes } from '../../utils/routes'
import { PasswordChangeModal } from '../ChangePassword'


function updatePasswordAndRedirect(setPasswordModalshow: any, navigate: any) {
  setPasswordModalshow(false)
  navigate(appRoutes.home)
}

export function ForcedChangePassword() {
    const navigate = useNavigate()
  
    const [passwordModalshow, setPasswordModalshow] = useState(true)
  
    const changePasswordModalClose = function () {
      updatePasswordAndRedirect(setPasswordModalshow, navigate)
    }

    return (
        <div>
          {passwordModalshow && <PasswordChangeModal isCancelDisabled={true} show={passwordModalshow} handleClose={changePasswordModalClose} />}
        </div>
    )
}