import React from 'react'
import Container from 'react-bootstrap/Container'
import { useTranslation } from 'react-i18next'
import { TabProps } from '../../../models/Sample'
import { ViewSampleDetailsRow } from './ViewSampleDetailsRow'
import { AppUtil } from '../../../utils/app.utils'
import _ from 'lodash'
import { MASTER_DATA_ID as MDI} from '../../../utils/constants'

export const CytogenicAbnormalities = (props: TabProps) => {
  const { sampleDetails } = props
  const { t } = useTranslation()
  const translatn = 'viewSample.'
  const parentClass = ''

  const detailsArr = [
    {
      label: t(`${translatn}cytogeneticTestingDone`),
      value: AppUtil.convertBooleanToStr(sampleDetails?.cytogeneticsTestingPerformance ?? null),
      show: true
    },
    {
      label: t(`${translatn}dateOfProcedure`),
      value: sampleDetails?.dateOfProcedureForCytogeneticsAbnormalities,
      show: sampleDetails?.cytogeneticsTestingPerformance == MDI.CYTOGENETICS_TESTING_PERFORMANCE_OPTION_YES
    },
    {
      label: t(`${translatn}ageAtProcedure`),
      value: sampleDetails?.ageAtProcedureForCytogeneticsAbnormalities,
      show: sampleDetails?.cytogeneticsTestingPerformance == MDI.CYTOGENETICS_TESTING_PERFORMANCE_OPTION_YES
    },
    {
      label: t(`${translatn}testTypeDeletionsTranslocations`),
      value: sampleDetails?.testTypeDeletionsTranslocations,
      show: sampleDetails?.cytogeneticsTestingPerformance == MDI.CYTOGENETICS_TESTING_PERFORMANCE_OPTION_YES
    },
  ]

  const data = detailsArr.filter((details) => details.show === true)

  const rowCount = _.chunk(data, 5)

  return (
    <Container fluid>
      <h5 className="fw-bold "> {t(`${translatn}cytogeneticAbnormalities`)}</h5>
      {rowCount.map((row, index) => (
        <ViewSampleDetailsRow
          key={index}
          index={index}
          rowArr={rowCount[index]}
          parentClass={parentClass}
        />
      ))}
    </Container>
  )
}
