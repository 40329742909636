import React, { useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container'
import { useTranslation } from 'react-i18next'
import { AppUtil } from '../../../utils/app.utils'
import { useParams } from 'react-router-dom'
import { Col, Row } from 'react-bootstrap'
import QRCode from 'react-qr-code'
import { generateBarcode } from '../../../services/Sample'
import "./styles/QRcode.scss"
import { TabProps } from '../../../models/Sample'
import { SampleStatusMasterData } from '../../../utils/sampleStatusMasterData'

interface BarcodeInfoProps {
  show: boolean,
  label1: string[],
  label2: string[],
  barcode: string
}

export const QRcode = ({sampleDetails}: TabProps) => {
  const { id } = useParams()
  const { t } = useTranslation()
  const translatn = 'viewSample.'
  const parentClass = 'view-sample-qrcode'
  const [barcodeInfo, setBarcodeInfo] = useState<BarcodeInfoProps>({ show: false, label1: [], label2: [], barcode: '' })

  const handleQRcode = async () => {
    try {
      const res = await generateBarcode(id ?? '')
      const { deidentifiedData, testDetails, blockLable } = res.data
      setBarcodeInfo({ show: true, label1: testDetails, label2: blockLable, barcode: deidentifiedData })

    } catch (error: any) {
      AppUtil.logError(error)
    }
  }

  useEffect(() => {
    if((sampleDetails?.sampleStatus)) {
      handleQRcode()
    }    
  }, [sampleDetails])

  return (
    <Container className={`${parentClass}`} fluid>
      <h5 className="fw-bold pt-3"> {t(`${translatn}sampleBarcode`)}</h5>
      <Row>
        <Col lg={3} className="pt-4">
          <h5 className={'mb-4'}>{t(`${translatn}clinicalSampleBarcode`)}</h5>
          <div className={`${parentClass}-qrInfo`}>
            <QRCode
              className={`${parentClass}-qr`}
              value={barcodeInfo?.barcode}
              viewBox={`0 0 25 25`}
            />
            <div className={`${parentClass}-qr-label`}>{barcodeInfo?.label1}</div>
          </div>
        </Col>
      </Row>
      <Row>
        {barcodeInfo?.label2?.length > 0 && (<h5 className="pt-4">{t(`${translatn}biorepositorySamplesBarcode`)}</h5>)
        }
        {barcodeInfo?.label2?.map((info, index) => (
          <Col lg={3} key={index} className={`${parentClass}-qrInfo pt-3`}>
            <QRCode
              className={`${parentClass}-qr`}
              value={barcodeInfo.barcode}
              viewBox={`0 0 25 25`}
            />
            <div className={`${parentClass}-qr-label`}>{info}</div>
          </Col>
        ))
        }
      </Row>
    </Container>
  )
}
