import React from 'react'
import Button from 'react-bootstrap/Button'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { appRoutes } from '../../utils/routes'
import { ExclamationTriangle } from 'react-bootstrap-icons'
import './UnauthorizedPage.scss'

export const UnauthorizedPage = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const translatn = 'unauthorized'
  const parentClass = 'vcr-pp-unauthorized-page'

  const handleGoHome = () => {
    navigate(appRoutes.home)
  }

  return (
    <div className={`${parentClass}-container`}>
      <div className={`${parentClass}-warning-icon`}>
        <ExclamationTriangle size={80} />
      </div>
      <h4>{t(`${translatn}.message`)}</h4>
      <Button onClick={handleGoHome}>{t(`${translatn}.goHome`)}</Button>
    </div>
  )
}
