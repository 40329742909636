import axios from 'axios'
import { apiRoutes } from '../utils/routes'
import { AppUtil } from '../utils/app.utils'

const { getCommonAPIConfig } = AppUtil

export const getMasterData = async () => {
  const response = await axios.post(apiRoutes.masterData.get, {}, getCommonAPIConfig())
  return response
}

export const getSiteList = async () => {
  const response = await axios.post(apiRoutes.masterData.getSites, {}, getCommonAPIConfig())
  return response
}

export const getDrsListForSite = async (siteId: string) => {
  const response = await axios.post(apiRoutes.masterData.getDrs, {
    siteId
  }, getCommonAPIConfig())
  return response
}

export const getTestIDs = async () => {
  const response = await axios.post(apiRoutes.masterData.getTestIds, {}, getCommonAPIConfig())
  return response
}
