import React, { useContext, useEffect, useState } from "react"
import { Button, Col, Image, Row } from "react-bootstrap"
import { FileUploadNoForm, MultiSelectDropdown, RadioInput, SelectInput, TextInput } from "../../InputComponents"
import { AppUtil } from "../../../utils/app.utils"
import { MASTER_DATA_KEYS as MDK } from "../../../utils/constants"
import { useTranslation } from "react-i18next"
import { useFormContext } from "react-hook-form"
import { MasterDataContext } from "../../../contexts"
import { ArrowClockwise, ArrowRightShort, X, ZoomIn, ZoomOut } from "react-bootstrap-icons"
import 'react-quill/dist/quill.snow.css';
import ReactQuill from "react-quill"
import { useParams } from "react-router-dom"
import { getHandwrittenNotesDownloadURLs, getLumiseekFiles, getLumiseekImageData } from "../../../services/HandwrittenNotes"
import { trackPromise } from "react-promise-tracker"
import { AxiosResponse } from "axios"

import './AddHandwrittenNotes.scss'
import { TransformComponent, TransformWrapper, useControls } from "react-zoom-pan-pinch"

interface AddHandwrittenNotesProps {
    isUploaded: boolean,
    setIsUploaded: (value: boolean) => void,
    handleUploadFile: () => void,
    imageSrc: string | null,
    setImageSrc: (value: string | null) => void
}

type LumiseekFile =
    {
        patient_id: string,
        url: string,
        file_name: string,
        file_extension: string
    }

interface HandwrittenNotesFiles {
    handwritten_notes_files: LumiseekFile[]
}

const MIN_FILE_SIZE = 2000000
const MAX_FILE_SIZE = 5000000
const ACCEPTED_IMAGE_TYPES = ['image/jpeg', 'image/jpg', 'image/png']
const ACCEPTED_IMAGE_EXTENSION = ['.jpeg', '.jpg', '.png']

const DATASOURCE_TYPE = [{
    label: 'Upload from this device',
    value: 'PP'
}, {
    label: 'Add from Lumiseek',
    value: 'LUMISEEK'
}]

export const AddHandwrittenNotes = (props: AddHandwrittenNotesProps) => {
    const { isUploaded, handleUploadFile, imageSrc, setImageSrc } = props
    const { patientId, id } = useParams()
    const { t } = useTranslation()
    const formMethods = useFormContext()
    const { masterData } = useContext(MasterDataContext)
    const [lumiseekFiles, setLumiseekFiles] = useState<LumiseekFile[]>([])

    const translatn = 'addHandwrittenNotes'
    const { setValue, watch, getValues, formState: { errors }, reset, resetField, clearErrors  } = formMethods

    const [rotation, setRotation] = useState(0);
    const [fileError, setFileError] = useState<string>('')

    let originalConvertedText = watch('original_Converted_Text')

    useEffect(() => {
        if (patientId) {
            trackPromise(
                getLumiseekFiles(patientId).then(
                    (res: AxiosResponse<HandwrittenNotesFiles>) => {
                        setLumiseekFiles(res.data.handwritten_notes_files)
                    },
                    (error) => {
                        AppUtil.logError(error)
                        setLumiseekFiles([])
                    },
                )
            )
        }
    }, [patientId])

    useEffect(() => {
        if (id && isUploaded) {
            const data = formMethods.getValues()
            trackPromise(
                getHandwrittenNotesDownloadURLs(patientId ?? '', data.file?.[0]?.name ?? '').then(
                    async (res: AxiosResponse) => {
                        const response = await fetch(res.data.path)
                        const blob = await response.blob();
                        const url = URL.createObjectURL(blob);
                        setImageSrc(url);
                    },
                    (error) => AppUtil.logError(error),
                ),
            )
        }
    }, [id, isUploaded])

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files && event.target.files[0];
        if (file) {
            if (!id && file?.type && !ACCEPTED_IMAGE_TYPES.includes(file?.type)) {
                setFileError(`Only .jpeg, jpg, or .png images are allowed`);
                if (imageSrc) {
                    setImageSrc(null)
                    resetField('predefined_Tags', { defaultValue: [] })
                    resetField('custom_Tags', { defaultValue: '' })
                    resetField('notes', { defaultValue: '' })
                    setValue('original_Converted_Text', '')
                    setRotation(0)
                }
            } else if (!id && file?.size && (file.size < MIN_FILE_SIZE || file.size > MAX_FILE_SIZE)) {
                setFileError(`Please upload a file with a size between 2mb - 5mb`);
                if (imageSrc) {
                    setImageSrc(null)
                    resetField('predefined_Tags', { defaultValue: [] })
                    resetField('custom_Tags', { defaultValue: '' })
                    resetField('notes', { defaultValue: '' })
                    setValue('original_Converted_Text', '')
                    setRotation(0)
                }
            } else {
                setFileError('')
                const url = URL.createObjectURL(file);
                setRotation(0)
                setImageSrc(url);
                resetField('notes', { defaultValue: '' })
                setValue('original_Converted_Text', '')
            }
        } else {
            setFileError('This field is required')
            setImageSrc(null)
            setRotation(0)
            reset()
        }
    }

    const handleConverter = () => {
        handleUploadFile()
    }

    const handleChange = (content: any) => {
        setValue('original_Converted_Text', content);
    };

    const handleRemovePredefinedTags = (value: string | number) => {
        setValue(
            'predefined_Tags',
            getValues('predefined_Tags')?.filter(
                (item: { value: string; label: string }) => item.value?.toString() !== value?.toString(),
            ),
        )
    }

    const handleRemoveFile = () => {
        setFileError('')
        setImageSrc(null)
        setRotation(0)
        reset()
    }

    function base64ToBlob(base64: string, mimeType: string) {
        const byteCharacters = atob(base64);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += 512) {
            const slice = byteCharacters.slice(offset, offset + 512);
            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        return new Blob(byteArrays, { type: mimeType });  // Return as a Blob with MIME type
    }

    const handleLumiseekFileChange = async (event: React.ChangeEvent<HTMLSelectElement>) => {
        if (event.target.value) {
            const response = await getLumiseekImageData(event.target.value);


            const binaryDataBlob = base64ToBlob(response.data, `image/${event.target.options[event.target.selectedIndex].label.split('.')[1]}`);

            const file = new File([binaryDataBlob], event.target.options[event.target.selectedIndex].label, { type: `image/${event.target.options[event.target.selectedIndex].label.split('.')[1]}` });

            const dataTransfer = new DataTransfer();
            dataTransfer.items.add(file);
            setFileError('')
            setRotation(0)
            setValue('file', dataTransfer.files)
            setImageSrc(event.target.value);
        } else {
            if (imageSrc) {
                setImageSrc(null)
                resetField('predefined_Tags', { defaultValue: [] })
                resetField('custom_Tags', { defaultValue: '' })
                resetField('notes', { defaultValue: '' })
                setValue('original_Converted_Text', '')
                setRotation(0)
            }
        }

    }

    const handleRotation = () => {
        setRotation(rotation + 90);
    };

    useEffect(() => {
        if (watch('dataSource')) {
            setFileError('')
            clearErrors()
            if (imageSrc) {
                setImageSrc(null)
                resetField('lumiseekfile', { defaultValue: '' })
                resetField('predefined_Tags', { defaultValue: [] })
                resetField('custom_Tags', { defaultValue: '' })
                resetField('notes', { defaultValue: '' })
                setValue('original_Converted_Text', '')
                setRotation(0)
            }
        }
    }, [watch('dataSource')])

    const Controls = () => {
        const { zoomIn, zoomOut } = useControls();
        return (
            <Row style={{
                color: 'white',
                padding: "5px",

            }} className="mt-0 justify-content-center">
                <Col lg="2">
                    <ArrowClockwise
                        size={23}
                        onClick={handleRotation}
                        title="Rotate"
                        style={{ cursor: 'pointer' }}
                    />
                </Col>
                <Col lg="2">
                    <ZoomOut
                        size={23}
                        onClick={() => zoomOut()}
                        title="Zoom out"
                        style={{ cursor: 'pointer' }}
                    />
                </Col>
                <Col lg="2">
                    <ZoomIn
                        size={23}
                        onClick={() => zoomIn()}
                        title="Zoom in"
                        style={{ cursor: 'pointer' }}
                    />


                </Col>
            </Row>
        );
    };

    return <>
        {!id && <Row>
            <Col>
                <RadioInput
                    name={`dataSource`}
                    id="dataSource"
                    valueArray={DATASOURCE_TYPE}
                    title={'Select Data Source'}
                    disabled={false}
                    inline={true}
                    required={true}
                />
            </Col>
        </Row>}

        {!id && watch('dataSource') === 'PP' && <Row lg={4} md={4} sm={4}>
            <Col>
                <FileUploadNoForm name={`file`} id={"file"} accept=".png, .jpg, .jpeg" title={t(`${translatn}.selectFile`)} size={"md"} onChange={handleFileChange} readOnly={false} required={true} />
                {fileError && <p style={{
                    fontSize: '0.875em',
                    color: `var(--bs-form-invalid-color)`
                }}>{fileError}</p>}
            </Col></Row>} 
            {!id && watch('dataSource') === 'LUMISEEK' && (<Row lg={4} md={4} sm={4}>
                <Col>
                    <SelectInput
                        name={`lumiseekfile`}
                        id={'lumiseekfile'}
                        title={t(`${translatn}.lumiseekFile`)}
                        placeholder={t(`${translatn}.selectLumiseekFilePlaceholder`)}
                        disabled={false}
                        required={true}
                        optionsValueArray={lumiseekFiles?.
                            filter((file: LumiseekFile) => ACCEPTED_IMAGE_EXTENSION.includes(file?.file_extension))
                            ?.map((file: LumiseekFile) => {
                                return {
                                    label: file.file_name,
                                    value: file.url
                                }
                            })}
                        onCustomChange={handleLumiseekFileChange}
                    />
                </Col>
            </Row>)}
        {imageSrc && <Row lg={4} md={4} sm={4}>
            <><Col >
                <MultiSelectDropdown
                    name={`predefined_Tags`}
                    id={`predefined_Tags`}
                    title={t(`${translatn}.tags`)}
                    placeholder={t(`${translatn}.tagsPlaceholder`)}
                    disabled={false}
                    required={false}
                    optionsValueArray={AppUtil.getOptions(MDK.NOTE_TAG, masterData)}
                    hideSelectedValues={false}
                    onCustomRemove={handleRemovePredefinedTags}
                />
            </Col>
                <Col>
                    <TextInput
                        type="text"
                        name={`custom_Tags`}
                        id={'otherTags'}
                        title={t(`${translatn}.otherTags`)}
                        placeholder={t(`${translatn}.otherTagsPlaceholder`)}
                        disabled={false}
                        readOnly={false}
                        required={false}
                    />
                </Col></>
        </Row>}
        {imageSrc &&
            <Row className="d-flex align-items-center mt-5">
                <Col lg={5} md={5} sm={5} style={{ height: 'auto' }}>
                    <div style={{ backgroundColor: '#31a5ab', height: 'inherit' }}>
                        <Row style={{
                            display: 'flex',
                            alignItems: 'center', color: 'white'
                        }} className="mt-0 p-1"><Col>
                                <b>Preview:</b>
                            </Col>
                            {!id && <Col style={{ textAlign: 'end' }}>
                                <X
                                    size={30}
                                    onClick={handleRemoveFile}
                                    title="Remove"
                                    style={{ cursor: 'pointer' }}
                                />
                            </Col>}
                        </Row>
                        <TransformWrapper>
                            <TransformComponent contentStyle={{
                                height: 'inherit',
                                width: 'inherit',
                                padding: '1px 2px 1px 2px'
                            }}>
                                <Image
                                    src={imageSrc || ''}
                                    alt="Scanned Handwritten Notes"
                                    fluid
                                    thumbnail
                                    style={{
                                        height: "100%",
                                        width: "100%",
                                        objectFit: 'contain',
                                        transform: `rotate(${rotation}deg)`,
                                    }}
                                />
                            </TransformComponent>
                            <Controls />
                        </TransformWrapper>

                    </div>
                </Col>
                <Col className="text-center">
                    <Button variant="primary" type="button" size={'sm'} onClick={handleConverter}>
                        {t(`${translatn}.convertImageToText`)}
                        <ArrowRightShort size={26} />
                    </Button>
                </Col>
                <Col lg={5} md={5} sm={5} style={{
                    height: 600,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between'
                }}>

                    <ReactQuill id="original_Converted_Text" theme="snow" value={originalConvertedText} onChange={handleChange} style={{
                        height: 500
                    }} readOnly={!id && !watch('image_Path')}/>
                    {errors?.original_Converted_Text?.message && <p style={{
                        fontSize: '0.875em',
                        color: `var(--bs-form-invalid-color)`
                    }}>{errors?.original_Converted_Text?.message}</p>}
                </Col>
            </Row>
        }
    </>
}