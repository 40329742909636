import { useState } from 'react'
import { useIdleTimer } from 'react-idle-timer'

export const UserIdleTimeout = ({ onIdlePromt, handleLogout, idleTime = 1 }: any) => {
  const idleTimeout = 1000 * idleTime
  const [idle, setIdle] = useState(false)
  
  const handleIdle = () => {
    setIdle(true)
    handleLogout()
  }
  
  const idleTimer = useIdleTimer({
    timeout: idleTimeout,
    promptBeforeIdle: idleTimeout / 4,
    onPrompt: onIdlePromt,
    onIdle: handleIdle,
    debounce: 500,
  })

  return {
    isIdle: idle,
    setIdle,
    idleTimer,
  }
}
