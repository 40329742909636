
import React, {
  createContext,
  useEffect,
  ReactNode,
  useState,
  useContext
} from 'react';
import axios from 'axios';
import ToastContainer from 'react-bootstrap/ToastContainer';
import Toast from 'react-bootstrap/Toast';
import { useTranslation } from 'react-i18next';
import { apiRoutes, showNoSuccessToastArr, showNoErrorToastArr } from '../utils/routes';
import { AuthContext } from './AuthContext'
import _ from 'lodash'

type APIContextProviderProps = {
  children: ReactNode;
};

export const APIContext = createContext(null);

export function APIProvider({
  children
}: Readonly<APIContextProviderProps>): React.ReactElement {
  const { t } = useTranslation()
  const translatn = 'toastHeading.'
  const [showToast, setShowToast] = useState(false)
  const [bg, setBg] = useState('success')
  const [bodyMsg, setBodyMsg] = useState('')
  const [header, setHeader] = useState('')

  const handleClose = () => setShowToast(false)
  const auth = useContext(AuthContext)

  function createAxiosResponseInterceptor() {
    const axiosInterceptor = axios.interceptors.response.use(
      response => {
        if(response?.config.url && !showNoSuccessToastArr.includes(response?.config.url)){
          setShowToast(!(_.includes(response?.config.url, 'overall_summary') || _.includes(response?.config.url, 'synopsisReport')))
          setBg('success')
          setBodyMsg(_.includes(response?.config.url, 's3/patients/') && !(_.includes(response?.config.url, 'overall_summary') || _.includes(response?.config.url, 'synopsisReport') || _.includes(response?.config.url, 'notes/'))? `Patient's consent uploaded successfully`
              :  (_.includes(response?.config.url, 's3/patients/') && !(_.includes(response?.config.url, 'overall_summary') || _.includes(response?.config.url, 'synopsisReport')) && _.includes(response?.config.url, 'notes/')) ? 'Note(s) uploaded successfully' :response?.data?.message,
          )
          setHeader(t(`${translatn}success`))
        }
        return response;
      },
      error => {
        if ((error?.config?.url !== apiRoutes.user.refreshAccessToken && error?.config?.url !== apiRoutes.user.logout) && error?.response?.status === 401 ) {
          axios.interceptors.response.eject(axiosInterceptor);
          const originalRequestResponse = auth.refreshAuthTokenAxioInterceptor(error.config, createAxiosResponseInterceptor)
          return Promise.resolve(originalRequestResponse)
        }
        if(error.config.url && !showNoErrorToastArr.includes(error.config.url) && error.code !== "ERR_CANCELED") {
          setShowToast(true)
          setBg('danger')
          setBodyMsg(error?.response?.data?.message)
          setHeader(t(`${translatn}error`))
        }
        return Promise.reject(error)
      }
    );
  }

  useEffect(() => {
    createAxiosResponseInterceptor()
  }, []);

  return (
    <APIContext.Provider value={null} >
      <ToastContainer position="top-center" className="p-1" style={{ zIndex: 10000 }}>
        <Toast autohide delay={5000} show={showToast} bg={bg} onClose={handleClose}
        >
          <Toast.Header style={{"height": "1.9rem"}} closeButton={false}>
            <small>{header}</small>
          </Toast.Header>
          <Toast.Body>
            <small>{bodyMsg}</small>
          </Toast.Body>
        </Toast>
      </ToastContainer>
      {children}
    </APIContext.Provider>
  );
}


