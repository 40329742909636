import React from 'react'
import Form from 'react-bootstrap/Form'
import { renderRequiredFieldLabel } from './InputComponentHelpers'

/* type SelectInputNoFormFieldsTypes = {
  name: string
  id: string
  title: string
  placeholder?: string
  defaultValue?: string
  optionsValueArray: any
  optionKeyFieldName?: string
  optionValueFieldName?: string
  disabled: boolean
  onChange?: any
  onBlur?: any
  required: boolean
  className?: string
  labelClassName?: string
} */

export const SelectInputNoForm = function (props: any) {
  const { name, id, title, defaultValue, placeholder, optionsValueArray, optionKeyFieldName, optionValueFieldName, disabled, onChange, required, className, labelClassName, ...otherProps } = props
  const placeholderStr = placeholder ? `Select ${placeholder}` : undefined
  const selectedValue = defaultValue ?? placeholderStr

  return (
    <>
      {title && <Form.Label className={labelClassName} htmlFor={id}>{required ? renderRequiredFieldLabel(title) : title}</Form.Label>}
      <Form.Select
        disabled={disabled}
        id={id}
        defaultValue={selectedValue}
        name={name}
        onChange={(value: React.ChangeEvent<HTMLSelectElement>) => {
          if (onChange) {
            onChange(value)
          }
        }}
        className={className}
        {...otherProps}
      >
        {placeholderStr ? <option value={''}>{placeholderStr}</option> : ''}
        {optionsValueArray?.map(
          (value: any) => {
            if (optionValueFieldName && optionKeyFieldName) {
              return (
                <option key={value[optionKeyFieldName]} value={value[optionKeyFieldName]}>
                  {value[optionValueFieldName]}
                </option>
              )
            }
            else {
              return (
                <option key={value} value={value}>
                  {value}
                </option>
              )
            }
          })
        }
      </Form.Select>
    </>
  )
}
