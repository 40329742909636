import React, { useContext } from 'react'
import { Navigate } from 'react-router-dom'
import { AuthContext, getLoggedInUserDetail } from '../../contexts'
import { appRoutes } from '../../utils/routes'
import { PRIVILEGES as P } from '../../utils/constants'
import { AuthUtil } from '../../utils/auth.utils'
import { ForcedChangePassword } from '../ChangePassword'


const { checkUserAccess } = AuthUtil

type Props = {
  children: any
  path: string
}

function checkPrivileges(path: string, children: any) {
  let noAccess = false
  switch (path) {
    case appRoutes.home:
      if (!checkUserAccess(P.VIEW_PATIENT) && !checkUserAccess(P.VIEW_USER_LIST)) noAccess = true
      if (checkUserAccess(P.VIEW_USER_LIST)) return <Navigate to={appRoutes.users} />
      break
    case appRoutes.registerPatient:
      if (!checkUserAccess(P.CREATE_PATIENT)) noAccess = true
      break
    case appRoutes.editPatient:
      if (!checkUserAccess(P.EDIT_PATIENT)) noAccess = true
      break
    case appRoutes.addSample:
      if (!checkUserAccess(P.ADD_SAMPLE)) noAccess = true
      break
    case appRoutes.editSample:
      if (!checkUserAccess(P.EDIT_SAMPLE)) noAccess = true
      break
    case appRoutes.viewSample:
      if (!checkUserAccess(P.VIEW_SAMPLE)) noAccess = true
      break
    case appRoutes.viewPatientDetails:
      if(!checkUserAccess(P.VIEW_PATIENT)) noAccess= true
      break
    case appRoutes.users:
      if(!checkUserAccess(P.VIEW_USER_LIST)) noAccess= true
      break
    }

  if (noAccess) {
    return <Navigate to={appRoutes.unauthorized} />
  }

  return children
}

export function PrivateRoute({ children, path }: Props) {
  const auth = useContext(AuthContext)

  const isCookieValid = auth.checkCookieIsValid()
  const userinfo = getLoggedInUserDetail()
  if (userinfo?.changePassword) {
    return ForcedChangePassword()
  }

  return auth.authenticatedUser && isCookieValid ? checkPrivileges(path, children) : <Navigate to={appRoutes.login} />
}
