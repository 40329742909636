import React, { createContext, useState, ReactNode } from 'react'
import { jwtDecode } from 'jwt-decode'
import { AppUtil } from '../utils/app.utils'
import { useNavigate } from 'react-router-dom'
import axios, { AxiosRequestConfig } from 'axios'
import { trackPromise } from 'react-promise-tracker'
import { logoutUser, refreshUserAccessToken, getUserDetailsByUsername } from '../services'
import { appRoutes } from '../utils/routes'
import { UserProfile } from '../models/Auth'
import { PP_LOGGED_IN_USER_DETAIL } from '../utils/constants'

export type AuthContextProps = {
  authenticatedUser: UserProfile | null
  updateChangePasswordFlag: (value: boolean) => void
  logout: () => void
  login: (authTokenStr: string, refreshTokenStr: string, redirectTo: string) => void
  checkCookieIsValid: () => boolean
  refreshAuthTokenAxioInterceptor: (originalRequest: AxiosRequestConfig | undefined, createAxiosResponseInterceptor: any) => void
  refreshAuthToken: () => void
}

type AuthProps = {
  children: ReactNode
}

const defaultProps = {
  authenticatedUser: null,
  updateChangePasswordFlag: () => { },
  logout: () => { },
  login: () => { },
  checkCookieIsValid: () => {
    return false
  },
  refreshAuthTokenAxioInterceptor: () => { },
  refreshAuthToken: () => { }
}

export const AuthContext = createContext<AuthContextProps>({ ...defaultProps })

export const getLoggedInUserDetail = () => {
  let userInfo = JSON.parse(localStorage.getItem(PP_LOGGED_IN_USER_DETAIL) ?? "{}")
  userInfo.name = ""
  if (userInfo) {
    if (userInfo?.firstName) userInfo.name = userInfo.name + userInfo.firstName
    if (userInfo?.firstName && userInfo?.lastName) userInfo.name = userInfo.name + " "
    if (userInfo?.lastName) userInfo.name = userInfo.name + userInfo.lastName
  }
  return userInfo
}

export const getTokenData = () => {
  const cookie = AppUtil.getAuthCookie()
  if (cookie) {
    const tokenData: UserProfile = jwtDecode(cookie)
    let userDetail = JSON.parse(localStorage.getItem(PP_LOGGED_IN_USER_DETAIL) ?? "{}")
    tokenData.siteName = userDetail.siteName
    return tokenData
  }
  return null
}

export const AuthProvider = ({ children }: AuthProps) => {
  const navigate = useNavigate()
  const [authenticatedUser, setAuthenticatedUser] = useState<UserProfile | null>(getTokenData())

  const checkCookieIsValid = () => {
    const tokenData = getTokenData()
    let isRefreshtoken = false
    let isCookieValid = false
    if (tokenData) {
      const currentDate = new Date()
      if (Number(tokenData.exp) * 1000 < currentDate.getTime()) {
        isRefreshtoken = true
      }
      else {
        isCookieValid = true
      }
    }

    if (isRefreshtoken === true) {
      refreshAccessToken()
      isCookieValid = true
    }
    return isCookieValid
  }

  const updateChangePasswordFlag = (flag: boolean) => {
    setAuthenticatedUser((prev: UserProfile | null) => { return { ...prev, changePassword: flag } as UserProfile })
    let userInfo = JSON.parse(localStorage.getItem(PP_LOGGED_IN_USER_DETAIL) ?? "{}")
    userInfo.changePassword = flag
    localStorage.setItem(PP_LOGGED_IN_USER_DETAIL, JSON.stringify(userInfo))
  }

  const setUserAuthdata = (authTokenStr: string, refreshTokenStr: string, cookieFunc: Function, redirectTo: string) => {
    const tokenData: UserProfile = jwtDecode(authTokenStr)
    const refreshTokenData: UserProfile = jwtDecode(refreshTokenStr)
    cookieFunc(authTokenStr, refreshTokenStr, new Date(Number(tokenData.exp) * 1000), new Date(Number(refreshTokenData.exp) * 1000))

    return getUserDetailsByUsername(tokenData.sub).then(
      (apiResponse) => {
        if (apiResponse.status !== 200 || !apiResponse.data) {
          return navigate(appRoutes.login, { replace: true })
        }
        tokenData.siteName = apiResponse.data.siteName
        setAuthenticatedUser(tokenData)
        localStorage.setItem(PP_LOGGED_IN_USER_DETAIL, JSON.stringify(apiResponse.data))
        if (redirectTo) return navigate(redirectTo)
      },
      (error) => {
        AppUtil.logError(error)
        navigate(appRoutes.login, { replace: true })
      },
    )
  }

  const login = (authTokenStr: string, refreshTokenStr: string, redirectTo: string) => {
    return setUserAuthdata(authTokenStr, refreshTokenStr, AppUtil.setAuthCookie, redirectTo)
  }

  const logout = () => {
    localStorage.clear()
    trackPromise(
      logoutUser().then(
        () => {
          //delete axios.defaults.headers.common['Authorization']
          setAuthenticatedUser(null)
          AppUtil.removeAuthCookie()
          navigate(appRoutes.login, { replace: true })
        },
        (error) => {
          AppUtil.logError(error)
          localStorage.clear()
          //delete axios.defaults.headers.common['Authorization']
          setAuthenticatedUser(null)
          AppUtil.removeAuthCookie()
          navigate(appRoutes.login, { replace: true })
        },
      ),
    )
  }

  function refreshAccessToken() {
    let refreshTokenValue = AppUtil.getRefreshCookie()
    refreshTokenValue = refreshTokenValue ?? ""

    return trackPromise(refreshUserAccessToken(refreshTokenValue).then(
      (apiResponse) => {
        if (apiResponse.status !== 200) {
          logout()
        } else {
          setUserAuthdata(apiResponse.data.accessToken, apiResponse.data.refreshToken, AppUtil.refreshAuthCookie, "")
        }
      },
      (error) => {
        AppUtil.logError(error)
        logout()
      },
    ).catch((error) => {
      AppUtil.logError(error)
      logout()
    })
    )
  }

  const refreshAccessTokenAxioInterceptor = (originalRequest: AxiosRequestConfig | undefined, createAxiosResponseInterceptor: any) => {
    let refreshTokenValue = AppUtil.getRefreshCookie()
    refreshTokenValue = refreshTokenValue ?? ""
    return trackPromise(
      refreshUserAccessToken(refreshTokenValue).then(
        (apiResponse) => {
          if (apiResponse.status !== 200) {
            return
          }
          if (originalRequest?.headers === undefined) {
            return
          }
          setUserAuthdata(apiResponse.data.accessToken, apiResponse.data.refreshToken, AppUtil.refreshAuthCookie, "")
          originalRequest.headers['Authorization'] = "Bearer " + apiResponse.data.accessToken
          return axios.request(originalRequest)
        },
        (error) => {
          logout()
          AppUtil.logError(error)
        },
      ).catch((error) => {
        AppUtil.logError(error)
      }).finally(createAxiosResponseInterceptor)
    )
  }

  const authValue = {
    authenticatedUser: authenticatedUser,
    updateChangePasswordFlag: updateChangePasswordFlag,
    logout: logout,
    login: login,
    checkCookieIsValid: checkCookieIsValid,
    refreshAuthTokenAxioInterceptor: refreshAccessTokenAxioInterceptor,
    refreshAuthToken: refreshAccessToken
  }

  return <AuthContext.Provider value={authValue}>{children}</AuthContext.Provider>
}

export const AuthConsumer = AuthContext.Consumer
