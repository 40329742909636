import React from 'react'
import { TumorSequencing } from './TumorSequencing'
import { SurgeryBiopsy } from './SurgeryBiopsy'
import { ClinicalConditions } from './ClinicalConditions'

export const TabFour = () => {
  return (
    <div >
      <TumorSequencing/>
      <SurgeryBiopsy/>
      <ClinicalConditions />
    </div>
  )
}