import { z } from 'zod'
import { MASTER_DATA_ID as MDI} from '../../../utils/constants'
import { userFormSiteFields } from './UserCreateFormHelpers'

const getTranslatedStr = (path: string): string => {
  const translatn = 'userCreateForm.errors'
  return `${translatn}.${path}`
}

const passwordSchema = (isRequired: boolean) => {
  if (isRequired)
    return z
      .string()
      .min(1,{message: getTranslatedStr('required') })
      .min(8, { message: getTranslatedStr('passwordMinLength') })
      .max(50, { message: getTranslatedStr('textLength') })
      .regex(/^[a-zA-Z]./, { message: getTranslatedStr('passwordStartChar') })
      .regex(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])/, { message: getTranslatedStr('invalidPassword') })
  else {
    return z
      .string()
      .min(1,{message: getTranslatedStr('required') })
      .min(8, { message: getTranslatedStr('passwordMinLength') })
      .max(50, { message: getTranslatedStr('textLength') })
      .regex(/^[a-zA-Z]./, { message: getTranslatedStr('passwordStartChar') })
      .regex(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])/, { message: getTranslatedStr('invalidPassword') })
      .or(z.literal(''))
  }
}

export const UserCreateFormSchema = (isPasswordRequired: any) =>
  z.object({
    username: z
      .string()
      .min(1, { message: getTranslatedStr('required') })
      .min(4, { message: getTranslatedStr('usernameMinLength') })
      .max(50, { message: getTranslatedStr('textLength') })
      .regex(/^\w+$/, getTranslatedStr('usernameCharCheck')),
    password: passwordSchema(isPasswordRequired),
    firstName: z
      .string()
      .min(1, { message: getTranslatedStr('required') })
      .max(50, { message: getTranslatedStr('textLength') })
      .regex(/^[a-zA-Z'._-]+$/, getTranslatedStr('nameGenericCharCheck')),
    lastName: z
      .string()
      .min(1, { message: getTranslatedStr('required') })
      .max(50, { message: getTranslatedStr('textLength') })
      .regex(/^[a-zA-Z'._-]+$/, getTranslatedStr('nameGenericCharCheck')),
    email: z
      .string()
      .min(1, { message: getTranslatedStr('required') })
      .max(100, { message: getTranslatedStr('emailTextLength') })
      .email(getTranslatedStr('invalidEmail')),
    roleId: z.coerce.string().min(1, { message: getTranslatedStr('selectGenericMin1Length') }),
    userStatus: z.coerce.string().min(1, { message: getTranslatedStr('selectGenericMin1Length') }),
    siteName: z.coerce.string().nullable(),
    siteId: z.string().nullable() , 
    employeeId: z
      .coerce
      .string()
      .min(1, { message: getTranslatedStr('required') })
      .pipe(z.coerce.number().positive(getTranslatedStr('invalidEmployeeId')))
      .refine((val) => {
        return val
      })
      .or(z.coerce.number().refine((val) => val === null, { message: getTranslatedStr('required') }))
    .or(z.number()), 
  }).superRefine((values: any, ctx) => {
    userFormSiteFields.forEach((field: string) => {
      if (values.roleId === MDI.CRC_ROLE && !values[field]) {
        ctx.addIssue({
          message: getTranslatedStr('required'),
          code: z.ZodIssueCode.custom,
          path: [field],
        })
      }
    })
  })
