import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Document, Page, pdfjs } from "react-pdf"
import { ChevronLeft, ChevronRight } from 'react-bootstrap-icons'
import "react-pdf/dist/esm/Page/TextLayer.css";
import 'react-pdf/dist/Page/AnnotationLayer.css';
import './PDFStyles.scss'

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

type PDFViewerProps = {
  pdfData: Uint8Array
  resetPageNumber: string
}

export const PDFViewer = (props: PDFViewerProps) => {
  const { pdfData, resetPageNumber } = props
  const translatn = 'pdf.'
  const { t } = useTranslation()
  const [pageNumber, setPageNumber] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [pdfBytesData, setPdfBytesData] = useState<Blob>(new Blob([pdfData], { type: "application/pdf" }));
  const parentClass = 'vcr-pp-view-pdf-render'

  function changePage(offset: number) {
    setPageNumber(prevPageNumber => prevPageNumber + offset)
  }

  useEffect(() => {
    if (resetPageNumber === "reset") setPageNumber(1)
    setPdfBytesData(new Blob([pdfData], { type: "application/pdf" }))
  }, [pdfData])

  useEffect(() => {
    if (resetPageNumber === "reset") setPageNumber(1)
  }, [resetPageNumber])

  return (
    <div className={`${parentClass}`}>
      <div className={`${parentClass}-doc-div`}>
        <Document
          file={URL.createObjectURL(pdfBytesData)}
          onLoadSuccess={(pdf) => {
            setTotalPages(pdf.numPages)
          }}
        >
          <div className={`${parentClass}-page-div`}>
            <Page
              key={`page_${pageNumber}`}
              pageNumber={pageNumber}
              width={750}
              renderAnnotationLayer={false}
              renderForms={false}
              renderTextLayer={false}
            />
          </div>
        </Document>
      </div>
      <div className={`${parentClass} pagination`}>
        <button
          type="button"
          disabled={pageNumber <= 1}
          onClick={() => changePage(-1)}
          className={`${parentClass}-btn`}
        >
          <ChevronLeft />
        </button>
        <p className={`${parentClass}-pageno`}>
          {t(`${translatn}page`)} {pageNumber} {t(`${translatn}of`)} {totalPages}
        </p>
        <button
          type="button"
          disabled={pageNumber >= totalPages}
          onClick={() => changePage(1)}
          className={`${parentClass}-btn`}
        >
          <ChevronRight />
        </button>
      </div>
    </div>
  )
}
