import React, { useState} from 'react'
import Container from 'react-bootstrap/Container'
import { useTranslation } from 'react-i18next'
import { Medication, TabProps } from '../../../models/Sample'

import { AppUtil } from '../../../utils/app.utils'
import Button from 'react-bootstrap/Button'
import { ReactTable } from '../../CommonComponents'
import { ViewMedicationDetailsContainer } from '../../ViewMedicationDetails/ViewMedicationDetailsContainer'
import _ from 'lodash'
import { BsEyeFill } from 'react-icons/bs'
import "./styles/Medication.scss"

export const Medications = (props: TabProps) => {
  const { sampleDetails } = props
  const { t } = useTranslation()
  const [selectedObj, setSelectedObj] = useState<Medication>()
  const [showViewModal, setShowViewModal] = useState(false)
  const translatn = 'viewSample.'
  const parentClass = 'medication'

  const renderAction = (info: any) => {
    const medObj = _.cloneDeep(info.row.original)
    return (
      <div className={`${parentClass}`}>
        <Button onClick={() => handleView( medObj)} className={`${parentClass}-view-btn`}><BsEyeFill size={20} /></Button>
      </div>
    )
  }
  const columns = [
    {
      id: 'medicationName',
      header: t(`${translatn}medicationName`),
      accessorKey: 'medicationName',
      enableSorting: false,
      cell: (info: any) => info.getValue(),
    },
    {
      id: 'medicationDosage',
      header: t(`${translatn}medicationDosage`),
      accessorKey: 'medicationDosage',
      enableSorting: false,
      cell: (info: any) => info.getValue(),
    },
    {
      id: 'medicationFrequency',
      header: t(`${translatn}medicationFrequency`),
      accessorKey: 'medicationFrequency',
      enableSorting: false,
      cell: (info: any) => info.getValue() ?? '-',
    },
    {
      id: 'medicationStartDate',
      header: t(`${translatn}medicationStartDate`),
      accessorKey: 'medicationStartDate',
      enableSorting: false,
      cell: (info: any) => AppUtil.formatDateToString(info.getValue()),
    },
    {
      id: 'medicationStopDate',
      header: t(`${translatn}medicationStopDate`),
      accessorKey: 'medicationStopDate',
      enableSorting: false,
      cell: (info: any) => AppUtil.formatDateToString(info.getValue()),
    },
    {
      id: 'action',
      header: t(`${translatn}medicationAction`),
      accessorKey: 'action',
      enableSorting: false,
      cell: (info: any) => renderAction(info),
    }

  ]

  const handleViewModalClose = () => {
    setShowViewModal(false)
  }
  const handleView = ( object: Medication) => {
    setShowViewModal(true)
    setSelectedObj(object)
  }


  return (
    <Container fluid>
      <h5 className="fw-bold pt-3"> {t(`${translatn}medications`)}</h5>
      <ReactTable
        data={sampleDetails?.medication ?? []}
        columns={columns}
        pageChangeHandler={() => {
          return
        }}
        tableClassName={'view-medication-table'}
        showPagination={false}
      />

      <ViewMedicationDetailsContainer open={showViewModal} handleClose={handleViewModalClose} medication={selectedObj} />
    </Container>
  )
}
