import axios from "axios";
import { apiRoutes } from "../utils/routes";
import { AppUtil } from "../utils/app.utils";

export const downladPDFDataAsFile = (fileName: string, pdfBytesData: Uint8Array) => {
  const fileBlob = new Blob([pdfBytesData],  {type: 'application/pdf'})
  const fileURL = URL.createObjectURL(fileBlob)

  const link = document.createElement("a")
  link.href = fileURL;
  link.download = fileName
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export const uploadPDFDataAsFile = async(uploadURL: string, pdfBytesData: Uint8Array) => {
  const contentType = 'application/pdf'
  const fileBlob = new Blob([pdfBytesData],  {type: contentType})

  const response = await axios.put(
    uploadURL,
    fileBlob,
    {
      headers: {
        "Content-Type": contentType,
        // "ContentType": contentType,
      }
    }
  )

  return response
}

export const getReportUploadURLs = async(sampleId: string, testId: string) => {
  const response = await axios.post(apiRoutes.reports.uploadURLs, 
    {sampleId: sampleId, testId: testId}, 
    AppUtil.getCommonAPIConfig()
  )
  return response
}

export const getReportDownloadURLs = async(sampleId: string, testId: string) => {
  const response = await axios.post(apiRoutes.reports.downloadURLs, 
    {sampleId: sampleId, testId: testId}, 
    AppUtil.getCommonAPIConfig()
  )
  return response
}

export const updateReportStatus = async (sampleId: string, testId: string, reportKey: string, presignedURL: string, isSigned: boolean) => {
  const response = await axios.post(
    apiRoutes.reports.statusUpdate,
    {sampleId: sampleId, testId: testId, reportKey: reportKey, path: presignedURL, isSigned: isSigned}, 
    AppUtil.getCommonAPIConfig()
  )
  return response
}
