import React from 'react'
import { useTranslation } from 'react-i18next'
import Form from 'react-bootstrap/Form'
import { access, renderRequiredFieldLabel } from './InputComponentHelpers'
import { useFormContext } from 'react-hook-form'

/* type NumberInputFieldsTypes = {
  name: string
  id: string
  title?: string
  placeholder: string
  disabled: boolean
  readOnly: boolean
  required: boolean
  onChange?: any
} */

export const NumberInput = function (props: any) {
  const { name, id, title, placeholder, disabled, readOnly, required, onChange, ...otherProps } = props
  const { t } = useTranslation()
  const formMethods = useFormContext()
  const {
    register,
    formState: { errors },
  } = formMethods
  const error = access(name, errors)
  const numberInputInvalidChars = ['-', '+', 'e']

  return (
    <div>
      {title && <Form.Label htmlFor={id}>{required ? renderRequiredFieldLabel(title) : title}</Form.Label>}
      <Form.Control
        autoComplete='off'
        disabled={disabled}
        readOnly={readOnly}
        id={id}
        type="number"
        placeholder={placeholder}
        {...register(name)}
        isInvalid={error}
        onChange={onChange}
        onKeyDown={(e) => {
          if (numberInputInvalidChars.includes(e.key.toLowerCase())) {
            e.preventDefault()
          }
        }}
        {...otherProps}
      />
      <Form.Control.Feedback type="invalid">{t(error?.message)}</Form.Control.Feedback>
    </div>
  )
}
