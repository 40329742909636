import React from 'react'
import { TumorMarkers } from './TumorMarkers'
import { Labs } from './Labs'
import { CytogenicAbnormalities } from './CytogenicAbnormalities'
import { Sample } from '../../../models/Sample'

type Props = {
  sampleDetails: Sample | null
}

export const TabThree = ({ sampleDetails }: Props) => {
    return (
        <div >
          <TumorMarkers  sampleDetails={sampleDetails}/>
          <Labs  sampleDetails={sampleDetails}/>
          <CytogenicAbnormalities  sampleDetails={sampleDetails}/>
        </div>
    )
}