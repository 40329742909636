import React from 'react'
import { AuthContext, getTokenData } from '../contexts'


export const AuthUtil = {
  checkUserAccess: function (privileges: string) {    
    const auth = React.useContext(AuthContext)
    let tokenData = getTokenData()
    
    if (tokenData === undefined || tokenData === null) {
      auth.refreshAuthToken()
      tokenData = getTokenData()
    }

    if (tokenData === undefined || tokenData === null) {
      return false
    }
    
    const userPrivileges = tokenData?.privileges ?? []
    return userPrivileges.includes(privileges)
  },
}
