import React, { useState, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { UserIdleTimeout } from '../../../hooks'
import { AuthContext, getTokenData } from '../../../contexts'
import { Container, Button, Modal, Row } from 'react-bootstrap'
import { DEFAULT_SESSION_TIMEOUT } from '../../../utils/constants'
import './styles/SessionTimeout.scss'

function SessionTimeout() {
  const { t } = useTranslation()
  const translatn = 'userIdleSession'

  const auth = useContext(AuthContext)
  const userName = auth?.authenticatedUser?.sub
  const parentClass = 'session-timeout'
  const [openModal, setOpenModal] = useState(false)

  const handleIdle = () => {
    setOpenModal(true)
  }

  const handleLogout = () => {
    auth.logout()
    setOpenModal(false)
  }

  const tokenData = getTokenData()

  let IDLE_SESSION_TIMEOUT = DEFAULT_SESSION_TIMEOUT
  if (tokenData) {
    IDLE_SESSION_TIMEOUT = Number(tokenData.exp) - Number(tokenData.iat)
  }

  const { idleTimer } = UserIdleTimeout({ onIdlePromt: handleIdle, handleLogout: handleLogout, idleTime: IDLE_SESSION_TIMEOUT })

  const stay = () => {
    auth.refreshAuthToken()
    setOpenModal(false)
    idleTimer.reset()
  }

  return (
    <>
      {userName && (
        <Container>
          <Modal className={`${parentClass}`} show={openModal} onHide={stay}>
            <Modal.Header closeButton>
              <Modal.Title>{t(`${translatn}.header`)}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>{t(`${translatn}.timeoutWarning`)}</Row>
              <Row>{t(`${translatn}.stayInConfirmation`)}</Row>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleLogout}>
                {t(`${translatn}.logOutButton`)}
              </Button>
              <Button className={`${parentClass}-logged-in-btn`} onClick={stay}>
                {t(`${translatn}.stayLoginedButton`)}
              </Button>
            </Modal.Footer>
          </Modal>
        </Container>
      )}
    </>
  )
}

export default SessionTimeout
