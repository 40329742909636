import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'

import { ReactTable } from '../../CommonComponents'
import { AppUtil } from '../../../utils/app.utils'
import { ExclamationCircle, TrashFill } from 'react-bootstrap-icons'
import { useFormContext } from 'react-hook-form'
import { Medication } from '../../../models/Sample'
import { MedicationModal } from './MedicationModal'
import _ from 'lodash'
import { ViewMedicationDetailsContainer } from '../../ViewMedicationDetails/ViewMedicationDetailsContainer'
import { BsEyeFill } from 'react-icons/bs'
import { MdEditNote } from 'react-icons/md'

export const Medications = () => {
  const { t } = useTranslation()
  const { getValues, setValue, getFieldState, trigger, watch } = useFormContext()
  console.log('check state==-->',getFieldState('medication'))
  const translatn = 'sampleInfoForm.'
  const parentClass = 'vcr-pp-sample-info-'
  const [selectedIndex, setSelectedIndex] = useState<number>()
  const [selectedObj, setSelectedObj] = useState<Medication>()
  const [showFormModal, setShowFormModal] = useState(false)
  const [showViewModal, setShowViewModal] = useState(false)

  const handleViewModalClose = () => {
    setShowViewModal(false)
  }

  const handleFormModalClose = () => {
    const medsValue = getValues('medication')
    const selectedMed = medsValue.find((item: Medication) => item.medId === selectedIndex)
    if (selectedMed.status === 'NEW') {
      setValue(
        'medication',
        [...medsValue.filter((item: Medication) => item.medId !== selectedIndex)]
      )
    } else {
      setValue(
        'medication',
        medsValue.map((item: Medication) => {
          if (item.medId == selectedIndex) {
            return { ...selectedObj }
          }
          return item
        }),
      )
    }
    setShowFormModal(false)
    trigger('medication')
  }

  const handleSave = () => {
    const medsValue = getValues('medication')
    trigger('medication').then(value => {
      if (value) {
        setValue(
          'medication',
          medsValue.map((item: Medication) => {
            if (item.medId == selectedIndex) {
              return {
                ...item,
                status: ''
              }
            }
            return item
          }),
        )
        setShowFormModal(false)
      }
    })
  }

  const handleView = (id: number, object: Medication) => {
    setShowViewModal(true)
    setSelectedIndex(id)
    setSelectedObj(object)
  }

  const handleDelete = (id: number) => {
    const medsValue = getValues('medication')
    setValue(
      'medication',
      medsValue.filter((item: Medication) => item.medId !== id),
    )
  }

  const handleEdit = (id: number, obj: Medication) => {
    trigger('medication')
    setSelectedIndex(id)
    setSelectedObj(obj)
    setShowFormModal(true)
  }

  const renderAction = (info: any) => {
    const medObj = _.cloneDeep(info.row.original)
    const id = medObj.medId
    return (
      <div className={`${parentClass}medication-action`}>
       <Col><Button onClick={() => handleView(id, medObj)}> <BsEyeFill size={20} /></Button></Col>
        <span className={`action-button-vertical-line`}></span>
        <Col><Button onClick={() => handleEdit(id, medObj)}><MdEditNote size={20} /></Button></Col>
        <span className={`action-button-vertical-line`}></span>
        <Col><Button onClick={() => handleDelete(id)}> <TrashFill size={22}/></Button></Col>
      </div>
    )
  }

  const handleAdd = () => {
    const count = getValues('medication')?.length
    setSelectedIndex(count)
    // set default value to null so validation does not throw invalid date error
    const defaultObj = {
      medicationName: '',
      medicationDosage: '',
      medicationFrequency: '',
      medicationPrescribingPhysician: '',
      medicationBrandOrManufacturer: '',
      medicationAdverseEffects: '',
      medicationClass: '',
      medicationDisease: '',
      medicationRegimen: '',
      medicationMissedDoses: '',
      medicationStartDate: null,
      medicationStopDate: null,
      status: 'NEW',
      medId: count
    }
    setValue(
      `medication.${count}`,
      defaultObj
    )
    setShowFormModal(true)
  }

  const columns = [
    {
      id: 'medicationName',
      header: t(`${translatn}medicationName`),
      accessorKey: 'medicationName',
      enableSorting: false,
      cell: (info: any) => info.getValue(),
    },
    {
      id: 'medicationDosage',
      header: t(`${translatn}medicationDosage`),
      accessorKey: 'medicationDosage',
      enableSorting: false,
      cell: (info: any) => info.getValue(),
    },
    {
      id: 'medicationFrequency',
      header: t(`${translatn}medicationFrequency`),
      accessorKey: 'medicationFrequency',
      enableSorting: false,
      cell: (info: any) => info.getValue() ?? '-',
    },
    {
      id: 'medicationStartDate',
      header: t(`${translatn}medicationStartDate`),
      accessorKey: 'medicationStartDate',
      enableSorting: false,
      cell: (info: any) => AppUtil.formatDateToString(info.getValue()),
    },
    {
      id: 'medicationStopDate',
      header: t(`${translatn}medicationStopDate`),
      accessorKey: 'medicationStopDate',
      enableSorting: false,
      cell: (info: any) => AppUtil.formatDateToString(info.getValue()),
    },
    {
      id: 'action',
      header: t(`${translatn}medicationAction`),
      accessorKey: 'action',
      enableSorting: false,
      cell: (info: any) => renderAction(info),
    }

  ]


  return (
    <div className={`${parentClass}input-field`}>
      <h5 className={`${parentClass}medication-form-heading`}> {t(`${translatn}medications`)} <Button onClick={handleAdd} className={`${parentClass}medication-addbtn px-4`}>Add</Button></h5>
      <ReactTable
        data={[...watch('medication')]?.filter(med => med.status !== 'NEW') ?? []}
        columns={columns}
        pageChangeHandler={() => {
          return
        }}
        tableClassName={'view-medication-table'}
        showPagination={false}
      />

      {getFieldState('medication')?.error ? <div className={`${parentClass}medication-error`}>
        <ExclamationCircle size={20} />
        {t(`${translatn}noMedications`)}
      </div> : null}
      <MedicationModal
        open={showFormModal}
        handleClose={handleFormModalClose}
        handleSave={handleSave}
        selectedIndex={selectedIndex ?? -1} />
      <ViewMedicationDetailsContainer open={showViewModal} handleClose={handleViewModalClose} medication={selectedObj} />
    </div>
  )
}
