import React, { useContext, useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import { useTranslation } from 'react-i18next'
import { PersonCircle, Power } from 'react-bootstrap-icons'
import './Header.scss'
import { appRoutes } from '../../../utils/routes'
import { AuthContext } from '../../../contexts'
import Button from 'react-bootstrap/Button'
import vcr_park_img from '../../../images/vcr_park.png'
import Dropdown from 'react-bootstrap/esm/Dropdown'
import { PasswordChangeModal } from '../../ChangePassword'
import { useNavigate } from 'react-router-dom'
import { Site } from '../../../models/MasterData'
import { trackPromise } from 'react-promise-tracker'
import { getSiteList } from '../../../services/MasterData'
import { AxiosResponse } from 'axios'
import { LogoutWarningModal } from './LogoutWarningModal'
import { AboutModal } from './AboutModal'


function Header() {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const translatn = 'header'
  const profileViewParentClass = 'logged-user-info'
  const auth = useContext(AuthContext)
  const userName = auth?.authenticatedUser?.sub
  const userRole = auth?.authenticatedUser?.role
  const userSiteId = auth?.authenticatedUser?.siteId
  const userEmployeeId = auth?.authenticatedUser?.employeeId
  const [userSiteName, setUserSiteName] = useState('')
  const [aboutModalshow, setAboutModalshow] = useState(false)
  const [passwordModalshow, setPasswordModalshow] = useState(false)
  const [logoutModalshow, setLogoutModalshow] = useState(false)
  const handleLogout= ()=>
  {
    setLogoutModalshow(false)
    auth.logout();
  }
 
  useEffect(() => {
    if(userName){
    trackPromise(
      getSiteList().then((res: AxiosResponse<Site[]>) => {
        setUserSiteName(res.data.find((state: Site) => state.siteId == userSiteId)?.siteName || '');
      }),
    )
  }
  return () => {
    setUserSiteName('')
  }
  }, [userName])
  
  return (
    <>
      {passwordModalshow && <PasswordChangeModal isCancelDisabled={false} show={passwordModalshow} handleClose={()=>setPasswordModalshow(false)} />}
      {aboutModalshow && <AboutModal show={aboutModalshow} handleClose={()=>setAboutModalshow(false)} />}
      {logoutModalshow && <LogoutWarningModal show={logoutModalshow} handleLogout={handleLogout} handleClose={() => setLogoutModalshow(false)} />}
      <Navbar fixed={'top'} variant={'dark'} bg={'white'} className="py-0">
        <Container fluid>
          <Nav className="justify-content-left">
            <Navbar.Brand href="" onClick={() => userName ? navigate(appRoutes.home) : navigate(appRoutes.login, { replace: true })}>
              <img alt='home' src={vcr_park_img}></img>
            </Navbar.Brand>
          </Nav>

          {userSiteName && (
            <Nav className={profileViewParentClass}>
              <Navbar.Text className={profileViewParentClass + '-header'}>{userSiteName}</Navbar.Text>
            </Nav>
          )}

          {userName && (
            <Nav className="justify-content-end">
              <div className={profileViewParentClass}>
                <div className={profileViewParentClass + '-text'}>
                  <Navbar.Text className={profileViewParentClass + '-text-link'}>{userName}</Navbar.Text>
                  <Navbar.Text className={profileViewParentClass + '-sm-text'}>{userRole} {userEmployeeId? "| " + userEmployeeId : ""}</Navbar.Text>
                </div>
                <Navbar.Text>
                  <Dropdown className={profileViewParentClass + '-dropdown'}>
                    <Dropdown.Toggle variant="white" id="dropdown-basic">
                      <PersonCircle color="#075883" size={22} />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className={profileViewParentClass + '-dropdown-menu'}>
                      <Dropdown.Item className={profileViewParentClass + '-dropdown-item'} onClick={() => setPasswordModalshow(true)}>
                        {t(`${translatn}.changePassword`)}
                      </Dropdown.Item>
                      <Dropdown.Item className={profileViewParentClass + '-dropdown-item'} onClick={() => setAboutModalshow(true)}>
                        {t(`${translatn}.about`)}
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Navbar.Text>
                <Navbar.Text>
                  <Button onClick={() => setLogoutModalshow(true)} variant="white">
                    <Power color="#075883" size={22} />
                  </Button>
                </Navbar.Text>
              </div>
            </Nav>
          )}
        </Container>
      </Navbar>
    </>
  )
}

export default Header
