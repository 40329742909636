import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'

import { Patient } from '../../../models/RegisterPatient'
import './styles/ViewPatientDetails.scss'
import { ViewDetailsColumn } from '../../CommonComponents'
import { AppUtil } from '../../../utils/app.utils'
import { MASTER_DATA_KEYS as MDK } from '../../../utils/constants'
import { MasterDataContext } from '../../../contexts'
import _ from 'lodash'

type Props = {
  patientDetails: Patient | null
}

type Value = string | Date | null | undefined | boolean | number

type Details = {
  label: string
  label2?: string
  value: Value
  show?: boolean
}

const parentClass = 'vcr-pp-view-patient-tab-'

const renderRow = (rowArr: Details[], index: number) => {
  return (
    <div key={index}>
      <Row className={`${parentClass}row mt-4 `} border="dark" key={index}>
        {rowArr.map((col) => (
          <ViewDetailsColumn
            key={col.label}
            label={col.label}
            label2={col.label2}
            value={col.value}
            parentClass={parentClass}
          />
        ))}
      </Row>
      <hr />
    </div>
  )
}

export const ViewPatientDetails = (props: Props) => {
  const { patientDetails } = props
  const { t } = useTranslation()
  const { masterData } = useContext(MasterDataContext)
  const translatn = 'viewPatient.'

  const detailsArr = [
    {
      label: t(`${translatn}patientId`),
      value: patientDetails?.id,
      show: true,
    },
    {
      label: t(`${translatn}siteId`),
      value: patientDetails?.siteID,
      show: true,
    },
    {
      label: t(`${translatn}siteName`),
      value: patientDetails?.siteName,
      show: true,
    },
    {
      label: t(`${translatn}dateOfRequest`),
      value: patientDetails?.registrationDate,
      show: true,
    },

    {
      label: t(`${translatn}consentProvided`),
      value: AppUtil.convertBooleanToStr(patientDetails?.consentProvided ?? ''),
      show: true,
    },
    {
      label: t(`${translatn}race`),
      value: AppUtil.getFormattedValueWithOther(MDK.RACE, masterData, patientDetails?.race, patientDetails?.raceOther),
      show: true,
    },
    {
      label: t(`${translatn}ethnicity`),
      value: AppUtil.getFormattedValueWithOther(
        MDK.ETHNICITY,
        masterData,
        patientDetails?.ethnicity,
        patientDetails?.ethnicityOther,
      ),
      show: true,
    },
    {
      label: t(`${translatn}nationality`),
      value: patientDetails?.nationality,
      show: true,
    },
    {
      label: t(`${translatn}gender`),
      value: AppUtil.getFormattedValueWithOther(
        MDK.GENDER,
        masterData,
        patientDetails?.gender,
        patientDetails?.genderOther,
      ),
      show: true,
    },
    {
      label: t(`${translatn}dateOfBirth`),
      value: patientDetails?.dob,
      show: true,
    },
    {
      label: t(`${translatn}age`),
      value: patientDetails?.age,
      show: true,
    },
    {
      label: t(`${translatn}countryCode`),
      value: patientDetails?.countryCode,
      show: true,
    },
    {
      label: t(`${translatn}contactDetails`),
      value: patientDetails?.phoneNumber,
      show: true,
    },
    {
      label: t(`${translatn}emailId`),
      value: patientDetails?.email,
      show: true,
    },
    {
      label: t(`${translatn}diagnosis`),
      value: patientDetails?.diagnosis,
      show: true,
    },
    {
      label: t(`${translatn}address`),
      value: patientDetails?.addressLine1,
      show: true,
    },
    {
      label: t(`${translatn}address2`),
      value: patientDetails?.addressLine2,
      show: true,
    },
    {
      label: t(`${translatn}state`),
      value: patientDetails?.state,
      show: true,
    },
    {
      label: t(`${translatn}city`),
      value: patientDetails?.city,
      show: true,
    },
    {
      label: t(`${translatn}pinCode`),
      value: patientDetails?.pinCode,
      show: true,
    },
    {
      label: t(`${translatn}uhid`),
      value: patientDetails?.uhid,
      show: true,
    },
  ]

  const data = detailsArr.filter((details) => details.show === true)

  const rowCount = _.chunk(data, 5)

  return (
    <div className={`${parentClass}container  bg-white`}>
      <Container fluid>{rowCount.map((row, index) => renderRow(row, index))}</Container>
    </div>
  )
}
