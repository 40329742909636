import React, { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import { RadioInput, SelectInput, TextInput } from '../../InputComponents'
import { MASTER_DATA_KEYS as MDK, MASTER_DATA_ID as MDI } from '../../../utils/constants'
import { MasterDataContext } from '../../../contexts'
import { AppUtil, AppUtil as _ } from '../../../utils/app.utils'
import { useFormContext } from 'react-hook-form'
import { TrashFill } from 'react-bootstrap-icons'
import { Button } from 'react-bootstrap'
import { useParams } from 'react-router-dom'

interface FamilyExistingHistorySchema {
  id?: string | number,
  sampleId?: string | number,
  relationToPatient: string,
  relationToPatientOther?: string,
  familyMemberCancerSite: string,
  familyMemberCancerSiteOther?: string,
  familyMemberAgeAtDiagnosis: string
}

export const FamilyHistory = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const translatn = 'sampleInfoForm'
  const parentClass = 'vcr-pp-sample-info-'
  const { masterData } = useContext(MasterDataContext)
  const { watch, setValue, getValues, resetField, clearErrors } = useFormContext()

  useEffect(() => {
    if (getValues('familyHistoryOfCancer') == MDI.FAMILY_HISTORY_OF_CANCER_OPTION_YES) {
      if (getValues('familyHistoryDetails')?.length) {
        setValue('familyHistoryDetails', getValues('familyHistoryDetails'))
      } else {
        setValue('familyHistoryDetails', [{
          sampleId: id ?? undefined,
          relationToPatient: '',
          relationToPatientOther: '',
          familyMemberCancerSite: '',
          familyMemberCancerSiteOther: '',
          familyMemberAgeAtDiagnosis: ''
        }])
      }
    } else if (getValues('familyHistoryOfCancer')) {
      setValue('familyHistoryDetails', [])
      clearErrors('familyHistoryDetails')
    }
  }, [id, watch('familyHistoryOfCancer')])

  const handleRemoveFamilyDetails = (index: number) => {
    getValues('familyHistoryDetails').splice(index, 1)
    setValue('familyHistoryDetails', [...getValues('familyHistoryDetails')])
  }

  const handleAddMore = () => {
    setValue('familyHistoryDetails', [...getValues('familyHistoryDetails'), {
      sampleId: id ?? undefined,
      relationToPatient: '',
      relationToPatientOther: '',
      familyMemberCancerSite: '',
      familyMemberCancerSiteOther: '',
      familyMemberAgeAtDiagnosis: ''
    }])
  }

  return (
    <div className={`${parentClass}input-field`}>
      <h5 className={`${parentClass}form-heading`}> {t(`${translatn}.familyHistory`)}</h5>
      <Row lg={4} md={4} sm={4}>
        <Col>
          <RadioInput
            name={`familyHistoryOfCancer`}
            id="existingHistory"
            valueArray={_.sortOptionsDesc(_.getOptions(MDK.FAMILY_HISTORY, masterData))}
            title={t(`${translatn}.existingHistory`)}
            disabled={false}
            inline={true}
            required={true}
          />
        </Col>
      </Row>
      {
        watch('familyHistoryDetails')?.map((familyHistoryItem: FamilyExistingHistorySchema, index: number) => {
          return <div key={index}><Row gap={0} lg={getValues('familyHistoryDetails').length === 1 ? 4 : undefined} md={getValues('familyHistoryDetails').length === 1 ? 4 : undefined} sm={getValues('familyHistoryDetails').length === 1 ? 4 : undefined}>
            <Col>
              <SelectInput
                name={`familyHistoryDetails.${[index]}.relationToPatient`}
                id={'relationToPatient'}
                title={t(`${translatn}.relationToPatient`)}
                optionsValueArray={_.getOptions(MDK.RELATION_TO_PATIENT, masterData)}
                placeholder={t(`${translatn}.relationToPatientPlaceholder`)}
                onCustomChange={(event: React.ChangeEvent<HTMLSelectElement>) => AppUtil.resetOtherField(event.target.value, MDI.RELATION_TO_PATIENT_OTHER, resetField, `familyHistoryDetails.${[index]}.relationToPatientOther`)}
                disabled={false}
                required={true}
              />
            </Col>
            {watch(`familyHistoryDetails.${[index]}.relationToPatient`) == MDI.RELATION_TO_PATIENT_OTHER && (
              <Col>
                <TextInput
                  type="text"
                  name={`familyHistoryDetails.${[index]}.relationToPatientOther`}
                  id={'relationToPatientOther'}
                  title={t(`${translatn}.relationToPatientOther`)}
                  placeholder={t(`${translatn}.relationToPatientOtherPlaceholder`)}
                  disabled={false}
                  readOnly={false}
                  required={false}
                />
              </Col>
            )}
            <Col>
              <SelectInput
                name={`familyHistoryDetails.${[index]}.familyMemberAgeAtDiagnosis`}
                id={'ageAtFamilyMemberDiagnosis'}
                title={t(`${translatn}.ageAtFamilyMemberDiagnosis`)}
                placeholder={t(`${translatn}.ageAtFamilyMemberDiagnosisPlaceholder`)}
                disabled={false}
                optionsValueArray={_.getOptions(MDK.AGE_AT_DIAGNOSIS, masterData)}
                required={true}
              />
            </Col>
            <Col>
              <SelectInput
                name={`familyHistoryDetails.${[index]}.familyMemberCancerSite`}
                id={'cancerSite'}
                title={t(`${translatn}.cancerSite`)}
                placeholder={t(`${translatn}.cancerSitePlaceholder`)}
                disabled={false}
                optionsValueArray={_.getOptions(MDK.FAMILY_MEMBER_CANCER_SITE, masterData)}
                onCustomChange={(event: React.ChangeEvent<HTMLSelectElement>) => AppUtil.resetOtherField(event.target.value, MDI.FAMILY_MEMBER_CANCER_SITE_OTHER, resetField, `familyHistoryDetails.${[index]}.familyMemberCancerSiteOther`)}
                required={true}
              />
            </Col>
            {watch(`familyHistoryDetails.${[index]}.familyMemberCancerSite`) == MDI.FAMILY_MEMBER_CANCER_SITE_OTHER && (
              <Col>
                <TextInput
                  type="text"
                  name={`familyHistoryDetails.${[index]}.familyMemberCancerSiteOther`}
                  id={'cancerSiteOther'}
                  title={t(`${translatn}.cancerSiteOther`)}
                  placeholder={t(`${translatn}.cancerSiteOtherPlaceholder`)}
                  disabled={false}
                  readOnly={false}
                  required={false}
                />
              </Col>
            )}
            {watch('familyHistoryDetails').length > 1 && (
              <Col className="d-flex align-items-end">
                <TrashFill size={22} onClick={() => handleRemoveFamilyDetails(index)} className="mb-2" style={{ cursor: 'pointer' }} />
              </Col>
            )}
          </Row>
            {index == watch('familyHistoryDetails').length - 1 && (
              <Row lg={4} md={4} sm={4}>
                <Col className={`${parentClass}add-text-btn-col`}>

                  <Button className={`${parentClass}add-text-btn`} variant="outline-primary" onClick={handleAddMore}>
                    {t(`${translatn}.addMoreBtn`)}
                  </Button>
                </Col>
              </Row>
            )}
          </div>
        })
      }
    </div>
  )
}
