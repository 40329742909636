import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { useFormContext } from 'react-hook-form'

import { NumberInput, DateInput, SelectInput, TextInput } from '../../InputComponents'
import { setAgeAtEvent } from '../helpers'
import { MASTER_DATA_KEYS as MDK, MASTER_DATA_ID as MDI } from '../../../utils/constants'
import { MasterDataContext } from '../../../contexts'
import { AppUtil as _, AppUtil } from '../../../utils/app.utils'
import { getDateOfBirth } from '../helpers/AddSampleInfoHelpers'

export const MetastaticDiagnosis = () => {
  const { t } = useTranslation()
  const { setValue, getValues, watch, resetField } = useFormContext()
  const { masterData } = useContext(MasterDataContext)
  const translatn = 'sampleInfoForm'
  const parentClass = 'vcr-pp-sample-info-'
  const dob = getValues('dob')

  let isNotApplicableSelected = watch('siteOfMetastasis') === MDI.METASTASIS_SITE_NOT_APPLICABLE

  return (
    <div className={`${parentClass}input-field`}>
      <h5 className={`${parentClass}form-heading`}> {t(`${translatn}.metastaticDisease`)}</h5>
      <Row lg={4} md={4} sm={4}>
        <Col>
          <SelectInput
            name={`originalPrimarySiteOfDiagnosis`}
            id={'originalPrimarySite'}
            title={t(`${translatn}.originalPrimarySite`)}
            placeholder={t(`${translatn}.originalPrimarySitePlaceholder`)}
            disabled={false}
            optionsValueArray={_.getOptions(MDK.ORIGINAL_PRIMARY_SITE_OF_DIAGNOSIS, masterData)}
            required={true}
          />
        </Col>
        <Col>
          <SelectInput
            name={`siteOfMetastasis`}
            id={'siteOfMetastasis'}
            title={t(`${translatn}.siteOfMetastasis`)}
            placeholder={t(`${translatn}.siteOfMetastasisPlaceholder`)}
            disabled={false}
            optionsValueArray={_.getOptions(MDK.METASTASIS_SITE, masterData)}
            required={true}
            onCustomChange={(event: React.ChangeEvent<HTMLSelectElement>) =>  {              
              if(event.target.value===MDI.METASTASIS_SITE_NOT_APPLICABLE){resetField('dateOfMetastasis',{defaultValue:null}); resetField('ageAtMetastasis',{defaultValue:null})}
              AppUtil.resetOtherField(event.target.value, MDI.METASTASIS_SITE_OTHER_ORGAN, resetField, 'siteOfMetastasisOther')              
            }}
          />
        </Col>
        {watch('siteOfMetastasis') === MDI.METASTASIS_SITE_OTHER_ORGAN && (
            <Col>
              <TextInput
                type="text"
                name={`siteOfMetastasisOther`}
                id={'siteOfMetastasisOther'}
                title={t(`${translatn}.siteOfMetastasisOther`)}
                placeholder={t(`${translatn}.siteOfMetastasisOtherPlaceholder`)}
                disabled={false}
                readOnly={false}
                required={false}
              />
            </Col>
          )}
      </Row>
      <Row lg={4} md={4} sm={4}>
        <Col>
          <DateInput
            name={`dateOfMetastasis`}
            id={'dateOfMetastasis'}
            title={t(`${translatn}.dateOfMetastasis`)}
            placeholder={t(`common.datePlaceholder`)}
            disabled={false}
            readOnly={false}
            min={new Date(getDateOfBirth(dob)) ?? undefined}
            max={new Date()}
            required={!isNotApplicableSelected}
            onChange={(date: Date) => {
              resetField('ageAtMetastasis')
              if(date)setAgeAtEvent(dob, date, `ageAtMetastasis`, setValue)
            }}
            isClearable={isNotApplicableSelected}
          />
        </Col>
      
        <Col>
          <NumberInput
            name={`ageAtMetastasis`}
            id={'ageAtMetastasis'}
            title={t(`${translatn}.ageAtMetastasis`)}
            placeholder={t(`${translatn}.ageAtMetastasisPlaceholder`)}
            disabled={false}
            readOnly={true}
            required={!isNotApplicableSelected}
          />
        </Col>
      </Row>
    </div>
  )
}
