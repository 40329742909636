import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { useFormContext } from 'react-hook-form'
import { NumberInput, DateInput, TextInput } from '../../InputComponents'
import { setAgeAtEvent } from '../helpers'
import { AppUtil } from '../../../utils/app.utils'
import { getDateOfBirth } from '../helpers/AddSampleInfoHelpers'

export const PhysicalAssessment = () => {
  const { t } = useTranslation()
  const { setValue, getValues } = useFormContext()
  const translatn = 'sampleInfoForm'
  const parentClass = 'vcr-pp-sample-info-'
  const dob = getValues('dob')
  const [bmiData, setBmiData] = useState({
    weight: getValues('weight') ?? 0,
    height: getValues('height') ?? 0,
  })
  useEffect(() => {
    setBmiData({
      weight: getValues('weight') ?? 0,
      height: getValues('height') ?? 0,
    })
  }, [])

  useEffect(() => {
    const bmi = AppUtil.getBMI(bmiData.weight, bmiData.height)
    setValue('bmi', bmi)
  }, [bmiData])

  return (
    <div className={`${parentClass}input-field`}>
      <h5 className={`${parentClass}form-heading`}> {t(`${translatn}.physicalAssessment`)}</h5>
      <Row lg={4} md={4} sm={4}>
        <Col>
          <DateInput
            name={`dateOfPhysical`}
            id={'dateOfPhysical'}
            title={t(`${translatn}.dateOfPhysical`)}
            placeholder={t(`common.datePlaceholder`)}
            disabled={false}
            readOnly={false}
            required={true}
            min={new Date(getDateOfBirth(dob)) ?? undefined}
            max={new Date()}
            onChange={(date: Date) => {
              setAgeAtEvent(dob, date, `ageAtPhysical`, setValue)
            }}
          />
        </Col>
        <Col>
          <NumberInput
            name={`ageAtPhysical`}
            id={'ageAtPhysical'}
            title={t(`${translatn}.ageAtPhysical`)}
            placeholder={t(`${translatn}.ageAtPhysicalPlaceholder`)}
            disabled={true}
            readOnly={true}
            required={true}
          />
        </Col>
      </Row>
      <Row lg={4} md={4} sm={4}>
        <Col>
          <TextInput
            name={`weight`}
            id={'weight'}
            title={t(`${translatn}.weight`)}
            placeholder={t(`${translatn}.weightPlaceholder`)}
            disabled={false}
            readOnly={false}
            required={true}
            min={1}
            onChange={(event: any) => 
              { setBmiData({ weight: event.target.value.trim(), height: bmiData.height })}}
          />
        </Col>
        <Col>
          <TextInput
            name={`height`}
            id={'height'}
            title={t(`${translatn}.height`)}
            placeholder={t(`${translatn}.heightPlaceholder`)}
            disabled={false}
            readOnly={false}
            required={true}
            min={1}
            onChange={(event: any) => setBmiData({ weight: bmiData.weight, height: event.target.value.trim() })}
          
          />
        </Col>
        <Col>
          <NumberInput
            name={`bmi`}
            id={'bmi'}
            title={t(`${translatn}.bmi`)}
            placeholder={t(`${translatn}.bmiPlaceholder`)}
            disabled={true}
            readOnly={true}
            required={true}
          />
        </Col>
      </Row>
    </div>
  )
}
