import React from 'react'
import { useTranslation } from 'react-i18next'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import "./styles/logoutWarningModal.scss"


type LogoutWarningModalProps = {
  show: boolean
  handleLogout: () => void
  handleClose: () => void
}

export function LogoutWarningModal(props: Readonly<LogoutWarningModalProps>) {
  const { show, handleLogout, handleClose } = props
  const translatn = 'logoutModal'
  const parentClass = 'logout'
  const { t } = useTranslation()

  return (

    <Modal className={t(`${parentClass}`)} show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>{t(`${translatn}.title`)}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {t(`${translatn}.logoutText`)}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          {t(`${translatn}.no`)}
        </Button>
        <Button className={t(`${parentClass}-btn`)} onClick={handleLogout}>
          {t(`${translatn}.yes`)}
        </Button>
      </Modal.Footer>
    </Modal>

  )
}
