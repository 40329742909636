import React from 'react'
import Modal from 'react-bootstrap/Modal'
import { ViewSampleDetailsRow } from './ViewSampleDetailsRow'
import { isArray } from 'lodash'

type Props = {
    open: boolean
    handleClose: () => void
    subCategoryFields:any
    categoryName:string
}
export const ViewSampleMarkerDetailsContainer = (props: Props) => {
    const { open, handleClose,subCategoryFields,categoryName} = props
    const parentClass = 'vcr-pp-sample-info-'
    return (
        <>
            <Modal className={parentClass} show={open} onHide={handleClose} centered backdrop="static" keyboard={false} size='lg'>
                <Modal.Header closeButton>
                    <Modal.Title>{categoryName}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {subCategoryFields &&
                    (
                        Object.keys(subCategoryFields).map((index:any)=>{
                            return (
                                <> {isArray(subCategoryFields[index]) ?<> <h5>{index}</h5> <ViewSampleDetailsRow key={index} index={index} rowArr={subCategoryFields[index]} parentClass={parentClass} /></>
                                : (<ViewSampleDetailsRow key={index} index={index} rowArr={[subCategoryFields[index]]} parentClass={parentClass} />)}                             
                                </>
                            )
                           
                        })
                    )

                    }
                </Modal.Body>
            </Modal>
        </>
    )
}
