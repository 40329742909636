import React, { isValidElement } from 'react'
import Col from 'react-bootstrap/Col'
import './styles/ViewDetailsColumn.scss'

type Value = string | Date | null | undefined | boolean | number | Element

type Props = {
  label: string
  label2?: string
  value: Value
  parentClass: string
  helperText?: string
}

export const ViewDetailsColumn = (props: Props) => {
  const { label, label2, value, helperText} = props
  const childClass = 'view-details-column'
  return (
    <Col lg={2} md={2} sm={2} className={`${childClass}`} key={label}>
      <div className={`${childClass}-label`}>{label}</div>
      <div className={`${childClass}-label`}>{label2 ?? ''}</div>
      { isValidElement(value) ? value :
          <div className={`${childClass}-value`}>{value || value === 0  ? value?.toString() : '-'}</div>
      }     
      {helperText && <div className={`${childClass}-helper-text`}>{helperText}</div> }
    </Col>
  )
}
