import React from 'react'
import { useTranslation } from 'react-i18next'
import Form from 'react-bootstrap/Form'
import { access, renderRequiredFieldLabel } from './InputComponentHelpers'
import { useFormContext } from 'react-hook-form'

/* type TextInputFieldsTypes = {
  name: string
  id: string
  title: string
  required: boolean
} */

export const TextInput = function (props: any) {
  const { name, id, title, required, ...otherProps } = props
  const { t } = useTranslation()
  const formMethods = useFormContext()
  const {
    register,
    formState: { errors },
  } = formMethods
  const error = access(name, errors)
  
  let lableValue = title
  if (required) lableValue = renderRequiredFieldLabel(title)
  if (props?.type === 'hidden') lableValue = ''
  
  return (
    <>
      {lableValue && <Form.Label htmlFor={id}>{lableValue}</Form.Label>}
      <Form.Control
          as="input"
          id={id}
          autoComplete='off'
          {...register(name)}
          isInvalid={error}
          {...otherProps}
        />
      <Form.Control.Feedback type="invalid">{t(error?.message)}</Form.Control.Feedback>
    </>
  )
}
