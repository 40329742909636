import { z } from 'zod'
import { schemaHelpers } from './RegisterPatientHelpers'
import { isEmpty } from 'lodash'

const {
  requiredSelect,
  number,
  requiredEmail,
  requiredStr,
  requiredStrWithMinMax,
  requiredPhone,
  string,
  emptyAlphabetText,
  nullableStr,
  nullableAddress,
  requiredDate,
  requiredConsent,
  requiredName,
  requiredPincode,
  requiredAddress,
  requiredNumeric
} = schemaHelpers

const MAX_FILE_SIZE = 5000000
// const ACCEPTED_IMAGE_TYPES = ['image/jpeg', 'image/jpg', 'image/png', 'image/webp']

export const RegisterPatientFormSchema = z.object({
  registrationDate: requiredDate,
  siteID: string,
  siteName: string,
  consentProvided: requiredConsent,
  consentFile: nullableStr,
  firstName: requiredName,
  middleName: emptyAlphabetText,
  lastName: requiredName,
  gender: requiredSelect,
  genderOther: nullableStr,
  dob: requiredDate,
  age: number,
  race: requiredSelect,
  raceOther: nullableStr,
  ethnicity: requiredSelect,
  ethnicityOther: nullableStr,
  nationality: requiredSelect,
  phoneNumber: requiredPhone,
  email: requiredEmail,
  diagnosis: requiredStrWithMinMax(1, 100),
  addressLine1: requiredAddress,
  addressLine2: nullableAddress,
  state: requiredSelect,
  city: requiredStr,
  pinCode: requiredPincode,
  file: z.any().nullable().optional(),
  uhid: requiredNumeric
}).superRefine((values: any, ctx) => {
  if (!values.consentFile) {
    if ((values.consentProvided === "true" || values.consentProvided === true) && isEmpty(values['file'])) {
      ctx.addIssue({
        message: `File is required`,
        code: z.ZodIssueCode.custom,
        path: ['file'],
      });
    }
  } else if (values.consentFile && (values.consentProvided === "true" || values.consentProvided === true) && !isEmpty(values['file'])) {
    const file = values.file?.[0];
    if (file?.size && file.size > MAX_FILE_SIZE) {
      ctx.addIssue({
        message: `File size exceeds the limit of 5MB.`,
        code: z.ZodIssueCode.custom,
        path: ['file']
      });
    }
  }
});
