import axios from 'axios'
import { Buffer } from 'buffer'
import { apiRoutes } from '../utils/routes'
import { AppUtil } from '../utils/app.utils'
import { IUserForm } from '../models/Auth'

export const getUserDetails = async (page: any) => {
  const response = await axios.post(apiRoutes.user.list, { page: Number(page) }, AppUtil.getCommonAPIConfig())
  return response
}

function userFormpayload(userFormdata: IUserForm) {
  let encodedPW = Buffer.from(userFormdata.password, 'utf8').toString('base64')
  return {
    id: userFormdata.userId,
    firstName: userFormdata.firstName,
    lastName: userFormdata.lastName,
    userName: userFormdata.username,
    password: encodedPW,
    userEmail: userFormdata.email,
    userState: userFormdata.userStatus,
    versionId: '1',
    roleId: userFormdata.roleId,
    siteId: userFormdata.siteId,
    siteName: userFormdata.siteName,
    employeeId: userFormdata.employeeId,
  }
}

export const addUser = async (userFormdata: IUserForm) => {
  const response = await axios.post(
    apiRoutes.user.add,
    userFormpayload(userFormdata),
    AppUtil.getCommonAPIConfig(),
  )
  return response
}

export const editUser = async (userFormdata: IUserForm) => {
  const response = await axios.put(
    apiRoutes.user.edit,
    userFormpayload(userFormdata),
    AppUtil.getCommonAPIConfig(),
  )
  return response
}

export const getUserDetailsByUsername = async (username: string) => {
  const response = await axios.post(
    apiRoutes.user.detailsByUsername,
    { userName: username },
    AppUtil.getCommonAPIConfig(),
  )
  return response
}
