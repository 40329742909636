import * as React from 'react'
import { useTranslation } from 'react-i18next'
import './styles/stepper.scss'

type Props = {
  selectedTab: number
  sampleId: string | undefined
  handleStepperClick: (index: number) => void
}

export default function Stepper({ selectedTab, sampleId, handleStepperClick }: Props) {
  const { t } = useTranslation(),
    translatn = 'sampleInfoForm.tabs.'
  const steps = [
    t(`${translatn}identity`),
    t(`${translatn}diagnosis`),
    t(`${translatn}labWork`),
    t(`${translatn}clinicalSurgery`),
    t(`${translatn}treamentStatus`),
    t(`${translatn}sampleTestDetailsAndShipment`),
    t(`${translatn}barcode`),

  ]
  function getStepClass(step: any) {
    let cls = 'step'
    if (selectedTab === step) {
      cls += ' step-active'
    } else if (selectedTab > step) {
      cls += ' step-done'
    } else {
      cls += ' step-inactive'
    }

    if(sampleId && step === steps.length - 1) {
      cls += ' step-disabled'
    } else if (sampleId) {
      cls += ' step-cursor-pointer'
    }
    return cls
  }

  const handleClick = (index: number) => {
    handleStepperClick(index)
  }

  return (
    <div className="steps-container p-3 bg-white text-dark container-fluid">
      {steps.map((label, index) => (
        <div className={getStepClass(index)} key={index} onClick={() => handleClick(index)}>
          <div>
            <div className="circle"></div>
          </div>
          <div className="label">{label}</div>
          {index < steps.length - 1 && <div className="line"></div>}
        </div>
      ))}
    </div>
  )
}
