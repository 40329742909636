import React from 'react'
import { Routes, Route, BrowserRouter } from 'react-router-dom'
import './App.scss'
import Layout from '../components/Layout/Layout'
import { appRoutes } from '../utils/routes'
import { Login } from '../components/Login'
import { AuthProvider, APIProvider } from '../contexts'
import { PrivateRoute } from '../components/PrivateRoute'
import { RegisterPatientContainer } from '../components/RegisterPatient'
import { SampleInformationFormContainer } from '../components/SampleInformationForm'
import { UnauthorizedPage } from '../components/UnauthorizedPage'
import { NotFoundPage } from '../components/NotFoundPage'
import { ViewSampleContainer } from '../components/ViewSample'
import { PatientTableInfo } from '../components/PatientList'
import { PatientDetailsContainer } from '../components/ViewPatient'
import { MasterDataProvider } from '../contexts/MasterDataContext'
import { UsersTableInfo } from '../components/UsersList/UsersList'
import { HandwrittenNotesContainer, ViewHandwrittenNotesContainer } from '../components/HandwrittenNotes'

const renderRoute = (component: any, path: string) => {
  return <PrivateRoute path={path}>{component}</PrivateRoute>
}

function App(): React.ReactElement {
  const privateRoutes = [
    { path: appRoutes.home, element: <PatientTableInfo /> },
    { path: appRoutes.registerPatient, element: <RegisterPatientContainer /> },
    { path: appRoutes.addSample, element: <SampleInformationFormContainer /> },
    { path: appRoutes.editSample, element: <SampleInformationFormContainer /> },
    { path: appRoutes.editPatient, element: <RegisterPatientContainer /> },
    { path: appRoutes.unauthorized, element: <UnauthorizedPage /> },
    { path: appRoutes.viewSample, element: <ViewSampleContainer /> },
    { path: appRoutes.users, element: <UsersTableInfo /> },
    { path: appRoutes.viewPatientDetails, element: <PatientDetailsContainer /> },
    { path: appRoutes.addHandwrittenNotes, element: <HandwrittenNotesContainer /> },
    { path: appRoutes.editHandwrittenNotes, element: <HandwrittenNotesContainer /> },
    { path: appRoutes.viewHandwrittenNotes, element: <ViewHandwrittenNotesContainer />}
  ]

  return (
    <div>
      <BrowserRouter>
        <AuthProvider>
          <APIProvider>
            <MasterDataProvider>
              <Layout>
                <Routes>
                  {privateRoutes.map((route) => {
                    const { path, element } = route
                    return <Route path={path} element={renderRoute(element, path)} key={path} />
                  })}

                  <Route path={appRoutes.login} element={<Login />} />
                  <Route path={'*'} element={<NotFoundPage />} />
                </Routes>
              </Layout>
            </MasterDataProvider>
          </APIProvider>
        </AuthProvider>
      </BrowserRouter>
    </div>
  )
}

export default App
