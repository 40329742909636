import React, { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { useFormContext } from 'react-hook-form'

import {
  MultiSelectDropdown,
  NumberInput,
  TextInput,
  DateInput,
  NumberInputWithAddOn,
  CheckboxInput,
} from '../../InputComponents'
import { setAgeAtEvent } from '../helpers'
import { MASTER_DATA_KEYS as MDK } from '../../../utils/constants'
import { MasterDataContext } from '../../../contexts'
import { AppUtil as _ } from '../../../utils/app.utils'
import { DependentFields } from './DependentFields'
import { MultiSelectItem } from '../../../models/Sample'
import { getDateOfBirth } from '../helpers/AddSampleInfoHelpers'

export const TumorMarkers = () => {
  const { t } = useTranslation()
  const { setValue, getValues, watch, resetField, clearErrors } = useFormContext()
  const { masterData } = useContext(MasterDataContext)
  const translatn = 'sampleInfoForm'
  const parentClass = 'vcr-pp-sample-info-'
  const dob = getValues('dob')

  const handleRemoveMarkerType = (value: string | number) => {
    setValue(
      'markerType',
      getValues('markerType')?.filter(
        (item: { value: string; label: string }) => item.value?.toString() !== value?.toString(),
      ),
    )
    resetField(`markerTypeDetails.type${value}`)
  }

  const removeProperty = (object: any, value: string | number) => {
    let childKey = `type${value}`
    delete object[childKey];
    return object;
  }

  const handleRemoveBiomarkerTest = (value: string | number) => {
    setValue(
      'biomarkerTestCategory',
      getValues('biomarkerTestCategory')?.filter(
        (item: { value: string; label: string }) => item.value?.toString() !== value?.toString(),
      ),
    )
    setValue('biomarkerTestSubCategory', removeProperty(getValues('biomarkerTestSubCategory'), value));
    setValue('biomarkerTestSubCategoryFields', removeProperty(getValues('biomarkerTestSubCategoryFields'), value));
  }

  const getBiomarkerTestCategoryValues = watch('biomarkerTestCategory')
  const getBiomarkerTestSubCategoryValues = watch('biomarkerTestSubCategory');

  const isBiomarkerBeyond4Months = watch('isBiomarkerBeyond4Months');

  useEffect(() => {
    if (isBiomarkerBeyond4Months !== 'true') {
      resetField('biomarkerBeyond4MonthsDate')
      clearErrors('biomarkerBeyond4MonthsDate')
    }
  }, [isBiomarkerBeyond4Months, resetField]);


  return (
    <div className={`${parentClass}input-field`}>
      <h5 className={`${parentClass}form-heading`}> {t(`${translatn}.tumorMarkers`)}</h5>
      <Row>
        <Col>
          <MultiSelectDropdown
            name={`biomarkerTestCategory`}
            id={'biomarkerTestCategory'}
            title={t(`${translatn}.biomarkerTestCategory`)}
            placeholder={getBiomarkerTestCategoryValues.length > 0 ? "" : t(`${translatn}.biomarkerTestCategoryPlaceholder`)}
            disabled={false}
            required={false}
            optionsValueArray={_.getOptions(MDK.BIOMARKER_TEST, masterData)}
            hideSelectedValues={false}
            onCustomRemove={handleRemoveBiomarkerTest}
            tooltipText={t(`${translatn}.testDetailsTooltip`)}
          />
        </Col>
        <Col>
          <CheckboxInput
            name={`isBiomarkerBeyond4Months`}
            id={`isBiomarkerBeyond4Months`}
            title={''}
            disabled={false}
            inline={true}
            required={false}
            checkboxValueArray={[{ label: `Beyond 4 months`, value: true }]}
          />
          {(watch('isBiomarkerBeyond4Months') === "true" || watch('isBiomarkerBeyond4Months') === true) && <DateInput
            name={`biomarkerBeyond4MonthsDate`}
            id={'biomarkerBeyond4MonthsDate'}
            title=''
            placeholder={t(`common.datePlaceholder`)}
            disabled={false}
            readOnly={false}
            max={_.fourMonthsFromNow()}
            required={false}
            isClearable={true} />}
        </Col>
      </Row>
      <DependentFields
        name={'biomarkerTest'}
        categories={getBiomarkerTestCategoryValues}
        subCategories={getBiomarkerTestSubCategoryValues}
      />
      <Row lg={4} md={4} sm={4}>
        <Col>
          <TextInput
            type="text"
            name={`specifyOtherTumor`}
            id={'otherTumor'}
            title={t(`${translatn}.otherTumor`)}
            placeholder={t(`${translatn}.otherTumorPlaceholder`)}
            disabled={false}
            readOnly={false}
            required={false}
          />
        </Col>
        <Col>
          <DateInput
            name={`dateOfResults`}
            id={'dateOfResults'}
            title={t(`${translatn}.dateOfResults`)}
            placeholder={t(`common.datePlaceholder`)}
            disabled={false}
            readOnly={false}
            min={new Date(getDateOfBirth(dob)) ?? undefined}
            max={new Date()}
            required={false}
            onChange={(date: Date) => {
              setAgeAtEvent(dob, date, `ageAtResults`, setValue)
            }} isClearable={true} />
        </Col>
        <Col>
          <NumberInput
            name={`ageAtResults`}
            id={'ageAtResult'}
            title={t(`${translatn}.ageAtResult`)}
            placeholder={t(`${translatn}.ageAtResultPlaceholder`)}
            disabled={false}
            readOnly={true}
            required={false}
          />
        </Col>
      </Row>

      <Row lg={4} md={4} sm={4}>
        <Col >
          <MultiSelectDropdown
            name={`markerType`}
            id={'markerType'}
            title={t(`${translatn}.markerType`)}
            placeholder={t(`${translatn}.markerTypePlaceholder`)}
            disabled={false}
            required={false}
            optionsValueArray={_.getOptions(MDK.MARKER_TYPE, masterData)}
            hideSelectedValues={false}
            onCustomRemove={handleRemoveMarkerType}
          />
        </Col>
      </Row>
      <Row >
        {watch('markerType')
          ?.filter((item: MultiSelectItem) => item.textBox)
          ?.map((mktp: MultiSelectItem) => {
            return (
              <Col lg={5} key={mktp.value}>
                <NumberInputWithAddOn
                  name={`markerTypeDetails.type${mktp.value}`}
                  id={`markerTypeDetails.${mktp.value}`}
                  title={`${mktp.label}`}
                  placeholder={t(`${translatn}.markerTypeDetailsPlaceholder`)}
                  disabled={false}
                  readOnly={false}
                  required={false}
                  helperText={mktp.helperText}
                />
              </Col>
            )
          })}
      </Row>
    </div>
  )
}


