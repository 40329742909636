import React, { ReactElement } from 'react';
import * as _ from 'lodash'
import './styles/InputComponentHelpers.scss'


export const access = (path:string, object : any) => {
    return  _.isEmpty(object) ? null : path.split('.').reduce((o, i) => o?.[i], object) 
}

 export const renderRequiredFieldLabel = (label: string): ReactElement => {
    return (
      <span>
        {label}
        <span className="req-field-label">*</span>
      </span>
    );
  };