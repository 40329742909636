import React from 'react'
import { MetastaticDiagnosis } from './MetastaticDiagnosis'
import { ImagingData } from './ImagingData'
import { CancerDiagnosis } from './CancerDiagnosis'
import { Sample } from '../../../models/Sample'

type Props = {
  sampleDetails: Sample | null
}

export const TabTwo = ({ sampleDetails }: Props) => {
  return (
    <div>
      <CancerDiagnosis sampleDetails={sampleDetails} />
      <MetastaticDiagnosis  sampleDetails={sampleDetails} />
      <ImagingData  sampleDetails={sampleDetails} />
    </div>
  )
}
