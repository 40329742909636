import React from 'react'
import Container from 'react-bootstrap/Container'
import { useTranslation } from 'react-i18next'
import { TabProps } from '../../../models/Sample'
import { ViewSampleDetailsRow } from './ViewSampleDetailsRow'
import { AppUtil } from '../../../utils/app.utils'
import _ from 'lodash'
import { MASTER_DATA_ID as MDI } from '../../../utils/constants'

export const RadiationTreatment = (props: TabProps) => {
  const { sampleDetails } = props
  const { t } = useTranslation()

  const translatn = 'viewSample.'
  const parentClass = ''
  const detailsArr = [
    {
      label: t(`${translatn}therapyDone`),
      value: AppUtil.convertBooleanToStr(sampleDetails?.radiationTherapy ?? null),
      show: true,
    },
    {
      label: t(`${translatn}originalPrimarySite`),
      value: sampleDetails?.originalSiteOfDiagnosisForRadiationTherapy,
      show: sampleDetails?.radiationTherapy == MDI.RADIATION_THERAPY_OPTION_YES,
    },
    {
      label: t(`${translatn}disease`),
      value: sampleDetails?.radiationDisease,
      show: sampleDetails?.radiationTherapy == MDI.RADIATION_THERAPY_OPTION_YES,
    },
    {
      label: t(`${translatn}regimen`),
      value: sampleDetails?.radiationRegimen,
      show: sampleDetails?.radiationTherapy == MDI.RADIATION_THERAPY_OPTION_YES,
    },
    {
      label: t(`${translatn}modality`),
      value: sampleDetails?.radiationModality,
      show: sampleDetails?.radiationTherapy == MDI.RADIATION_THERAPY_OPTION_YES,
    },
    {
      label: t(`${translatn}dose`),
      value: sampleDetails?.radiationDose,
      show: sampleDetails?.radiationTherapy == MDI.RADIATION_THERAPY_OPTION_YES,
    },
    {
      label: t(`${translatn}start`),
      value: sampleDetails?.radiationStart,
      show: sampleDetails?.radiationTherapy == MDI.RADIATION_THERAPY_OPTION_YES,
    },
    {
      label: t(`${translatn}stop`),
      value: sampleDetails?.radiationStop,
      show: sampleDetails?.radiationTherapy == MDI.RADIATION_THERAPY_OPTION_YES,
    },
    
  ]

  const data = detailsArr.filter((details) => details.show === true)

  const rowCount = _.chunk(data, 5)

  return (
    <Container fluid>
      <h5 className="fw-bold "> {t(`${translatn}radiationTreatment`)}</h5>
      {rowCount.map((row, index) => (
        <ViewSampleDetailsRow key={index} index={index} rowArr={rowCount[index]} parentClass={parentClass} />
      ))}
    </Container>
  )
}
