import React, { useContext } from 'react'
import Container from 'react-bootstrap/Container'
import { useTranslation } from 'react-i18next'
import { TabProps } from '../../../models/Sample'
import { ViewSampleDetailsRow } from './ViewSampleDetailsRow'
import { SampleStatusMasterData } from '../../../utils/sampleStatusMasterData'
import { MASTER_DATA_ID as MDI, MASTER_DATA_KEYS as MDK } from '../../../utils/constants'
import { AppUtil } from '../../../utils/app.utils'
import { MasterDataContext } from '../../../contexts'

export const SampleInformation = (props: TabProps) => {
  const { sampleDetails } = props
  const { t } = useTranslation()
  const translatn = 'viewSample.'
  const { masterData } = useContext(MasterDataContext)
  const parentClass = ''
  const showFFPEFields = sampleDetails?.typeOfSample === MDI.SAMPLE_TYPE_FFPE
  const showHAndEFields = sampleDetails?.hAndeStaining?.toString() === MDI.H_E_OPTION_YES
  const showBloodFields = MDI.SAMPLE_TYPE_BLOOD.includes(sampleDetails?.typeOfSample ?? '')

  const detailsArr = [
    {
      label: t(`${translatn}sampleCollectionDate`),
      value: sampleDetails?.sampleCollectionDate,
      row: 1,
    },
    {
      label: t(`${translatn}sampleCollectedBy`),
      value: sampleDetails?.sampleCollectedBy,
      row: 1,
    },
    {
      label: t(`${translatn}employeeId`),
      value: sampleDetails?.employeeId,
      row: 1,
    },
    {
      label: t(`${translatn}sampleType`),
      value: AppUtil.getLabel(MDK.SAMPLE_TYPE, masterData, sampleDetails?.typeOfSample),
      row: 1,
    },
    {
      label: t(`${translatn}sampleStatus`),
      value: t(`sampleStatus.${SampleStatusMasterData.getValue(sampleDetails?.sampleStatus)}`),
      row: 1,
    },
    {
      label: t(`${translatn}replicateNumber`),
      value: sampleDetails?.replicateNumber,
      row: 1,
    },
  ]
  const ffpeRelatedFields = [
    {
      label: t(`${translatn}blockNumber`),
      value: sampleDetails?.blockNumber,
      row: 1,
    },
    {
      label: t(`${translatn}fixativeUsed`),
      value: sampleDetails?.fixativeUsed,
      row: 1,
    },
    {
      label: t(`${translatn}fixationDuration`),
      value: sampleDetails?.fixationDuration,
      row: 1,
    },
    {
      label: t(`${translatn}coldIschemiaTime`),
      value: sampleDetails?.coldIschemiaTime,
      row: 1,
    },
    {
      label: t(`${translatn}hAndeStaining`),
      value: AppUtil.getLabel(MDK.H_E_STAINING, masterData, sampleDetails?.hAndeStaining),
      row: 1,
    },
  ]

  const hAndERelatedFields = [
    {
      label: t(`${translatn}tumorTissue`),
      value: sampleDetails?.tumorTissue,
      row: 1,
    },
    {
      label: t(`${translatn}necrosisTissues`),
      value: sampleDetails?.necrosisTissues,
      row: 1,
    },
  ]

  const bloodRelatedFields = [
    {
      label: t(`${translatn}tubeType`),
      value: sampleDetails?.tubeType,
      row: 1,
    },
  ]

  const rowCount = [1]

  return (
    <Container fluid>
      <h5 className="fw-bold pt-3"> {t(`${translatn}sampleInformation`)}</h5>
      {rowCount.map((row, index) => (
        <ViewSampleDetailsRow
          key={row}
          index={index}
          rowArr={detailsArr.filter((details) => details.row == row)}
          parentClass={parentClass}
        />
      ))}
      {showFFPEFields
        ? rowCount.map((row, index) => (
            <ViewSampleDetailsRow
              key={row}
              index={index}
              rowArr={ffpeRelatedFields.filter((details) => details.row == row)}
              parentClass={parentClass}
            />
          ))
        : null}
      {showHAndEFields
        ? rowCount.map((row, index) => (
            <ViewSampleDetailsRow
              key={row}
              index={index}
              rowArr={hAndERelatedFields.filter((details) => details.row == row)}
              parentClass={parentClass}
            />
          ))
        : null}
      {showBloodFields
        ? rowCount.map((row, index) => (
            <ViewSampleDetailsRow
              key={row}
              index={index}
              rowArr={bloodRelatedFields.filter((details) => details.row == row)}
              parentClass={parentClass}
            />
          ))
        : null}
    </Container>
  )
}
