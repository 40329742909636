import { zodResolver } from '@hookform/resolvers/zod'
import React, { useContext, useEffect, useState } from 'react'
import { z } from 'zod'
import { useForm, FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Form, Container, Button, Row, Col } from 'react-bootstrap'
import { AuthResponse } from '../../models/Auth'
import { UserLoginFormSchema, UserLoginDefaultData } from './helpers'
import { getAuthToken } from '../../services'
import { appRoutes } from '../../utils/routes'
import { TextInput } from '../InputComponents'
import { AuthContext } from '../../contexts'
import { AxiosError, AxiosResponse } from 'axios'
import { ArrowRightShort, LockFill, PersonCircle } from 'react-bootstrap-icons'
import './Login.scss'
import { useNavigate } from 'react-router-dom'

type ValidationSchema = z.infer<typeof UserLoginFormSchema>

export function Login() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const auth = useContext(AuthContext)
  
  const parentClass = 'vcr-pp-usr-login'
  const [loginError, setLoginError] = useState('')

  useEffect(() => {
    if(auth?.authenticatedUser) {
      navigate(appRoutes.home)
    }
  }, [auth])

  const getTranslatedString = (path: string): string => {
    const translatn = 'userLogin'
    return t(`${translatn}.${path}`)
  }

  const formMethods = useForm<ValidationSchema>({
    resolver: zodResolver(UserLoginFormSchema),
    mode: 'all',
    defaultValues: UserLoginDefaultData,
  })
 
  const onSubmit = async (data: ValidationSchema) => {
    setLoginError('')
    await getAuthToken(data.userName, data.password)
      .then(
        (res: AxiosResponse) => {
          if (res.status == 200) {
            auth.login(res.data.accessToken, res.data.refreshToken, appRoutes.home)
          }
        },
        (error: AxiosError<AuthResponse>) => {
          setLoginError(error?.response?.data?.message ?? '')
        },
      )
      .catch((error) => {
        setLoginError(error?.message)
      })
  }
  const usernameTitle = (
    <>
      <PersonCircle color={'#066093'} size={'18px'} /> {getTranslatedString('username')}
    </>
  )
  const passwordTitle = (
    <>
      <LockFill color={'#066093'} /> {getTranslatedString('password')}
    </>
  )
  return (
    <div className="vcr-pp-login-container">
      <FormProvider {...formMethods}>
        <Form className={parentClass} noValidate onSubmit={formMethods.handleSubmit(onSubmit)}>
          <Container>
            <Row className={parentClass + '-heading'}>
              <h1 className={parentClass + '-sub-heading'}>{getTranslatedString('login')}</h1>
            </Row>
            <Row>
              <hr className={parentClass + '-horizontal-line'} />
            </Row>
            <Row>
              <Col>
                <TextInput
                  type="text"
                  name="userName"
                  id="usr-login-username"
                  title={usernameTitle}
                  placeholder={getTranslatedString('usernamePlaceholder')}
                  disabled={false}
                  readOnly={false}
                  required={true}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <TextInput
                  type="password"
                  name="password"
                  id="usr-login-password"
                  title={passwordTitle}
                  placeholder={getTranslatedString('passwordPlaceholder')}
                  disabled={false}
                  readOnly={false}
                  required={true}
                  onChange={(e:React.ChangeEvent<HTMLInputElement>) => {setLoginError('');formMethods.setValue('password', e.target.value)}}
                  onPaste={(e:React.ChangeEvent<HTMLInputElement>) => e.preventDefault()}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="invalid-login-message">{loginError}</div>
              </Col>
            </Row>
            <Row>
              <Col>
                <Button variant="primary" type="submit" className={parentClass + '-submit-btn'} size={'lg'}>
                  {getTranslatedString('loginButton')}
                  <ArrowRightShort size={'26'} />
                </Button>
              </Col>
            </Row>
          </Container>
        </Form>
      </FormProvider>
    </div>
  )
}
