import React from 'react'
import Form from 'react-bootstrap/Form'
import { access, renderRequiredFieldLabel } from './InputComponentHelpers'
import { useTranslation } from 'react-i18next'
import { useFormContext } from 'react-hook-form'

/* type FileUploadNoFormProps = {
  name: string
  id: string
  title: string
  placeholder?: string
  defaultValue?: string
  disabled: boolean
  onChange?: any
  readOnly: boolean
} */

export const FileUploadNoForm = (props: any) => {
  const { name, id, title, required, disabled, readOnly, ...otherProps } = props
  const { t } = useTranslation()
  const formMethods = useFormContext()
  if (!formMethods) {
    return <Form.Control
    type="file"
    id={id}
    disabled={disabled}
    readOnly={readOnly}
    name={name}
    {...otherProps}
  />; 
  }
  const {
    register,
    formState: { errors },
  } = formMethods
  const error = access(name, errors)
  return (
    <>
      {title && <Form.Label htmlFor={id}>{required ? renderRequiredFieldLabel(title) : title}</Form.Label>}
      <Form.Control
        type="file"
        id={id}
        disabled={disabled}
        readOnly={readOnly}        
        {...register(name)}
        name={name}
        isInvalid={error}
        {...otherProps}
      />
      <Form.Control.Feedback type="invalid">{t(error?.message)}</Form.Control.Feedback>
    </>
  )
}
