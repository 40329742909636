import React, { useState } from 'react'
import { ReferralDetails } from './ReferralDetails'
import { PhysicalAssessment } from './PhysicalAssessment'
import { FamilyHistory } from './FamilyHistory'
import { History } from './History'
import { Col, Row } from 'react-bootstrap'

export const TabOne = () => {
  return (
    <Row>
      <Col>
          <ReferralDetails />
          <PhysicalAssessment />
          <History />
          <FamilyHistory />
      </Col>
    </Row>

  )
}