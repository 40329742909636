import React from 'react'
import { QRcode } from './QRcode'
import { TabProps } from '../../../models/Sample'

export const TabSeven = ({sampleDetails}: TabProps) => {
  return (
    <div>
    <QRcode sampleDetails={sampleDetails}/>
    </div>
  )
}
